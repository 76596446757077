import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Service
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { HelperService } from 'src/app/shared/services/helper.service';

// Model
import { LoginResponseDetails } from 'src/app/shared/models/auth.model';


// Environment
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private helperService: HelperService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve) => {
      const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');
      let token;
      // if (isRememberMeChecked === 'true') {
        token = localStorage.getItem('token');
      // } else {
      //   token = sessionStorage.getItem('token');
      // }

      const autoLogin = next && next.queryParams && next.queryParams.autoLogin &&
        JSON.parse(next.queryParams.autoLogin);

      if (autoLogin) {
        const payload: any = {
          userName: next && next.queryParams && next.queryParams.userName,
          password: this.helperService.handleConvertBASE64Encode(
            next && next.queryParams && next.queryParams.password
          ),
          userPoolId: contractorEnvironment.userPoolId,
          isContractor: 1
        };
    
        // if (this.portalName === ROLE_CONSTANTS.CONTRACTOR) {
          // payload.isContractor = 1;
        // } else if (this.portalName === ROLE_CONSTANTS.CHANNEL) {
        //   payload.isChannel = 1;
        // }
        this.authService.handleLogin(payload).subscribe(
          (response: LoginResponseDetails) => {
            // const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');
            // if (isRememberMeChecked === 'true') {
              const loginResponse = response && response.responseData;
              localStorage.setItem('token', loginResponse && loginResponse.idToken);
              // localStorage.setItem('authTime', response.authTime.toString());
              localStorage.setItem('userName', loginResponse && loginResponse.userName);
            // } else {
            //   sessionStorage.setItem('token', response.token);
            //   sessionStorage.setItem('authTime', response.authTime.toString());
            //   sessionStorage.setItem('userName', response.userName);
            // }
            // this.router.navigate(['/' + this.routeLink]);
            // this.helperService.setSelectedTab(this.routeLink);
            // this.isActionBtnLoading = false;
            // this.helperService.showToaster(ToasterType.SUCCESS, response.status);
            resolve(true);
          }, (error) => {
            // this.isActionBtnLoading = false;
            // const message = error.error && error.error.status;
            // this.helperService.showToaster(ToasterType.ERROR, message);
            resolve(false);
          }
        );
      } else if (token) {
        resolve(true);
      } else {
        this.router.navigate(['/login']);
        resolve(false);
      }
    });
      // return true;
  }

}
