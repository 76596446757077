import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-symbol',
  templateUrl: './loading-symbol.component.html',
  styleUrls: ['./loading-symbol.component.scss']
})
export class LoadingSymbolComponent implements OnInit {

  @Input() style: string;
  @Input() animation: string;

  constructor() { }

  ngOnInit() {
    // debugger;
  }

}
