
import { Email, Phone, Address } from 'src/app/shared/models/common.model';

export class ContractorDetails {
    contractorId: number;
    awsContractorId: string;
    contractorName: string;
    contactPerson: string;
    logoUrls: string[];
    logoName: string[];
    docUrl: any[];
    docName: any[];
    firmName?: string;
    website?: string;
    phone: Phone[];
    email: Email[];
    addresses: Address[];
    permissionId: number;
    permissionType?: any;
    resourceTypeId: number;
    resourceType: boolean;
    totalMelis: number;
    totalCustomers: number;
    operatingSince: number;
    numOfTechnicians: number;
    dealerType: string;
    notificatonsStatusModel:Notifications;
    channelId:string;
    channelName:string;
    constructor(data) {
        this.contractorId = data.contractorId;
        this.awsContractorId = data.awsContractorId;
        this.contractorName = data.contractorName;
        this.contactPerson = data.contactPerson;
        this.logoUrls = data.logoUrl;
        this.logoName = data.logoName;
        this.docName = data.docName;
        this.docUrl = data.docUrl;
        this.firmName = data.firmName;
        this.website = data.website;
        this.phone = data.phone && data.phone.length &&
            data.phone.map((value, index) => new Phone(value));
        this.email = data.email && data.email.length &&
            data.email.map((value, index) => new Email(value));
        this.addresses = data.addresses && data.addresses.length &&
            data.addresses.map((value, index) => new Address(value));
        this.permissionId = data.permissionId;
        this.permissionType = data.permissionType;
        this.resourceTypeId = data.resourceTypeId;
        this.resourceType = data.resourceType;
        this.totalMelis = data.totalMelis;
        this.totalCustomers = data.totalConsumers;
        this.operatingSince = data.operatingSince;
        this.numOfTechnicians = data.numOfTechnicians;
        this.dealerType = data.dealerType;
        this.notificatonsStatusModel=data.notificatonsStatusModel&& new Notifications(data.notificatonsStatusModel);
        this.channelId= data.channelId;
        this.channelName=data.channelName;
    }
}

export class Notifications{
    majorAlertsEmail:boolean;
    majorAlertsSms:boolean;
    minorAlertsEmail:boolean;
    minorAlertsSms:boolean;

    constructor(data)
    {
        this.majorAlertsEmail=data.majorAlertsEmail;
        this.majorAlertsSms=data.majorAlertsSms;
        this.minorAlertsEmail=data.minorAlertsEmail;
        this.minorAlertsSms=data.minorAlertsSms;
    }
}

export class ContractorDetailsResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: ContractorDetails;
    requestData?: any;

    constructor(data) {
      this.statusCode = data.statusCode;
      this.statusMessage = data.statusMessage;
      this.statusInfo = data.statusInfo;
      this.responseData = data.responseData && new ContractorDetails(data.responseData);
      this.requestData = data.requestData;
    }
}

export class ContractorListResponseDetails {
    page: number;
    size: number;
    contractorList: ContractorDetails[];
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;

    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.contractorList = data.content && data.content.length &&
            data.content.map((value, index) => new ContractorDetails(value));
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
    }
}

export class ContractorListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    contractorListResponse: ContractorListResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.contractorListResponse = data.responseData &&
            new ContractorListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class CreateContractorDetails {
    msg: string;
    role: string;
    awsContractorId: string;
    contractorId: string;

    constructor(data) {
        this.msg = data.msg;
        this.role = data.role;
        this.awsContractorId = data.awsContractorId;
        this.contractorId = data.contractorId;
    }
}

export class CreateContractorResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    createContractorResponse: CreateContractorDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.createContractorResponse = data.responseData &&
            new CreateContractorDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class Dealer {
    dealer: string;
    dealerId: number;
    noCustomerOrTenant: string;
    isMdu: string;
    installedBoards: string;
    city: string;
    state?: any;

    constructor(data) {
        this.dealer = data.dealer;
        this.dealerId = data.dealerId;
        this.noCustomerOrTenant = data.no_customer_or_tenant;
        this.isMdu = data.isMdu;
        this.installedBoards = data.installedBoards;
        this.city = data.city;
        this.state = data.state;
    }
}

export class DealerListResponseDetails {
    page: number;
    size: number;
    dealerList: Dealer[];
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;

    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.dealerList = data.content && data.content.length &&
            data.content.map((value, index) => new Dealer(value));
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
    }
}

export class DealerListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: DealerListResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new DealerListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class BulkDealerUploadInfo {
    rowNumber: number;
    message: string;

    constructor(data) {
        this.rowNumber = data.rowNumber;
        this.message = data.message;
    }
}


export class BulkDealerUploadResponseDetails {
    message: string;
    validRecordsCount: number;
    inValidRecordsCount: number;
    info: BulkDealerUploadInfo[];

    constructor(data) {
        this.message = data.Message;
        this.validRecordsCount = data.valid_records_count;
        this.inValidRecordsCount = data.in_valid_records_count;
        this.info = data.info && data.info.length &&
            data.info.map((value, index) => new BulkDealerUploadInfo(value));
    }
}

export class BulkDealerUploadResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: BulkDealerUploadResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new BulkDealerUploadResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}
