import { Email, Phone, Address } from 'src/app/shared/models/common.model';
import { AssetDetail } from 'src/app/shared/models/customer.model';
import { InstallationReportDetails } from './inventory.model';
import { PreSeasonDetail } from './preseason.model';
export class MduControlAddress {
    addressId: number;
    address: string;
    city: string;
    state: string;
    stateCode: string;
    zipcode: any;
    type: string;
    boardSerialNumber: string;
    deviceType: string;
    propertyName: string;
    consumerName?: string;
    propertyUnitLocation: string;
    unitAptNo: string;
    consumerId: string;
    constructor(data) {
        this.consumerId = data.consumerId;
        this.addressId = data.addressId;
        this.address = data.address;
        this.city = data.city;
        this.state = data.state;
        this.stateCode = data.stateCode;
        this.zipcode = data.zipcode;
        this.type = data.type;
        this.boardSerialNumber = data.boardSerialNumber;
        this.deviceType = data.deviceType;
        this.propertyName = data.propertyName;
        this.consumerName = data.consumerName;
        this.propertyUnitLocation = data.propertyUnitLocation;
        this.unitAptNo = data.unitAptNo;
    }
}

export class PreseasonInfo {
    report: PreSeasonDetail;
    status: string;
    preseasonTestDate: string;
    preseasonTestStatus: string;
    equipmentId: string;
    testEnabled:boolean
    constructor(data) {
        this.report = data.report && new PreSeasonDetail(data.report);
        this.status = data.status;
        this.preseasonTestDate = data.preseasonTestDate;
        this.preseasonTestStatus = data.preseasonTestStatus
        this.equipmentId = data.equipmentId;
        this.testEnabled=data.testEnabled;
    }
}
export class meliInfo {
    meliId: number;
    meliSerialNumber: string;
    meliType: string;
    deviceType: string;
    installedOn: string;
    installationReport?: InstallationReportDetails;
    submitinstallationReport?:any;
    preseason: PreseasonInfo;
    constructor(data) {
        this.meliId = data.meliId;
        this.meliSerialNumber = data.meliSerialNumber;
        this.meliType = data.meliType;
        this.deviceType = data.deviceType;
        this.installedOn = data.installedOn;
        this.installationReport = data.installationReport && new InstallationReportDetails(data.installationReport);
        this.submitinstallationReport = data.installationReport ;
        this.preseason = data.preseason && new PreseasonInfo(data.preseason);
    }

}
export class Info {
    consumerId: number;
    consumerName: string;
    companyName:string;
    consumerType:string;
    propertyModel: MduControlAddress;
    meliList: meliInfo[];
    addresses: string;

    constructor(data) {
        this.consumerId = data.consumerId;
        this.consumerName = data.consumerName;
        this.companyName=data.companyName;
        this.consumerType=data.consumerType;
        this.propertyModel = data.propertyModel && new MduControlAddress(data.propertyModel);
        this.meliList = data.meliList && data.meliList.length && data.meliList.map((value) => new meliInfo(value));

    }
}
export class Control {
    consumerId: number;
    consumerName: string;
    info: Info[];
    criticalFaultsDetected?: number;
    faultsDetected?: number;
    passed?: number;

    constructor(data) {
        this.consumerId = data.consumerId;
        this.consumerName = data.consumerName;
        this.info = data.info && data.info.length && data.info.map((value) => new Info(value));
        this.criticalFaultsDetected = data.criticalFaultsDetected;
        this.faultsDetected = data.faultsDetected;
        this.passed = data.passed;

    }
}

export class ControlListResponseDetails {
    page: number;
    size: number;
    content: Control;
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;


    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
        this.content = data.content && new Control(data.content);

    }
}

export class ControlListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: ControlListResponseDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new ControlListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}





