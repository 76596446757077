import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormArray, FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Constants
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  createNewPassword: FormGroup;
  infoModalType = '';
  infoModalTitle = '';
  isShowConfirmationModal = false;
  confirmationModalTitle = '';
  isConfirmationModalHasYesOrNoButton = false;
  confirmationModalLine1Content: any;
  confirmationModalLine2Content: any;
  isPasswordBtnLoading: boolean;
  reEnterNewPasswordFieldType: string = 'password';
  newPasswordFieldType: string = 'password';
  oldPasswordFieldType: string = 'password';
  constructor(private formBuilder: FormBuilder, private sharedService: SharedService, private helperService: HelperService) { }

  ngOnInit() {
    this.createNewPassword = this.formBuilder.group(
      {
        oldPassword: new FormControl(''),
        newPassword: new FormControl('', [Validators.required, this.helperService.validateWithOldPassword('oldPassword'),
        Validators.minLength(8),
        Validators.maxLength(16),
        Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/)]),
        confirmPassword: new FormControl(''),
      }, {
      validator: this.ConfirmedValidator('newPassword', 'confirmPassword')
    });
  }

  ConfirmedValidator(newPassword: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.get('newPassword');
      const matchingControl = formGroup.get('confirmPassword');
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      if (control.value !== matchingControl.value) {
        //console.log('Dsff');
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        //console.log('DSds');
        matchingControl.setErrors(null);
      }
    };
  }

  toggleShowOrHidePassword(field, type: string) {
    if (field === 'oldPassword') {
      this.oldPasswordFieldType = type;
    } else if (field === 'newPassword') {
      this.newPasswordFieldType = type;
    } else if (field === 'confirmPassword') {
      this.reEnterNewPasswordFieldType = type;
    }
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  handleModalType(type: string) {
    this.infoModalType = type;
    if (type === 'password-reset') {
      this.handlePasswordReset();
    } else if (type === 'confirmation-modal') {
      if (this.handleIsChangePasswordFormHasValue()) {
        this.confirmationModalTitle = 'Warning!';
        this.confirmationModalLine1Content = `Entered information will be lost.`;
        this.confirmationModalLine2Content = `Do you want to continue?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.toggleConfirmationModal();
      } else {
        this.closeModal();
      }
    }
  }

  handleIsChangePasswordFormHasValue() {
    let flag = false;
    if (this.createNewPassword.get('oldPassword').value ||
      this.createNewPassword.get('newPassword').value ||
      this.createNewPassword.get('confirmPassword').value) {
      flag = true;
    }

    return flag;
  }


  // if press reset button
  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;

  }
  handleInfoModalEmitter() {
    if (this.infoModalType === 'confirmation-modal') {
      this.handleResetFormFields();
      this.toggleConfirmationModal();
      this.emitCloseModal.emit();

    }
  }
  handleResetFormFields() {
    this.createNewPassword.patchValue({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });

  }

  // if data submitted
  handlePasswordReset() {
    const payload = this.handlePasswordPayload();
    console.log(payload);
    // this.isPasswordBtnLoading = true;
    this.sharedService.passwordReset(payload).subscribe(
      (response) => {
        console.log(response);
        const message = response['statusInfo'];
        // this.router.navigate(['/technicians']);
        // this.helperService.setSelectedTab('technicians');
        this.handleResetFormFields();
        this.closeModal();
        this.helperService.showToaster(ToasterType.SUCCESS, message);
      }, (error) => {
        // this.closeModal();
        console.log(error);
        // const message = this.helperService.handleAPIErrors(error);
        const message = error ? error.error.statusInfo : '';
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handlePasswordPayload() {
    const payload: any = {
      userName: localStorage.getItem('userName'),
      newPassword: this.createNewPassword.value.newPassword,
      oldPassword: this.createNewPassword.value.oldPassword,
      userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
    };
    return payload;
  }
}
