import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import { PreSeasonSortingKeys, SortingTypes, AlertSortingKeys } from '../../constants/sorting-enum';
import { COMMON_CONSTANTS, PRESEASON_STATUS, REPORTSTATUS } from '../../constants/commom.constant';
import { HelperService } from '../../services/helper.service';
import { SharedService } from '../../services/shared.service';
import { ToasterType } from '../../constants/toaster.enum';
import { PreSeasonListResponse, PreSeasonDetail, PreSeasonResponse, emailResponse, PreSeasonResponseData } from '../../models/preseason.model';
//import * as html2pdf from 'html2pdf.js';
import { SensorDetails } from '../../models/graph.model';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import * as moment from 'moment';
import { Address } from 'src/app/shared/models/common.model';
import { ContractorDetails } from '../../models/contractor.modal';
declare const html2pdf: any;
@Component({
  selector: 'app-pre-season-report',
  templateUrl: './pre-season-report.component.html',
  styleUrls: ['./pre-season-report.component.scss']
})
export class PreSeasonReportComponent implements OnInit {
  @Input() meliSerialNumber: string;
  @Output() emitCloseModal = new EventEmitter();
  showReport: boolean = true;
  showReportList: boolean = false;

  //reportlist
  ListSkip = 0;
  ListLimit = 10;
  ListPage = 1;
  ListTotalCount = 0;
  ListTotalPage = 0;
  paginationFor: string;
  Lists: any;
  placeHolder: string;
  sortingConstants = PreSeasonSortingKeys;
  CurrentSortingField = PreSeasonSortingKeys.TEST_TIME;
  sortingTypes = SortingTypes;
  CurrentSortingType = SortingTypes.DESCENDING;
  isShowListsLoading: boolean = true;
  commonConstants = COMMON_CONSTANTS;
  isTestTimeColumnSort: boolean = false;
  isSeasonColumnSort: boolean = false;
  isTypeColumnSort: boolean = false;
  reportStatus = REPORTSTATUS;
  defaultFile = require('src/assets/images/defaultLogo.png');
  cclogo = require('src/assets/images/defaultLogo.png');
  erroricon = require('src/assets/images/report_failure.svg');
  minorerroricon = require('src/assets/images/report_minor_error.svg');
  successicon = require('src/assets/images/report_pass.svg');
  incompleteicon = require('src/assets/images/report_incomplete.svg');
  sensorworking = require('src/assets/images/report_sensor_working.svg');
  sensorfailure = require('src/assets/images/report_sensor_notworking.svg');
  cellularicon = require('src/assets/images/cellular_exc_icon.svg');
  wifiicon = require('src/assets/images/wi-fi_good_icon.svg');
  inactivesensoricon = require('src/assets/images/report_sensor_inactive.svg');
    emptysensoricon = require('src/assets/images/report_empty_sensor.svg');

  isShowLoading: boolean = false;
  isShowReportLoading: boolean = true;
  @ViewChild('htmlData', { static: false }) htmlData: ElementRef;
  reportData: PreSeasonDetail;
  chartDataInfo: any[] = [];
  chartHeight: any = '300px';
  InvestigatechartData: any;
  download: any;
  pdfConversion: any = '';
  reportId: any;
  isDat: boolean;
  isRat: boolean;
  isSlt: boolean;
  isSpd: boolean;
  isLlt: boolean;
  isCds:boolean;
  isReport: boolean = true;
  conversion: string = null;
  isLoading: boolean;
  reportLink: string;
  @Input() equipmentIds: number;

  isAllCheckboxChecked = true;
  isFanChecked = true;
  isCoolingChecked = true;
  isHeatingChecked = true;
  // commonConstants = COMMON_CONSTANTS;
  // isLoading = false;
  currentTemeperatureConvertion = COMMON_CONSTANTS.FAHRENHEIT;
  currentPressureConvertion = COMMON_CONSTANTS.WATER_COLUMN;
  TemperaturecheckedValue: boolean = false;
  PressureCheckedValue: boolean = false;
  contractorLogo: any;
preseasonStatus = PRESEASON_STATUS;
  constructor(private sharedService: SharedService, private helperService: HelperService) { }

  ngOnInit() {
    this.getReportInformation('FromReport');
    this.getReportList();
    this.getContractorInformation();
  }
  closeModal() {
    this.emitCloseModal.emit();
  }
  getContractorInformation() {
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        console.log(response)
        this.contractorLogo = response.logoUrls ? response.logoUrls : '';
        if (this.contractorLogo.length > 0) {
          this.cclogo = this.contractorLogo[0]
        }

      }
    });
  }
  showHideData(data) {
    if (data == 'report') {
      this.showReportList = false;
      this.showReport = true;
    }
    if (data == 'reportList') {
      this.showReport = false;
      this.showReportList = true;
    }

  }
  //reportlist code

  getReportList() {
    const queryParams: any = {
      size: this.ListLimit,
      page: this.ListPage - 1,
      equipmentId: this.equipmentIds
      //boardSerialNumber: this.meliSerialNumber,
    };
    if (this.CurrentSortingField) {
      queryParams.sortingField = this.CurrentSortingField;
      queryParams.sortingType = this.CurrentSortingType;
    }
    this.isShowLoading = true;
    this.sharedService.getPreSeasonList(queryParams).subscribe(
      (response: PreSeasonListResponse) => {
        this.Lists = response.ReportListResponse.preseasonsList;
        this.ListTotalCount = response.ReportListResponse.totalNumberOfElements;
        this.ListTotalPage = response.ReportListResponse.totalPage;
        this.isShowLoading = false;
        console.log(this.Lists)
      },
      (error) => {
        this.isShowLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }


  //dropdown select 
  handleLimitOnChange(selectedLimit: any) {
    this.handleResetPagination();
    if (selectedLimit === 'all') {
      this.ListLimit = this.ListTotalCount;
    } else {
      this.ListLimit = selectedLimit;
    }
    this.getReportList();
  }

  handleResetPagination() {
    this.ListSkip = 0;
    this.ListPage = 1;

  }
  //handlepagination
  handlePagination(payload) {
    const type = payload.paginationType;
    if (type === this.commonConstants.PAGINATION_NEXT) {
      this.ListPage += 1;
      this.ListSkip =
        this.ListPage * this.ListLimit - this.ListLimit;
    } else if (type === this.commonConstants.PAGINATION_PREVIOUS) {
      this.ListPage -= 1;
      this.ListSkip =
        this.ListPage * this.ListLimit - this.ListLimit;
    } else if (type === this.commonConstants.PAGINATION_FIRST) {

    } else if (type === this.commonConstants.PAGINATION_LAST) {
      this.ListPage = this.ListTotalPage;
      this.ListSkip =
        this.ListPage * this.ListLimit - this.ListLimit;
    }
    this.getReportList();
  }

  handleSorting(key: string) {

    if (key === this.sortingConstants.TEST_TIME) {
      this.isTestTimeColumnSort = !this.isTestTimeColumnSort;
      this.CurrentSortingField = this.sortingConstants.TEST_TIME;
      this.CurrentSortingType = this.isTestTimeColumnSort
        ? this.sortingTypes.ASCENDING
        : this.sortingTypes.DESCENDING;
    }
    if (key === this.sortingConstants.SEASON) {
      this.isSeasonColumnSort = !this.isSeasonColumnSort;
      this.CurrentSortingField = this.sortingConstants.SEASON;
      this.CurrentSortingType = this.isSeasonColumnSort
        ? this.sortingTypes.ASCENDING
        : this.sortingTypes.DESCENDING;
    }
    if (key === this.sortingConstants.TYPE) {
      this.isTypeColumnSort = !this.isTypeColumnSort;
      this.CurrentSortingField = this.sortingConstants.TYPE;
      this.CurrentSortingType = this.isTypeColumnSort
        ? this.sortingTypes.ASCENDING
        : this.sortingTypes.DESCENDING;
    }

    this.getReportList();
  }

  downloadReport(id) {
    //console.log("Sdds")
    // let downloadName = this.reportData && this.reportData.date ? 'pre-season-report_' + this.getDate(this.reportData.date) : 'pre-season-report' + '.pdf';
    // console.log(downloadName)
    // const options = {
    //   margin: 2,
    //   filename: downloadName,
    //   image: { type: 'jpeg', },
    //   html2canvas: { scale: 4 },
    //   jsPDF: {
    //     unit: 'mm', format: 'a3', orientation: 'portrait', putOnlyUsedFonts: true,
    //     floatPrecision: 16,
    //     fontStyle: 'SourceSansPro-Regular'
    //   },
    //   pageBreak: { mode: 'css', before: '.break-page' }

    // }
    // var element: Element = document.getElementById('htmlData');
    // this.download = new html2pdf().from(element).set(options).save()

    let queryParams = {
      filename: id
    }
    this.sharedService.downloadPreseasonReport(queryParams).subscribe(data => {
      console.log(data.size);
      // //const message = data;
      // //this.helperService.showToaster(ToasterType.SUCCESS, message)
      if (data.size == 120) {
        const message = 'No Reports Found';
        this.helperService.showToaster(ToasterType.ERROR, message)
      }
      else {
        const bloba = new Blob([data], { type: 'application/pdf' });
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const url = window.URL.createObjectURL(bloba);
        a.href = url;
        a.download = id;
        a.click();
        window.URL.revokeObjectURL(url);
      }

      // var mediaType = 'application/zip';
      // let blobaa: Blob = data.blob();
      // window['saveAs'](blob, 'sample.zip');

    }, (error) => {
      console.log(error);
      const message = this.helperService.handleAPIErrors(error);
      this.helperService.showToaster(ToasterType.ERROR, message);
    }
    )
  }

  // reportConversion() {

  //   let downloadName = this.reportData && this.reportData.date ? 'pre-season-report_' + this.getDate(this.reportData.date) : 'pre-season-report' + '.pdf';
  //   console.log(downloadName)
  //   const options = {
  //     margin: 2,
  //     filename: downloadName,
  //     image: { type: 'jpeg', },
  //     html2canvas: { scale: 4 },
  //     jsPDF: {
  //       unit: 'mm', format: 'a3', orientation: 'portrait', putOnlyUsedFonts: true,
  //       floatPrecision: 16,
  //       fontStyle: 'SourceSansPro-Regular'
  //     },
  //     pageBreak: { mode: 'css', before: '.break-page' }
  //   }
  //   var element: Element = document.getElementById('htmlData');
  //   html2pdf().from(element).set(options).toPdf().output('datauristring').then((pdfAsString) => {
  //     // The PDF has been converted to a Data URI string and passed to this function.
  //     // Use pdfAsString however you like (send as email, etc)! For instance:
  //     //console.log(pdfAsString);
  //     this.pdfConversion = pdfAsString;
  //     this.sendMail();
  //   });

  //   //return this.pdfConversion;
  // }


  //get single report data
  getReportInformation(data, id?) {
    let queryParams: any = '';
    if (data == 'FromReport') {
      queryParams = {
        equipmentId: this.equipmentIds,
        //boardSerialNumber: this.meliSerialNumber,
      };
      this.getPreseasonreport(queryParams)
    }
    else if (data == 'FromList') {
      queryParams = {
        reportId: id,
      };
      this.getPreseasonreport(queryParams)
    }
    else if (data == 'FromDownload') {
      this.downloadReport(id);
    }
    else if (data == 'FromMail') {
      this.sendMail(id);
    }
  }

  getPreseasonreport(queryParams) {
    this.isShowReportLoading = true;
    this.sharedService.getPreSeasonReport(queryParams).subscribe(
      (response: PreSeasonResponse) => {
        console.log(response)
        this.reportData = response.Response.reportData;
        this.reportId = response.Response.reportId;
        this.reportLink = response.Response.reportLink;
        console.log(this.reportData);
     
        if (this.reportData.installedSensors && this.reportData.installedSensors.length != 0) {
          let sensor = this.reportData.installedSensors;
          sensor.includes('DAT') ? this.isDat = true : this.isDat = false;
          sensor.includes('RAT') ? this.isRat = true : this.isRat = false;
          sensor.includes('SLT') ? this.isSlt = true : this.isSlt = false;
          sensor.includes('LLT') ? this.isLlt = true : this.isLlt = false;
          sensor.includes('SDP') ? this.isSpd = true : this.isSpd = false;
          sensor.includes('CDS') ? this.isCds = true : this.isCds = false;
           //this.isCds = true;
        }
        if (this.reportData.alerts) {
          this.conversion = this.reportData.alerts[0].deltaUnit;
          if (this.reportData.alerts[0].deltaUnit == null) {
            this.conversion = "null"
          }
          this.handleAlertDiscoveryChartData(this.reportData.alerts);
        }
        if (this.reportData.investigateData) {
          this.handleAlertInvestigationChartData(this.reportData.investigateData)
        }
        this.showHideData('report')
     
        this.isShowReportLoading = false;
      },
      (error) => {
        this.isShowReportLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }
  handleAlertDiscoveryChartData(response) {
    this.chartDataInfo = [];
    response.forEach(alert => {
      let data = this.helperService.handleAlertDiscoveryChartData(
        alert,
        'alert-discovery'
      );
      data['alertName'] = alert.alertDetails.alertName;
      data['alertDescription'] = alert.alertDetails.description;
      this.chartDataInfo.push(data);
      console.log(this.chartDataInfo)
    });

    //console.log(this.chartDataInfo);
  }

  handleAlertInvestigationChartData(response: SensorDetails) {
    let responseInfo = new SensorDetails(response);
    this.InvestigatechartData = this.helperService.handleChartData(
      responseInfo,
      'advance-diagnostic'
    );
    this.handleParsePlotBandsData()
  }

  handleTemperatureConvertion(event?: any) {
    // this.TemperaturecheckedValue = event == undefined ? false : event.target.checked;
    this.TemperaturecheckedValue = !this.TemperaturecheckedValue;
    this.gethandleTemperatureConvertion();
  }
  gethandleTemperatureConvertion() {
    if (this.TemperaturecheckedValue) {
      this.currentTemeperatureConvertion = COMMON_CONSTANTS.CELCIUS;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.CELCIUS);
      this.InvestigatechartData.temperature = data.temperature.celciusData;
      // this.HVACCycleData = this.helperService.handleReturnHVACCycleData(COMMON_CONSTANTS.CELCIUS);
      this.handleParsePlotBandsData();
    } else {
      this.currentTemeperatureConvertion = COMMON_CONSTANTS.FAHRENHEIT;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.FAHRENHEIT);
      this.InvestigatechartData.temperature = data.temperature.fahrenheitData;
      // this.HVACCycleData = this.helperService.handleReturnHVACCycleData(COMMON_CONSTANTS.FAHRENHEIT);
      this.handleParsePlotBandsData();
    }
  }

  handlePressureConvertion(event?: any) {
    // this.PressureCheckedValue = event == undefined ? false : event.target.checked;
    this.PressureCheckedValue = !this.PressureCheckedValue;
    this.gethandlePressureConvertion();
  }
  gethandlePressureConvertion() {
    if (this.PressureCheckedValue) {
      this.currentPressureConvertion = COMMON_CONSTANTS.PASCAL;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.PASCAL);
      this.InvestigatechartData.diffStaticPressure = data.diffStaticPressure.pascalData;
      this.handleParsePlotBandsData();
    } else {
      this.currentPressureConvertion = COMMON_CONSTANTS.WATER_COLUMN;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.WATER_COLUMN);
      this.InvestigatechartData.diffStaticPressure = data.diffStaticPressure.waterColumnData;
      this.handleParsePlotBandsData();
    }
  }

  handleParsePlotBandsData() {
    this.isLoading = true;
    // this.HVACCycleLoading = true;
    if (this.InvestigatechartData && this.InvestigatechartData.plotBandsData) {
      this.InvestigatechartData.plotBandsData = [];
    }
    const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.WATER_COLUMN);
    const chartData: any = data.thermostatReads;
    chartData.plotBandsData = [];
    if (this.isFanChecked && chartData.fanSpeed) {
      chartData.plotBandsData.push(
        ...chartData.fanSpeed
      );
    }
    if (this.isCoolingChecked && chartData.cooling) {
      chartData.plotBandsData.push(
        ...chartData.cooling
      );
    }
    if (this.isHeatingChecked) {
      if (chartData.heatPump) {
        chartData.plotBandsData.push(
          ...chartData.heatPump
        );
      }

      if (chartData.heatFurnace) {
        chartData.plotBandsData.push(
          ...chartData.heatFurnace
        );
      }
      // chartData.plotBandsData.push(
      //   ...chartData.heatPump, ...chartData.heatFurnace
      // );
    }
    if (this.InvestigatechartData && this.InvestigatechartData.plotBandsData) {
      this.InvestigatechartData.plotBandsData = JSON.parse(JSON.stringify(chartData.plotBandsData));
    }
    setTimeout(() => {
      this.isLoading = false;
      // this.HVACCycleLoading = false;
    }, 400);
  }

  handleOnchangeCheckbox(flag: string) {
    if (flag === 'all') {
      this.isAllCheckboxChecked = !this.isAllCheckboxChecked;
      this.handleAllCheckboxValue(this.isAllCheckboxChecked);
    } else if (flag === 'fan') {
      this.isFanChecked = !this.isFanChecked;
    } else if (flag === 'cooling') {
      this.isCoolingChecked = !this.isCoolingChecked;
    } else if (flag === 'heating') {
      this.isHeatingChecked = !this.isHeatingChecked;
    }
    this.handleParsePlotBandsData();
    this.isAllCheckboxChecked = this.handleIsAllCheckboxChecked();
  }

  handleAllCheckboxValue(value: boolean) {
    this.isFanChecked = value;
    this.isCoolingChecked = value;
    this.isHeatingChecked = value;
  }

  handleIsAllCheckboxChecked() {
    if (this.isFanChecked && this.isCoolingChecked && this.isHeatingChecked) {
      return true;
    }
    return false;
  }


  trackByReportId(index: number, item: any): string {
    return item ? item.reportId : '';
  }
  getDate(val) {
    let date = moment(val).format('MM-DD-YYYY');
    return date;
  }

  sendMail(reportId) {
    let emails = this.reportData.customerInfo.email;
    // debugger
    let queryParams = {
      boardSerialNumber: this.reportData.hvacInfo.performanceBoard,
      //scheduledTime: this.reportData.testTime,
      email: emails,
     
      consumer:this.reportData && this.reportData.customerInfo && this.reportData.customerInfo.customerFirstName?this.reportData.customerInfo.customerFirstName:this.reportData.customerInfo.customerName,
      data: this.pdfConversion,
      reportId: reportId,
      channelName:this.reportData.channelName,
      consumerId:this.reportData.customerInfo.customerId
    }
    this.isShowLoading = true;
    this.sharedService.sendAttachmentMail(queryParams).subscribe(
      (response: emailResponse) => {
        this.isShowLoading = false;
        this.helperService.showToaster(ToasterType.SUCCESS, "Report has been Sent Successfully");
      },
      (error) => {
        this.isShowLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  handleAddressSelection(addressList: Address[], requiredType: string) {
    let a = [];
    a.push(addressList);
    let address = this.helperService.handleAddressSelection(a, requiredType);
    if (address) {
      let fullAddress = this.helperService.handleRemoveAddressLinesCombinator(address && address.address) + ', ';
      fullAddress += address.city ? address.city + ', ' : null;
      fullAddress += address.state ? address.state + ' ' : null;
      fullAddress += address.zipcode ? address.zipcode : null;
      return fullAddress;
    }
    return null;
  }

  handleTempConvertion(event) {
    // this.isLoading = true;
    // this.conversion = event;
    // this.getAlertDiscovery(this.details);
  }

}
