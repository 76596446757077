export class RelayStatus {
  relay1: boolean;
  relay2: boolean;
  relay3: boolean;
  relay4: boolean;

  constructor(data) {
    this.relay1 = data.relay1;
    this.relay2 = data.relay2;
    this.relay3 = data.relay3;
    this.relay4 = data.relay4;
  }
}

export class CommonDetailsForModes {
  startTime: string;
  endTime: string;
  mode: string;

  constructor(data) {
    this.startTime = data[0];
    this.endTime = data[1];
    this.mode = data[2];
  }
}

export class ModeDetails {
  ac: CommonDetailsForModes[];
  fan: CommonDetailsForModes[];
  heatPump: CommonDetailsForModes[];
  heatFurnance: CommonDetailsForModes[];

  constructor(data) {
    this.ac = data.ac && data.ac.length && data.ac.map((value, index) =>
      new CommonDetailsForModes(value));
    this.fan = data.fan && data.fan.length && data.fan.map((value, index) =>
      new CommonDetailsForModes(value));
    this.heatPump = data.heatpump && data.heatpump.length && data.heatpump.map((value, index) =>
      new CommonDetailsForModes(value));
    this.heatFurnance = data.heatfurnance && data.heatfurnance.length && data.heatfurnance.map((value, index) =>
      new CommonDetailsForModes(value));
  }
}

export class UnitDetails {
  data: number;
  unit: string;

  constructor(data) {
    this.data = data.data;
    this.unit = data.unit;
  }
}

export class PressureUnits {
  pascal: UnitDetails;
  wc: UnitDetails;

  constructor(data) {
    this.pascal = {data: data!=null?data * 249:null, unit: 'pascal'};
    this.wc = {data: data, unit: 'wc'};
  }
}

export class TemperatureUnits {
  celsius: UnitDetails;
  fahrenheit: UnitDetails;

  constructor(data) {
    this.celsius = {data: data, unit: 'celsius'}
    this.fahrenheit = {data: data==null?null:(data*1.8)+32, unit: 'fahrenheit'}
  }
}

export class DeltaUnits {
  celsius: UnitDetails;
  fahrenheit: UnitDetails;

  constructor(data) {
    this.celsius = {data: data[0], unit: 'celsius'}
    this.fahrenheit = {data: data[1], unit: 'fahrenheit'}
  }
}

export class Alert {
  deviceId?: string;
  pbSerialNumber?: string;
  timeStamp: string;
  suctionAirTemperature: TemperatureUnits;
  dischargeAirTemperature: TemperatureUnits;
  returnAirtemperature: TemperatureUnits;
  liquidLineTemperature: TemperatureUnits;
  pressure: PressureUnits;
  outsideTemperature: TemperatureUnits;
  ac?: string;
  fan?: string;
  heatingFurnance?: string;
  heatPump?: string;
  meliStatus?: any;
  delta: DeltaUnits;
  supplyPressure: PressureUnits;
  returnPressure: PressureUnits;

  constructor(data) {
    this.deviceId = data['101'];
    this.timeStamp = data['1'];
    this.heatingFurnance = data.heatingFurnance;
    this.heatPump = data.heatPump;
    this.suctionAirTemperature = data['2'] &&
      new TemperatureUnits(data['2']);
    this.dischargeAirTemperature = data['3'] &&
      new TemperatureUnits(data['3']);
    this.returnAirtemperature = data['4'] &&
      new TemperatureUnits(data['4']);
    this.liquidLineTemperature = data['5'] &&
      new TemperatureUnits(data['5']);
    this.pressure = data['9'] && new PressureUnits(data['9']);
    this.outsideTemperature = (data['10'] || data['10']==0) && new TemperatureUnits(data['10']);
    this.ac = data.ac;
    this.fan = data['113'];
    this.meliStatus = data['117'];
    this.delta = data['6'] && new DeltaUnits(data['6']);
    this.supplyPressure = data['7'] && new PressureUnits(data['7']);
    this.returnPressure = data['8'] && new PressureUnits(data['8']);
  }
}

export class InActiveValueDetails {
  suctionAirTemp: any[];
  delta: any[];
  liquidLineTemperature: any[];
  dischargeAirTemp: any[];
  returnAirTemp: any[];

  constructor(data) {
    this.suctionAirTemp = data.suctionairtemp;
    this.delta = data.delta;
    this.liquidLineTemperature = data.liquidlinetemperature;
    this.dischargeAirTemp = data.dischargeairtemp;
    this.returnAirTemp = data.returnairtemp;
  }
}
export class SensorLastActiveInfo{
  sensorName:string;
  sensorStatus:string;
  sensorValue:number;
  timestamp:string;

  constructor(data){
    this.sensorName=data.sensorName;
    this.sensorStatus=data.sensorStatus;
    this.sensorValue=data.sensorValue;
    this.timestamp=data.timestamp;
  }
}

export class SensorDetails {
  alerts: Alert[];
  relayStatus: RelayStatus;
  modeDetails: ModeDetails;
  inActiveValueDetails: InActiveValueDetails;
  sensorLastActiveInfo:SensorLastActiveInfo[];
  sensorType: string;
  HVACCycleDetails: HVACCycleResponseDetails[];
  alarmDataList:any[];

  constructor(data) {
    this.alerts = data.alerts && data.alerts.length &&
      data.alerts.map((value) => new Alert(value));
    this.relayStatus = data.relayStatus && new RelayStatus(data.relayStatus);
    this.modeDetails = data.modeDetails && new ModeDetails(data.modeDetails);
    this.inActiveValueDetails = data.inActiveValueDetails &&
      new InActiveValueDetails(data.inActiveValueDetails);
      this.sensorLastActiveInfo = data.sensorLastActiveInfo && data.sensorLastActiveInfo.length &&
      data.sensorLastActiveInfo.map((value) => new SensorLastActiveInfo(value));
       this.HVACCycleDetails = data.cycleDataDetails && data.cycleDataDetails.length &&
      data.cycleDataDetails.map((value, index) => new HVACCycleResponseDetails(value));
      this.alarmDataList = data.alarmDataList;
      this.sensorType = data.sensorType;
  }
}

export class AlertDiscoveySensorDetails {
  alertCode: string;
  alertName: string;
  alertOccuredTime: string;
  applicableSensor: string;
  baseLine: number;
  color: string;
  dectection: string;
  deltaInfo: any[];
  description: string;
  deviceId: string;
  graphType: string;
  max: number;
  min: number;
  mode: string;
  pb: string;
  priority: string;
  thermostateInput: number;
  threshold: number;
  title: string;
  xAxis: string;
  yAxis: string;

  constructor(data) {
    this.alertCode = data.alertCode;
    this.alertName = data.alertName;
    this.alertOccuredTime = data.alertOccuredTime;
    this.applicableSensor = data.applicableSensor;
    this.baseLine = data.baseLine;
    this.color = data.color;
    this.dectection = data.dectection;
    this.deltaInfo = data.deltaInfo;
    this.description = data.description;
    this.deviceId = data.deviceId;
    this.graphType = data.graphType;
    this.max = data.max;
    this.min = data.min;
    this.mode = data.mode;
    this.pb = data.pb;
    this.priority = data.priority;
    this.thermostateInput = data.thermostateInput;
    this.threshold = data.threshold;
    this.title = data.title;
    this.xAxis = data.xAxis;
    this.yAxis = data.yAxis;
  }
}

export class Sensor {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: SensorDetails;
  requestData: string;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData =
      data.responseData && new SensorDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class AlertDiscoverySensor {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: AlertDiscoveySensorDetails;
  requestData: string;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData = data.responseData;
    this.requestData = data.requestData;
  }
}

export class HVACCycleResponseDetails {
  meliSerialNo: string;
  deviceId: string;
  timeStamp: string;
  totalRunTimeInHrs: number;
  totalRunCycles: number;
  avgCycleRunTimeInMin: number;
  avgTempSplitInCelcius: number;
  avgTempSplitInFahrenheit: number;
  outsideTempInCelcius: number;
  outsideTempInFahrenheit: number;

  constructor(data) {
    this.meliSerialNo = data['201'];
    this.deviceId = data['202'];
    this.timeStamp = data['0'];
    this.totalRunTimeInHrs = data['1'];
    this.totalRunCycles = data['2'];
    this.avgCycleRunTimeInMin = data['4'];
    this.avgTempSplitInCelcius = data['5'];
    this.avgTempSplitInFahrenheit = data['6'];
    this.outsideTempInCelcius = data['7'];
    this.outsideTempInFahrenheit = data['8'];
  }
}

export class HVACCycleResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  HVACCycleDetails: HVACCycleResponseDetails[];
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.HVACCycleDetails = data.responseData && data.responseData.length &&
      data.responseData.map((value, index) => new HVACCycleResponseDetails(value));
    this.requestData = data.requestData;
  }
}
