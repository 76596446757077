import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


// Directives
import { ClosePopOverDirective } from 'src/app/shared/directives/close-pop-over.directive';
import { ScrollDirective } from './directives/scroll.directive';

// Pipes
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { SafePipePipe } from './pipes/safe-pipe.pipe';
import { ReportsPreseasonInArrayPipe } from './pipes/reports-preseason-in-array.pipe';

// Components
import { TabsComponent } from './components/tabs/tabs.component';
import { GuideModalComponent } from './components/guide-modal/guide-modal.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SplineChartComponent } from './components/spline-chart/spline-chart.component';
import { AdvanceDiagnosticModalComponent } from './components/advance-diagnostic-modal/advance-diagnostic-modal.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ButtonComponent } from './components/button/button.component';
import { AlertDiscoveryModalComponent } from './components/alert-discovery-modal/alert-discovery-modal.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { HvacDetailModalComponent } from './components/hvac-detail-modal/hvac-detail-modal.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { LoadingSymbolComponent } from './components/loading-symbol/loading-symbol.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { InstallationReportComponent } from './components/installation-report/installation-report.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { PaginationLimitDropdownComponent } from './components/pagination-limit-dropdown/pagination-limit-dropdown.component';
import { PersonalInfoTypesComponent } from './components/personal-info-types/personal-info-types.component';
import { AlertDiscoveryChartComponent } from './components/alert-discovery-chart/alert-discovery-chart.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DatetimepickerComponent } from './components/datetimepicker/datetimepicker.component';
import { PersonalInfoModalComponent } from './components/personal-info-modal/personal-info-modal.component';
import { AlertsTableComponent } from './components/alerts-table/alerts-table.component';
import { PreSeasonReportComponent } from './components/pre-season-report/pre-season-report.component';
import { HvacComfortbridgeModalComponent } from './components/hvac-comfortbridge-modal/hvac-comfortbridge-modal.component';
import { MapsComponent } from './components/maps/maps.component';
import { PerformanceBoardDetailsComponent } from './components/performance-board-details/performance-board-details.component';
import { ReadMoreModalComponent } from './components/read-more-modal/read-more-modal.component';
import { HvacOperationsComponent } from './components/hvac-operations/hvac-operations.component';
import { CookieService } from 'ngx-cookie-service';
import { CreateContractorComponent } from './components/create-contractor/create-contractor.component';
import { BillingComponent } from './components/billing/billing.component';
import { AssignTechnicianModalComponent } from './components/assign-technician-modal/assign-technician-modal.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TransferDeleteAccountComponent } from './components/transfer-delete-account/transfer-delete-account.component';
import { WelcomeScreenComponent } from './components/welcome-screen/welcome-screen.component';
import { UploadListModalComponent } from './components/upload-list-modal/upload-list-modal.component';
import { ShareAlertModalComponent } from './components/share-alert-modal/share-alert-modal.component';
import { NgxTagsInputModule } from 'ngx-tags-input';
const Components = [
  TabsComponent,
  ClosePopOverDirective,
  GuideModalComponent,
  OverlayComponent,
  SplineChartComponent,
  AdvanceDiagnosticModalComponent,
  ModalHeaderComponent,
  ButtonComponent,
  AlertDiscoveryModalComponent,
  InfoModalComponent,
  HvacDetailModalComponent,
  ToasterComponent,
  LoadingSymbolComponent,
  ScrollDirective,
  PaginationComponent,
  InstallationReportComponent,
  ConfirmationModalComponent,
  ReadMoreModalComponent,
  PaginationLimitDropdownComponent,
  PersonalInfoTypesComponent,
  AlertDiscoveryChartComponent,
  ChangePasswordComponent,
  DatetimepickerComponent,
  PersonalInfoModalComponent,
  AlertsTableComponent,
  PreSeasonReportComponent,
  HvacComfortbridgeModalComponent,
  MapsComponent,
  PerformanceBoardDetailsComponent,
  HvacOperationsComponent,
  CreateContractorComponent,
  BillingComponent,
  AssignTechnicianModalComponent,
  SubscriptionComponent,
  TransferDeleteAccountComponent,
  WelcomeScreenComponent,
  UploadListModalComponent,
  ShareAlertModalComponent
];

const Pipes = [MomentDatePipe, SafePipePipe, ReportsPreseasonInArrayPipe];

@NgModule({
  declarations: [...Components, ...Pipes],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
     InfiniteScrollModule,
     NgxTagsInputModule
  ],
  exports: [...Components, ...Pipes],
  providers: [
    CookieService
  ],
})
export class SharedModule { }
