import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
// import { } from '@types/googlemaps';
// import {google} from 'googlemaps';
import { Subject } from 'rxjs';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';

// Models
import { Email, Phone, Address } from 'src/app/shared/models/common.model';
import { Customer } from 'src/app/shared/models/inventory.model';
import { COMMON_CONSTANTS } from '../../constants/commom.constant';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import { ActivatedRoute } from '@angular/router';

declare const google: any;
@Component({
  selector: 'app-personal-info-modal',
  templateUrl: './personal-info-modal.component.html',
  styleUrls: ['./personal-info-modal.component.scss']
})
export class PersonalInfoModalComponent implements OnInit, AfterViewInit {

  @Output() emitCloseModal = new EventEmitter();
  @Input() modalType: string;
  @Input() modalTitle: string;
  @Input() customerDetails: Customer;
  @ViewChild('mapContainer', { static: false }) gmapElement: ElementRef;
  map: any;
  address: Subject<object> = new Subject();
  hasAddress = true;
  isMapLoading = false;

  currentLocation: any;
  parsedAddress: any;
  portalName: any;
  isdisableDoubleClick: boolean = false;

  constructor(
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });
    let address: Address;
    this.setListenerForAddress();
    if (this.customerDetails && this.customerDetails.addresses
      && this.customerDetails.addresses.length) {
      address = this.handleAddressSelection(this.customerDetails.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_SERVICE);
    }

    if (address) {
      if (this.portalName != ROLE_CONSTANTS.CHANNEL) {
        this.parsedAddress = this.handleRemoveAddressLinesCombinator(address.address) + ', ' +
          address.city + ', ' + address.stateCode + ' ' + address.zipcode;
      }
      else {
        this.parsedAddress = address.city + ', ' + address.stateCode + ' ' + address.zipcode;
        this.isdisableDoubleClick = true;
      }

    }
  }

  setListenerForAddress() {
    return this.address.asObservable();
  }

  ngAfterViewInit() {
    if (this.modalType === 'map') {
      this.generateAddress();
    }
  }

  handleRemoveAddressLinesCombinator(address: string) {
    return this.helperService.handleRemoveAddressLinesCombinator(address);
  }

  handleAddressSelection(addressList: Address[], requiredType: string) {
    return this.helperService.handleAddressSelection(addressList, requiredType);
  }


  generateAddress() {

    // if (this.customerDetails && this.customerDetails.length) {
    //   addresses = this.handleRemoveAddressLinesCombinator(this.customerDetails[0].address) + ',' +
    //   this.customerDetails[0].city + ',' + this.customerDetails[0].state + ',' +
    //   this.customerDetails[0].zipcode;
    // }

    this.isMapLoading = true;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      address: this.parsedAddress
    }, (results, status) => {
      this.isMapLoading = false;
      if (status === google.maps.GeocoderStatus.OK) {
        this.address.next(results);
      } else {
        this.hasAddress = false;
      }
    }, this);

    this.setListenerForAddress().subscribe(
      (response) => {
        this.isMapLoading = false;
        const myOptions = {
          zoom: 8,
          center: response[0].geometry.location,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          position: response[0].geometry.location,
        };

        const contentString = '<div class="color_black-primary font-size_14 font-SourceSansPro-Regular">' +
          this.parsedAddress +
          '</div>';

        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });

        const map = new google.maps.Map(this.gmapElement.nativeElement, myOptions);
        const marker = new google.maps.Marker({
          position: response[0].geometry.location,
          map
        });

        this.map = marker;
        infowindow.open(map, marker);
        marker.addListener('click', () => {
          infowindow.open(map, marker);
        });
        map.setOptions({ disableDoubleClickZoom: this.isdisableDoubleClick });
      }
    );
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}
