export const environment = {
  production: false,
  loginBaseUrl: 'https://coreservices.aurai.io/cognito' || 'https://k2zn8ojkke.execute-api.us-east-2.amazonaws.com/v1',
  baseUrl: 'https://cpservices.aurai.io/yantra' || 'https://prod-cpgoodman.ihvac.io/yantra',
  updateRelayBaseUrl: 'https://comfort-yantraconsole.aurai.io/yantra' || 'https://prod-yantraconsole.ihvac.io/yantra',
  preSeasonTestBaseUrl: 'https://comfort-preseason.aurai.io/preseason' || 'https://k2zn8ojkke.execute-api.us-east-2.amazonaws.com/v1/pre-season',
  phoneCodesRestAPIBaseUrl: 'https://restcountries.eu/rest/v2/all',
  lambdaBaseUrl: 'https://vs9m4h1zac.execute-api.us-east-2.amazonaws.com/finalprod' || 'https://io4muk95w9.execute-api.us-east-2.amazonaws.com/prod',
  comfortBridgeUrl: 'https://n0korjfy58.execute-api.us-east-2.amazonaws.com/v1/model' || 'https://n0korjfy58.execute-api.us-east-2.amazonaws.com/v1/model',
  userPoolId: 'us-east-2_mxAxkLlLk' || 'us-east-2_DSgKB2NiH',
  minCelciusValue: '-18' || -18,
  maxCelciusValue: '93' || 93,
  minWaterColumnValue: '0' || 0,
  maxWaterColumnValue: '0.9' || 0.9,
  sensorMaxNegativeValue: '' || -150,
  sensorMaxPositiveValue: '' || 150,
  theme: 'keenhome-theme' || 'dark-theme',
  siteName: 'keenhome-contractor' || 'goodman-contractor',
  mapboxKey: ''||'pk.eyJ1IjoiY29ubmVjdG0iLCJhIjoiY2tmOG9xMzhmMGVlODJ5bzBrZGM3dzNpeSJ9.gZfzhnzROQ_NDyJSCR_8Xg',
  stripeKey: 'pk_live_enRMLdXYcDBFlPCEcU10zDBm00P8z9P2RU' || 'pk_test_MLqe6uLxvPk209PiEgblk9oq00uyn3cgbJ',
  branchKey: 'key_live_lkXyoXwwo4t4QkMdKQB03jnauseCLmwI' || 'key_test_bbHWNWSbngfXUlnt51YrwpakwwkscjMs',
  termsOfUse: '' || 'https://keenhome.io/pages/terms',
  privacyPolicy: '' || 'https://keenhome.io/pages/privacy'
};




// CODES DEFAULTLY PRESENT IN ENVIRONMENT.TS file under environments folder.



// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'https://cp-goodman.ihvac.io/yantra',
//   // baseUrl: 'http://18.189.81.204:8081/yantra',
//   loginBaseUrl: 'https://q1w3ryo4sc.execute-api.us-east-2.amazonaws.com/dev/',
//   updateRelayBaseUrl: 'http://3.19.77.212:8088/yantra'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

