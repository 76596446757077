export const SITE_NAME_CONSTANTS = {
    GOODMAN_CHANNEL: 'goodman-channel',
    GOODMAN_CONTRACTOR: 'goodman-contractor',
    GOODMAN_MDU: 'goodman-mdu',
    FERGUSON_CHANNEL: 'ferguson-channel',
    FERGUSON_CONTRACTOR: 'ferguson-contractor',
    FERGUSON_MDU: 'ferguson-mdu',
    UTEC_CHANNEL: 'utec-channel',
    UTEC_CONTRACTOR: 'utec-contractor',
    UTEC_MDU: 'utec-mdu',
    CONNECTM_CHANNEL: 'connectm-channel',
    CONNECTM_CONTRACTOR: 'connectm-contractor',
    CONNECTM_MDU: 'connectm-mdu',
    KEENHOME_CHANNEL: 'keenhome-channel',
    KEENHOME_CONTRACTOR: 'keenhome-contractor',
    KEENHOME_MDU: 'keenhome-mdu',

};
