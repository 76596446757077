import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { SharedService } from 'src/app/shared/services/shared.service';

// Constants
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { SITE_NAME_CONSTANTS } from 'src/app/shared/constants/site-name.constant';

import { CookieService } from 'ngx-cookie-service';

// Models
import { ContractorDetails } from 'src/app/shared/models/contractor.modal';
import { Customer } from 'src/app/shared/models/inventory.model';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {

  portalName: string;
  idleTime: any;
  warningTimeOut: any;
  isConfimationModal = false;
  modalTitle: string;
  confirmationModalLine1Content: string;
  isConfirmationModalHasYesOrNoButton = false;
  idleTimeSubscription: Subscription;
  isShowTabs = true;
  isShowHeader = true;
  isShowFooter = true;
  authTime: string;
  firmName: string;
  mduInformation: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private sharedService: SharedService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    const siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);

    if (siteName === SITE_NAME_CONSTANTS.FERGUSON_CONTRACTOR ||
      siteName === SITE_NAME_CONSTANTS.FERGUSON_CHANNEL ||
      siteName === SITE_NAME_CONSTANTS.FERGUSON_MDU ||
      siteName === SITE_NAME_CONSTANTS.CONNECTM_CONTRACTOR ||
      siteName === SITE_NAME_CONSTANTS.CONNECTM_CHANNEL ||
      siteName === SITE_NAME_CONSTANTS.CONNECTM_MDU ) {
      this.isShowFooter = false;
    }

    this.activatedRoute.data.subscribe(data => {
      if (data) {
        this.portalName = data.portalName;
        if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
          this.getContractorInformation();
        }
        else if (data.portalName === ROLE_CONSTANTS.MDU) {
          this.getMduInformation();
        }
      }
    });

    this.activatedRoute.queryParams.subscribe(
      (response) => {
        console.log(response)
        if (response && response.isShowTabs && !JSON.parse(response.isShowTabs)) {
          this.isShowTabs = false;
        } else {
          this.isShowTabs = true;
        }
      }
    );

    if (this.activatedRoute && this.activatedRoute.snapshot.queryParams &&
      this.activatedRoute.snapshot && this.activatedRoute.snapshot.queryParams) {
      if (this.activatedRoute.snapshot.queryParams.isShowTabs &&
        !JSON.parse(this.activatedRoute.snapshot.queryParams.isShowTabs)) {
        this.isShowTabs = false;
      }
      if (this.activatedRoute.snapshot.queryParams.isShowHeader &&
        !JSON.parse(this.activatedRoute.snapshot.queryParams.isShowHeader)) {
        this.isShowHeader = false;
      }
      if (this.activatedRoute.snapshot.queryParams.isShowFooter &&
        !JSON.parse(this.activatedRoute.snapshot.queryParams.isShowFooter)) {
        this.isShowFooter = false;
      }
    }

    this.getLastLoggedInTime();
    this.helperService.handleStartIdleTime();
    this.setListnerForWarningIdleTime();
  }

  getMduInformation() {
    this.sharedService.getMduInformation((response: Customer, isError) => {
      if (!isError) {
        this.firmName = response ? response.contractorName : '';
      }
    });
  }

  getContractorInformation() {
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        this.firmName = response.firmName ? response.firmName : response.contractorName;
        // sessionStorage.setItem('firmname', response.firmName);
        // if (response.logoUrls && response.logoUrls.length) {
        //   this.profileImageUrl = response.logoUrls[0];
        // }
      }
    });
  }

  getLastLoggedInTime() {
    let payload = {};
    const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');
    if (isRememberMeChecked === 'true') {
      payload = {
        userName: localStorage.getItem('userName'),
        userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
      };
    } else {
      payload = {
        userName: localStorage.getItem('userName'),
        userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
      };
    }

    this.sharedService.getLastLoggedInTime(payload).subscribe(
      (response) => {
        this.sharedService.setLastLoginDetailsSubject(response);
        if (response && response.responseData && response.responseData.lastLoggedInTime) {
          this.handleCalculateLastLoggedTime(response.responseData.lastLoggedInTime);
          // if (response.responseData.siteTour == 'Enabled') {
          //   this.setListenerForSiteTour();
          // }
        }
      }, (error) => {

      }
    );
  }

  handleCalculateLastLoggedTime(time) {
    const lastLoggedInTime = moment.utc(time).local();
    const currentTime = moment(new Date());

    if (currentTime.diff(lastLoggedInTime, 'years') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'years') + ' year';
    } else if (currentTime.diff(lastLoggedInTime, 'years') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'years') + ' years';
    } else if (currentTime.diff(lastLoggedInTime, 'months') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'months') + ' month';
    } else if (currentTime.diff(lastLoggedInTime, 'months') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'months') + ' months';
    } else if (currentTime.diff(lastLoggedInTime, 'days') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'days') + ' day';
    } else if (currentTime.diff(lastLoggedInTime, 'days') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'days') + ' days';
    } else if (currentTime.diff(lastLoggedInTime, 'hours') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'hours') + ' hour';
    } else if (currentTime.diff(lastLoggedInTime, 'hours') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'hours') + ' hours';
    } else if (currentTime.diff(lastLoggedInTime, 'minutes') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'minutes') + ' minute';
    } else if (currentTime.diff(lastLoggedInTime, 'minutes') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'minutes') + ' minutes';
    } else if (currentTime.diff(lastLoggedInTime, 'seconds') === 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'seconds') + ' second';
    } else if (currentTime.diff(lastLoggedInTime, 'seconds') > 1) {
      this.authTime = currentTime.diff(lastLoggedInTime, 'seconds') + ' seconds';
    }
  }

  handleResetIdleTime() {
    if (this.isConfimationModal) {
      // this.helperService.handleResetWarningIdleTime();
    } else {
      this.helperService.handleResetIdleTime();
    }
  }

  setListnerForWarningIdleTime() {
    this.idleTimeSubscription = this.helperService.setListnerForWarningIdleTime().subscribe(
      (response) => {
        this.handleWarningIdleTime();
      }, (error) => {

      }
    );
  }

  handleWarningIdleTime() {
    this.modalTitle = 'Session Time-out';
    this.isConfirmationModalHasYesOrNoButton = false;
    this.toggleConfirmationModal(true);
    this.helperService.handleWarningIdleTime();
  }

  handleConfirmationModalEmitter() {
    this.handleLogout();
    this.toggleConfirmationModal(false);
  }

  handleLogout() {
    this.helperService.setValueForIsAlreadyIdleTimeRunning(false);
    // localStorage.clear();
    // // sessionStorage.clear();
    // this.router.navigate(['/login']);
    const queryParams = this.helperService.handleParseLogoutQueryParam();
    // this.sharedService.handleLogout(queryParams).subscribe(
    //   (response) => {
    this.helperService.handleLogoutAPISuccessResponse();
    //   }, (error) => {
    //     const message = this.helperService.handleAPIErrors(error);
    //     this.helperService.showToaster(ToasterType.ERROR, message);
    //   }
    // );
  }

  toggleConfirmationModal(value: boolean) {
    this.isConfimationModal = value;
    // if (!value) {
    //   this.helperService.handleResetIdleTime();
    // }
  }

  ngOnDestroy() {
    this.idleTimeSubscription.unsubscribe();
  }

}
