import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(dateString: string, format: string): any {
    let formatToConvert = format;
    if (!format) {
      formatToConvert = 'DD-MMM-YYYY hh:mm:ss A';
    }

    // const date = moment.utc(dateString).format(formatToConvert);
    const stillUtc = moment.utc(dateString).toDate();
    const formatedDate = moment(stillUtc).local().format(formatToConvert);

    return formatedDate;
  }
}
