export const API_CONSTANTS = {
  SIGN_UP: '/dealer/signup',
  LOGIN: '/login',
  REGISTRATION_STATUS:'/getUserSelfRegisteredStatus',
  FORGOT_PASSWORD: '/forgotPassword',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/resetPassword',
  GET_CONTRACTOR_INFORMATION: '/contractor',
  GET_MDU_INFORMATION: '/mdu/profile',
  GET_SEARCH_LIST: '/search',
  ALERTS_LIST: '/alerts',
  FIRMWARE_VERSION: '/boardFWversion',
  SENSOR_CURRENT_READ: '/sensorcurrentread',
  SENSOR_READ: '/sensorread',
  ALERT_DISCOVERY_READ: '/alertconfigbyapi',
  GET_MELI_ASSET_INFO: '/assetdetails',
  CHANGE_PASSWORD: '/changePassword',
  GET_INVENTORY_LIST: '/melidetails',
  GET_BOARDDATA_LIST:'/boardPerformanceInfo',
  CUSTOMER_LIST: '/v1/consumers',
  HVAC_ASSET_LIST: '/v1/assetdetails',
  GET_SENSOR_STATUS: '/getSensorInstallStatus',
  UPDATE_MELI_STATUS: '/contractor/meli/updateStatus',
  GET_LAST_LOGGED_IN: '/getlastlogintime',
  GET_SITE_TOUR_DATA: '/getsitetourinfo',
  COMPLETE_SITE_TOUR: '/completesitetour',
  PRESEASON_LIST: '/preseasonreports',
  PRESEASON_REPORT: '/report',
  SEND_PRESEASON_REPORT_MAIL: '/sendReport',
  UPDATE_INSTALL_REPORT: '/jobs',
  COMFORTT_BRIDGE_INFO: '/dataacinfo',
  SYSTEM_INFO: '/systemInfo',
  GAS_FURANCE_INFO: '/gasFurnaceInfo',
  AC_INFO: '/acInfo',
  HEAT_PUMP_INFO: '/heatPumpInfo',
  GET_HVAC_CYCLE_DATA: '/hvacCycleData',
  GET_PROPERTIES_MAP_LIST: '/maps',
  GET_TECHNICIANS_LIST: '/technician',
  DELETE_TECHNICIAN: '/technician',
  EDIT_TECHNICIAN: '/technicianForEdit',
  CHECK_EMAIL: '/checkEmail',
  UPLOAD_TECHNICIAN_LIST: '/uploadUserRecord',
  DOWNLOAD_TEMPLATE: '/getTemplate',
  GET_COMFORTBRIDGE_STATUS: '/comfortbrige/status',
  GET_ASSET_UNITLOCATION: '/assetUnitLocation',
  GET_CONTROL_LIST: '/mdu/controls',
  GET_CONTRACTOR_CONTROL_LIST: '/cp/reports',
  CANCEL_PRE_SEASON_TEST: '/cancelTest',
  DOWNLOAD_BULK_PRESEASON_REPORTS: '/downloadEquipmentTestReportZip',
  DOWNLOAD_PRESEASON_REPORT: '/downloadTestReport',
  DOWNLOAD_BULK_INSTALLATION_REPORT: '/ir/downloadTestReportZip',
  CREATE_CONTRACTOR: '/dealer',
  GET_INSTALLATION_REPORT: '/installationsReport',
  ASSIGN_TECHNICIAN: '/jobs',
  CREATE_TECHNICIAN: '/technician',
  GET_CUSTOMER_SECRETKEY: '/clientSecrete',
  SEND_CARD_TOKEN: '/assignCardToCustomer',
  SEND_ACH_TOKEN: '/assignAchtoCustomer',
  SUBSCRIPTION_PLAN: '/plansSubscription',
  UPDATE_SUBSCRIPTION_PLAN: '/changeSubscriptionPlan',
  // GET_CELLULAR_PLAN_LIST: '/subscriptionPlanStatus',
  GET_CELLULAR_CURRENT_STATUS: '/simcard/currentStatus',
  UPDATE_CELLULAR_PLAN: '/changeSubscriptionPlanStatus',
  IS_CARD_AVAILABLE: '/isBillingInfoFound',
  GET_BILLING_LIST: '/billingInfo',
  UPDATE_CREDIT_CARD: '/updateCard',
  UPDATE_ACH: '/updateACH',
  DELETE_BILLING_INFO: '/deletePaymentMethod',
  MAKE_DEFAULT_PAYMENT_METHOD: '/makeDefaultPayment',
  VERIFY_ACH: '/verifyACH',
  GET_UPLOAD_ERROR_FILE: '/getUploadErrorFile',
  ACTIVATE_TECHNICIAN: '/technician/activate',
  DE_ACTIVATE_TECHNICIAN: '/technician/deactivate',
  RE_INVITE_TECHNICIAN: '/resendInvitationToTechnician',
  TRANSFER_ACCOUNT: '/transferdealer',
  DELETE_ACCOUNT: '/dealer',
  GET_SENSOR_CURRENT_STATUS: '/boardDeviceStatus',
  GET_HVAC_STATUS: '/boardOnlineOffline',
  USER_EXIST: '/userExist',
  EDIT_ASSET_UNIT: '/addAssetUnit',
  DELETE_LOGO:'/contractorLogo',
    UPLOAD_CUSTOMER_LIST:'/uploadUserRecord',
    GET_UPLOAD_STATUS:'/getUploadFileStatus',
    UPLOAD_STATUS_RESET:'/userDataReport',
    GET_HVAC_INFORMATION:'/getAllMakeModel',
    UNSUBSCRIBE:'/emailSubscription',
    ACTIVATE_CONTRACTOR:'/resendInvitationToDealer',
    UPDATESWITCH:'/updateSwitch',
    GET_CUSTOMER_COUNT:'/boardTypeCount',
     PRE_SEASON_TEST_STATUS: '/scheduledTestStatus',
     RESOLVE_ALERT:'/alarmStatus',
     GENERATE_SHORT_LINK:'/generateShortLink',
     SHARE_ALERT:'/shareAlert',
     IS_PRESEASON_ENABLE:'/isTestEnable'
};
