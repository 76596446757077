/* tslint:disable */
import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, Output, EventEmitter, HostListener } from '@angular/core';
import * as Highcharts from 'highcharts';
import AnnotationModule from 'highcharts/modules/annotations';
import Breaks from 'highcharts/modules/broken-axis';
import highchartsTreemap from 'highcharts/modules/treemap';
import * as Highstock from 'highcharts/highstock';
import exporting from 'highcharts/modules/exporting';
import { SITE_NAME_CONSTANTS } from 'src/app/shared/constants/site-name.constant';
import { HelperService } from 'src/app/shared/services/helper.service';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
highchartsTreemap(Highcharts);
AnnotationModule(Highcharts);
Breaks(Highcharts);
exporting(Highcharts);
AnnotationModule(Highstock);
exporting(Highstock);

// import HC_exportData from 'highcharts/modules/export-data';
// HC_exportData(Highcharts);
// import HC_exporting from 'highcharts/modules/exporting';
// HC_exporting(Highcharts);
// HC_exporting(Highstock);

// Constants
import { COMMON_CONSTANTS, CHART_TYPES, CHART_UNIT_TYPES } from 'src/app/shared/constants/commom.constant';
// import { first } from 'rxjs/operators';


@Component({
  selector: 'app-spline-chart',
  templateUrl: './spline-chart.component.html',
  styleUrls: ['./spline-chart.component.scss']
})
export class SplineChartComponent implements OnInit, OnChanges {

  @Input() chartData: any;
  @Input() plotBandsData: any;
  @Input() isFahrenheit: boolean;
  @Input() isPascal: boolean;
  @Input() heightStyle: any;
  @Input() chartType = CHART_TYPES.SPLINE;
  @Input() HVACCycleData: any;
  @Output() emitHandleTemperatureConvertion = new EventEmitter();
  @Output() emitHandleOnClickNextBtn = new EventEmitter();
  @Output() emitHandleOnClickPrevBtn = new EventEmitter();
  @Output() emitHandlePressureConvertion = new EventEmitter();
  @Input() fromReport: boolean = false;
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;

  options: any;
  siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
  primaryColor: string = '';
  display:boolean=true;
  constructor(
    private helperService: HelperService,
  ) {  
    window.innerWidth<=480?this.display=false:this.display=true;
  }

//    @HostListener('window:resize', ['$event'])
// onResize(event) {
//    if(window.innerWidth<=480)
//     {
//       this.display=false;
//       console.log(this.display, window.innerWidth,"width dddddddddddddddddddd")
//       //alert(window.innerWidth)
//     } 
//     else
//     {
//       this.display=true
//     }
// }
  ngOnInit() {
    // debugger
    
    if(this.siteName === SITE_NAME_CONSTANTS.KEENHOME_CHANNEL ||
      this.siteName === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR ||
      this.siteName === SITE_NAME_CONSTANTS.KEENHOME_MDU){
        this.primaryColor = '#00B7A5';
    } else {
      this.primaryColor = '#71CECA';
    }

    if (this.chartType === CHART_TYPES.SPLINE) {
      this.drawSplineChart(this.chartData);
      // console.log("chart",this.chartData);
    } else if (this.chartType === CHART_TYPES.COLUMN) {
      this.drawColumnChart(this.HVACCycleData);
    } else if (this.chartType === CHART_TYPES.LINE) {
      this.drawLineChart(this.HVACCycleData);
    }

    
  }

  ngOnChanges(changes: SimpleChanges) {
    // debugger
    // if (changes && changes.chartType && !changes.chartType.firstChange) {
    //   if (this.chartType === CHART_TYPES.SPLINE) {
    //     this.drawSplineChart(this.chartData);
    //   } else if (this.chartType === CHART_TYPES.COLUMN) {
    //     this.drawColumnChart(this.HVACCycleData);
    //   } else if (this.chartType === CHART_TYPES.LINE) {
    //     this.drawLineChart(this.HVACCycleData);
    //   }
    // }
  }

  handleExportingButtons(obj) {
    const self = this;
    return {
      // buttonSpacing: 100,
      enabled: true,
      buttons: [{
        text: obj && obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE ? 'Fahrenheit' : '“ WC',
        // buttonSpacing: 100,
        // selected: true,
        // disableHover: true,
        onclick: function () {
          // alert('clicked');
          if (obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE) {
            self.emitHandleTemperatureConvertion.emit();
          } else {
            self.emitHandlePressureConvertion.emit();
          }
        },
        x: -70,
        // style: {
        //   fontSize: '100px',
        //   width: '18px'
        // },
        theme: {
          class: "myButton highcharts-button highcharts-button-normal",
          /* 'stroke-width': 1, */
          /* stroke: 'silver', */
          fill: obj && ((obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE && obj.unit === COMMON_CONSTANTS.FAHRENHEIT) ||
            (obj.chartUnitType === CHART_UNIT_TYPES.PRESSURE && obj.unit === COMMON_CONSTANTS.WATER_COLUMN)) ? this.primaryColor : 'none',
          // fill: 'none',
          r: 18,
          padding: 10,
          // width: 60,
          // textAlign: 'center',
          /* fontSize: '200px', */
          style: {
            // fontSize: '10px',
            color: obj && ((obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE && obj.unit === COMMON_CONSTANTS.FAHRENHEIT) ||
              (obj.chartUnitType === CHART_UNIT_TYPES.PRESSURE && obj.unit === COMMON_CONSTANTS.WATER_COLUMN)) ? '#242A34' : '#FFFFFF',
            fontSize: '1.3rem',
            // fontFamily: 'cursive',
            fontFamily: 'SourceSansPro-Regular',
            fontWeight: 700,
            //display:,
            // textAlign: 'center',

            // states: {
            //   hover: {
            //     // fill: '#a4edba'
            //     fill: '#71CECA',
            //     color: '#FFFFFF',
            //   },
            // }
          },
          /* height: 40, */
          /* width: 48,
          symbolSize: 24, */
          /* textAlign: 'center', */
          states: {
            hover: {
              fill: this.primaryColor
            } 
              
            // hover: {
            //   // fill: '#a4edba'
            //   fill: '#71CECA',
            //   // color: '#FFFFFF',
            // },
            // select: {
            //   // stroke: '#039',
            //   // fill: '#a4edba'
            //   fill: '#71CECA',
            // }
          }
        }
      },
      {
        text: obj && obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE ? 'Celsius' : 'Pascal',
        onclick: function () {
          // alert('clicked');
          if (obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE) {
            self.emitHandleTemperatureConvertion.emit();
          } else {
            self.emitHandlePressureConvertion.emit();
          }
        },
        x: -10,
        theme: {
          /* 'stroke-width': 1, */
          /* stroke: 'silver', */
          /* fill: '#71CECA', */
          padding: 10,
          // x: -10,
          r: 18,
          // margin: '100px',
          // color: false ? '#242A34' : '#FFFFFF',
          // fill: 'none',
          fill: obj && ((obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE && obj.unit === COMMON_CONSTANTS.CELCIUS) ||
            (obj.chartUnitType === CHART_UNIT_TYPES.PRESSURE && obj.unit === COMMON_CONSTANTS.PASCAL)) ? this.primaryColor : 'none',
          style: {
            // color: '#FFFFFF',
            color: obj && ((obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE && obj.unit === COMMON_CONSTANTS.CELCIUS) ||
              (obj.chartUnitType === CHART_UNIT_TYPES.PRESSURE && obj.unit === COMMON_CONSTANTS.PASCAL)) ? '#242A34' : '#FFFFFF',
            // fontSize: '10px',
            fontSize: '1.3rem',
            // margin: '100px',
            // fontFamily: 'cursive',
            fontFamily: 'SourceSansPro-Regular',
            fontWeight: 700,
            textAlign: 'center'
          },
          states: {
            hover: {
              fill: this.primaryColor
            } 
            // select: {
            //   stroke: '#039',
            //   fill: '#a4edba'
            // }
          }
        }
      },
        // obj && obj.chartName === 'investigate-overview' ? {
        //   // text: `<div class="a"><svg width="9" height="16" viewBox="0 0 9 16" fill="#DADADA" xmlns="http://www.w3.org/2000/svg">
        //   // <path d="M1.79445 0.945312L0.136719 2.60308L5.52141 7.99964L0.136719 13.3962L1.79445 15.054L8.84863 7.99964L1.79445 0.945312Z" fill="#E9E9F0"/>
        //   // </svg></div>`,
        //   text: '>',
        //   // useHTML: true,
        //   //   contextButton: {
        //   //     useHTML: true,
        //   //     symbol: '>',
        //   //     // symbol: 'url(../../../../assets/images/arrow_right.svg)'
        //   // },
        //   onclick: function () {
        //     // alert('clicked');
        //     // if (obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE) {
        //     self.emitHandleOnClickNextBtn.emit();
        //     // } else {
        //     //   self.emitHandlePressureConvertion.emit();
        //     // }
        //   },
        //   x: 0,
        //   y: 90,
        //   // navigation: {
        //   //   buttonOptions: {
        //   //     verticalAlign: 'middle',
        //   //     y: -20,
        //   //     align: 'center'
        //   //   }
        //   // },
        //   theme: {
        //     /* 'stroke-width': 1, */
        //     /* stroke: 'silver', */
        //     /* fill: '#71CECA', */
        //     // width: 20,
        //     // height: 20,
        //     height: 25,
        //     padding: 10,
        //     // x: -10,
        //     r: 50,
        //     // x: 15,
        //     //   y: 30,
        //     // top: -10,
        //     // left: 10,
        //     // margin: '100px',
        //     // color: false ? '#242A34' : '#FFFFFF',
        //     // fill: 'none',
        //     fill: '#71CECA',
        //     style: {
        //       // color: '#FFFFFF',
        //       color: '#242A34',
        //       // fontSize: '10px',
        //       fontSize: '2.4rem',
        //       // margin: '100px',
        //       // fontFamily: 'cursive',
        //       fontFamily: 'Lato-Regular',
        //       fontWeight: 700,
        //       // textAlign: 'center',
        //       // verticalAlign: 'middle',
        //       // x: 15,
        //       // y: 30,
        //       // top: -10,
        //       // left: 10,
        //       // padding: 10
        //     },
        //     states: {
        //       hover: {
        //         // fill: '#a4edba'
        //         // fill: 'none'
        //         fill: '#71CECA',
        //       },
        //       // select: {
        //       //   stroke: '#039',
        //       //   fill: '#a4edba'
        //       // }
        //     }
        //   }
        // } : null,
        // obj && obj.chartName === 'investigate-overview' ? {
        //   text: '<',
        //   //   useHTML: true,
        //   //   contextButton: {
        //   //     symbol: 'url(https://lh3.google.com/u/0/ogw/ADGmqu-9P3qi_PkkS3kHm9KtQ1XkRATArPPY123ULOno=s64-c-mo)'
        //   // },
        //   //   navigation: {
        //   //   buttonOptions: {
        //   //     verticalAlign: 'middle',
        //   //     y: -20,
        //   //     align: 'center'
        //   //   }
        //   // },
        //   onclick: function () {
        //     // alert('clicked');
        //     // if (obj.chartUnitType === CHART_UNIT_TYPES.TEMPERATURE) {
        //     //   self.emitHandleTemperatureConvertion.emit();
        //     // } else {
        //     //   self.emitHandlePressureConvertion.emit();
        //     // }
        //     self.emitHandleOnClickPrevBtn.emit();
        //   },
        //   x: '-0%',
        //   y: 90,
        //   theme: {
        //     /* 'stroke-width': 1, */
        //     /* stroke: 'silver', */
        //     /* fill: '#71CECA', */
        //     // width: 20,
        //     height: 25,
        //     padding: 10,
        //     // x: -10,
        //     r: 50,
        //     // x: 15,
        //     //   y: 30,
        //     // top: -10,
        //     // left: 10,
        //     // margin: '100px',
        //     // color: false ? '#242A34' : '#FFFFFF',
        //     // fill: 'none',
        //     fill: '#71CECA',
        //     style: {
        //       // color: '#FFFFFF',
        //       color: '#242A34',
        //       // fontSize: '10px',
        //       fontSize: '2.4rem',
        //       // margin: '100px',
        //       // fontFamily: 'cursive',
        //       fontFamily: 'Lato-Regular',
        //       fontWeight: 700,
        //       // textAlign: 'center',
        //       // verticalAlign: 'middle',
        //       // x: 15,
        //       // y: 30,
        //       // top: -10,
        //       // left: 10,
        //       // padding: 10
        //     },
        //     states: {
        //       hover: {
        //         // fill: '#a4edba'
        //         // fill: 'none'
        //         fill: '#71CECA',
        //       },
        //       // select: {
        //       //   stroke: '#039',
        //       //   fill: '#a4edba'
        //       // }
        //     }
        //   }
        // } : null
      ]
    };    
  }

  handleNotchDisplay(obj)
  {
    console.log(obj,"annobj")
    let info=[];
    if(obj!=undefined)
    {
 obj.forEach(ele => {
      let data  = {point: {
                xAxis: 0,
                yAxis: 0,
                x: ele.alerttime,
                y: 0,
              },
              text: Highcharts.dateFormat('%d %b %Y %l:%M %p ', ele.alerttime),
              height: 250,
              width: 10,
              backgroundColor: '#2A3343',
              color: '#ffffff',
              borderColor: '#898989',
            }

            info.push(data)
    });

            console.log("inof",info)
    return info
    }
   
  }
  drawSplineChart(obj) {
     console.log("obj",obj)
    //debugger
    // const plotBandsData = this.plotBandsData;
    // debugger
    // // obj.chartData && obj.chartData.plotBandsData;
    // let minValue = obj.data[0].data[0][1];
    // let maxValue = obj.data[0].data[0][1];
    // obj.data[0].data.map((item) => {
    //   minValue = (item[1] < minValue) ? item[1] : minValue;
    //   maxValue = (item[1] > maxValue) ? item[1] : maxValue;
    // });
    let maxValue = null;
    let tickInterval = null;
    if (this.chartData && this.chartData.title &&
      this.chartData.title === 'Cooling' ||
      this.chartData.title === 'Fan/Heat Pump' ||
      this.chartData.title === 'Heating Furnace') {
      maxValue = 1;
      tickInterval = 1;
    }

    const self = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: '',
      },
      time: {
        useUTC: false,
      },
    });
    this.options = {

      chart: {
        type: 'spline',
        backgroundColor: '#2A3343',
        // zoomEnabled: true,
        height: this.heightStyle
      },
      exporting: this.display?this.handleExportingButtons(obj):'',

      legend: {
        enabled: true,
        itemStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontWeight: 100,
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '1.3rem',
        },
        itemHoverStyle: {
          color: 'rgba(255,255,255,0.85)'
        },
        itemHiddenStyle: {
          color: 'rgba(255,255,255,0.85)'
        },
        useHTML: true,
        symbolWidth: 0,
        labelFormatter: function () {
          // console.log("Z", this.name , '=', this.selected);
          // debugger
          // this.selected = !this.selected;
          // return '<input checked type="checkbox"></input>' + this.name
          return `<div class='legend_wrapper display_flex align-items_center'>
          <input ${this.selected ? 'checked' : ''} class="styled-checkbox" type="checkbox" />
          <span class="replacement-checkbox ${this.userOptions && this.userOptions.checkBoxClassName} background_white mr5"></span>
          <span class="${this.userOptions && this.userOptions.checkBoxClassName}_graph_label">
          ${this.name}
          </span>
          <div>`;
        },
        // events: {
        //   labelFormatter: function (event){
        //     debugger
        //   }
        // }
      },

      credits: {
        enabled: false
      },

      title: {
        text: obj.title,
        style: {
          color: 'rgba(255,255,255,0.85)',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '2rem'
        },
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        //type: 'datetime',
        // lineWidth:1.5,
        // startOnTick: true,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: 'rgba(255,255,255,0.85)',
        // tickWidth: 1.5,
        tickWidth: 1,
        // tickColor: 'black',
        tickColor: 'rgba(255,255,255,0.85)',
        tickLength: 7,

        labels: {

          // step: 15,
          // step: 30,
          style: {
            color: 'rgba(255,255,255,0.85)',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
          formatter: function () {
            // debugger
            // console.log("value", this.value)
            var output = ''
            if (this.isFirst) {
              output = Highcharts.dateFormat(
                '%l:%M%p <br> %m-%d-%Y',
                this.value
              );
              obj.emptydata1 = Highcharts.dateFormat(
                '%d-%m-%Y',
                this.value
              ).toString()
              // console.log(obj.emptydata1)

            }
            else {
              if (Highcharts.dateFormat('%d-%m-%Y', this.value).toString() === obj.emptydata1) {
                output = Highcharts.dateFormat('%l:%M%p', this.value);
              }
              else {
                // console.log(obj.emptydata1)
                obj.emptydata1 = Highcharts.dateFormat('%d-%m-%Y', this.value).toString();
                output = Highcharts.dateFormat(
                  '%l:%M%p <br> %m-%d-%Y',
                  this.value
                );
              }

            }
            // console.log('AAAAAAAAAA', output);
            return output;
          },
        },

        // dateTimeLabelFormats: {
        //   second: '%Y-%m-%d<br/>%I:%M:%S %p',
        //   minute: '%Y-%m-%d<br/>%I:%M %p',
        //   hour: '%Y-%m-%d<br/>%I:%M %p',
        //   day: '%Y<br/>%m-%d',
        //   week: '%Y<br/>%m-%d',
        //   month: '%Y-%m',
        //   year: '%Y'
        // },
        title: {
          // text: 'Time Duration',

          style: {
            color: 'rgba(255,255,255,0.85)',
            // fontWeight: 'bold',
            fontFamily: 'SourceSansPro-Regular',
            // fontSize: '1.2rem'
          },
        },


        allowDecimals: false,
        // tickInterval: 5000 * 1000

        // tickInterval: Math.ceil(tickIntervalValue),
        // tickInterval: 500 * 1000,
        // tickPositioner: function () {
        //   var positions = [],
        //     min = Math.floor(this.min),
        //     max = Math.ceil(this.max),
        //     tick = min,
        //     increment = Math.ceil((max - min) / 10);

        //   for (; tick < max; tick += increment) {
        //     positions.push(tick);
        //   }
        //   positions.push(max);
        //   return positions;
        // },
        plotBands: self.plotBandsData,
        // crosshair: false

      },
      yAxis: {
        title: {
          text: obj.yAxisTitle,
          style: {
            color: 'rgba(255,255,255,0.85)',
            fontWeight: 100,
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.3rem',
          },
        },
        opposite: false,
         min: null,
        max: maxValue,
        tickInterval: tickInterval,
        gridLineWidth: 0,        // Hide Horizontal line
        // tickColor: 'black',
        tickColor: 'rgba(255,255,255,0.85)',
        tickLength: 7,
        // tickWidth: 1.5,
        tickWidth: 1,
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: 'rgba(255,255,255,0.85)',
        tickPosition: 'outside',

        labels: {
          style: {
            color: 'rgba(255,255,255,0.85)',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
        },

      },
      // tooltip: {
      //   headerFormat: '<b>{series.name}</b><br>',
      //   pointFormat: '{point.x:.2f} {point.y:.2f}'
      // },

      // tooltip: {
      //   split: false,
      //   shared: true,
      //   crosshairs: {
      //     enabled: false
      //   },
      //   // formatter: function() {
      //   //   debugger
      //   // }
      // },

      tooltip: {
        split: false,
        shared: true,
        // crosshairs: {
        //   enabled: false
        // },
        useHTML: true,
        formatter: function () {
          // debugger54%
          // debugger
          const dateInUTC = Date.UTC(
            new Date(this.x).getFullYear(),
            new Date(this.x).getMonth(),
            new Date(this.x).getDate(),
            new Date(this.x).getHours(),
            new Date(this.x).getMinutes(),
            new Date(this.x).getSeconds()
          );
          // let date: any = new Date(dateInUTC);
          let toolTipUnit = obj.unit === COMMON_CONSTANTS.FAHRENHEIT ? '°F' : '°C';
          let supplyValue: any = '-';
          let returnValue: any = '-';
          // if (this.series && this.series.userOptions && this.series.userOptions.name &&
          //   this.series.userOptions.name === 'Cooling' ||
          //   this.series.userOptions.name === 'Fan' ||
          //   this.series.userOptions.name === 'Heat Pump' ||
          //   this.series.userOptions.name === 'Heating Furnace') {
          //   const binaryState = this.y ? 'ON' : 'OFF';
          //   return '<b> Time: ' + Highcharts.dateFormat('%I:%M %p', this.x) + '<br>' + binaryState + '</b>';

          // } else {
          if (this.points && this.points.length && this.points[0].series &&
            this.points[0].series.userOptions && this.points[0].series.userOptions.name &&
            this.points[0].series.userOptions.name === 'Total Static Pressure') {
            toolTipUnit = obj.unit === COMMON_CONSTANTS.PASCAL ? 'Pa' : '"WC';
            supplyValue = this.points[0].point.s != null ? (this.points[0].point.s).toFixed(0) + ' ' + toolTipUnit : 'NA';
            returnValue = this.points[0].point.r != null ? (this.points[0].point.r).toFixed(0) + ' ' + toolTipUnit : 'NA';
            // if (this.points[0].point.s != null || this.points[0].point.r != null) {
            return '<b> Time: ' + Highcharts.dateFormat('%l:%M %p', this.x) + '<br/>' +
              'Value: ' + (this.y).toFixed(0) + ' ' + toolTipUnit + '<br/><br/>' +
              'Supply Pressure: ' + supplyValue + '<br>' +
              'Return Pressure: ' + returnValue + '</b>';
            // }
          }
          //   if (this.series && this.series.userOptions && this.series.userOptions.name && this.series.userOptions.name === 'Differential Static Pressure' ||
          //     this.series.userOptions.name === 'Supply/Return Static Pressure Difference') {
          //     toolTipUnit = obj.unit === COMMON_CONSTANTS.PASCAL ? 'Pa' : '"WC';
          //     return '<b>Time: ' + '' + Highcharts.dateFormat('%I:%M %p', this.x) + '<br>' +
          //       'Value: ' + (this.y).toFixed(2) + ' ' + toolTipUnit + '</b>';

          //   }
          //   if (this.series && this.series.userOptions && this.series.userOptions.name &&
          //     this.series.userOptions.name === 'Discharge Air Temperature' || this.series.userOptions.name === 'Return Air Temperature') {
          //     return '<b>Time: ' + Highcharts.dateFormat('%I:%M %p', this.x) + '<br>' +
          //       'Value: ' + (this.y).toFixed(2) + ' ' + toolTipUnit + '<br>' +
          //       'Delta: ' + (this.point.z).toFixed(2) + ' ' + toolTipUnit + '</b>';
          //   }
          //   else {
          // return '<b>Time: ' + Highcharts.dateFormat('%I:%M %p', this.x) + '<br>' +
          //   'Value: ' + (this.y).toFixed(2) + ' ' + toolTipUnit + "</b>";
          //   }

          // }

          let tooltipValue = 'Time:' + '<b>' +
            Highcharts.dateFormat('%l:%M%p', this.x) + '</b>' + '<br/>';
          this.points && this.points.length && this.points.map((value, index) => {
            if (value.series && value.series.userOptions && value.series.userOptions.name &&
              (value.series.userOptions.name === 'Total Static Pressure' ||
                value.series.userOptions.name === 'Differential Static Pressure')) {
              toolTipUnit = obj.unit === COMMON_CONSTANTS.PASCAL ? 'Pa' : '"WC';
            }

            tooltipValue += '<div style="display: flex; align-items: center;"><span style="border-radius: 50%; width: 1rem; height: 1rem; margin-right: 0.5rem; background-color:' + value.series.userOptions.color + '"></span>' +
              value.series.userOptions.name + ':' +
              '<b>' + (value.y).toFixed(0) + ' ' + toolTipUnit + '</b>' + '<br></div>';

            if (value.series.userOptions.name === 'Supply Air Temperature') {
              tooltipValue += 'Delta:' + '<b>' +
                (value.point && value.point.z ? value.point.z.toFixed(0) : '') +
                ' ' + toolTipUnit + '</b>' + '<br>';
              // tooltipValue += 'Ambient Temp:' + '<b>' +
              //   (value.point && value.point.z1 ? value.point.z1.toFixed(2) : '') +
              //   ' ' + toolTipUnit + '</b>' + '<br>';
            }
          });
          return tooltipValue;
        }
      },

      plotOptions: {
        spline: {
          marker: {
            enabled: false
          }
        },
        series: {
          gapSize: 10,
          gapUnit: "relative",
          // showCheckbox: true,
          connectNulls: false, // by default
          // visible: false,
          events: {
            checkboxClick: function (event) {
              // debugger  
              // console.log("Z-", this)
              if (!event.checked) {
                //   event.target.userOptions.visible = false;
                //   // this.legendSymbol.hide();
                //   this.update({
                //     // dataLabels:{
                //     //     enabled: true
                //     // },
                //     // series: {
                //     //   visible: false
                //     // }
                // });
                this.hide();
                this.selected = false;
              } else {
                this.selected = true;
                this.show();
              }
            },
            legendItemClick: function () {
                             console.log(this.userOptions.checkBoxClassName,"checkbox")
               if(localStorage.getItem(this.checkBoxClassName))
               {
                 this.selected = !this.selected;
                 this.selected == true? localStorage.setItem(this.userOptions.checkBoxClassName,"true"):localStorage.setItem(this.userOptions.checkBoxClassName,"false")
               }
               else
               {
                  this.selected = !this.selected;
                 this.selected == true? localStorage.setItem(this.userOptions.checkBoxClassName,"true"):localStorage.setItem(this.userOptions.checkBoxClassName,"false")
               }
             
            }
          }
        },
        // line: {
        //   events: {
        //     checkboxClick: function (event){
        //             debugger  
        //       if(event.checked) {
    
        //       }
        //     }
        //   }
        // }
      },

      rangeSelector: {
        // inputEnabled: false,
        enabled: false
      },

      // colors: ['#71CECA', '#F89308', '#F24766', '#529AD5', '#06C', '#036', '#000'],

      // series: [
      //   {
      //     name: obj.yAxisTitle,
      //     data: obj.data[0].data,
      //   },
      // ]

      series: obj.data,

      // series: [
      //  obj.data.map((value) => {
      //    debugger
      //    return value;
      //  })
      // ]

      // series: obj.supplyAirTemperature

      navigator: {
        xAxis: {
          labels: {

          // step: 15,
          // step: 30,
          style: {
            color: '#FFFFFF',
            fontWeight: 'bold',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
          formatter: function () {
            // debugger
            // console.log("value", this.value)
            var output = ''
            if (this.isFirst) {
              output = Highcharts.dateFormat(
                '%l:%M%p',
                this.value
              );
              obj.emptydata1 = Highcharts.dateFormat(
                '%d-%m-%Y',
                this.value
              ).toString()
              // console.log(obj.emptydata1)

            }
            else {
              if (Highcharts.dateFormat('%d-%m-%Y', this.value).toString() === obj.emptydata1) {
                output = Highcharts.dateFormat('%l:%M%p', this.value);
              }
              else {
                // console.log(obj.emptydata1)
                obj.emptydata1 = Highcharts.dateFormat('%d-%m-%Y', this.value).toString();
                output = Highcharts.dateFormat(
                  '%l:%M%p',
                  this.value
                );
              }

            }
            // console.log('AAAAAAAAAA', output);
            return output;
          },
        },
      
      },
        
    },

     annotations: [
        {
          labels: this.handleNotchDisplay(obj.annotation)
        },
      ],
    }
    
    
    // Highstock.chart(obj.element, this.options);
    this.fromReport ? Highcharts.chart(this.chartContainer.nativeElement, this.options) : Highstock.stockChart(this.chartContainer.nativeElement, this.options);

  }

  drawColumnChart(obj) {
    console.log(obj)
    // debugger
    this.options = {
      chart: {
        type: 'column',
        backgroundColor: '#2A3343',
      },
      exporting: this.display?this.handleExportingButtons(obj):'',
      title: {
        // text: 'Step line types, with null values in the series'
        text: obj.title,
        style: {
          color: '#DFE1E3',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '2rem'
        },
      },
      legend: {
        enabled: true,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        squareSymbol: false,
        useHTML: true,
       
        labelFormatter: function () {
          let name = this.name=="Hour(s)"?"Hours":this.name;
          return '<div class="wrapper" style="display:flex">' +
            '<div style="margin-right:1rem"><div class="square" style="background:' + this.color + ';height:0.2rem;width:2rem;margin-top: 0.8rem;"></div></div>' +
            '<div class="label" style="color:#DFE1E3;font-weight:100;font-size:1.4rem;font-family:SourceSansPro-Regular;">' + name + '</div>' +
            '</div>';
        },
        itemStyle: {
          color: '#DFE1E3',
          fontWeight: 100,
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '1.4rem',
        },
        itemHoverStyle: {
          color: '#DFE1E3'
        },
        itemHiddenStyle: {
          color: '#DFE1E3'
        },
        // symbolHeight: 10,
        //     symbolWidth: 10,
        //     symbolRadius: 10
        legendSymbol: 'line',
      },
      tooltip: {
        xDateFormat: '%m-%d-%y',

      },
      xAxis: {
        type: 'datetime',
        // categories: ['Device 1', 'Device 2', 'Device 3', 'Device 4', 'Device 5', 'Device 6'],
        // categories: [
        //   1601918538000,
        //       1601918598000,
        //       1601918658000,
        //       1601918718000,
        //       1601918778000,
        //       1601918838000,
        //       1601918898000,
        //       1601918958000,
        //       1601919018000,
        //       1601919078000
        //   ],
        categories: obj.categoriesData,
        labels: {
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
          formatter: function () {
            // debugger
            var output = ''
            // if (this.isFirst) {
            output = Highcharts.dateFormat(
              '%m-%d-%y',
              this.value
            );
            // obj.emptydata1 = Highcharts.dateFormat(
            //   '%d-%m-%Y',
            //   this.value
            // ).toString()
            // console.log(obj.emptydata1)

            // }

            // console.log('AAAAAAAAAA', output);
            return output;
          },
        },
      },

      yAxis: {
        title: {
          text: obj.yAxisTitle,
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.4rem'
          },
        },
           min: 0,
    minRange: 2,
        gridLineWidth: 0,// Hide Horizontal line
        // tickColor: 'black',
        tickColor: '#DFE1E3',
        tickLength: 7,
        // tickWidth: 1.5,
        tickWidth: 1,
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: '#DFE1E3',
        tickPosition: 'outside',

        labels: {
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
        },

      },

      plotOptions: {
        column: {
          grouping: false
        },
        legend: {
          cursor: 'default',
          useHTML: true,
          labelFormatter: function () {
            return '<span title="' + this.name + '">' + this.name + '</span>';
          }
          /* itemStyle: {
              color: '#ff0000'
          },
          itemHoverStyle: {
              color: null
          },
          itemHiddenStyle: {
              color: '#0000ff'
          } */
        },

        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            style: {
              // fontWeight: 'bold',
              color: '#FFC06A',
              fontFamily: 'SourceSansPro-Regular',
              fontSize: '1.4rem',
              // stroke: '#FFC06A'
              // borderWidth: 0,
              textOutline: 0
            },
            formatter: function () {
              if(this.point&&this.point.t){
                parseInt(this.point.t).toFixed(0);
              }
              return this.point.t;
            }
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          states: {
            inactive: {
              opacity: 1
            }
          }
        }
      },

      /*  xAxis: {
       
       title: {
         text: `Avg 
           <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M1.32513 9.75L6.00049 1.95774L10.6758 9.75H1.32513Z" stroke="#000000" stroke-width="1.5"/>
       </svg>T`,
         useHTML: true,
       
         style: {
           color: 'rgba(255,255,255,0.85)',
           fontWeight: 100,
           fontFamily: 'SourceSansPro-Regular',
           fontSize: '1.3rem',
           textAlign: 'center'
         },
       
       }
              }, */

      //       series: [{
      //         data: [
      //           { y: 29.9, t: "12:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" }
      //         ]
      //       },
      //       {
      //         name: `Avg <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      // <path d="M1.32513 9.75L6.00049 1.95774L10.6758 9.75H1.32513Z" stroke="#000000" stroke-width="1.5"/></svg>`,
      //         color: '#71CECA',
      //         /* visible: false,*/
      //         /* data: [
      //           {y:29.9,t:"12:45"}, 
      //           {y:71.5,t:"14:45"}
      //         ] */
      //       }
      //       ]

      series: obj.data,
      credits: {
        enabled: false
      },
    }
    
    Highcharts.chart(this.chartContainer.nativeElement, this.options);
  }

  drawLineChart(obj) {
    // debugger
    // Highcharts.setOptions({
    //   lang: {
    //     thousandsSep: '',
    //   },
    //   time: {
    //     useUTC: false,
    //   },
    // });

    this.options = {
      chart: {
        type: 'line',
        backgroundColor: '#2A3343',
      },
      exporting: this.display?this.handleExportingButtons(obj):'',
      title: {
        // text: 'Step line types, with null values in the series'
        text: obj.title,
        style: {
          color: '#DFE1E3',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '2rem'
        },
      },
      legend: {
        enabled: true,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        squareSymbol: true,
        useHTML: true,
        labelFormatter: function () {
          return '<div class="wrapper" style="display:flex">' +
            '<div style="margin-right:1rem"><div class="square" style="background:' + this.color + ';height:1.5rem;width:1.5rem;margin-top: 0.2rem;"></div></div>' +
            '<div class="label" style="color:#DFE1E3;font-weight:100;font-size:1.4rem;font-family:SourceSansPro-Regular;">' + this.name + '</div>' +
            '</div>';
        },
        itemStyle: {
          color: '#DFE1E3',
          fontWeight: 100,
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '1.4rem',
        },
        itemHoverStyle: {
          color: '#DFE1E3'
        },
        itemHiddenStyle: {
          color: '#DFE1E3'
        }
      },
      tooltip: {
        xDateFormat: '%m-%d-%y',
        valueSuffix: obj.unit == COMMON_CONSTANTS.CELCIUS?'°C':'°F'
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          connectNulls: false
        }
      },
      xAxis: {
        type: 'datetime',
        tickColor: '#DFE1E3',
        tickLength: 7,
        // tickWidth: 1.5,
        tickWidth: 1,
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: '#DFE1E3',
        labels: {
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
          formatter: function () {
            // debugger
            return Highcharts.dateFormat('%m-%d-%y', this.value);
          }
        },
        // dateTimeLabelFormats: {
        //   second: '%Y-%m-%d<br/>%I:%M:%S %p',
        //   minute: '%Y-%m-%d<br/>%I:%M %p',
        //   hour: '%Y-%m-%d<br/>%I:%M %p',
        //   day: '%Y<br/>%m-%d',
        //   week: '%Y<br/>%m-%d',
        //   month: '%Y-%m',
        //   year: '%Y'
        // },
        // categories: [
        //   1601918538000,
        //       1601918598000,
        //       1601918658000,
        //       1601918718000,
        //       1601918778000,
        //       1601918838000,
        //       1601918898000,
        //       1601918958000,
        //       1601919018000,
        //       1601919078000
        //   ]
        // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      },
      yAxis: {
        title: {
          text: obj.yAxisTitle,
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.4rem'
          },
        },
        gridLineWidth: 0,// Hide Horizontal line
        // tickColor: 'black',
        tickColor: '#DFE1E3',
        tickLength: 7,
        // tickWidth: 1.5,
        tickWidth: 1,
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: '#DFE1E3',
        tickPosition: 'outside',

        labels: {
          style: {
            color: '#DFE1E3',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem'
          },
        },

      },
      // series: [{
      //   marker: {
      //     fillColor: '#FFFFFF',
      //     /* lineColor: null // inherit from series */
      //   },
      //   /* data: [1, 3, 2.5, 4, 3.5, 6, 7, 6.5, 9], */
      //   // data: [['Jan', 1], ['Feb', 3], ['Mar', 2.5], ['Apr', 4], ['May', 3.5], ['Jun', 6], ['Jul', 7], ['Aug', 6.5], ['Sep', 9]],
      //   /* step: 'right', */
      //   // data:[
      //   //   22.4,
      //   //   23.9,
      //   //   22.3,
      //   //   22.2,
      //   //   23.1,
      //   //   22.9,
      //   //   23.7,
      //   //   23.6,
      //   //   22.6,
      //   //   22.5
      //   // ],   
      //   data:[
      //     [1601918538000, 22.4],
      //     [1601918598000, 23.9],
      //     [1601918658000, 22.3],
      //     [1601918718000, 22.2],
      //     [1601918778000, 23.1],
      //     [1601918838000, 22.9],
      //     [1601918898000, 23.7],
      //     [1601918958000, 23.6],
      //     [1601919018000, 22.6],
      //     [1601919078000, 22.5]
      //   ],
      //   name: 'Right'
      // },
      //   /* {
      //       data: [5, 6, 7, 8, null, 10, 11, null, 13],
      //       step: 'center',
      //       name: 'Center'
      //   }, {
      //       data: [9, 10, 11, 12, null, 14, 15, null, 17],
      //       step: 'left',
      //       name: 'Left'
      //   } */
      // ],
      series: obj.data,
      credits: {
        enabled: false
      },
    }
    
    Highcharts.chart(this.chartContainer.nativeElement, this.options);
  }

}

// // var supplyAirTemp;

// drawSupplyAirTemprature(data,fdate,tdate) {
//   var supplyAirTemp;
//     let supplyAirTemperature=[];
//     let returnAirTemp=[];
// data.alerts.forEach(info=>{
//     let a={};
//     a['x'] = new Date(info.timestamp);
//     a['y'] = info.SupplyAirTemp;
//     supplyAirTemperature.push(a);
//     let b={};
//     b['x'] = new Date(info.timestamp);
//     b['y']= info.ReturnAirTemp;
//     returnAirTemp.push(b);
// })

// console.log("supp",supplyAirTemperature);
// if(fdate=="empty")
// {
//     fdate = this.getYesterdayDate();
//     tdate = this.getTodayDate();
// }
// console.log(data,supplyAirTemperature,returnAirTemp);
//     supplyAirTemp = new CanvasJS.Chart("supplyAirTemp", { 
//         theme: 'dark2',
//         width: 700,

//         title: {
//             text: "Air Temperature",
//         },
//         toolTip:{   
// 			content: "{x}: {y}°F"      
//         },
//         axisX : {
//             title: 'Time(Dated '+fdate+' - '+tdate+')',
//             labelangle:90,
//             // interval:4, 
//         //intervalType: "hour",        
//         valueFormatString: "DD MMM,HH:mm", 
//         },
//         zoomEnabled:true,
//         axisY : {
//             title: 'Temperature',
//             suffix: " °F"
//         },
//         //xValueType: "dateTime",
//         // xValueFormatString:"DD-MMM hh:mm tt",
//         data: [{
//                     type:"spline",
//                     name: "Supply Air Temp",
//                     showInLegend: true,
//                     markerSize: 0,
//                     xValueFormatString: "YYYY-MM-DD hh:mm:ss",
//                     dataPoints: supplyAirTemperature
//                 },
//                 {
//                     type: "spline",
//                     name: "Return Air Temp",
//                     showInLegend: true,
//                     markerSize: 0,
//                     // xValueFormatString: "After",
//                     // yValueFormatString: "°F",
//                     xValueFormatString: "YYYY-MM-DD hh:mm:ss",
//                     dataPoints: returnAirTemp
//                 }
//             ],

//     });
//     supplyAirTemp.render();	
// }

