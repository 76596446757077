export class LoginResponse {
    userName: string;
    idToken: string;
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    authTime: number;
    status: string;

    constructor(data) {
        this.userName = data.userName;
        this.idToken = data.idToken;
        this.accessToken = data.accessToken;
        this.refreshToken = data.refreshToken;
        this.expiresIn = data.expiresIn;
        this.authTime = data.authTime;
        this.status = data.status;
    }
}

export class LoginResponseDetails {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: LoginResponse;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new LoginResponse(data.responseData);
        this.requestData = data.requestData;
    }
}


export class ForgotPasswordResponseDetails {
    statusCode: string;
    statusMessage: string;
    statusInfo: string;
    responseData?: any;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}

export class ResetPasswordResponseDetails {
    statusCode: string;
    statusMessage: string;
    statusInfo: string;
    responseData?: any;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}
