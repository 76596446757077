export class ComfortbridgeObject {
    ac: ACObject;
    system: SystemObject;
    furnace: GasFurnaceObject;
    heatPump: HEATPUMPObject;
    constructor(data) {
        this.ac = data.ac && new ACObject(data.ac);
        this.system = data.system && new SystemObject(data.system);
        this.furnace = data.furnace && new GasFurnaceObject(data.furnace);
        this.heatPump = data.heatPump && new HEATPUMPObject(data.heatPump);
    }
}
export class SystemObject {
    config: Config;
    status: Status;
    constructor(data) {
        this.config = data.config && new Config(data.config);
        this.status = data.status && new Status(data.status)
    }
}

export class Status {
    HVACOperationMode: string;
    heatVentMode: string;
    tstat24Wterminal: string;
    tstat24Yterminal: string;
    tstat24Gterminal: string;
    constructor(data) {
        this.HVACOperationMode = data.HVACOperationMode;
        this.heatVentMode = data.heatVentMode;
        this.tstat24Wterminal = data.tstat24Wterminal;
        this.tstat24Yterminal = data.tstat24Yterminal;
        this.tstat24Gterminal = data.tstat24Gterminal;
    }
}

export class Config {
    compressorHeatLockout: number;
    setPointSafetyRate: number;
    customTargetRange: number;
    customStageUpPercentage: number;
    customStageDownPercentage: number;
    constructor(data) {
        this.compressorHeatLockout = data.compressorHeatLockout;
        this.setPointSafetyRate = data.setPointSafetyRate;
        this.customTargetRange = data.customTargetRange;
        this.customStageUpPercentage = data.customStageUpPercentage;
        this.customStageDownPercentage = data.customStageDownPercentage;
    }
}

export class GasFurnaceObject {
    config: GF_Config;
    status: GF_Status;
    constructor(data) {
        this.config = data.config && new GF_Config(data.config);
        this.status = data.status && new GF_Status(data.status)
    }
}

export class GF_Status {
    constantFanAirFlowMultiplier: string;
    gasHeatAirFlowTrim: string;
    airFlowForHighStageGasHeat: string;
    airFlowForLowStageGasHeat: string;
    gasHeatOnDelay: string;
    HVACOperationMode: string;
    accessoryDeviceOperatingMode: string;
    tstat24Wterminal: string;
    tstat24Yterminal: string;
    tstat24Gterminal: string;
    tstat24DHY2Terminal: string;
    gasHeatDevicePercentage: string;
    currentAirFlow: string;
    hvacoperationMode: string;

    constructor(data) {
        this.constantFanAirFlowMultiplier = data.constantFanAirFlowMultiplier;
        this.gasHeatAirFlowTrim = data.gasHeatAirFlowTrim;
        this.airFlowForHighStageGasHeat = data.airFlowForHighStageGasHeat;
        this.airFlowForLowStageGasHeat = data.airFlowForLowStageGasHeat;
        this.gasHeatOnDelay = data.gasHeatOnDelay;
        this.HVACOperationMode = data.HVACOperationMode;
        this.accessoryDeviceOperatingMode = data.accessoryDeviceOperatingMode;
        this.tstat24Wterminal = data.tstat24Wterminal;
        this.tstat24Yterminal = data.tstat24Yterminal;
        this.tstat24Gterminal = data.tstat24Gterminal;
        this.tstat24DHY2Terminal = data.tstat24DHY2Terminal;
        this.gasHeatDevicePercentage = data.gasHeatDevicePercentage;
        this.currentAirFlow = data.currentAirFlow;
        this.hvacoperationMode = data.hvacoperationMode;
    }


}

export class GF_Config {
    firmware: string;
    sharedDataVersion: string;
    blowerMotorHorsePower: string;
    maxAirFlow: string;
    furnaceModelNumber: string;
    constructor(data) {
        this.firmware = data.firmware;
        this.sharedDataVersion = data.sharedDataVersion;
        this.blowerMotorHorsePower = data.blowerMotorHorsePower;
        this.maxAirFlow = data.maxAirFlow;
        this.furnaceModelNumber = data.furnaceModelNumber;

    }
}


export class ACObject {
    config: AC_Config;
    status: AC_Status;
    constructor(data) {
        this.config = data.config && new AC_Config(data.config);
        this.status = data.status && new AC_Status(data.status)
    }
}

export class AC_Status {
    coolCapacityRequest: string;
    dehumdificationRequest: string;
    outdoorFanSpeed: string;
    reportedAirFlow: string;
    constructor(data) {
        this.coolCapacityRequest = data.coolCapacityRequest;
        this.dehumdificationRequest = data.dehumdificationRequest;
        this.outdoorFanSpeed = data.outdoorFanSpeed;
        this.reportedAirFlow = data.reportedAirFlow;
    }
}

export class AC_Config {
    firmware: string;
    serialNumber: string;
    coolTrimFactorHigh: string;
    coolingAirflowProfile: string;
    blowerOnDelayCooling: string;
    blowerOffDelayCooling: string;
    dehumdificationEnable: string;
    acModelNumber: string;

    constructor(data) {
        this.firmware = data.firmware;
        this.serialNumber = data.serialNumber;
        this.coolTrimFactorHigh = data.coolTrimFactorHigh;
        this.coolingAirflowProfile = data.coolingAirflowProfile;
        this.blowerOnDelayCooling = data.blowerOnDelayCooling;
        this.blowerOffDelayCooling = data.blowerOffDelayCooling;
        this.dehumdificationEnable = data.dehumdificationEnable;
        this.acModelNumber = data.acModelNumber;
    }
}


export class HEATPUMPObject {
    config: HP_Config;
    status: HP_Status;
    constructor(data) {
        this.config = data.config && new HP_Config(data.config);
        this.status = data.status && new HP_Status(data.status)
    }
}

export class HP_Status {
    heatCapacityRequest: string;
    coolCapacityRequest: string;
    heatCapacityRequestDuringDefrost: string;
    dehumidificationRequest: string;
    reversingValue: string;
    outdoorFanSpeed: string;
    reportedAirFlow: string;
    constructor(data) {
        this.heatCapacityRequest = data.heatCapacityRequest;
        this.coolCapacityRequest = data.coolCapacityRequest;
        this.heatCapacityRequestDuringDefrost = data.heatCapacityRequestDuringDefrost;
        this.dehumidificationRequest = data.dehumidificationRequest;
        this.reversingValue = data.reversingValue;
        this.outdoorFanSpeed = data.outdoorFanSpeed;
        this.reportedAirFlow = data.reportedAirFlow;
    }
}

export class HP_Config {
    outdoorTemperature: number;
    coilTemperature: number;
    liquidLineTemperature: number;
    dischargeTemperature: number;
    defrostSensorTemperature: number;
    suctionPressure: number;
    heatPumpModelNumber: string;
    constructor(data) {
        this.outdoorTemperature = data.outdoorTemperature;
        this.coilTemperature = data.coilTemperature;
        this.liquidLineTemperature = data.liquidLineTemperature;
        this.dischargeTemperature = data.dischargeTemperature;
        this.defrostSensorTemperature = data.defrostSensorTemperature;
        this.suctionPressure = data.suctionPressure;
        this.heatPumpModelNumber = data.heatPumpModelNumber;
    }
}
export class ComfortBridgeResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: ComfortbridgeObject;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new ComfortbridgeObject(data.responseData);
        this.requestData = data.requestData;
    }
}

export class SystemResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: SystemObject;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new SystemObject(data.responseData);
        this.requestData = data.requestData;
    }
}


export class GFResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: GasFurnaceObject;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new GasFurnaceObject(data.responseData);
        this.requestData = data.requestData;
    }
}
export class ACResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: ACObject;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new ACObject(data.responseData);
        this.requestData = data.requestData;
    }
}
export class HPResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: HEATPUMPObject;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new HEATPUMPObject(data.responseData);
        this.requestData = data.requestData;
    }
}