import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personal-info-types',
  templateUrl: './personal-info-types.component.html',
  styleUrls: ['./personal-info-types.component.scss']
})
export class PersonalInfoTypesComponent implements OnInit {

  @Input() groupName: FormGroup;
  @Input() typeControlName: string;
  @Input() forEmail = false;
  @Input() forPhone = false;
  @Input() isDisabled = false;

  constructor() { }

  ngOnInit() {
  }

}
