import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

// Modules
import { CoreModule } from 'src/app/core/core.module';
import { LayoutModule } from 'src/app/layout/layout.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SharedContractorModule } from 'projects/contractor/src/app/shared-contractor/shared-contractor.module';

// Router
import { ContractorRoutingModule } from './contractor-routing.module';
import { UnsubscribeComponent } from 'src/app/modules/unsubscribe/pages/unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    AppComponent,
    UnsubscribeComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ContractorRoutingModule,
    CoreModule,
    LayoutModule,
    SharedModule,
    SharedContractorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
