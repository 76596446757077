import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Output() downloadErrorFile = new EventEmitter();
  @Input() modalTitle: string;
  @Input() line1Content: any;
  @Input() line2Content: any;
  @Input() line3Content: any;
  @Input() line4Content: any;
  @Input() count: any;
  @Input() hasYesOrNoButtom = false;
  @Output() emitHandleButtonAction = new EventEmitter();
  @Input() isPrimaryActionBtnLoading = false;
  @Input() hasCommentBox = false;
  @Input() isEmergencyShutoff = false;
  @Input() isScheduleBoard=false;
  @Input() isSuccessToasterModal = false;
  @Input() isScheduleError = false;
  @Input() primaryButtonName = 'Close';
  @Input() isForUploadError = false;
  @Input() isForSessionTimeout = false;
  @Input() isForBoldTitle = false;
  @Input() isDisplayLoop = false;
  @Input() isScheduledSuccessInfo=false;
  @Input() isEmergencySuccessInfo=false;
  checkboxText = "I am authorized to shut-off this equipment"
  commentBox = new FormControl(null, Validators.required);
  checkBox = new FormControl(null, Validators.required);
  logoutTimeLeft: number = 60;
  constructor() { }

  ngOnInit() {
    if (this.isForSessionTimeout) {
      this.handleSessionTimeoutModal();
    }

    if(this.isScheduleBoard)
    {
      this.checkboxText = "I am authorized to remotely test this equipment"
    }
  }

  handleEmergencyShutoff() {
    if (this.checkBox && this.checkBox.valid) {
      this.checkBox.reset();
    } else {
      this.checkBox.patchValue('1');
    }
  }

  handlePrimaryBtnValidation() {
    let flag = true;
    if (this.hasCommentBox) {
      flag = false;
      if (this.commentBox.valid) {
        flag = true;
      }
    } else if (this.isEmergencyShutoff) {
      flag = false;
      if (this.checkBox.valid) {
        flag = true;
      }
    }

    if (flag) {
      return 'primary-action_btn font-size_11 min-width_140 cursor_pointer ml10';
    } else {
      return 'invalid-btn font-size_11 min-width_140 cursor_pointer ml10';
    }
  }

  handleSessionTimeoutModal() {
    this.line1Content = `You will be logged out in ${this.logoutTimeLeft}s due to inactivity.`;
    this.line2Content = `Would you like to continue?`;
    setInterval(() => {
      this.logoutTimeLeft--;
      this.line1Content = `You will be logged out in ${this.logoutTimeLeft}s due to inactivity.`;
    }, 1000)
  }

  closeModal() {
    if ( this.isScheduledSuccessInfo || this.isEmergencySuccessInfo) {
      this.emitHandleButtonAction.emit();
    } else {
      this.emitCloseModal.emit();
    }
  }

  downloadFile() {
    this.emitHandleButtonAction.emit();
  }

  handleButtonAction() {
    this.emitHandleButtonAction.emit(this.commentBox.value);
  }

}
