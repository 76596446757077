import { Email, Phone, Address, BoardTypeCount } from 'src/app/shared/models/common.model';
import { InstallationReportDetails } from './inventory.model';

// export class PhoneAndEmail {
//     mobile: string[];
//     home: string[];
//     office: string[];

//     constructor(data) {
//         this.mobile = data.mobile;
//         this.home = data.home;
//         this.office = data.office;
//     }
// }

export class ModelInfo {
    id: number;
    modelNo: string;
    deviceType: string;
    brochureLink: string;

    constructor(data) {
        this.id = data.id;
        this.modelNo = data.modelNo;
        this.deviceType = data.deviceType;
        this.brochureLink = data.brochureLink;
    }
}

export class AssetUnitParameter {
    unitParameterId: number;
    unitParameterName: string;
    unitParameterValue: string;

    constructor(data) {
        this.unitParameterId = data.unitParameterId;
        this.unitParameterName = data.unitParameterName;
        this.unitParameterValue = data.unitParameterValue;
    }
}

export class AssetModelInfo {
    modelNo: string;
    ratedElectricStripCapacity?: string;
    equipment: TypeList;
    indoorEquipmentType: TypeList;
    outdoorEquipmentType: TypeList;
    filterLength: TypeList;
    filterThickness: TypeList;
    filterType: TypeList;
    filterWidth: TypeList;
    fuelType: TypeList;
    heatType: TypeList;
    indoorEquipmentMake: TypeList;
    meteringDevice: TypeList;
    indoorMultiStage: TypeList;
    outdoorEquipmentMake: TypeList;
    outdoorMultiStage: TypeList;
    outdoorNominalACCapacity: TypeList;
    outdoorSeer: TypeList;
    ratedCapacity: TypeList;
    
    refigerantType: TypeList;
    systemConfiguration: TypeList;
    variableSpeedBlower: TypeList;
    locations: TypeList;
    zonedSystem: TypeList;
    assetModelInfoId: number;
    reversingValve:Text;
    heatSource:boolean;
    equipmentIs: TypeList;
    outdoorInverterCompressor: TypeList;

    constructor(data) {
        this.modelNo = data.modelNo;
        this.assetModelInfoId = data.assetModelInfoId;
        this.reversingValve=data.reversingValve;
        this.heatSource = data.heatSource;
        this.ratedElectricStripCapacity = data.ratedElectricStripCapacity;
        this.equipment = data.equipment && new TypeList(data.equipment);
        this.indoorEquipmentType = data.indoorEquipmentType && new TypeList(data.indoorEquipmentType);
        this.outdoorEquipmentType = data.outdoorEquipmentType && new TypeList(data.outdoorEquipmentType);
        this.filterLength = data.filterLength && new TypeList(data.filterLength);
        this.filterThickness = data.filterThickness && new TypeList(data.filterThickness);
        this.filterType = data.filterType && new TypeList(data.filterType);
        this.filterWidth = data.filterWidth && new TypeList(data.filterWidth);
        this.fuelType = data.fuelType && new TypeList(data.fuelType);
        this.heatType = data.heatType && new TypeList(data.heatType);
        this.indoorEquipmentMake = data.indoorEquipmentMake && new TypeList(data.indoorEquipmentMake);
        this.meteringDevice = data.meteringDevice && new TypeList(data.meteringDevice);
        this.indoorMultiStage = data.indoorMultiStage && new TypeList(data.indoorMultiStage);
        this.outdoorEquipmentMake = data.outdoorEquipmentMake && new TypeList(data.outdoorEquipmentMake);
        this.outdoorMultiStage = data.outdoorMultistage && new TypeList(data.outdoorMultistage);
        this.outdoorNominalACCapacity = data.outdoorNominalACCapacity && new TypeList(data.outdoorNominalACCapacity);
        this.outdoorSeer = data.outdoorSeer && new TypeList(data.outdoorSeer);
        this.ratedCapacity = data.ratedCapacity && new TypeList(data.ratedCapacity);
        this.refigerantType = data.refigerantType && new TypeList(data.refigerantType);
        this.systemConfiguration = data.systemConfiguration && new TypeList(data.systemConfiguration);
        this.variableSpeedBlower = data.variableSpeedBlower && new TypeList(data.variableSpeedBlower);
        this.zonedSystem = data.zonedSystem && new TypeList(data.zonedSystem);
        this.locations = data.locations && new TypeList(data.locations);
        this.equipmentIs = data.isEquipment && new TypeList(data.isEquipment);
        this.outdoorInverterCompressor = data.isOutdoorInverter && new TypeList(data.isOutdoorInverter)

    }
}

export class Report {
    comments:[];
    constructor(data) {
        this.comments=data.comments;
    }

}

export class AssetDetail {
    assetUnitTypeId: number;
    assetId:number;
    assetUnitComments: any[];
    assetUnitName: string;
    assetUnitType: string;
    assetUnitSerialNumber: string;
    assetUnitUniqueId?: any;
    inInventoryDate: string;
    installedOn?: string;
    meliId: number;
    meliSerialNumber?: string;
    meliStatus?: string;
    // manufacturerId: number;
    // manufacturerName: string;
    // modelInfo: ModelInfo;
    assetModelInfo: AssetModelInfo;
    images: any[];
    boardConnectivityStatus?: string;
    connectivityStatus?: string;
    subscriptionPlanName?: any;
    subscriptionPlanId: number;
    deviceType?: any;
    report?: Report;
signalStrength:string;
    constructor(data) {
        this.assetUnitTypeId = data.assetUnitTypeId;
        this.assetUnitName = data.assetUnitName;
        this.assetUnitComments = data.assetUnitComments;
        this.assetUnitType = data.assetUnitType;
        this.assetUnitSerialNumber = data.assetUnitSerialNumber;
        this.assetUnitUniqueId = data.assetUnitUniqueId;
        this.installedOn = data.installedOn;
        this.inInventoryDate = data.inInventoryDate;
        this.meliId = data.meliId;
        this.meliSerialNumber = data.meliSerialNumber;
        this.meliStatus = data.meliStatus;
        this.assetId = data.assetId;
        //this.modelInfo = new ModelInfo(data.modelInfo);
        this.assetModelInfo = data.assetModelInfo && new AssetModelInfo(data.assetModelInfo);
        this.report = data.report && new Report(data.report)
        this.images = data.images;
        this.boardConnectivityStatus = data.boardConnectivityStatus;
        this.connectivityStatus = data.connectivityStatus;
        this.subscriptionPlanName = data.subscriptionPlanName;
        this.subscriptionPlanId = data.subscriptionPlanId;
        this.deviceType = data.deviceType;
        this.signalStrength=data.signalStrength;
    }
}

export class Customer {
    customerId: number;
    customerName: string;
    customerFirstName: string;
    customerLastName: string;
    addresses: Address[];
    phone?: Phone[];
    email?: Email[];
    assetId: number;
    assetName: string;
    assetDetails: AssetDetail[];
    awsConsumerId:number;
    companyName?:string;
    consumerType?:string;

    constructor(data) {
        this.customerId = data.consumerId;
        this.customerName = data.consumerName;
        this.customerFirstName = data.consumerFirstName;
        this.customerLastName = data.consumerLastName;
        this.companyName= data.companyName;
        this.consumerType=data.consumerType;
        this.addresses = data.addresses && data.addresses.length &&
            data.addresses.map((value, index) => new Address(value));
        this.phone = data.phone && data.phone.length &&
            data.phone.map((value, index) => new Phone(value));
        this.email = data.email && data.email.length &&
            data.email.map((value, index) => new Email(value));
        this.assetId = data.assetId;
        this.assetName = data.assetName;
        this.assetDetails = data.assetDetails && data.assetDetails.length &&
            data.assetDetails.map((value, index) => new AssetDetail(value));
        this.awsConsumerId=data.awsConsumerId;
        }
}

export class CustomerListResponseDetails {
    page: number;
    size: number;
    customers: Customer[];
    boardCount: BoardTypeCount;
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;

    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.customers = data.content && data.content.consumerList && data.content.consumerList.length &&
            data.content.consumerList.map((value) => new Customer(value));
        this.boardCount = data.content && data.content.boardCount && new BoardTypeCount(data.content.boardCount);
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
    }
}

export class CustomerListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: CustomerListResponseDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new CustomerListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}



export class CustomerListCount {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: CustomerListCountDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new CustomerListCountDetails(data.responseData);
        this.requestData = data.requestData;
    }
}


export class CustomerListCountDetails
{
Cellular: number;
    WiFi: number;

    constructor(data) {
          this.Cellular= data.Cellular
            this.WiFi = data['Wi-Fi']
    }
}




export class AssetDetailResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: AssetDetail;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new AssetDetail(value));
        this.requestData = data.requestData;
    }
}
// export class UnassignedCustomerDetail {
//     customerId: number;
//     customerName: string;

//     constructor(data) {
//         this.customerId = data.consumerId;
//         this.customerName = data.consumerName;
//     }
// }

export class UnassignedCustomersListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    unassignedCustomersList: Customer[];
    requestData: number;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.unassignedCustomersList = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new Customer(value));
        this.requestData = data.requestData;
    }
}

export class Model {
    assetModelInfoId: number;
    modelNo: string;

    constructor(data) {
        this.assetModelInfoId = data.assetModelInfoId;
        this.modelNo = data.modelNo;
    }
}

// export class Phone {
//     id?: any;
//     type: string;
//     phone: string;
// }

// export class Email {
//     id?: any;
//     type: string;
//     email: string;
// }

// export class Address {
//     addressId?: any;
//     addressType?: any;
//     street: string;
//     city: string;
//     state: string;
//     zipcode: string;
// }

export class CreateCustomerDetails {
    customerId?: any;
    customerName: string;
    assetId?: any;
    assetName?: any;
    phone: Phone[];
    email: Email[];
    addresses: Address[];
    assetDetails: AssetDetail[];

    constructor(data) {
        this.customerName = data.consumerName;
        this.customerId = data.consumerId;
        this.assetId = data.assetId;
        this.assetName = data.assetName;
        this.addresses = data.addresses && data.addresses.length &&
            data.addresses.map((value, index) => new Address(value));
        this.phone = data.phone && data.phone.length &&
            data.phone.map((value, index) => new Phone(value));
        this.email = data.email && data.email.length &&
            data.email.map((value, index) => new Email(value));
        this.assetDetails = data.assetDetails && data.assetDetails.length &&
            data.assetDetails.map((value, index) => new AssetDetail(value));
    }
}

export class CreateCustomer {
    customerId: number;
    customerName: string;
    awsConsumerId: string;
    assetId: number;
    assetName?: any;
    addresses: any[];
    phone: any[];
    email: any[];
    homeId: number;
    assetDetails: any[];

    constructor(data) {
        this.customerId = data.consumerId;
        this.customerName = data.consumerName;
        this.awsConsumerId = data.awsConsumerId;
        this.assetId = data.assetId;
        this.assetName = data.assetName;
        this.addresses = data.addresses;
        this.phone = data.phone;
        this.email = data.email;
        this.homeId = data.homeId;
        this.assetDetails = data.assetDetails;
    }
}

export class CreateCustomerResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    createCustomer: CreateCustomer;
    createCustomerDetails: CreateCustomerDetails;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.createCustomer = data.responseData && new CreateCustomer(data.responseData);
        this.createCustomerDetails = data.requestData && new CreateCustomerDetails(data.requestData);
    }
}

export class DeleteCustomerResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData?: any;
    requestData: number[];

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}

export class UnassignCustomerFromMeliDetails {
    meliId: number;
    assetId: number;
    consumerId: number;

    constructor(data) {
        this.meliId = data.meliId;
        this.assetId = data.assetId;
        this.consumerId = data.consumerId;
    }
}

export class UnassignCustomerFromMeliResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: string;
    requestData: UnassignCustomerFromMeliDetails;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData && new UnassignCustomerFromMeliDetails(data.requestData);
    }
}

export class TypeList {
    id: number;
    value: any;

    constructor(data) {
        this.id = data.id;
        this.value = data.value || data.title;
    }
}

export class HVACInformation {
    typeList: TypeList[];
    equipmentList: TypeList[];
    filterLenghtList: TypeList[];
    filterThicknessList: TypeList[];
    filterTypeList: TypeList[];
    filterWidthList: TypeList[];
    indoorFuelTypeList: TypeList[];
    indoorHeatTypeList: TypeList[];
    indoorEquipmentMakeList: TypeList[];
    meteringDeviceList: TypeList[];
    indoorMultiStageList: TypeList[];
    outdoorEquipmentMakeList: TypeList[];
    outdoorMultistageList: TypeList[];
    outdoorNominalACCapacityList: TypeList[];
    outdoorSeerList: TypeList[];
    indoorRatedCapacityList: TypeList[];
    ratedElectricStripCapacityList: TypeList[];
    indoorRefigerantTypeList: TypeList[];
    systemConfigurationList: TypeList[];
    variableSpeedBlowerList: TypeList[];
    zonedSystemList: TypeList[];
    locationsList: TypeList[];
    indoorEquipmentTypeList: TypeList[];
    outdoorEquipmentTypeList: TypeList[];
    isEquipmentList: TypeList[];
    outdoorInverterCompressorList: TypeList[];

    constructor(data) {
        this.typeList = data.typeList && data.typeList.length &&
            data.typeList.map((value) => new TypeList(value));
        this.equipmentList = data.equipmentList && data.equipmentList.length &&
            data.equipmentList.map((value) => new TypeList(value));
        this.filterLenghtList = data.filterLenghtList && data.filterLenghtList.length &&
            data.filterLenghtList.map((value) => new TypeList(value));
        this.filterThicknessList = data.filterThicknessList && data.filterThicknessList.length &&
            data.filterThicknessList.map((value) => new TypeList(value));
        this.filterTypeList = data.filterTypeList && data.filterTypeList.length &&
            data.filterTypeList.map((value) => new TypeList(value));
        this.filterWidthList = data.filterWidthList && data.filterWidthList.length &&
            data.filterWidthList.map((value) => new TypeList(value));
        this.indoorFuelTypeList = data.fuelTypeList && data.fuelTypeList.length &&
            data.fuelTypeList.map((value) => new TypeList(value));
        this.indoorHeatTypeList = data.heatTypeList && data.heatTypeList.length &&
            data.heatTypeList.map((value) => new TypeList(value));
        this.indoorEquipmentMakeList = data.indoorEquipmentMakeList && data.indoorEquipmentMakeList.length &&
            data.indoorEquipmentMakeList.map((value) => new TypeList(value));
        this.meteringDeviceList = data.meteringDeviceList && data.meteringDeviceList.length &&
            data.meteringDeviceList.map((value) => new TypeList(value));
        this.indoorMultiStageList = data.indoorMultiStageList && data.indoorMultiStageList.length &&
            data.indoorMultiStageList.map((value) => new TypeList(value));
        this.outdoorEquipmentMakeList = data.outdoorEquipmentMakeList && data.outdoorEquipmentMakeList.length &&
            data.outdoorEquipmentMakeList.map((value) => new TypeList(value));
        this.outdoorMultistageList = data.outdoorMultistageList && data.outdoorMultistageList.length &&
            data.outdoorMultistageList.map((value) => new TypeList(value));
        this.outdoorNominalACCapacityList = data.outdoorNominalACCapacityList && data.outdoorNominalACCapacityList.length &&
            data.outdoorNominalACCapacityList.map((value) => new TypeList(value));
        this.outdoorSeerList = data.outdoorSeerList && data.outdoorSeerList.length &&
            data.outdoorSeerList.map((value) => new TypeList(value));
        this.indoorRatedCapacityList = data.ratedCapacityList && data.ratedCapacityList.length &&
            data.ratedCapacityList.map((value) => new TypeList(value));
            this.ratedElectricStripCapacityList = data.ratedElectricStripCapacityList && data.ratedElectricStripCapacityList.length &&
            data.ratedElectricStripCapacityList.map((value) => new TypeList(value));
        this.indoorRefigerantTypeList = data.refigerantTypeList && data.refigerantTypeList.length &&
            data.refigerantTypeList.map((value) => new TypeList(value));
        this.systemConfigurationList = data.systemConfigurationList && data.systemConfigurationList.length &&
            data.systemConfigurationList.map((value) => new TypeList(value));
        this.variableSpeedBlowerList = data.variableSpeedBlowerList && data.variableSpeedBlowerList.length &&
            data.variableSpeedBlowerList.map((value) => new TypeList(value));
        this.zonedSystemList = data.zonedSystemList && data.zonedSystemList.length &&
            data.zonedSystemList.map((value) => new TypeList(value));
        this.locationsList = data.locationsList && data.locationsList.length &&
            data.locationsList.map((value) => new TypeList(value));
        this.indoorEquipmentTypeList = data.indoorEquipmentTypeList && data.indoorEquipmentTypeList.length &&
            data.indoorEquipmentTypeList.map((value) => new TypeList(value));
        this.outdoorEquipmentTypeList = data.outdoorEquipmentTypeList && data.outdoorEquipmentTypeList.length &&
            data.outdoorEquipmentTypeList.map((value) => new TypeList(value));
        this.isEquipmentList = data.isEquipmentList && data.isEquipmentList.length &&
            data.isEquipmentList.map((value) => new TypeList(value));
        this.outdoorInverterCompressorList = data.isOutdoorInverterList && data.isOutdoorInverterList.length &&
            data.isOutdoorInverterList.map((value) => new TypeList(value));
    }
}

export class HVACInformationResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    HVACInformation: HVACInformation;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.HVACInformation = data.responseData && new HVACInformation(data.responseData);
        this.requestData = data.requestData;
    }
}

export class SubscriptionPlanListResponseDetails {
    subscriptionPlanId: number;
    planSubscriptionName: string;
    planSubscriptionSubTitle: string;
    planSubscriptionPeriod: string;

    constructor(data) {
        this.subscriptionPlanId = data.planSubscriptionId;
        this.planSubscriptionName = data.planSubscriptionName;
        this.planSubscriptionSubTitle = data.planSubscriptionSubTitle;
        this.planSubscriptionPeriod = data.planSubscriptionPeriod;
    }
}

export class SubscriptionPlanListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    subscriptionPlanList: SubscriptionPlanListResponseDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.subscriptionPlanList = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new SubscriptionPlanListResponseDetails(value));
        this.requestData = data.requestData;
    }
}

export class CellularPlansStatusList {
    meliCellularPlanStatusId: number;
    meliCellularPlanStatusName: string;
    meliCellularPlanStatusSubTitle: string;

    constructor(data) {
        this.meliCellularPlanStatusId = data.meliSubscriptionPlanStatusId;
        this.meliCellularPlanStatusName = data.meliSubscriptionPlanStatusName;
        this.meliCellularPlanStatusSubTitle = data.meliSubscriptionPlanStatusSubTitle;
    }
}

export class CellularPlanListResponseDetails {
    currecntcellularPlan: number;
    cellularPlanList: CellularPlansStatusList[];

    constructor(data) {
        this.currecntcellularPlan = data.currecntSubscriptionPlanStatus;
        this.cellularPlanList = data.allSubscriptionPlansStatusList && data.allSubscriptionPlansStatusList.length &&
            data.allSubscriptionPlansStatusList.map((value, index) => new CellularPlansStatusList(value));
    }
}

export class CellularPlanListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    cellularPlanDetails: CellularPlanListResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.cellularPlanDetails = data.responseData && new CellularPlanListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class OtherDetails {
    timeStamp: string;
    reason: string;
    amount: string;
    txId: string;

    constructor(data) {
        this.timeStamp = data.timeStamp;
        this.reason = data.reason;
        this.amount = data.amount;
        this.txId = data.txId;
    }
}

export class BillingListResponseDetails {
    meliSerialNumber: string;
    planName: string;
    otherDetails: OtherDetails[];

    constructor(data) {
        this.meliSerialNumber = data.meliSerialNumber;
        this.planName = data.planName;
        this.otherDetails = data.others && data.others.length &&
            data.others.map((value, index) => new OtherDetails(value));
    }
}

export class BillingListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    billingList: BillingListResponseDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.billingList = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new BillingListResponseDetails(value));
        this.requestData = data.requestData;
    }
}

export class CurrentCellularStatusResponseDetails {
    simCardNumber: string;
    simSubscriptionStatus: string;
    simCardStatus: string;

    constructor(data) {
        this.simCardNumber = data.simCardNumber;
        this.simSubscriptionStatus = data.simSubscriptionStatus;
        this.simCardStatus = data.simCardStatus;
    }
}

export class CurrentCellularStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: CurrentCellularStatusResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new CurrentCellularStatusResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}
