
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
// Constants
import {
  COMMON_CONSTANTS, DOWNLOAD_TEMPLATE_FILE_NAME,
  TECHNICIAN_STATUS,
  UPLOAD_TEMPLATE_NAMES,CUSTOMER_TYPES
} from 'src/app/shared/constants/commom.constant';
import { Alert } from '../../models/alerts.model';
import { Technician, TechnicianInfo } from '../../models/technician.model';
import { SortingTypes } from '../../constants/sorting-enum';
import { SortingConstants } from '../../constants/sorting-enum';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { API_CONSTANTS } from '../../constants/api.constant';
import { ContractorDetails } from '../../models/contractor.modal';
@Component({
  selector: 'app-share-alert-modal',
  templateUrl: './share-alert-modal.component.html',
  styleUrls: ['./share-alert-modal.component.scss']
})
export class ShareAlertModalComponent implements OnInit {
 @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Output() emitInvestigate = new EventEmitter();
  @Input() details: Alert;
  technicianList: Technician[];
  commonConstants = COMMON_CONSTANTS;
  Math: any;
  skip = 0;
  limit = COMMON_CONSTANTS.INITIAL_TABLE_ROW_COUNT;
  page = 1;
  totalCount = 0;
  totalPage = 0;
  isShowLoading = false;
  sortingTypes = SortingTypes;
  sortingConstants = SortingConstants;
  lastSortedColumn = SortingConstants.TECHNICIAN;
  currentSortingField = SortingConstants.TECHNICIAN;
  currentSortingType = SortingTypes.ASCENDING;
  customerConstants= CUSTOMER_TYPES;
    noOfElementsInCurrentPage: number;
    isTechnicianNameColumnSort = false;
  isStatusColumnSort = false;
  tags=[];
   private BASE_URL;
   isshareBtnLoading:boolean=false;
   //shortLink:any='';
  removeLastOnBackspace:boolean=true;
  isCopy:boolean=false;
  isShowCrossMark:boolean=true;
  @ViewChild('tableWrapperHeight', { static: false }) tableWrapperHeight: ElementRef;
  contractorDetails: ContractorDetails;
  constructor(private sharedService: SharedService, private helperService: HelperService) { 
    this.Math = Math;
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMMON_BASE);}

  ngOnInit() {
    console.log(this.details)
    this.helperService.customerPersonalInformation
    //this.getAlertLink();
    this.getContractorInformation();
  }
   ngAfterViewInit() {
    this.getTableHeight(true);
  }

  getTableHeight(isinitialCall?: boolean) {
    if (this.tableWrapperHeight && this.tableWrapperHeight.nativeElement &&
      this.tableWrapperHeight.nativeElement.offsetHeight) {
      this.limit = Math.round(this.tableWrapperHeight.nativeElement.offsetHeight / COMMON_CONSTANTS.TABLE_TR_HEIGHT) + 2;
      if (this.limit < 10) {
        this.limit = 10;
      }
    }

    if (isinitialCall) {
      this.getTechnicianList();
    }
  }

  getContractorInformation() {
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        console.log(response);
        this.contractorDetails = response;
            }
    });
  }
  getTechnicianList(isInfiniteScroll = false, query?: any) {
    if (!isInfiniteScroll) {
      this.handleResetPagination();
    }
    let queryParams: any = {
      page: this.page - 1,
      size: this.limit
    };
    if (query) {
      queryParams = query;
    }
    if (this.currentSortingField && !query) {
      queryParams.sortingField = this.currentSortingField;
      queryParams.sortingType = this.currentSortingType;
    }
    this.isShowLoading = true;

    this.sharedService.getTechnicianList(queryParams).subscribe(
      (response: TechnicianInfo) => {
        if (response) {
          if (this.technicianList && this.technicianList.length && isInfiniteScroll) {
            this.technicianList.push(...response.responseData.technicianList);
          } else {
            this.technicianList = response.responseData && response.responseData.technicianList;
          }
          this.totalCount = response.responseData && response.responseData.totalNumberOfElements;
          this.noOfElementsInCurrentPage = response.responseData && response.responseData.noOfElementsInCurrentPage;
          this.totalPage = response.responseData.totalPage;
        }
        this.isShowLoading = false;
        this.handleTechniciankey()
      }, (error) => {
        this.isShowLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  
  handleSorting(key: string) {
    this.getTableHeight();
    if (key === this.sortingConstants.TECHNICIAN) {
      this.resetPreviousSorting(key);
      // this.customerList = this.helperService.handleDateSorting(this.customerList, this.isInstalledOnColumnSort, key);
      this.currentSortingField = this.sortingConstants.TECHNICIAN;
      this.currentSortingType = this.isTechnicianNameColumnSort ? this.sortingTypes.ASCENDING : this.sortingTypes.DESCENDING;
      this.isTechnicianNameColumnSort = !this.isTechnicianNameColumnSort;
      this.getTechnicianList();
      this.lastSortedColumn = this.sortingConstants.TECHNICIAN;
    }
  }

  resetPreviousSorting(currentSort) {
    if (this.lastSortedColumn !== currentSort) {
      if (this.lastSortedColumn === this.sortingConstants.TECHNICIAN) {
        this.isTechnicianNameColumnSort = false;
      }
      else if (this.lastSortedColumn === this.sortingConstants.STATUS) {
        this.isStatusColumnSort = false;
      }
    }
  }
  
  handleResetPagination() {
    this.skip = 0;
    this.page = 1;
  }

  handleTechniciankey()
  {
    this.technicianList.map((data)=>{
      data['click']=false
    })
  }

  onTagsChanged(event)
  {
    console.log(this.tags)
  }


  handleAddRecipient(type:string,data:Technician)
 
  {
     //console.log(type,data);
    if(type=="add")
    {
this.tags.push({'displayValue':data.technicianName, 'value':data.email[0].email})
    let obj = this.technicianList.find((o, i) => {
    if (o.technicianId === data.technicianId) {
        this.technicianList[i]['click']=true;
        return true; // stop searching
    }
        console.log(this.technicianList)
});
    }
    else if(type=="remove")
    {
      //console.log(this.tags)
      const index = this.tags.findIndex((v:any)=>v.value === data.email[0].email);
      //console.log(index)
      this.tags.splice(index,1)
let obj = this.technicianList.find((o, i) => {
    if (o.technicianId === data.technicianId) {
        this.technicianList[i]['click']=false;
        return true; // stop searching
    }
        //console.log(this.technicianList)
});
    }
    

  }

  //function to get the alert short link
  // getAlertLink()
  // {
  //   const url = this.BASE_URL + API_CONSTANTS.ALERT_DISCOVERY_READ+'?pb='+this.details.meliSerialNo+'&alerttime='+this.details.timeStamp+'&alertcode='+this.details.alertCode+'&conversionUnit=null';
  //   let  obj={longUrl:url}
  //   this.sharedService.getAlertShortLink(obj).subscribe((res)=>{
  //     this.shortLink = res.responseData.shortLink
  //   })
  // }

  //copy to clipboard
  copy(val:any) {

    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val!=''?val:' ';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    console.log(selBox,this.details.shortLink);
//     this.isCopy=true;
//     setTimeout(()=>{
// this.isCopy=false
//     },5000)

 this.helperService.showToaster(ToasterType.SUCCESS, 'Alert Link Copied');
}

//shareAlert
shareAlert()
{
  if(this.tags.length==0)
  {
    this.helperService.showToaster(ToasterType.ERROR, 'Please add atleast one recipient');
  }
  else
  {
    
    let emails=[];
      this.tags.map((data=>{
        data.value?emails.push(data.value):emails.push(data.displayValue);
    }))
    
  if(this.validateEmails(emails))
  {
     let obj={
    meliSerialNo:this.details.meliSerialNo,
    shortLink:this.details.shortLink,
    alarmOccureTime:this.details.timeStamp,
    severity:this.details.priority,
    contractorId:this.contractorDetails.contractorId,
    contractorName:this.contractorDetails.contractorName,
    consumerId:this.details.consumer.customerId,
    consumerName:this.details.consumer.customerName,
    companyName:this.details.consumer.companyName,
    consumerType:this.details.consumer.consumerType,
    consumerAddressFirstLine:this.details.consumer.addresses[0].address,
    consumerAddressCity:this.details.consumer.addresses[0].city,
    channelName:this.contractorDetails.channelName,
    userEmails: [],
     }
    obj.userEmails=emails;
    this.isshareBtnLoading=true;
    this.sharedService.shareAlerttoEmails(obj).subscribe((res)=>{
      if(res)
      {
        const message = res.statusInfo;
        this.helperService.showToaster(ToasterType.SUCCESS, message);
        this.closeModal()
      }
         },(error)=>{
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
    }) 
  }
  else
  {
    this.helperService.showToaster(ToasterType.ERROR, 'Please enter valid recipient email');
  }
}

}
validateEmails(emails)
{
let val = emails.every(d=>{
    if(!d.match('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}'))
    {
      return false;
    }
    return true;
  })
  return val;
}
   closeModal() {
    this.emitCloseModal.emit();
  }

}
