import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as channelEnvironment } from 'projects/channel/src/environments/environment';
import { Observable } from 'rxjs';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { ApiService } from 'src/app/shared/services/api.service';

// Constants
import { CHANNEL_API_CONSTANTS } from 'projects/channel/src/app/shared-channel/constants/api.constants';

// Models
import {
  BulkDealerUploadResponse, ContractorListResponse,
  CreateContractorResponse,
  DealerListResponse
} from 'src/app/shared/models/contractor.modal';
import { InventoryResponse } from 'src/app/shared/models/inventory.model';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { UploadProperyListResponse } from 'src/app/shared/models/properties.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelSharedService {

  private BASE_URL = channelEnvironment.baseUrl;

  constructor(
    private helperService: HelperService,
    private apiService: ApiService
  ) { }

  getContractorsList(queryParams): Observable<ContractorListResponse> {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.GET_CONTRACTOR_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new ContractorListResponse(response)));
  }

  getDealerList(queryParams): Observable<DealerListResponse> {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.GET_DEALER_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new DealerListResponse(response)));
  }

  getInventoryList(queryParams): Observable<InventoryResponse> {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.GET_CHANNEL_INVENTORY_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new InventoryResponse(response)));
  }

  uploadCompanyLogo(payload, queryParams, hasHeader = true) {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.CONTRACTOR_LOGO;
    return this.apiService.post(url, payload, queryParams, false, hasHeader).pipe(map(response => response));
  }

  // createContractor(payload): Observable<CreateContractorResponse> {
  //   const url = this.BASE_URL + CHANNEL_API_CONSTANTS.CREATE_CONTRACTOR;
  //   return this.apiService.post(url, payload).pipe(map(response => new CreateContractorResponse(response)));
  // }

  uploadDealerList(payload): Observable<UploadProperyListResponse> {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.UPLOAD_DEALER_LIST;
    return this.apiService.post(url, payload).pipe(map(response => new UploadProperyListResponse(response)));
  }

}
