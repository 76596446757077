import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';

// Models
//import { MeliAssetInfo } from 'src/app/shared/models/meli.model';
import { AssetDetail, AssetModelInfo, HVACInformation, HVACInformationResponse, TypeList } from '../../models/customer.model';
import { HVAC_ID } from "../../constants/commom.constant";
import { SharedService } from 'src/app/shared/services/shared.service';
import * as moment from 'moment';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractorSharedService } from 'projects/contractor/src/app/shared-contractor/services/shared.service';
import { ToasterType } from '../../constants/toaster.enum';
import { HelperService } from '../../services/helper.service';
import { arrayMax } from 'highcharts';
// import { Event } from 'rxjs';
@Component({
  selector: 'app-hvac-detail-modal',
  templateUrl: './hvac-detail-modal.component.html',
  styleUrls: ['./hvac-detail-modal.component.scss']
})
export class HvacDetailModalComponent implements OnInit, OnChanges {

  @ViewChild('hvacImageTag', { static: false }) hvacImageTag: ElementRef;

  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandleDeleteHavac = new EventEmitter();
  @Input() modalTitle: string;
  @Input() hvacDetail: AssetDetail[];
  @Input() statusMessage: any;
  @Input() isShowRightHeader: boolean;
  @Input() isShowCrossMark: boolean;
  @Input() isLoading = true;
  @Input() showDeleteButton : boolean;
  @Output() emitHandleRefreshPage = new EventEmitter();
  portalName: string;
  roleConstants = ROLE_CONSTANTS;
  isHVACLoading = true;
  hvacDetailCopy:AssetDetail[]=[];
  HVACInformationList: HVACInformation;
  assetModelInfo: AssetModelInfo;
  editHVAC: FormGroup;
  indoorEquipment: FormGroup;
  outdoorEquipment: FormGroup;
  assetUnitType: any;
  nextDisabled = false;
  prevDisabled = true;
  readonly HVAC_ID = HVAC_ID;
  isShowHVACComfortModal = false;
  isShowComfortBridgeButton = false;
  HVACDetails: any;
  isChannel:any;
  HVACImageList = [];
  currentImageIndex = 0;
  isShowEnlargedImage = false;
  currentZoomSize = 0;
  currentImageWidth = 0;
  currentImageHeight = 0;
  defaultImageWidth = 0;
  defaultImageHeight = 0;
  maxZoomout = -4;
  maxZoomin = 5;
  showImageOptions = true;
  imageIdleTime;
  isEdit = false;
  count = 0;
  prevGroup;
  prevIndex;
  isToggleButtons = true;
  prevHVACInfo;
  infoModalType: string;
  confirmationModalTitle: string;
  confirmationModalLine2Content: string;
  isConfirmationModalHasYesOrNoButton: boolean;
  isShowConfirmationModal: boolean=false;
  confirmationModalLine1Content: string;
  tempHVACInformationList: HVACInformation;
SELECTED_ID1:number;
SELECTED_ID2:number;
  preSeasonTestResponseDetails: any;
  constructor(private SharedService: SharedService,
    private router: Router,
    private helperService: HelperService,
    private contractorSharedService: ContractorSharedService,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isHVACLoading = true;
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
        if (this.portalName === this.roleConstants.CHANNEL) {
          this.isEdit = true;
        }
      }
    });
    
    // this.getDropdownData();
    this.HVACImageList = this.hvacDetail && this.hvacDetail.length &&
      this.hvacDetail[0] && this.hvacDetail[0].images;
    // this.hvacDetail.map(hvacDetail => {
      this.activatedRoute.data.subscribe((data) => {
        if (data) {
          let portalName = data.portalName;
           if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
            this.isChannel=true;
           }
          }       
      });
    this.editHVAC = new FormGroup(
      {
        systemConfiguration: new FormControl(
          '', [Validators.required]),
        zonedSystem: new FormControl('', ),
        filterType: new FormControl('', ),
        filterLength: new FormControl('', ),
        filterWidth: new FormControl('',),
        filterThickness: new FormControl('', ),
        heatType: new FormControl('', ),
        fuelType: new FormControl('', ),
        indoorMultiStage: new FormControl('', ),
        locations: new FormControl('',[Validators.required] ),
        indoorAssetUnitSerialNumber: new FormControl('', ),
        indoorEquipmentMake: new FormControl('', ),
        indoorModelNo: new FormControl('', ),
        indoorEquipmentType: new FormControl('', [Validators.required]),
        indoorEquipmentHeatType:new FormControl('',),
        ratedCapacity: new FormControl('', ),
        ratedElectricStripCapacity: new FormControl('', ),
        variableSpeedBlower: new FormControl('', ),
        outdoorAssetUnitSerialNumber: new FormControl('', ),
        outdoorEquipmentMake: new FormControl('', ),
        outdoorModelNo: new FormControl('', ),
        outdoorEquipmentType: new FormControl('', [Validators.required]),
        outdoorNominalACCapacity: new FormControl('', ),
        outdoorSeer: new FormControl('', ),
        outdoorInverterCompressor: new FormControl('', ),
        outdoorMultiStage: new FormControl('', ),
        reversingValve:new FormControl('',),
        heatSourceValue:new FormControl(false)
      });

    // }, this);
    this.handleHVACdata();

    

   
  }
  handleHVACdata(){
  let isIndoorDataPushed = false;
  let hvac1:AssetDetail;
  if(this.hvacDetail){
    this.hvacDetail.forEach(hvac => {
      if(hvac.assetUnitType=='indoor' && !isIndoorDataPushed){
        this.hvacDetailCopy.push(hvac);
        isIndoorDataPushed=true;
      }
      else if(hvac.assetUnitType=='outdoor' && isIndoorDataPushed){
        this.hvacDetailCopy.push(hvac);
      }
      else{
         hvac1=hvac
      }
     
    });
    
    if(hvac1){
      this.hvacDetailCopy.push(hvac1);
    }
    
    this.hvacDetail=this.hvacDetailCopy
    
    this.getDropdownData(this.hvacDetail);
  }
  
  }
  handleModelOnChange(flag: string, event: number, e?) {
    console.log(flag,event)
    if(e)
    {
  e.preventDefault();
    e.stopPropagation();
    }
  
    if (this.prevIndex !== event) {
      this.count = 0;
    }
    if (this.count === 0) {
      this.prevGroup = this.editHVAC.getRawValue();
    }
    else {
      if (event === 1) {
        this.editHVAC.addControl('fuelType', new FormControl(this.prevGroup.fuelType,));
        //this.editHVAC.addControl('heatType', new FormControl(this.prevGroup.heatType,));
        this.editHVAC.addControl('ratedElectricStripCapacity', new FormControl(this.prevGroup.ratedElectricStripCapacity,));
      } else if(event === HVAC_ID.ID2 || event==HVAC_ID.ID1){
        this.editHVAC.addControl('outdoorAssetUnitSerialNumber', new FormControl(this.prevGroup.outdoorAssetUnitSerialNumber));
        this.editHVAC.addControl('outdoorEquipmentMake', new FormControl(this.prevGroup.outdoorEquipmentMake));
        this.editHVAC.addControl('outdoorModelNo', new FormControl(this.prevGroup.outdoorModelNo));
        this.editHVAC.addControl('outdoorSeer', new FormControl(this.prevGroup.outdoorSeer));
        this.editHVAC.addControl('outdoorInverterCompressor', new FormControl(this.prevGroup.outdoorInverterCompressor));
        this.editHVAC.addControl('outdoorMultiStage', new FormControl(this.prevGroup.outdoorMultiStage));
        this.editHVAC.addControl('ratedCapacity', new FormControl(this.prevGroup.ratedCapacity));
      }
    }
    if (flag === 'deviceType') {
      if(event===2) {
          if (this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID2.toString())
          {
            let arr=[];
            let arrout=[];
             this.tempHVACInformationList.indoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arr.push(d):'';
            })
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=1?arrout.push(d):'';
            })
            this.HVACInformationList.indoorEquipmentTypeList=arr;
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
            //console.log( this.tempHVACInformationList.indoorEquipmentTypeList, this.HVACInformationList.indoorEquipmentTypeList)
         // this.editHVAC.get('outdoorEquipmentType').value = data['id']
          this.editHVAC.removeControl('outdoorAssetUnitSerialNumber');
          this.editHVAC.removeControl('outdoorEquipmentMake');
          this.editHVAC.removeControl('outdoorModelNo');
          this.editHVAC.removeControl('outdoorSeer');
          this.editHVAC.removeControl('outdoorInverterCompressor');
          this.editHVAC.removeControl('outdoorMultiStage');
           // this.editHVAC.patchValue({
          //    outdoorEquipmentType:data[0]['id']})
           }
           else if (this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID3.toString())
          {
            let arr=[];
            let arrout=[];
             this.tempHVACInformationList.indoorEquipmentTypeList.forEach(d=>{
                d.id==2?arr.push(d):'';
            })
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arrout.push(d):'';
            })
            this.HVACInformationList.indoorEquipmentTypeList=arr;
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
          }
          else {
             let arrout=[];
              this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
            this.HVACInformationList.indoorEquipmentTypeList=this.tempHVACInformationList.indoorEquipmentTypeList;
            this.editHVAC.addControl('outdoorAssetUnitSerialNumber', new FormControl(''));
            this.editHVAC.addControl('outdoorEquipmentMake', new FormControl(''));
            this.editHVAC.addControl('outdoorModelNo', new FormControl(''));
            this.editHVAC.addControl('outdoorSeer', new FormControl(''));
            this.editHVAC.addControl('outdoorInverterCompressor', new FormControl(''));
            this.editHVAC.addControl('outdoorMultiStage', new FormControl(''));
          }
          this.handleOutdoorDropDownValues();
          this.handleheatSourceValue(e);
          // if(e)
          // {
          //   this.handleEmptyDropdown(event)
          // }
        }
      if (event === 1) {
        if (this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_FURNACE.toString() ) 
         {
          this.editHVAC.removeControl('ratedElectricStripCapacity');
          this.editHVAC.addControl('fuelType', new FormControl(''));
          //this.editHVAC.addControl('heatType', new FormControl(''));
        } 
        else if (this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString()) 
        {
          this.editHVAC.removeControl('fuelType');
          //this.editHVAC.removeControl('heatType');
          this.editHVAC.removeControl('ratedElectricStripCapacity');
        } 
        else if (
          this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER_WITH_ELEC_STRIP.toString()
          ) 
          {
          this.editHVAC.removeControl('fuelType');
          //this.editHVAC.removeControl('heatType');
          this.editHVAC.addControl('ratedElectricStripCapacity', new FormControl(''));
        }
        this.handleOutdoorDropDownValues();
        this.handleheatSourceValue(e);
        // if(e)
        //   {
        //     this.handleEmptyDropdown(event)
        //   }
      } 
      if(event===3) {
          let data =  this.HVACInformationList.outdoorEquipmentTypeList.filter((dataa)=>dataa.id==HVAC_ID.ID2)
          console.log(this.editHVAC.get('outdoorEquipmentType').value)
          if (this.editHVAC.get('outdoorEquipmentType').value === HVAC_ID.ID2.toString()||this.editHVAC.get('outdoorEquipmentType').value === HVAC_ID.ID4.toString())
          {
             this.editHVAC.addControl('reversingValve', new FormControl('O TERMINAL'));
           }
          else {
             this.editHVAC.removeControl('reversingValve');
          }
          this.handleheatSourceValue(e);
        }
    }
    this.count++;
    this.prevIndex = event;
    // this.prevHVACInfo = this.editHVAC.getRawValue();
  }

  handleheatSourceValue(e?)
  {
    if(e)
    {
  if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID1.toString()&&
          this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString()
          &&this.editHVAC.get('outdoorEquipmentType').value === HVAC_ID.ID1.toString()){
            this.editHVAC.addControl('heatSourceValue',new FormControl(''));
          }
          else
          {
             this.editHVAC.removeControl('heatSourceValue');
          }
    }
    else
     {

      if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID1.toString()&&
          this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString()
          &&this.editHVAC.get('outdoorEquipmentType').value === HVAC_ID.ID1.toString()){
            let a = this.hvacDetail[0].assetModelInfo.heatSource==true?true:false;
            console.log(this.prevGroup)
this.editHVAC.addControl('heatSourceValue',new FormControl(a));
          }
          else
          {
             this.editHVAC.removeControl('heatSourceValue');
          }
       
    }
  
  }
handleOutdoorDropDownValues()
{
 if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID2.toString()&&this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_FURNACE.toString())
        {
            let arrout=[];

             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id==3?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
        }
         else if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID2.toString()&&this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString())
        {
             let arr=[];
            let arrout=[];
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id==2?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
        }
        else if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID1.toString()&&(this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_FURNACE.toString()||this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER_WITH_ELEC_STRIP.toString()))
        {
            let arr=[];
            let arrout=[];
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
        }
         else if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID1.toString()&&this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString())
        {
            let arr=[];
            let arrout=[];
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
        }
        else if(this.editHVAC.get('systemConfiguration').value === HVAC_ID.ID3.toString()&&this.editHVAC.get('indoorEquipmentType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString())
        {
            let arrout=[];
             this.tempHVACInformationList.outdoorEquipmentTypeList.forEach(d=>{
                d.id!=3?arrout.push(d):'';
            })
             this.HVACInformationList.outdoorEquipmentTypeList=arrout;
        }
}
handleEmptyDropdown(dropdownevent)
{
  if(dropdownevent==2)
  {
   this.editHVAC.patchValue({
               indoorEquipmentType:this.HVACInformationList.indoorEquipmentTypeList[0].id,
               outdoorEquipmentType:this.HVACInformationList.outdoorEquipmentTypeList[0].id
             })
  }
  else
  {
    this.editHVAC.patchValue({
               outdoorEquipmentType:this.HVACInformationList.outdoorEquipmentTypeList[0].id
             })
            
  }
}

 updateCheckBoxVal(eve) {
    this.editHVAC.patchValue({
      heatSourceValue:eve.target.checked}
      );
      this.handleButtonsOnChanges();
  }

  handleFetchValueFromId(key: string, id: any) {
    let selectedValue;
    if (this.HVACInformationList && this.HVACInformationList[key] &&
      this.HVACInformationList[key].length) {
      selectedValue = this.HVACInformationList[key].find((currentValue: TypeList) => currentValue.id.toString() === (id && id.toString()));
    }
    return selectedValue;
  }

  checkValidation()
  {
     if(this.editHVAC.valid)
    {
      this.checkPreseasonStatus();
    }
    else
    {
      console.log(this.editHVAC);
      this.editHVAC.markAllAsTouched();
    }
  }

  checkPreseasonStatus()
  {
     
    const queryParams = {
      boardSerialNumber: this.hvacDetail[0].meliSerialNumber,
    };
    this.SharedService.getPreSeasonTestStatus(queryParams).subscribe(
      (response) => {
        //et sdate = response.scheduleTime;
        this.preSeasonTestResponseDetails = response;
        if (response.responseData.status == 'SCHEDULED') {
          this.handleModalType('preseasoncheck')
        }
        else if (response.responseData.status == 'RUNNING') {
                   this.handleModalType('preseasoncheck')
        }
      }, (error) => {
   this.editHvacData();
      }
    );
  }

  handleCancelPreSeasonTest() {
    const queryParams = {
      boardSerialNumber: this.hvacDetail[0].meliSerialNumber,

    };
    this.SharedService.handleCancelPreSeasonTest(queryParams).subscribe(
      (response) => {
       this.editHvacData();
      }, (error) => {
        this.editHvacData();
      }
    );
  }
  editHvacData() {
   //console.log(this.editHVAC.get('heatSourceValue')?this.editHVAC.get('heatSourceValue').value:'')
    let payload = [];
    let indoor = {
      assetModelInfo: {}
    };
    let outdoor = {
      assetModelInfo: {}
    };
    this.hvacDetail.forEach(hvac => {
      if (hvac.assetUnitType == 'indoor') {
        console.log(this.editHVAC)
        const deviceTypeValue = this.handleFetchValueFromId('indoorEquipmentTypeList', this.editHVAC.get('indoorEquipmentType').value);
        indoor['assetUnitTypeId'] = hvac.assetUnitTypeId ? hvac.assetUnitTypeId : null;
        indoor['assetUnitName'] = hvac.assetUnitName ? hvac.assetUnitName : null
        indoor['assetUnitType'] = hvac.assetUnitType ? hvac.assetUnitType : null
        // indoor['assetUnitSerialNumber'] = hvac.assetUnitSerialNumber ? hvac.assetUnitSerialNumber : null
        indoor['assetUnitUniqueId'] = hvac.assetUnitUniqueId ? hvac.assetUnitUniqueId : null
        indoor['assetModelInfo']['systemConfigurationId'] = this.editHVAC.get('systemConfiguration').value == "" ? null : parseInt(this.editHVAC.get('systemConfiguration').value)
        indoor['assetModelInfo']['assetModelInfoId'] = hvac.assetModelInfo.assetModelInfoId ? hvac.assetModelInfo.assetModelInfoId : null
        indoor['assetModelInfo']['meteringDeviceId'] = hvac.assetModelInfo.meteringDevice ? hvac.assetModelInfo.meteringDevice.id : null
        indoor['assetModelInfo']['isEquipmentId'] = hvac.assetModelInfo.equipmentIs ? hvac.assetModelInfo.equipmentIs.id : null
        indoor['assetModelInfo']['zonedSystemId'] = this.editHVAC.get('zonedSystem').value == "" ? null : parseInt(this.editHVAC.get('zonedSystem').value)
        indoor['assetModelInfo']['filterTypeId'] = this.editHVAC.get('filterType').value == "" ? null : parseInt(this.editHVAC.get('filterType').value)
        indoor['assetModelInfo']['filterLengthId'] = this.editHVAC.get('filterLength').value == "" ? null : parseInt(this.editHVAC.get('filterLength').value)
        indoor['assetModelInfo']['filterWidthId'] = this.editHVAC.get('filterWidth').value == "" ? null : parseInt(this.editHVAC.get('filterWidth').value)
        indoor['assetModelInfo']['filterThicknessId'] = this.editHVAC.get('filterThickness').value == "" ? null : parseInt(this.editHVAC.get('filterThickness').value)
        indoor['assetModelInfo']['heatingTypeId'] = this.editHVAC.get('heatType')?this.editHVAC.get('heatType').value&&this.editHVAC.get('heatType').value == "" ? null : parseInt(this.editHVAC.get('heatType').value):null
        indoor['assetModelInfo']['fuelTypeId'] = this.editHVAC.get('fuelType')?this.editHVAC.get('fuelType').value&&this.editHVAC.get('fuelType').value == "" ? null : parseInt(this.editHVAC.get('fuelType').value):null
        indoor['assetModelInfo']['indoorMultiStageId'] = this.editHVAC.get('indoorMultiStage').value == "" ? null : parseInt(this.editHVAC.get('indoorMultiStage').value)
        indoor['assetModelInfo']['locationId'] = this.editHVAC.get('locations').value == "" ? null : parseInt(this.editHVAC.get('locations').value)
        indoor['assetUnitSerialNumber'] = this.editHVAC.get('indoorAssetUnitSerialNumber').value == "" ? hvac.assetUnitSerialNumber : this.editHVAC.get('indoorAssetUnitSerialNumber').value.toUpperCase()
        indoor['assetModelInfo']['indoorEquipmentMakeId'] = this.editHVAC.get('indoorEquipmentMake').value == "" ? null : parseInt(this.editHVAC.get('indoorEquipmentMake').value.toUpperCase())
        indoor['assetModelInfo']['modelNo'] = this.editHVAC.get('indoorModelNo').value == "NA" ? null : this.editHVAC.get('indoorModelNo').value.toUpperCase()
        indoor['assetModelInfo']['indoorEquipmentTypeId'] = this.editHVAC.get('indoorEquipmentType').value == "" ? "" : parseInt(this.editHVAC.get('indoorEquipmentType').value)
        indoor['assetModelInfo']['ratedCapacityId'] = this.editHVAC.get('ratedCapacity').value == "" ? null : parseInt(this.editHVAC.get('ratedCapacity').value)
        indoor['assetModelInfo']['ratedElectricStripCapacity'] = this.editHVAC.get('ratedElectricStripCapacity')?this.editHVAC.get('ratedElectricStripCapacity').value&&this.editHVAC.get('ratedElectricStripCapacity').value == ""  ? null : this.editHVAC.get('ratedElectricStripCapacity').value:null
        indoor['assetModelInfo']['variableSpeedBlowerId'] = this.editHVAC.get('variableSpeedBlower').value == "" ? null : parseInt(this.editHVAC.get('variableSpeedBlower').value)
        indoor['assetModelInfo']['outdoorEquipmentMakeId'] = null
        indoor['assetModelInfo']['outdoorEquipmentTypeId'] = ""
        indoor['assetModelInfo']['outdoorMultiStageId'] = null
        indoor['assetModelInfo']['nomicalAcCapacityId'] = null
        indoor['assetModelInfo']['isOutdoorInverterId'] = null
        indoor['assetModelInfo']['seerId'] = null
        indoor['assetModelInfo']['deviceType'] = deviceTypeValue && deviceTypeValue.value?deviceTypeValue.value:""
        indoor['assetModelInfo']['reversingValve']='O TERMINAL'
        indoor['assetModelInfo']['heatSource']=(this.editHVAC.get('heatType').value=="" || this.editHVAC.get('heatType').value!=9) ?false:true

      }
      else if (hvac.assetUnitType == "outdoor") {
        const deviceTypeValue = this.handleFetchValueFromId('outdoorEquipmentTypeList', this.editHVAC.get('outdoorEquipmentType').value);
        outdoor['assetUnitTypeId'] = hvac.assetUnitTypeId ? hvac.assetUnitTypeId : null;
        outdoor['assetUnitName'] = hvac.assetUnitName ? hvac.assetUnitName : null
        outdoor['assetUnitType'] = hvac.assetUnitType ? hvac.assetUnitType : null
        // outdoor['assetUnitSerialNumber'] = hvac.assetUnitSerialNumber ? hvac.assetUnitSerialNumber : null
        outdoor['assetUnitUniqueId'] = hvac.assetUnitUniqueId ? hvac.assetUnitUniqueId : null
        outdoor['assetModelInfo']['assetModelInfoId'] = hvac.assetModelInfo.assetModelInfoId ? hvac.assetModelInfo.assetModelInfoId : null
        outdoor['assetModelInfo']['meteringDeviceId'] = hvac.assetModelInfo.meteringDevice ? hvac.assetModelInfo.meteringDevice.id : null
        outdoor['assetModelInfo']['isEquipmentId'] = hvac.assetModelInfo.equipmentIs ? hvac.assetModelInfo.equipmentIs.id : null
        outdoor['assetModelInfo']['systemConfigurationId'] = null
        outdoor['assetModelInfo']['zonedSystemId'] = null
        outdoor['assetModelInfo']['filterTypeId'] = null
        outdoor['assetModelInfo']['filterLengthId'] = null
        outdoor['assetModelInfo']['filterWidthId'] = null
        outdoor['assetModelInfo']['filterThicknessId'] = null
        outdoor['assetModelInfo']['heatingTypeId'] = null
        outdoor['assetModelInfo']['fuelTypeId'] = null
        outdoor['assetModelInfo']['indoorMultiStageId'] = null
        outdoor['assetModelInfo']['locationId'] = null
        outdoor['assetUnitSerialNumber'] = this.editHVAC.get('outdoorAssetUnitSerialNumber')?(this.editHVAC.get('outdoorAssetUnitSerialNumber').value == "" ? hvac.assetUnitSerialNumber : this.editHVAC.get('outdoorAssetUnitSerialNumber').value.toUpperCase()):hvac.assetUnitSerialNumber
        outdoor['assetModelInfo']['indoorEquipmentMakeId'] = null
        outdoor['assetModelInfo']['modelNo'] = this.editHVAC.get('outdoorModelNo')?(this.editHVAC.get('outdoorModelNo').value == "" ? null : this.editHVAC.get('outdoorModelNo').value.toUpperCase()):null
        outdoor['assetModelInfo']['indoorEquipmentTypeId'] = ""
        outdoor['assetModelInfo']['ratedCapacityId'] = null
        outdoor['assetModelInfo']['ratedElectricStripCapacity'] = null
        outdoor['assetModelInfo']['variableSpeedBlowerId'] = null
        outdoor['assetModelInfo']['outdoorEquipmentMakeId'] = this.editHVAC.get('outdoorEquipmentMake')?(this.editHVAC.get('outdoorEquipmentMake').value == "" ? null : parseInt(this.editHVAC.get('outdoorEquipmentMake').value.toUpperCase())):null
        outdoor['assetModelInfo']['outdoorEquipmentTypeId'] = this.editHVAC.get('outdoorEquipmentType')?(this.editHVAC.get('outdoorEquipmentType').value == "" ? "" : parseInt(this.editHVAC.get('outdoorEquipmentType').value)):null
        outdoor['assetModelInfo']['outdoorMultiStageId'] = this.editHVAC.get('outdoorMultiStage')?(this.editHVAC.get('outdoorMultiStage').value == "" ? null : parseInt(this.editHVAC.get('outdoorMultiStage').value)):null
          outdoor['assetModelInfo']['nomicalAcCapacityId'] = this.editHVAC.get('outdoorNominalACCapacity').value == "" ? null : parseInt(this.editHVAC.get('outdoorNominalACCapacity').value)
        outdoor['assetModelInfo']['isOutdoorInverterId'] = this.editHVAC.get('outdoorInverterCompressor')?(this.editHVAC.get('outdoorInverterCompressor').value == "" ? null : parseInt(this.editHVAC.get('outdoorInverterCompressor').value)):null
        outdoor['assetModelInfo']['seerId'] = this.editHVAC.get('outdoorSeer')?(this.editHVAC.get('outdoorSeer').value == "" ? null : parseInt(this.editHVAC.get('outdoorSeer').value)):null
        outdoor['assetModelInfo']['deviceType'] = deviceTypeValue && deviceTypeValue.value ? deviceTypeValue.value : ""
        outdoor['assetModelInfo']['reversingValve']=this.editHVAC.get('reversingValve')?this.editHVAC.get('reversingValve').value==""?"O TERMINAL":this.editHVAC.get('reversingValve').value:'O TERMINAL'
        outdoor['assetModelInfo']['heatSource']=(this.editHVAC.get('heatType').value=="" || this.editHVAC.get('heatType').value!=9) ?false:true
      }
    })
    payload.push(indoor);
    payload.push(outdoor);
    let querParams =
    {
      assetId: this.hvacDetail[0]['assetId']
    }

    this.SharedService.editHvac(payload, querParams).subscribe(
      (response) => {
        // console.log(response);
        this.helperService.showToaster(ToasterType.SUCCESS, "Equipment info successfully updated");
        this.emitHandleRefreshPage.emit();
        this.closeModal();
      }, (error) => {

        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      })
    // console.log(payload)
  }

  getDropdownData(hvacDetail) {
    // this.isHVACLoading = true;
    
    if(this.hvacDetail){

      this.SharedService.getHVACInformationList().subscribe(
        (response: HVACInformationResponse) => {
          // this.isHVACLoading = false;
          this.tempHVACInformationList= {...response.HVACInformation};
          this.HVACInformationList = response.HVACInformation;
          // this.handleAutoFillForm();
          //console.log('hvacInfo', this.HVACInformationList);
          //console.log('hvacDetail', this.hvacDetail);
          this.handleAutoFillForm();
          this.isHVACLoading = false;
        }, (error) => {
          this.isHVACLoading = false;
          const message = this.helperService.handleAPIErrors(error);
          this.helperService.showToaster(ToasterType.ERROR, message);
        }
      );
    }
    
  }

  handleDeleteHavac() {
    
    this.emitHandleDeleteHavac.emit();
  }

  ///////////////check whether the form info is changed or not if it is changed then updtae the flasg so the submit buttons will be togled///////////////////////////////////
  handleHVACForm() {
    let flag = true;
    let currentHVACInfo = this.editHVAC.getRawValue();
    //console.log(JSON.stringify(currentHVACInfo) !== JSON.stringify(this.prevHVACInfo))
    if (JSON.stringify(currentHVACInfo) == JSON.stringify(this.prevHVACInfo)) {
    //   if((this.editHVAC.get('systemConfiguration').value != ""||this.editHVAC.get('systemConfiguration').value != null)&&(this.editHVAC.get('indoorEquipmentType').value != ""||this.editHVAC.get('indoorEquipmentType').value != null)&&(this.editHVAC.get('outdoorEquipmentType').value != ""||this.editHVAC.get('outdoorEquipmentType').value != null)){
    // }
    flag = true;
  }
  else{flag=false}
  console.log(this.editHVAC)
    //  if((this.editHVAC.get('systemConfiguration').value != ""||
    //  this.editHVAC.get('systemConfiguration').value != null)&&
    //  (this.editHVAC.get('indoorEquipmentType').value != ""||
    //  this.editHVAC.get('indoorEquipmentType').value != null)&&
    //  (this.editHVAC.get('outdoorEquipmentType').value != ""||
    //  this.editHVAC.get('outdoorEquipmentType').value != null)){
    //    flag = true;
    //  }
    //  else
    //  {
    //    flag=false;
    //  }
  //  if (this.editHVAC && !this.editHVAC.valid) {
  //     flag = false;
  //   }
  //    if(this.editHVAC && this.editHVAC.valid){
  //     flag = true;
  //   }
    return flag;
  }
//////////////////////////////display submit or ok buttons based on the data change//////////////////////////////////////////
  handleButtonsOnChanges() {
    if (this.handleHVACForm()) {
      this.isToggleButtons = true;
    } else {
      this.isToggleButtons = false;
    }
  }

  //////////////////handle Validation /////////////////////////////////////////////////////////////
  handleValidation()
  {
    let flag = true;
  if((this.editHVAC.get('systemConfiguration').value != ""||
     this.editHVAC.get('systemConfiguration').value != null)&&
     (this.editHVAC.get('indoorEquipmentType').value != ""||
     this.editHVAC.get('indoorEquipmentType').value != null)&&
     (this.editHVAC.get('outdoorEquipmentType').value != ""||
     this.editHVAC.get('outdoorEquipmentType').value != null)){
       flag = true;
     }
     else
     {
       flag=false;
     }
   if (this.editHVAC && !this.editHVAC.valid) {
      flag = false;
    }
     if(this.editHVAC && this.editHVAC.valid){
      flag = true;
    }
    return flag;
  }
 
////////////////handlemodal type //////////////////////////////////////////////////////////////////
handleModalType(type)
{
  if (type === 'cancel') {
      if (!this.handleHVACForm() ) {
        this.infoModalType = 'close-create-customer-modal';
        this.confirmationModalTitle = 'Warning!';
        this.confirmationModalLine1Content = `Entered information will be lost.`;
        this.confirmationModalLine2Content = `Do you want to continue?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.toggleConfirmationModal();
      } else {
        this.closeModal();
      }
    }
    if(type=="preseasoncheck")
    {
       this.infoModalType = 'preseasoncheck-modal';
        this.confirmationModalTitle = 'Warning!';
        this.confirmationModalLine1Content = `Scheduled Pre-Season test will be cancelled.`;
        this.confirmationModalLine2Content = `Do you want to continue?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.toggleConfirmationModal();
    }
}

handleAction()
{
  if(this.infoModalType == 'close-create-customer-modal')
  {
    this.closeModal()

  }
  else if(this.infoModalType == 'preseasoncheck-modal')
  {
this.handleCancelPreSeasonTest()
  }
}

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal
  }

  handleAutoFillForm() {
    if (this.hvacDetail) {
      this.hvacDetail.forEach(hvac => {
        if (hvac.assetUnitType == 'indoor') {
          this.editHVAC.patchValue({
            systemConfiguration: hvac.assetModelInfo.systemConfiguration == null ? '' : hvac.assetModelInfo.systemConfiguration.id.toString(),
            zonedSystem: hvac.assetModelInfo.zonedSystem ? hvac.assetModelInfo.zonedSystem.id.toString() : '',
            filterType: hvac.assetModelInfo.filterType ? hvac.assetModelInfo.filterType.id.toString() : '',
            filterLength: hvac.assetModelInfo.filterLength ? hvac.assetModelInfo.filterLength.id.toString() : '',
            filterWidth: hvac.assetModelInfo.filterWidth ? hvac.assetModelInfo.filterWidth.id.toString() : '',
            filterThickness: hvac.assetModelInfo.filterThickness ? hvac.assetModelInfo.filterThickness.id.toString() : '',
            heatType: hvac.assetModelInfo.heatType ? hvac.assetModelInfo.heatType.id.toString() : '',
            fuelType: hvac.assetModelInfo.fuelType ? hvac.assetModelInfo.fuelType.id.toString() : '',
            indoorMultiStage: hvac.assetModelInfo.indoorMultiStage ? hvac.assetModelInfo.indoorMultiStage.id.toString() : '',
            locations: hvac.assetModelInfo.locations ? hvac.assetModelInfo.locations.id.toString() : '',
            indoorAssetUnitSerialNumber: hvac.assetUnitSerialNumber ? hvac.assetUnitSerialNumber.toUpperCase() : '',
            indoorEquipmentMake: hvac.assetModelInfo.indoorEquipmentMake ? hvac.assetModelInfo.indoorEquipmentMake.id.toString() : '',
            indoorModelNo: hvac.assetModelInfo.modelNo == null ? '' : hvac.assetModelInfo.modelNo.toUpperCase(),
            indoorEquipmentType: hvac.assetModelInfo.indoorEquipmentType == null ? '' : hvac.assetModelInfo.indoorEquipmentType.id.toString(),
            ratedCapacity: hvac.assetModelInfo.ratedCapacity == null ? '' : hvac.assetModelInfo.ratedCapacity.id.toString(),
            ratedElectricStripCapacity: hvac.assetModelInfo.ratedElectricStripCapacity == null ? '' : hvac.assetModelInfo.ratedElectricStripCapacity,
            variableSpeedBlower: hvac.assetModelInfo.variableSpeedBlower == null ? '' : hvac.assetModelInfo.variableSpeedBlower.id.toString(),
            heatSourceValue:hvac.assetModelInfo.heatSource==true?true:false
          })
          this.handleModelOnChange("deviceType",1)
        }
        if (hvac.assetUnitType == 'outdoor') {
          this.editHVAC.patchValue({
            outdoorAssetUnitSerialNumber: hvac.assetUnitSerialNumber ? hvac.assetUnitSerialNumber.toUpperCase() : '',
            outdoorModelNo: hvac.assetModelInfo.modelNo == null ? "" : hvac.assetModelInfo.modelNo.toUpperCase(),
            outdoorEquipmentMake: hvac.assetModelInfo.outdoorEquipmentMake == null ? '' : hvac.assetModelInfo.outdoorEquipmentMake.id.toString(),
            outdoorEquipmentType: hvac.assetModelInfo.outdoorEquipmentType == null ? '' : hvac.assetModelInfo.outdoorEquipmentType.id.toString(),
            outdoorMultiStage: hvac.assetModelInfo.outdoorMultiStage == null ? '' : hvac.assetModelInfo.outdoorMultiStage.id.toString(),
            outdoorNominalACCapacity: hvac.assetModelInfo.outdoorNominalACCapacity == null ? '' : hvac.assetModelInfo.outdoorNominalACCapacity.id.toString(),
            outdoorSeer: hvac.assetModelInfo.outdoorSeer == null ? '' : hvac.assetModelInfo.outdoorSeer.id.toString(),
            outdoorInverterCompressor: hvac.assetModelInfo.outdoorInverterCompressor == null ? '' : hvac.assetModelInfo.outdoorInverterCompressor.id.toString(),
            reversingValve:hvac.assetModelInfo.reversingValve==null?'O TERMINAL':hvac.assetModelInfo.reversingValve,
            
          })
          console.log(this.editHVAC);
          this.handleModelOnChange("deviceType",3)
        }
      });
      this.prevHVACInfo = this.editHVAC.getRawValue();

     //
     this.handleModelOnChange("deviceType",2)
    }

  }
  handleStartIdleTime() {
    this.imageIdleTime = setTimeout(
      () => {
        this.showImageOptions = false;
      }, 2000
    );
  }

  handleResetImageIdleTime() {
    clearTimeout(this.imageIdleTime);
    this.showImageOptions = true;
    this.handleStartIdleTime();
  }

  showEnlargedImageWindow() {
    this.isShowEnlargedImage = !this.isShowEnlargedImage;
  }

  handleZoomImage(isZoomIn: number) {
    if (!this.currentZoomSize) {
      this.defaultImageHeight = this.hvacImageTag && this.hvacImageTag.nativeElement &&
        this.hvacImageTag.nativeElement.clientHeight;
      this.defaultImageWidth = this.hvacImageTag && this.hvacImageTag.nativeElement &&
        this.hvacImageTag.nativeElement.clientWidth;

      this.currentImageHeight = this.defaultImageHeight;
      this.currentImageWidth = this.defaultImageWidth;
    }

    if (isZoomIn) {
      this.currentZoomSize += 1;
      if (this.currentZoomSize <= 2) {
        this.currentImageHeight += (this.currentImageHeight / 4);
        this.currentImageWidth += (this.currentImageWidth / 4);
      } else {
        this.currentImageHeight += (this.currentImageHeight / 2);
        this.currentImageWidth += (this.currentImageWidth / 2);
      }

    } else {
      this.currentZoomSize -= 1;
      if (this.currentZoomSize >= -2) {
        this.currentImageHeight -= (this.currentImageHeight / 4);
        this.currentImageWidth -= (this.currentImageWidth / 4);
      } else {
        this.currentImageHeight -= (this.currentImageHeight / 2);
        this.currentImageWidth -= (this.currentImageWidth / 2);
      }

      if (this.currentImageHeight < 10 || this.currentImageWidth < 10) {
        this.currentImageHeight = 10;
        this.currentImageWidth = 10;
        this.maxZoomout = this.currentZoomSize;
      }
    }
  }

  resetImageSize() {
    this.currentZoomSize = 0;
    this.currentImageHeight = 0;
    this.currentImageWidth = 0;
  }

  showPreviousImage() {
    this.resetImageSize();
    this.currentImageIndex -= 1;
  }

  showNextImage() {
    this.resetImageSize();
    this.currentImageIndex += 1;
  }

  closeImageModal() {
    this.resetImageSize();
    this.isShowEnlargedImage = false;
  }

  // comfortbridgeButtonStatus() {
  //   let model = this.hvacDetail[0]['assetModelInfo']['modelNo'];
  //   // let model=this.hvacDetail
  //   const queryParams: any = {
  //     modelNumber: model
  //   }
  //   // console.log(model);
  //   this.SharedService.getComfortBridgeStatus(queryParams).subscribe(
  //     (response) => {
  //       // console.log(response['responseData']['comfortbridge']);
  //       if (response['responseData']['comfortbridge']) {
  //         this.isShowComfortBridgeButton = response['responseData']['comfortbridge'];

  //       }
  //     });

  // }
  getReversingValue(data)
  {
    if(data =='O TERMINAL')
    {
        return ('Engaged with Cooling (O)')
    }
    else if(data=='B TERMINAL')
    {
      return 'Engaged with Heating (B)';
    }
    else if(data=='W TERMINAL')
    {
      return 'Engaged by Equipment (W)'
    }
    else 
    {
      return ' '
    }
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  trackByAssetUnitTypeId(index: number, item: any): string {
    return item ? item.assetUnitTypeId : '';
  }

  editHvacButtonAction() {
    this.isEdit = !this.isEdit;
  }

  toggleHVACDetailModal() {
    this.isShowHVACComfortModal = !this.isShowHVACComfortModal
  }

  openModal(Info) {
    this.HVACDetails = Info;
    this.toggleHVACDetailModal();
  }

  ngOnChanges() {
    // console.log(this.hvacDetail)
    // if (this.hvacDetail) {
    //   this.comfortbridgeButtonStatus();
    // }
    //this.assetModelInfo = this.hvacDetail ? this.hvacDetail.assetModelInfo : this.assetModelInfo;
    //this.assetUnitType = this.hvacDetail && this.hvacDetail.assetUnitType && this.hvacDetail.assetUnitType == null ? '-' : this.hvacDetail.assetUnitType + ' Unit';
    //this.hvacDetail.assetModelInfo.filterWidth == null ? this.hvacDetail.assetModelInfo.filterWidth.value = '-' : this.hvacDetail.assetModelInfo.filterWidth.value = this.hvacDetail.assetModelInfo.filterWidth.value;
  }
}
