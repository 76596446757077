import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Services
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';

// Constants
import { API_CONSTANTS } from 'src/app/shared/constants/api.constant';
import { CONTRACTOR_API_CONSTANTS } from 'projects/contractor/src/app/shared-contractor/constants/api.constant';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';

// Models
import { InstallationReportDetails, InstallationReportResponse, InventoryResponse } from 'src/app/shared/models/inventory.model';
import {
  SensorStatusResponse, ScheduleEmergencyShutoffResponse,
  EmergencyShutoffStatusResponse,
  CanScheduleEmergencyShutoffResponse,
  CancelEmergencyShutoffResponse,
  SensorCurrentStatusResponse,
  HVACStatusResponse
} from 'src/app/shared/models/meli.model';


@Injectable({
  providedIn: 'root'
})
export class MeliService {

  private BASE_URL;
  private PRE_SEASON_BASE_URL;
  private YANTRA_CONSOLE_BASE_URL;
  private COMFORTBRIDGE_BASE_URL;
  constructor(
    private apiService: ApiService,
    private helperService: HelperService
  ) {
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMMON_BASE);
    this.PRE_SEASON_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.PRE_SEASON_TEST);
    this.YANTRA_CONSOLE_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.UPDATE_RELAY);

  }

  handleSchedulePreSeasonTest(payload: object, isBulk): Observable<any> {
    if (isBulk) {
      const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.SCHEDULE_BULK_PRE_SEASON_TEST;
      return this.apiService.post(url, payload).pipe(map(response => (response)));
    }
    else {
      const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.SCHEDULE_PRE_SEASON_TEST;
      return this.apiService.post(url, payload).pipe(map(response => (response)));
    }

  }

  getSensorCurrentStatus(queryParams): Observable<SensorCurrentStatusResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_SENSOR_CURRENT_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => new SensorCurrentStatusResponse(response)));
  }
  getSensorInstallationStatus(payload: object): Observable<InstallationReportResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_INSTALLATION_REPORT;
    return this.apiService.get(url, payload).pipe(map(response => new InstallationReportResponse(response)));
  }
  getPreSeasonTestStatus(payload: object): Observable<any> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.PRE_SEASON_TEST_STATUS;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }

  handleCancelPreSeasonTest(payload: object): Observable<any> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.CANCEL_PRE_SEASON_TEST;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }



  handleScheduleEmergencyShutoff(payload: object): Observable<ScheduleEmergencyShutoffResponse> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.SCHEDULE_EMERGENCY_SHUTOFF;
    return this.apiService.post(url, payload).pipe(map(response => new ScheduleEmergencyShutoffResponse(response)));
  }

  getEmergencyShutoffStatus(queryParams: object): Observable<EmergencyShutoffStatusResponse> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.GET_EMERGENCY_SHUTOFF_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => new EmergencyShutoffStatusResponse(response)));
  }

  getCanScheduleEmergencyShutoff(queryParams: object): Observable<CanScheduleEmergencyShutoffResponse> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.CAN_SCHEDULE_EMERGENCY_SHUTOFF;
    return this.apiService.get(url, queryParams).pipe(map(response => new CanScheduleEmergencyShutoffResponse(response)));
  }

  handleCancelEmergencyShutoff(payload: object): Observable<CancelEmergencyShutoffResponse> {
    const url = this.PRE_SEASON_BASE_URL + CONTRACTOR_API_CONSTANTS.CANCEL_EMERGENCY_SHUTOFF;
    return this.apiService.get(url, payload).pipe(map(response => new CancelEmergencyShutoffResponse(response)));
  }

  getHVACStatus(queryParams: object): Observable<HVACStatusResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_HVAC_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => new HVACStatusResponse(response)));
  }

}

