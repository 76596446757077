

import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormArray, FormGroup, Validators, FormControl, AbstractControl, Form } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { ContractorSharedService } from 'projects/contractor/src/app/shared-contractor/services/shared.service';

// Models
import { AssetModelResponse, AssetModel, CreateAssetModelResponse } from 'src/app/shared/models/assest-model.model';
import { CreateCustomerResponse, HVACInformationResponse, HVACInformation, Customer, TypeList } from 'src/app/shared/models/customer.model';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Constants
import { COMMON_CONSTANTS, HVAC_ID, CUSTOMER_TYPES } from 'src/app/shared/constants/commom.constant';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { CommonSuccessResponse, IsEmailAlreadyExistsResponse } from 'src/app/shared/models/common.model';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandleDeleteCustomer = new EventEmitter();
  @Input() modalTitle: string;
  @Input() isEdit = false;
  @Input() isDelete:boolean;
  @Input() editCustomerData: any;
  @Input() assetId:any;
  @Output() emitHandleRefreshPage = new EventEmitter();
  @ViewChild('customerStreetAddress', { static: false }) customerStreetAddress: any;
  createConsumer: FormGroup;
  prevFormValues;
  assetModelList: AssetModel[];
  selectedIndoorAssetModel: AssetModel;
  selectedOutdoorAssetModel: AssetModel;
  isIndoorEquipmentCustomModal = false;
  isOutdoorEquipmentCustomModal = false;
  phonesCodesList: any;
  isShowIndoorEquipmentCustomField = false;
  isShowOutdoorEquipmentCustomField = false;
  createIndoorEquipmentCustomFields: FormControl;
  createOutdoorEquipmentCustomFields: FormControl;
  indoorCustomFieldsList = [];
  outdoorCustomFieldsList = [];
  isIndoorFieldAlreadyExist = false;
  isOutdoorFieldAlreadyExist = false;
  selectedCountryForPhoneCode = 'United States of America';
  infoModalType: any;
  infoModalTitle: any;
  confirmationModalTitle: any;
  confirmationModalLine1Content: any;
  isConfirmationModalHasYesOrNoButton = false;
  isShowCustomerInfoModal = false;
  isShowConfirmationModal = false;
  createCustomerPayload: any;
  isCreateCustomerBtnLoading = false;
  isResendBtnLoading=false;
  deviceTypeList = [];
  commonConstants = COMMON_CONSTANTS;
  HVACInformationList: HVACInformation;
  confirmationModalLine2Content: any;
  isShowIndoorField = false;
  editCustomerDataCopy: any;
  currentHVACInfoViewIndex: number;
  hvacDeleteIndex: number;
  isActionBtnLoading = false;
  createCustomerSubscription: Subscription;
  currentCustomerId: any;
  currentAwsCustomerId: any;
  isHVACInformationListLoading = false;
  isSHowEdit: boolean = false;
  isToggleButtons = false;
  count = 0;
  prevGroup;
  prevIndex = 0;
isIndividual:boolean=true;
  customerType: string=CUSTOMER_TYPES.RESIDENTIAL;
  constructor(
    private router: Router,
    private helperService: HelperService,
    private contractorSharedService: ContractorSharedService,
    private elementRef: ElementRef,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    // this.getAssetModalList();
    // this.getHVACInformationList();
    this.createConsumer = new FormGroup(
      {
        consumerFirstName: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces,this.helperService.validateAlteastOneCharacter]),
        consumerLastName: new FormControl('', [this.helperService.validateOnlySpaces,this.helperService.validateAlteastOneCharacter]),
        companyName: new FormControl('', [Validators.required,this.helperService.validateOnlySpaces,this.helperService.validateAlteastOneCharacter]),
        primaryEmail: new FormControl('', [this.helperService.validateIsEmail]),
        secondaryEmail: new FormControl('', [this.helperService.validateIsEmail]),
        phone: new FormArray(
          [new FormGroup(
            {
              // countryCode: new FormControl('', Validators.required),
              phoneNumber: new FormControl('', [this.helperService.validatePhoneNumber]),
              type: new FormControl('Home')
            }
          )]
        ),
        serviceAddress: new FormGroup(
          {
            addressLine1: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            addressLine2: new FormControl(''),
            unitNo: new FormControl('',[Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/)]),
            city: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            state: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            zipcode: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            placeId: new FormControl(''),
            formattedAddress: new FormControl(''),
          }
        ),
        billingAddress: new FormGroup(
          {
            addressLine1: new FormControl(''),
            addressLine2: new FormControl(''),
            unitNo: new FormControl('',[Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/)]),
            city: new FormControl(''),
            state: new FormControl(''),
            zipcode: new FormControl(''),
            placeId: new FormControl(''),
            formattedAddress: new FormControl(''),
          }
        ),
        // serialNumber: new FormControl('', Validators.required),
        // assetName: new FormControl('', Validators.required),

        // hvacEquipments: new FormArray([
        //   // new FormArray([
        //   //   new FormGroup({
        //   //     deviceType: new FormControl('', Validators.required),
        //   //     make: new FormControl('', Validators.required),
        //   //     serialNumber: new FormControl('', Validators.required),
        //   //     modelNo: new FormControl('', Validators.required),
        //   //     variableSpeedBlower: new FormControl(''),
        //   //     zonedSystem: new FormControl(''),
        //   //     filterType: new FormControl(''),
        //   //     filterLength: new FormControl(''),
        //   //     filterWidth: new FormControl(''),
        //   //     filterThickness: new FormControl(''),
        //   //     meteringDevice: new FormControl(''),
        //   //     systemConfiguration: new FormControl('', Validators.required),
        //   //     multiStage: new FormControl(''),
        //   //     ratedElectricStripCapacity: new FormControl(''),
        //   //     ratedCapacity: new FormControl(''),
        //   //     heatType: new FormControl(''),
        //   //     fuelType: new FormControl(''),
        //   //     refigerantType: new FormControl('', Validators.required)
        //   //   }),
        //   //   new FormGroup({
        //   //     deviceType: new FormControl('', Validators.required),
        //   //     make: new FormControl('', Validators.required),
        //   //     serialNumber: new FormControl('', Validators.required),
        //   //     modelNo: new FormControl('', Validators.required),
        //   //     nominalACCapacity: new FormControl(''),
        //   //     seer: new FormControl(''),
        //   //     multiStage: new FormControl(''),
        //   //   })
        //   // ])
        // ]),

        // indoorEquipment: new FormArray(
        //   [new FormGroup({
        //     serialNumber: new FormControl(''),
        //     // assetUnitName: new FormControl('', Validators.required),
        //     // fuel: new FormControl('', Validators.required),
        //     // coilOrHeatKit: new FormControl('', Validators.required),
        //     // meteringDevice: new FormControl('', Validators.required),
        //     // type: new FormControl({ value: '', disabled: true }, Validators.required),
        //     // equipment: new FormControl('', Validators.required),
        //     // make: new FormControl('', Validators.required),
        //     make: new FormControl(''),
        //     deviceType: new FormControl(''),
        //     // systemConfiguration: new FormControl('', Validators.required),
        //     // zonedSystem: new FormControl('', Validators.required),
        //     // variableSpeedBlower: new FormControl('', Validators.required),
        //     // filterType: new FormControl('', Validators.required),
        //     // filterLength: new FormControl('', Validators.required),
        //     // filterWidth: new FormControl('', Validators.required),
        //     // filterThickness: new FormControl('', Validators.required),
        //     // // refigerantType: new FormControl('', Validators.required),
        //     // meteringDevice: new FormControl('', Validators.required),
        //     // heatType: new FormControl('', Validators.required),
        //     // fuelType: new FormControl('', Validators.required),
        //     // multiStage: new FormControl('', Validators.required),
        //     // ratedCapacity: new FormControl('', Validators.required),
        //     // ratedElectricStripCapacity: new FormControl('', Validators.required),
        //   })
        //   ]),
        // outdoorEquipment: new FormArray(
        //   [
        //     //   new FormGroup({
        //     //   // serialNumber: new FormControl('', Validators.required),
        //     //   // assetUnitName: new FormControl('', Validators.required),
        //     //   // tonnage: new FormControl('', Validators.required),
        //     //   // type: new FormControl({ value: '', disabled: true }, Validators.required),
        //     //   deviceType: new FormControl(COMMON_CONSTANTS.AIR_CONDITIONER, Validators.required),
        //     //   // equipment: new FormControl('', Validators.required),
        //     //   make: new FormControl('', Validators.required),
        //     //   nominalACCapacity: new FormControl('', Validators.required),
        //     //   seer: new FormControl('', Validators.required),
        //     //   // multiStage: new FormControl('', Validators.required),
        //     //   refigerantType: new FormControl('', Validators.required),
        //     // })
        //   ]),

        // model: new FormControl(''),
        // brochureLink: new FormControl(''),
        // deviceType: new FormControl(''),
        // modelNo: new FormControl(''),
      }
    );
    // this.handleFormValidators('initial-render');

    this.createIndoorEquipmentCustomFields = new FormControl('', Validators.required);
    this.createOutdoorEquipmentCustomFields = new FormControl('', Validators.required);
    this.handleCustomerType(CUSTOMER_TYPES.RESIDENTIAL)
    // this.getPhoneCodesList();
    if (this.isEdit && this.editCustomerData) {
      // this.getHVACInformationList();
      this.editCustomerDataCopy = this.editCustomerData;
      this.handleAutoFillForm();
    }
    // this.handleAutoSaveCustomer();
    console.log(this.editCustomerData)
  }

  handleCustomerType(data:any)
  {
    console.log(data)
    this.customerType = data;
    if(data==CUSTOMER_TYPES.RESIDENTIAL){
      
        this.createConsumer.get('companyName').clearValidators();
         this.createConsumer.get('companyName').updateValueAndValidity();
        this.createConsumer.get('consumerFirstName').setValidators([Validators.required, this.helperService.validateOnlySpaces,this.helperService.validateAlteastOneCharacter])
        this.isIndividual=true;
    }
      else
      {
         this.createConsumer.get('consumerFirstName').clearValidators();
         this.createConsumer.get('consumerFirstName').updateValueAndValidity();
          this.createConsumer.get('companyName').setValidators([Validators.required, this.helperService.validateOnlySpaces,this.helperService.validateAlteastOneCharacter])

        this.isIndividual=false;
        console.log(this.createConsumer.get('consumerFirstName'))
      }
    
  }

  getHVACInformationList() {
    this.isHVACInformationListLoading = true;
    this.sharedService.getHVACInformationList().subscribe(
      (response: HVACInformationResponse) => {
        this.isHVACInformationListLoading = false;
        this.HVACInformationList = response.HVACInformation;
        if (this.isEdit && this.editCustomerData) {
          // setTimeout(() => {
          this.handleAutoFillForm();
          // },3000);
          // this.handleAutoFillForm();
        }
      }, (error) => {
        this.isHVACInformationListLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleAutoSelectTypeForOutdoor() {
    // if (this.HVACInformationList && this.HVACInformationList.typeList &&
    //   this.HVACInformationList.typeList.length) {
    //   this.HVACInformationList.typeList.map((value, index) => {
    //     if (value.value === COMMON_CONSTANTS.AIR_CONDITIONER) {
    //       this.outdoorEquipment.controls[0].patchValue({
    //         deviceType: value.id
    //       });
    //     }
    //   });
    // }
  }

  getPhoneCodesList() {
    this.contractorSharedService.getPhoneCodesList(
      (response: any, isError) => {
        if (!isError) {
          // const phonesCodes = [];
          // if (response && response.length) {
          //   response.map((value, index) => {
          //     if (value.name && value.callingCodes && value.callingCodes.length) {
          //       phonesCodes.push(
          //         {
          //           name: value.name,
          //           code: value.callingCodes[0]
          //         }
          //       );
          //     }
          //   }, this);
          //   this.phonesCodesList = phonesCodes;
          // }
          this.phonesCodesList = this.helperService.handlePhoneCodes(response);
          this.handlePatchPhoneCodeDropdown();
        }
      }
    );
  }

  handleUpdateEmailFieldStatus(emailType: string) {
    if (emailType === 'primaryEmail') {
      this.createConsumer.get('primaryEmail').disable();
    }
  }

  handleAutoFillForm() {
    console.log("DFsfds", this.editCustomerData)
    // this.editCustomerData.propertyUnitLocation=;
    const primaryEmail = this.helperService.handleEmailSelection(
      this.editCustomerData && this.editCustomerData.email, COMMON_CONSTANTS.EMAIL_PRIMARY);
    const secondaryEmail = this.helperService.handleEmailSelection(
      this.editCustomerData && this.editCustomerData.email, COMMON_CONSTANTS.EMAIL_SECONDARY);

    if (primaryEmail && primaryEmail.email) {
      this.handleUpdateEmailFieldStatus('primaryEmail');
    }

    this.handleCustomerType(this.editCustomerData.consumerType)

    this.createConsumer.patchValue({
      consumerFirstName: this.editCustomerData.customerFirstName ?
        this.editCustomerData.customerFirstName :
        this.editCustomerData.customerName,
      consumerLastName: this.editCustomerData.customerLastName,
      companyName:this.editCustomerData.companyName,
      primaryEmail: primaryEmail && primaryEmail.email ? primaryEmail.email : '',
      secondaryEmail: secondaryEmail && secondaryEmail.email ? secondaryEmail.email : '',
    });

    if (this.editCustomerData.phone && this.editCustomerData.phone.length) {
      this.editCustomerData.phone.map((value, index) => {
        if (index) {
          this.handleAddFormFields('phone', value);
        } else {
          this.phone.patchValue([{
            phoneNumber: this.helperService.handleFormatPhoneNumber(value.phoneNumber),
            type: value.type
          }]);
        }
      }, this);
    }


    const serviceAddress = this.helperService.handleAddressSelection(
      this.editCustomerData && this.editCustomerData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_SERVICE);
    // let addressLines = this.helperService.handleAddressLinesSplit(serviceAddress && serviceAddress.address);
    // let line2:any;
    // if(serviceAddress &&serviceAddress.addressLine2!=null){
    //   line2=serviceAddress.propertyUnitLocation?'Unit No.'+(serviceAddress.propertyUnitLocation + ',' + serviceAddress.addressLine2):serviceAddress.addressLine2;      
    // }
    // else if(serviceAddress && serviceAddress.propertyUnitLocation&&serviceAddress.addressLine2==null){
    //   line2='Unit No.'+serviceAddress.propertyUnitLocation + ',';
    // }

//console.log("DFsfds",line2)   
this.serviceAddress.patchValue({
      city: serviceAddress && serviceAddress.city,
      state: serviceAddress && serviceAddress.state,
      addressLine1: serviceAddress && serviceAddress.address,
      addressLine2: serviceAddress && serviceAddress.addressLine2,
      unitNo: serviceAddress && serviceAddress.propertyUnitLocation,
      zipcode: serviceAddress && serviceAddress.zipcode,
      placeId: serviceAddress && serviceAddress.placeId,
      formattedAddress: serviceAddress && serviceAddress.formattedAddress,
    });

    const billingAddress = this.helperService.handleAddressSelection(
      this.editCustomerData && this.editCustomerData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_BILLING);
    // addressLines = this.helperService.handleAddressLinesSplit(billingAddress && billingAddress.address);

    this.billingAddress.patchValue({
      city: billingAddress && billingAddress.city ? billingAddress.city : '',
      state: billingAddress && billingAddress.state ? billingAddress.state : '',
      addressLine1:billingAddress && billingAddress.address?billingAddress.address:'',
      addressLine2:billingAddress && billingAddress.addressLine2?billingAddress.addressLine2:'',
       unitNo: billingAddress && billingAddress.propertyUnitLocation?billingAddress.propertyUnitLocation:'',
      zipcode: billingAddress && billingAddress.zipcode ? billingAddress.zipcode : '',
      placeId: billingAddress && billingAddress.placeId ? billingAddress.placeId : '',
      formattedAddress: billingAddress && billingAddress.formattedAddress,
    });

    if (this.editCustomerData && this.editCustomerData.assetDetails &&
      this.editCustomerData.assetDetails.length) {

      this.editCustomerData.assetDetails.map((value, index) => {
        if (value.assetUnitType === 'indoor') {
          let selectedOutdoorValue = this.editCustomerData.assetDetails.find(currentValue =>
            currentValue.assetUnitType === 'outdoor' &&
            currentValue.assetUnitUniqueId === value.assetUnitUniqueId
          );
          this.handleAddHVACInformation(value, selectedOutdoorValue);
        }
      });

      // if (this.editCustomerData.assetDetails.length === 1) {
      //   this.handleAddHVACInformation(this.editCustomerData.assetDetails[0]);
      // } else if (this.editCustomerData.assetDetails.length > 1) {
      //   this.handleAddHVACInformation(this.editCustomerData.assetDetails[0], this.editCustomerData.assetDetails[1]);
      // }
    }
    // this.indoorEquipment.controls.map((value: FormGroup, index) => {
    //   value.patchValue({
    //     serialNumber: this.editCustomerData.assetDetails &&
    //       this.editCustomerData.assetDetails.length &&
    //       this.editCustomerData.assetDetails[0].assetUnitSerialNumber,
    //     make: this.editCustomerData.assetDetails &&
    //       this.editCustomerData.assetDetails.length &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo.indoorEquipmentMake &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo.indoorEquipmentMake.id,
    //     deviceType: this.editCustomerData.assetDetails &&
    //       this.editCustomerData.assetDetails.length &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo.type &&
    //       this.editCustomerData.assetDetails[0].assetModelInfo.type.id,
    //   });

    //   this.handleTypeOnChange(value, 'deviceType', 1, this.editCustomerData.assetDetails[0]);
    // });

    if (this.editCustomerData && this.editCustomerData.assetDetails
      && this.editCustomerData.assetDetails[1]) {
      this.handleAddFormFields('outdoorEquipment', this.editCustomerData.assetDetails[1]);
    }
    this.prevFormValues = this.createConsumer.getRawValue();
    // this.indoorEquipment.controls[0].patchValue({
    //   make: this.HVACInformationList.indoorEquipmentMakeList[2],
    //   // deviceType: this.editCustomerData.assetDetails[0].assetModelInfo.type
    // });
    // this.indoorEquipment.controls[0].get('make').patchValue(
    //   this.editCustomerData.assetDetails[0].assetModelInfo.indoorEquipmentMake.id
    // )
    // this.indoorEquipment.controls[0].get('make').updateValueAndValidity()
  }

  handleTypeOnChange(group: FormGroup, formControlName: string, index: number, defaultValue?: any) {
    // this.handleOnChangeIndoorEquipment(group);
    // let value = group.get(formControlName).value;
    // // value = value && value.value;
    // this.isShowIndoorField = true;
    // group.addControl('modelNo', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.modelNo ?
    //   defaultValue.assetModelInfo.modelNo : '')
    // );
    // group.addControl('variableSpeedBlower', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.variableSpeedBlower && defaultValue.assetModelInfo.variableSpeedBlower.id ?
    //   defaultValue.assetModelInfo.variableSpeedBlower.id : '')
    // );
    // group.addControl('zonedSystem', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.zonedSystem && defaultValue.assetModelInfo.zonedSystem.id ?
    //   defaultValue.assetModelInfo.zonedSystem.id : '')
    // );
    // group.addControl('filterType', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.filterType && defaultValue.assetModelInfo.filterType.id ?
    //   defaultValue.assetModelInfo.filterType.id : '')
    // );
    // group.addControl('filterLength', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.filterLength && defaultValue.assetModelInfo.filterLength.id ?
    //   defaultValue.assetModelInfo.filterLength.id : '')
    // );
    // group.addControl('filterWidth', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.filterWidth && defaultValue.assetModelInfo.filterWidth.id ?
    //   defaultValue.assetModelInfo.filterWidth.id : '')
    // );
    // group.addControl('filterThickness', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.filterThickness && defaultValue.assetModelInfo.filterThickness.id ?
    //   defaultValue.assetModelInfo.filterThickness.id : '')
    // );
    // group.addControl('meteringDevice', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.meteringDevice && defaultValue.assetModelInfo.meteringDevice.id ?
    //   defaultValue.assetModelInfo.meteringDevice.id : '')
    // );
    // group.addControl('systemConfiguration', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.systemConfiguration && defaultValue.assetModelInfo.systemConfiguration.id ?
    //   defaultValue.assetModelInfo.systemConfiguration.id : '')
    // );

    // group.addControl('multiStage', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   defaultValue.assetModelInfo.indoorMultiStage && defaultValue.assetModelInfo.indoorMultiStage.id ?
    //   defaultValue.assetModelInfo.indoorMultiStage.id : '')
    // );

    // // This condition won't occur because we hided type field in outdoor equipment so IF condition is commented
    // if (value === COMMON_CONSTANTS.AIR_CONDITIONER) {
    //   // group.addControl('nominalACCapacity', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   //   defaultValue.assetModelInfo.outdoorNominalACCapacity && defaultValue.assetModelInfo.outdoorNominalACCapacity.id ?
    //   //   defaultValue.assetModelInfo.outdoorNominalACCapacity.id : '')
    //   //   );
    //   // group.addControl('seer', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   //   defaultValue.assetModelInfo.outdoorSeer && defaultValue.assetModelInfo.outdoorSeer.id ?
    //   //   defaultValue.assetModelInfo.outdoorSeer.id : '')
    //   //   );
    //   // group.addControl('refigerantType', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //   //   defaultValue.assetModelInfo.refigerantType && defaultValue.assetModelInfo.refigerantType.id ?
    //   //   defaultValue.assetModelInfo.refigerantType.id : '')
    //   //   );
    //   // // Remove Air Handler Fields
    //   // group.removeControl('heatType');
    //   // group.removeControl('fuelType');
    //   // group.removeControl('ratedCapacity');
    //   // group.removeControl('ratedElectricStripCapacity');
    // } else {
    //   group.addControl('heatType', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //     defaultValue.assetModelInfo.heatType && defaultValue.assetModelInfo.heatType.id ?
    //     defaultValue.assetModelInfo.heatType.id : '')
    //   );
    //   group.addControl('fuelType', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //     defaultValue.assetModelInfo.fuelType && defaultValue.assetModelInfo.fuelType.id ?
    //     defaultValue.assetModelInfo.fuelType.id : '')
    //   );
    //   group.addControl('ratedCapacity', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //     defaultValue.assetModelInfo.ratedCapacity && defaultValue.assetModelInfo.ratedCapacity.id ?
    //     defaultValue.assetModelInfo.ratedCapacity.id : '')
    //   );
    //   group.addControl('ratedElectricStripCapacity', new FormControl(defaultValue && defaultValue.assetModelInfo &&
    //     defaultValue.assetModelInfo.ratedElectricStripCapacity ?
    //     defaultValue.assetModelInfo.ratedElectricStripCapacity : '')
    //   );
    //   // Remove Furnace Fields
    //   group.removeControl('nominalACCapacity');
    //   group.removeControl('seer');
    //   group.removeControl('refigerantType');
    // }

  }

  // ngAfterViewInit() {
  //   this.handlePlacesAutoComplete();
  // }


  // handlePlacesAutoComplete() {

  //   this.helperService.handleAddress(this.customerStreetAddress.nativeElement);

  //   this.helperService.setListenerForAddressObject().subscribe(
  //     (response: any) => {
  //       this.createConsumer.patchValue({
  //         addresses: {
  //           addressLine1: response.street,
  //           city: response.city,
  //           state: response.state,
  //           zipcode: response.zipcode
  //         }
  //       });
  //     }, (error) => {

  //     }
  //   );

  // }

  handlePlacesAutoComplete(element: ElementRef, type: string, index?: number) {
    this.helperService.handleAddress(element, type, index);
    const addressListener = this.helperService.setListenerForAddressObject().subscribe(
      (response: any) => {
        console.log(response)
        if (response.type === COMMON_CONSTANTS.ADDRESS_TYPE_SERVICE) {
          this.serviceAddress.patchValue({
            addressLine1: response.street,
            city: response.city,
            state: response.state,
            zipcode: response.zipcode,
            placeId: response.placeId,
            formattedAddress: response.formattedAddress,
          });
        } else if (response.type === COMMON_CONSTANTS.ADDRESS_TYPE_BILLING) {
          this.billingAddress.patchValue({
            addressLine1: response.street,
            city: response.city,
            state: response.state,
            zipcode: response.zipcode,
            placeId: response.placeId,
            formattedAddress: response.formattedAddress,
          });
        }
        addressListener.unsubscribe();
      }, (error) => {
        addressListener.unsubscribe();
      }
    );

  }

  handleAddFormFields(type: string, defaultValue?: any) {
    if (type === 'phone' && this.phone.length < COMMON_CONSTANTS.PHONE_NUMBERS_COUNT_MAX) {
      this.phone.push(new FormGroup({
        // countryCode: new FormControl(
        //   this.helperService.handleSelectDefaultValueForPhoneCodeDropdown(this.selectedCountryForPhoneCode, 'name'),
        //   Validators.required
        // ),
        phoneNumber: new FormControl(
          defaultValue && defaultValue.phoneNumber ? defaultValue.phoneNumber : '',
          [this.helperService.validatePhoneNumber]
        ),
        type: new FormControl(
          defaultValue && defaultValue.type ? defaultValue.type : 'Home'
         
        )
      }));
      //this.handleButtonsOnLengthChanges();
      // } else if (type === 'indoorEquipment') {
      //   this.indoorEquipment.push(new FormGroup({
      //     serialNumber: new FormControl('', Validators.required),
      //     modelNo: new FormControl('', Validators.required),
      //     deviceType: new FormControl('', Validators.required),
      //     // equipment: new FormControl('', Validators.required),
      //     // make: new FormControl('', Validators.required),
      //     // systemConfiguration: new FormControl('', Validators.required),
      //     // zonedSystem: new FormControl('', Validators.required),
      //     // variableSpeedBlower: new FormControl('', Validators.required),
      //     // filterType: new FormControl('', Validators.required),
      //     // filterLength: new FormControl('', Validators.required),
      //     // filterWidth: new FormControl('', Validators.required),
      //     // filterThickness: new FormControl('', Validators.required),
      //     // // refigerantType: new FormControl('', Validators.required),
      //     // meteringDevice: new FormControl('', Validators.required),
      //     // heatType: new FormControl('', Validators.required),
      //     // fuelType: new FormControl('', Validators.required),
      //     // multiStage: new FormControl('', Validators.required),
      //     // ratedCapacity: new FormControl('', Validators.required),
      //     // ratedElectricStripCapacity: new FormControl('', Validators.required),
      //   }));
      // } else if (type === 'outdoorEquipment') {
      //   this.outdoorEquipment.push(new FormGroup({
      //     // equipment: new FormControl('', Validators.required),
      //     deviceType: new FormControl('', Validators.required),
      //     make: new FormControl(defaultValue && defaultValue.assetModelInfo &&
      //       defaultValue.assetModelInfo.outdoorEquipmentMake && defaultValue.assetModelInfo.outdoorEquipmentMake.id ?
      //       defaultValue.assetModelInfo.outdoorEquipmentMake.id : ''),
      //     nominalACCapacity: new FormControl(defaultValue && defaultValue.assetModelInfo &&
      //       defaultValue.assetModelInfo.outdoorNominalACCapacity && defaultValue.assetModelInfo.outdoorNominalACCapacity.id ?
      //       defaultValue.assetModelInfo.outdoorNominalACCapacity.id : ''),
      //     seer: new FormControl(defaultValue && defaultValue.assetModelInfo &&
      //       defaultValue.assetModelInfo.outdoorSeer && defaultValue.assetModelInfo.outdoorSeer.id ?
      //       defaultValue.assetModelInfo.outdoorSeer.id : ''),
      //     // multiStage: new FormControl('', Validators.required),
      //     refigerantType: new FormControl(defaultValue && defaultValue.assetModelInfo &&
      //       defaultValue.assetModelInfo.refigerantType && defaultValue.assetModelInfo.refigerantType.id ?
      //       defaultValue.assetModelInfo.refigerantType.id : ''),
      //   }));
      //   this.handleAutoSelectTypeForOutdoor();
    }
  }

  handleRemoveFormFields(type: string, index: number, isAll = false) {
    if (type === 'phone') {
      if (isAll && this.phone) {
        this.phone.clear();
        this.handleAddFormFields('phone');
        if (this.editCustomerData && this.editCustomerData.phone) {
          this.editCustomerData.phone = [];
        }
      } else {
        this.phone.removeAt(index);
        if (this.editCustomerData && this.editCustomerData.phone) {
          this.editCustomerData.phone.splice(index, 1);
        }
        this.handleButtonsOnLengthChanges();
      }
      // } else if (type === 'indoorEquipment') {
      //   if (isAll && this.phone) {
      //     this.indoorEquipment.clear();
      //     this.handleAddFormFields('indoorEquipment');
      //   } else {
      //     this.indoorEquipment.removeAt(index);
      //   }
      // } else if (type === 'outdoorEquipment') {
      //   if (isAll && this.phone) {
      //     this.outdoorEquipment.clear();
      //     this.handleAddFormFields('outdoorEquipment');
      //   } else {
      //     this.outdoorEquipment.removeAt(index);
      //   }
    }
  }

  handleFormatPhoneNumber(phoneGroup: FormGroup, phoneNumber: any) {
    const number = this.helperService.handleFormatPhoneNumber(phoneNumber);
    phoneGroup.patchValue({
      phoneNumber: number
    });
  }

  get phone() {
    return this.createConsumer.get('phone') as FormArray;
  }

  get serviceAddress() {
    return this.createConsumer.get('serviceAddress') as FormGroup;
  }

  get billingAddress() {
    return this.createConsumer.get('billingAddress') as FormGroup;
  }

  // get indoorEquipment() {
  //   return this.createConsumer.get('indoorEquipment') as FormArray;
  // }

  // get outdoorEquipment() {
  //   return this.createConsumer.get('outdoorEquipment') as FormArray;
  // }

  get hvacEquipments() {
    return this.createConsumer.get('hvacEquipments') ? this.createConsumer.get('hvacEquipments') as FormArray : new FormArray([]);
  }

  handleHVACInfoView(index: number) {
    this.currentHVACInfoViewIndex = index;
  }

  handleAddHVACInformation(defaultIndoorValue?: any, defaultOutdoorValue?: any) {
    this.hvacEquipments.push(
      new FormArray([
        new FormGroup({
          location: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.locations &&
              defaultIndoorValue.assetModelInfo.locations.id ?
              defaultIndoorValue.assetModelInfo.locations.id : ''),
          deviceType: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.indoorEquipmentType &&
              defaultIndoorValue.assetModelInfo.indoorEquipmentType.id ?
              defaultIndoorValue.assetModelInfo.indoorEquipmentType.id : '', Validators.required),
          make: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.indoorEquipmentMake &&
              defaultIndoorValue.assetModelInfo.indoorEquipmentMake.id ?
              defaultIndoorValue.assetModelInfo.indoorEquipmentMake.id : ''),
          serialNumber: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetUnitSerialNumber ?
              defaultIndoorValue.assetUnitSerialNumber : ''),
          modelNo: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.modelNo ?
              defaultIndoorValue.assetModelInfo.modelNo : ''),
          variableSpeedBlower: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.variableSpeedBlower &&
              defaultIndoorValue.assetModelInfo.variableSpeedBlower.id ?
              defaultIndoorValue.assetModelInfo.variableSpeedBlower.id : ''),
          zonedSystem: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.zonedSystem &&
              defaultIndoorValue.assetModelInfo.zonedSystem.id ?
              defaultIndoorValue.assetModelInfo.zonedSystem.id : ''),
          filterType: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.filterType &&
              defaultIndoorValue.assetModelInfo.filterType.id ?
              defaultIndoorValue.assetModelInfo.filterType.id : ''),
          filterLength: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.filterLength &&
              defaultIndoorValue.assetModelInfo.filterLength.id ?
              defaultIndoorValue.assetModelInfo.filterLength.id : ''),
          filterWidth: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.filterWidth &&
              defaultIndoorValue.assetModelInfo.filterWidth.id ?
              defaultIndoorValue.assetModelInfo.filterWidth.id : ''),
          filterThickness: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.filterThickness &&
              defaultIndoorValue.assetModelInfo.filterThickness.id ?
              defaultIndoorValue.assetModelInfo.filterThickness.id : ''),
          meteringDevice: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.meteringDevice &&
              defaultIndoorValue.assetModelInfo.meteringDevice.id ?
              defaultIndoorValue.assetModelInfo.meteringDevice.id : ''),
          systemConfiguration: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.systemConfiguration &&
              defaultIndoorValue.assetModelInfo.systemConfiguration.id ?
              defaultIndoorValue.assetModelInfo.systemConfiguration.id : '', Validators.required),
          multiStage: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.indoorMultiStage &&
              defaultIndoorValue.assetModelInfo.indoorMultiStage.id ?
              defaultIndoorValue.assetModelInfo.indoorMultiStage.id : ''),
          ratedElectricStripCapacity: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.ratedElectricStripCapacity ?
              defaultIndoorValue.assetModelInfo.ratedElectricStripCapacity : ''),
          ratedCapacity: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.ratedCapacity && defaultIndoorValue.assetModelInfo.ratedCapacity.id ?
              defaultIndoorValue.assetModelInfo.ratedCapacity.id : ''),
          heatType: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.heatType && defaultIndoorValue.assetModelInfo.heatType.id ?
              defaultIndoorValue.assetModelInfo.heatType.id : ''),
          fuelType: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.fuelType && defaultIndoorValue.assetModelInfo.fuelType.id ?
              defaultIndoorValue.assetModelInfo.fuelType.id : ''),
          refigerantType: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.refigerantType &&
              defaultIndoorValue.assetModelInfo.refigerantType.id ?
              defaultIndoorValue.assetModelInfo.refigerantType.id : ''),
          unitTypeId: new FormControl(
            defaultIndoorValue &&
              defaultIndoorValue.assetUnitTypeId ?
              defaultIndoorValue.assetUnitTypeId : null),
          assetModelInfoId: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.assetModelInfoId ?
              defaultIndoorValue.assetModelInfo.assetModelInfoId : null),
          assetUnitUniqueId: new FormControl(
            defaultIndoorValue &&
              defaultIndoorValue.assetUnitUniqueId ?
              defaultIndoorValue.assetUnitUniqueId : null),
          equipmentIs: new FormControl(
            defaultIndoorValue && defaultIndoorValue.assetModelInfo &&
              defaultIndoorValue.assetModelInfo.equipmentIs && defaultIndoorValue.assetModelInfo.equipmentIs.id ?
              defaultIndoorValue.assetModelInfo.equipmentIs.id : '', Validators.required),
        }),
        new FormGroup({
          deviceType: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentType &&
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentType.id ?
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentType.id : '', Validators.required),
          make: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentMake &&
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentMake.id ?
              defaultOutdoorValue.assetModelInfo.outdoorEquipmentMake.id : ''),
          serialNumber: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetUnitSerialNumber ?
              defaultOutdoorValue.assetUnitSerialNumber : ''),
          modelNo: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.modelNo ?
              defaultOutdoorValue.assetModelInfo.modelNo : ''),
          nominalACCapacity: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorNominalACCapacity &&
              defaultOutdoorValue.assetModelInfo.outdoorNominalACCapacity.id ?
              defaultOutdoorValue.assetModelInfo.outdoorNominalACCapacity.id : ''),
          seer: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorSeer &&
              defaultOutdoorValue.assetModelInfo.outdoorSeer.id ?
              defaultOutdoorValue.assetModelInfo.outdoorSeer.id : ''),
          multiStage: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorMultiStage &&
              defaultOutdoorValue.assetModelInfo.outdoorMultiStage.id ?
              defaultOutdoorValue.assetModelInfo.outdoorMultiStage.id : ''),
          unitTypeId: new FormControl(
            defaultOutdoorValue &&
              defaultOutdoorValue.assetUnitTypeId ?
              defaultOutdoorValue.assetUnitTypeId : null),
          assetModelInfoId: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.assetModelInfoId ?
              defaultOutdoorValue.assetModelInfo.assetModelInfoId : null),
          inverterCompresser: new FormControl(
            defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
              defaultOutdoorValue.assetModelInfo.outdoorInverterCompressor &&
              defaultOutdoorValue.assetModelInfo.outdoorInverterCompressor.id ?
              defaultOutdoorValue.assetModelInfo.outdoorInverterCompressor.id : ''),
          // equipmentIs: new FormControl(
          //   defaultOutdoorValue && defaultOutdoorValue.assetModelInfo &&
          //   defaultOutdoorValue.assetModelInfo.equipmentIs && defaultOutdoorValue.assetModelInfo.equipmentIs.id ?
          //   defaultOutdoorValue.assetModelInfo.equipmentIs.id : ''),
        })
      ])
    );
    this.handleHVACInfoView(this.hvacEquipments.controls && (this.hvacEquipments.controls.length - 1));
  }

  handleSystemConfigOnchange(group: FormArray) {
    // System config-Indoor Equipment-Outdoor Equipment Mapping
    if (group.controls && group.controls[0] &&
      group.controls[0].get('systemConfiguration') &&
      group.controls[0].get('systemConfiguration').value === '2') {
      // Heat only = Furnace = None
      group.controls[0].get('deviceType').patchValue('1');
      group.controls[1].get('deviceType').patchValue('3');
    } else if (group.controls && group.controls[0] &&
      group.controls[0].get('systemConfiguration') &&
      group.controls[0].get('systemConfiguration').value === '3') {
      // AC only = Air handler
      group.controls[0].get('deviceType').patchValue('2');
      group.controls[1].get('deviceType').reset();
    } else {
      group.controls[0].get('deviceType').reset();
      group.controls[1].get('deviceType').reset();
    }
  }


  handleModelOnChange(flag: string, group: FormGroup, i: number) {
    if (this.prevIndex !== i) {
      this.count = 0;
    }
    if (this.count === 0) {
      this.prevGroup = group.getRawValue();
    } else {
      group.addControl('fuelType', new FormControl(this.prevGroup.fuelType));
      group.addControl('heatType', new FormControl(this.prevGroup.heatType));
      group.addControl('ratedElectricStripCapacity', new FormControl(this.prevGroup.ratedElectricStripCapacity));
    }
    if (flag === 'deviceType') {
      if (group.get('deviceType') && group.get('deviceType').value) {
        if (group.get('deviceType').value === HVAC_ID.INDOOR_EQUIP_TYPE_FURNACE.toString()) {
          group.removeControl('ratedElectricStripCapacity');
        } else if (group.get('deviceType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString()) {
          group.removeControl('fuelType');
          group.removeControl('heatType');
          group.removeControl('ratedElectricStripCapacity');
        } else if (group.get('deviceType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER_WITH_ELEC_STRIP.toString()) {
          group.removeControl('fuelType');
          group.removeControl('heatType');
        }
      }
      // (group.get('deviceType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER.toString() ||
      //   group.get('deviceType').value === HVAC_ID.INDOOR_EQUIP_TYPE_AIR_HANDLER_WITH_ELEC_STRIP.toString())) {
      // group.get('fuelType') && group.get('fuelType').patchValue(HVAC_ID.FUEL_TYPE_ELECTRIC);

    } else {
      group.get('fuelType') && group.get('fuelType').patchValue('');
    }
    this.count++;
    this.prevIndex = i;
  }

  handleDeleteHVACInformation(index: number) {
    this.isActionBtnLoading = true;
    if (this.hvacEquipments && this.hvacEquipments.controls &&
      this.hvacEquipments.controls.length && this.hvacEquipments.controls[index]) {
      if (this.hvacEquipments.controls[index].value[0].unitTypeId ||
        this.hvacEquipments.controls[index].value[1].unitTypeId) {
        this.handleDeleteHVAC([this.hvacEquipments.controls[index].value[0].unitTypeId,
        this.hvacEquipments.controls[index].value[1].unitTypeId], index);
      } else {
        this.hvacEquipments.removeAt(index);
        this.isActionBtnLoading = false;
        this.toggleConfirmationModal();
      }
    }
  }

  handleDeleteHVAC(ids: Array<any>, index: number) {
    const queryParams = {
      assetUnitTypeId: [...ids]
    };
    this.contractorSharedService.handleDeleteHVACUnit(queryParams).subscribe(
      (response) => {
        this.emitHandleRefreshPage.emit();
        this.hvacEquipments.removeAt(index);
        this.isActionBtnLoading = false;
        this.toggleConfirmationModal();
        this.helperService.showToaster(ToasterType.SUCCESS, response.statusInfo);
      }, (error) => {
        this.isActionBtnLoading = false;
        this.toggleConfirmationModal();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleRadioBtnValue(form: FormGroup, controlName: string, value: any) {
    // form.patchValue({
    //   controlName: value
    // });
    form.controls[controlName].patchValue(value.id);
  }

  // handleFormValidators(flag?: string) {

  //   if (!this.isIndoorEquipmentCustomModal) {
  //     // const abstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //     if (this.indoorEquipment) {
  //       (this.indoorEquipment).addControl('model', new FormControl(''));
  //       (this.indoorEquipment).addControl('deviceType', new FormControl('', Validators.required));
  //       // (this.indoorEquipment).removeControl('brochureLink');
  //       // (this.indoorEquipment).removeControl('deviceType');
  //       (this.indoorEquipment).removeControl('modelNo');
  //     }
  //   } else if (this.isIndoorEquipmentCustomModal) {
  //     // const abstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //     if (this.indoorEquipment) {
  //       // (this.indoorEquipment).addControl('brochureLink', new FormControl('', Validators.required));
  //       // (this.indoorEquipment).addControl('deviceType', new FormControl('', Validators.required));
  //       (this.indoorEquipment).addControl('modelNo', new FormControl('', Validators.required));
  //     }
  //   }

  //   if (!this.isOutdoorEquipmentCustomModal) {
  //     // const abstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //     if (this.outdoorEquipment) {
  //       (this.outdoorEquipment).addControl('model', new FormControl(''));
  //       (this.outdoorEquipment).addControl('deviceType', new FormControl('', Validators.required));
  //       // (this.outdoorEquipment).removeControl('brochureLink');
  //       // (this.outdoorEquipment).removeControl('deviceType');
  //       (this.outdoorEquipment).removeControl('modelNo');
  //     }
  //   } else if (this.isOutdoorEquipmentCustomModal) {
  //     // const abstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //     if (this.outdoorEquipment) {
  //       // (this.outdoorEquipment).addControl('brochureLink', new FormControl('', Validators.required));
  //       // (this.outdoorEquipment).addControl('deviceType', new FormControl('', Validators.required));
  //       (this.outdoorEquipment).addControl('modelNo', new FormControl('', Validators.required));
  //     }
  //   }

  //   if (flag === 'initial-render') {
  //     this.setDefaultValueForModel(this.assetModelList);
  //   }

  // }

  // setTypeAndModelValue(flag: string) {
  //   if (flag === 'indoorEquipment') {
  //     // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //     if (!this.isIndoorEquipmentCustomModal) {
  //       if (this.indoorEquipment) {
  //         (this.indoorEquipment).controls.deviceType.patchValue(this.selectedIndoorAssetModel.deviceType);
  //         // (this.indoorEquipment).controls.make.patchValue(this.selectedIndoorAssetModel.deviceType);
  //       }
  //     } else {
  //       if (this.indoorEquipment) {
  //         (this.indoorEquipment).controls.deviceType.patchValue('');
  //         // (this.indoorEquipment).controls.make.patchValue('');
  //       }
  //     }
  //   } else if (flag === 'outdoorEquipment') {
  //     // const indoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //     if (!this.isOutdoorEquipmentCustomModal) {
  //       if (this.outdoorEquipment) {
  //         (this.outdoorEquipment).controls.deviceType.patchValue(this.selectedOutdoorAssetModel.deviceType);
  //         // (this.outdoorEquipment).controls.make.patchValue(this.selectedOutdoorAssetModel.deviceType);
  //       }
  //     } else {
  //       if (this.outdoorEquipment) {
  //         (this.outdoorEquipment).controls.deviceType.patchValue('');
  //         // (this.outdoorEquipment).controls.make.patchValue('');
  //       }
  //     }
  //   }
  // }

  handleOnChangeIndoorEquipment(group: FormGroup) {

    if (group.get('serialNumber').value ||
      group.get('make').value ||
      group.get('deviceType').value) {
      group.get('serialNumber').setValidators(Validators.required);
      // group.get('make').setValidators(Validators.required);
      group.get('deviceType').setValidators(Validators.required);
    } else {
      group.get('serialNumber').clearValidators();
      // group.get('make').clearValidators();
      group.get('deviceType').clearValidators();
    }

    group.get('serialNumber').updateValueAndValidity();
    // group.get('make').updateValueAndValidity();
    group.get('deviceType').updateValueAndValidity();
  }

  handleOnChangeBillingAddress(currentBillingAddress: FormGroup) {
    if (currentBillingAddress.get('addressLine1').value ||
      currentBillingAddress.get('addressLine2').value ||
      currentBillingAddress.get('city').value ||
      currentBillingAddress.get('state').value ||
      currentBillingAddress.get('zipcode').value||
      currentBillingAddress.get('unitNo').value) {
      currentBillingAddress.get('addressLine1').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      // currentBillingAddress.get('addressLine2').setValidators(Validators.required);
      currentBillingAddress.get('city').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBillingAddress.get('state').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBillingAddress.get('zipcode').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBillingAddress.get('unitNo')
      currentBillingAddress.patchValue({
        addressLine1: currentBillingAddress.get('addressLine1').value ? currentBillingAddress.get('addressLine1').value : '',
        // addressLine2: currentBillingAddress.get('addressLine2').value ? currentBillingAddress.get('addressLine2').value : '',
        city: currentBillingAddress.get('city').value ? currentBillingAddress.get('city').value : '',
        state: currentBillingAddress.get('state').value ? currentBillingAddress.get('state').value : '',
        zipcode: currentBillingAddress.get('zipcode').value ? currentBillingAddress.get('zipcode').value : '',
        unitNo: currentBillingAddress.get('unitNo').value ? currentBillingAddress.get('unitNo').value : ''

      });
      currentBillingAddress.markAllAsTouched();
    } else {
      currentBillingAddress.get('addressLine1').clearValidators();
      // currentBillingAddress.get('addressLine2').clearValidators();
      currentBillingAddress.get('city').clearValidators();
      currentBillingAddress.get('state').clearValidators();
      currentBillingAddress.get('zipcode').clearValidators();
      currentBillingAddress.reset();
    }
  }

  handleParseAddresses() {
    let serviceAddressId;
    let billingAddressId;
    //let unit:any;
    let addressLine2:String;
    if (this.isEdit && this.editCustomerData && this.editCustomerData.addresses) {
      serviceAddressId = this.helperService.handleAddressSelection(
        this.editCustomerData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_SERVICE);
        
      billingAddressId = this.helperService.handleAddressSelection(
        this.editCustomerData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_BILLING);
    }
// if(serviceAddressId && serviceAddressId.propertyUnitLocation){
//   // unit= String(this.serviceAddress.value.addressLine2).split(',');
//   String(this.serviceAddress.value.addressLine2).substr(0,String(this.serviceAddress.value.addressLine2).indexOf(',')); // "72"
//   unit = String(this.serviceAddress.value.addressLine2).substr(String(this.serviceAddress.value.addressLine2).indexOf(',')+1);
// }
// else{
//   unit=String(this.serviceAddress.value.addressLine2)
// }
    const address = [];
    address.push({
      address: this.serviceAddress.value.addressLine1,
      addressLine2:this.serviceAddress.value.addressLine2,
      city: this.serviceAddress.value.city,
      state: this.serviceAddress.value.state,
      zipcode: this.serviceAddress.value.zipcode,
      type: COMMON_CONSTANTS.ADDRESS_TYPE_SERVICE,
      addressId: this.isEdit ? serviceAddressId && serviceAddressId.addressId : null,
      placeId: this.serviceAddress.value.placeId,
      formattedAddress: this.serviceAddress.value.formattedAddress,
      propertyUnitLocation:this.serviceAddress.value.unitNo,
    });

    if (this.billingAddress.value.addressLine1) {
      address.push({
        address:  this.billingAddress.value.addressLine1,
          addressLine2: this.billingAddress.value.addressLine2,
        city: this.billingAddress.value.city,
        state: this.billingAddress.value.state,
        zipcode: this.billingAddress.value.zipcode,
        type: COMMON_CONSTANTS.ADDRESS_TYPE_BILLING,
        addressId: this.isEdit ? billingAddressId && billingAddressId.addressId : null,
        placeId: this.billingAddress.value.placeId,
        formattedAddress: this.billingAddress.value.formattedAddress,
        propertyUnitLocation:this.billingAddress.value.unitNo,
      });
    }
    return address;
  }

  handleFetchValueFromId(key: string, id: any) {
    let selectedValue;
    if (this.HVACInformationList && this.HVACInformationList[key] &&
      this.HVACInformationList[key].length) {
      selectedValue = this.HVACInformationList[key].find((currentValue: TypeList) => currentValue.id.toString() === (id && id.toString()));
    }
    return selectedValue;
  }

  handleParseIndoorEquipment(isEditPayload: boolean, indoorValue, index) {
    let indoorList;
    // if (this.indoorEquipment && this.indoorEquipment.value &&
    //   this.indoorEquipment.value.length) {
    // this.indoorEquipment.value.map((indoorValue, index) => {
    // if (indoorValue.serialNumber) {
    if (isEditPayload) {
      indoorList =
      {
        // assetUnitName: this.indoorEquipment && value.assetUnitName,
        assetUnitType: 'indoor',
        assetUnitSerialNumber: indoorValue.serialNumber,
        // manufacturerId: 2,
        //  assetUnitModel: {
        //     assetModelInfoId: null,
        //     modelNo: null
        //  },
        index: index + 1,
        assetModelInfo: {
          locations: this.handleFetchValueFromId('locationsList', indoorValue.location),
          modelNo: indoorValue.modelNo,
          ratedElectricStripCapacity: indoorValue.ratedElectricStripCapacity,
          // equipmentId: indoorValue.equipment.value,
          indoorEquipmentType: this.handleFetchValueFromId('indoorEquipmentTypeList', indoorValue.deviceType),
          systemConfiguration: this.handleFetchValueFromId('systemConfigurationList', indoorValue.systemConfiguration),
          indoorEquipmentMake: this.handleFetchValueFromId('indoorEquipmentMakeList', indoorValue.make),
          variableSpeedBlower: this.handleFetchValueFromId('variableSpeedBlowerList', indoorValue.variableSpeedBlower),
          zonedSystem: this.handleFetchValueFromId('zonedSystemList', indoorValue.zonedSystem),
          filterType: this.handleFetchValueFromId('filterTypeList', indoorValue.filterType),
          filterLength: this.handleFetchValueFromId('filterLenghtList', indoorValue.filterLength),
          filterWidth: this.handleFetchValueFromId('filterWidthList', indoorValue.filterWidth),
          filterThickness: this.handleFetchValueFromId('filterThicknessList', indoorValue.filterThickness),
          meteringDevice: this.handleFetchValueFromId('meteringDeviceList', indoorValue.meteringDevice),
          // nomicalAcCapacityId: value.,
          indoorMultiStage: this.handleFetchValueFromId('indoorMultiStageList', indoorValue.multiStage),
          refigerantType: this.handleFetchValueFromId('indoorRefigerantTypeList', indoorValue.refigerantType),
          // seerId: value.,
          heatType: this.handleFetchValueFromId('indoorHeatTypeList', indoorValue.heatType),
          fuelType: this.handleFetchValueFromId('indoorFuelTypeList', indoorValue.fuelType),
          ratedCapacity: this.handleFetchValueFromId('indoorRatedCapacityList', indoorValue.ratedCapacity),
          equipmentIs: this.handleFetchValueFromId('isEquipmentList',
            indoorValue.equipmentIs),
        },
        //  assetUnitParameters: [
        //     // {
        //     //    unitParameterName:Fuel,
        //     //    unitParameterValue:78
        //     // },
        //     // {
        //     //    unitParameterName:Metering Device,
        //     //    unitParameterValue:78
        //     // }
        //  ]
      }

    } else {
      // let assetUnitTypeId;
      // let assetModelInfoId;
      // if (this.isEdit && this.editCustomerData && this.editCustomerData.assetDetails &&
      //   this.editCustomerData.assetDetails.length) {
      //   assetUnitTypeId = this.editCustomerData.assetDetails[0].assetUnitTypeId;
      //   assetModelInfoId = this.editCustomerData.assetDetails[0].assetModelInfo.assetModelInfoId;
      // }
      const deviceTypeValue = this.handleFetchValueFromId('indoorEquipmentTypeList', indoorValue.deviceType);
      indoorList =
      {
        // assetUnitName: this.indoorEquipment && indoorValue.assetUnitName,
        assetUnitType: 'indoor',
        assetUnitSerialNumber: indoorValue.serialNumber,
        // manufacturerId: 2,
        //  assetUnitModel: {
        //     assetModelInfoId: null,
        //     modelNo: null
        //  },
        assetUnitTypeId: this.isEdit ? indoorValue && indoorValue.unitTypeId : null,
        assetUnitUniqueId: this.isEdit ? indoorValue && indoorValue.assetUnitUniqueId : null,
        assetModelInfo: {
          locationId: indoorValue.location,
          assetModelInfoId: this.isEdit ? indoorValue && indoorValue.assetModelInfoId : null,
          modelNo: indoorValue.modelNo,
          ratedElectricStripCapacity: indoorValue.ratedElectricStripCapacity,
          // equipmentId: indoorValue.equipment.id,
          indoorEquipmentTypeId: indoorValue.deviceType,
          systemConfigurationId: indoorValue.systemConfiguration,
          indoorEquipmentMakeId: indoorValue.make,
          variableSpeedBlowerId: indoorValue.variableSpeedBlower,
          zonedSystemId: indoorValue.zonedSystem,
          filterTypeId: indoorValue.filterType,
          filterLengthId: indoorValue.filterLength,
          filterWidthId: indoorValue.filterWidth,
          filterThicknessId: indoorValue.filterThickness,
          meteringDeviceId: indoorValue.meteringDevice,
          // nomicalAcCapacityId: indoorValue.,
          indoorMultiStageId: indoorValue.multiStage,
          refigerantTypeId: indoorValue.refigerantType,
          // seerId: indoorValue.,
          heatingTypeId: indoorValue.heatType,
          fuelTypeId: indoorValue.fuelType,
          ratedCapacityId: indoorValue.ratedCapacity,
          isEquipmentId: indoorValue.equipmentIs,
          deviceType: deviceTypeValue && deviceTypeValue.value
        },
        //  assetUnitParameters: [
        //     // {
        //     //    unitParameterName:Fuel,
        //     //    unitParameterValue:78
        //     // },
        //     // {
        //     //    unitParameterName:Metering Device,
        //     //    unitParameterValue:78
        //     // }
        //  ]
      }

    }
    // }
    // });
    // }

    return indoorList;
  }

  handleParseOutdoorEquipment(isEditPayload: boolean, outdoorValue, indoorValue, index) {
    let oudoorList;
    // if (this.outdoorEquipment && this.outdoorEquipment.value &&
    //   this.outdoorEquipment.value.length) {
    // this.outdoorEquipment.value.map((outdoorValue, index) => {
    if (isEditPayload) {
      oudoorList =
      {
        //  assetUnitName: this.outdoorEquipment && value.assetUnitName,
        assetUnitType: 'outdoor',
        assetUnitSerialNumber: outdoorValue.serialNumber,
        //  assetUnitSerialNumber: this.outdoorEquipment && value.serialNumber,
        //  manufacturerId: 2,
        //  assetUnitModel: {
        //     assetModelInfoId: null,
        //     modelNo: null
        //  },
        index: index + 1,
        assetModelInfo: {
          // equipmentId: this.indoorEquipment.value[index].equipment.value,
          outdoorEquipmentMake: this.handleFetchValueFromId('outdoorEquipmentMakeList',
            outdoorValue.make),
          modelNo: outdoorValue.modelNo,
          // variableSpeedBlower: this.handleFetchValueFromId('variableSpeedBlowerList',
          //   this.indoorEquipment.value[index].variableSpeedBlower),
          // zonedSystem: this.handleFetchValueFromId('zonedSystemList',
          //   this.indoorEquipment.value[index].zonedSystem),
          // filterType: this.handleFetchValueFromId('filterTypeList',
          //   this.indoorEquipment.value[index].filterType),
          // filterLength: this.handleFetchValueFromId('filterLenghtList',
          //   this.indoorEquipment.value[index].filterLength),
          // filterWidth: this.handleFetchValueFromId('filterWidthList',
          //   this.indoorEquipment.value[index].filterWidth),
          // filterThickness: this.handleFetchValueFromId('filterThicknessList',
          //   this.indoorEquipment.value[index].filterThickness),
          // meteringDevice: this.handleFetchValueFromId('meteringDeviceList',
          //   this.indoorEquipment.value[index].meteringDevice),
          outdoorNominalACCapacity: this.handleFetchValueFromId('outdoorNominalACCapacityList',
            outdoorValue.nominalACCapacity),
          outdoorMultiStage: this.handleFetchValueFromId('outdoorMultistageList',
            outdoorValue.multiStage),
          // refigerantType: this.handleFetchValueFromId('indoorRefigerantTypeList',
          //   outdoorValue.refigerantType),
          outdoorSeer: this.handleFetchValueFromId('outdoorSeerList',
            outdoorValue.seer),
          outdoorEquipmentType: this.handleFetchValueFromId('outdoorEquipmentTypeList',
            outdoorValue.deviceType),
          equipmentIs: this.handleFetchValueFromId('isEquipmentList',
            outdoorValue.equipmentIs),
          outdoorInverterCompressor: this.handleFetchValueFromId('outdoorInverterCompressorList',
            outdoorValue.inverterCompresser),
        },
        //  assetUnitParameters: [
        //     // {
        //     //    unitParameterName:Tonnage,
        //     //    unitParameterValue:100
        //     // },
        //     // {
        //     //    unitParameterName:CustomLabel,
        //     //    unitParameterValue:CustomValue
        //     // }
        //  ]
      }

    } else {
      // let assetUnitTypeId;
      // let assetModelInfoId;
      // if (this.isEdit && this.editCustomerData && this.editCustomerData.assetDetails &&
      //   this.editCustomerData.assetDetails.length >= 2) {
      //   assetUnitTypeId = this.editCustomerData.assetDetails[1].assetUnitTypeId;
      //   assetModelInfoId = this.editCustomerData.assetDetails[1].assetModelInfo.assetModelInfoId;
      // }
      const deviceTypeValue = this.handleFetchValueFromId('typeList', outdoorValue.deviceType);
      oudoorList =
      {
        //  assetUnitName: this.outdoorEquipment && value.assetUnitName,
        assetUnitType: 'outdoor',
        assetUnitSerialNumber: outdoorValue.serialNumber,
        //  manufacturerId: 2,
        //  assetUnitModel: {
        //     assetModelInfoId: null,
        //     modelNo: null
        //  },
        assetUnitTypeId: this.isEdit ? outdoorValue && outdoorValue.unitTypeId : null,
        assetUnitUniqueId: this.isEdit ? indoorValue && indoorValue.assetUnitUniqueId : null,
        assetModelInfo: {
          locationId: indoorValue.location,
          assetModelInfoId: this.isEdit ? outdoorValue && outdoorValue.assetModelInfoId : null,
          modelNo: outdoorValue.modelNo,
          // equipmentId: this.indoorEquipment.value[index].equipment,
          outdoorEquipmentMakeId: outdoorValue.make,
          // variableSpeedBlowerId: this.indoorEquipment.value[index].variableSpeedBlower,
          // zonedSystemId: this.indoorEquipment.value[index].zonedSystem,
          // filterTypeId: this.indoorEquipment.value[index].filterType,
          // filterLengthId: this.indoorEquipment.value[index].filterLength,
          // filterWidthId: this.indoorEquipment.value[index].filterWidth,
          // filterThicknessId: this.indoorEquipment.value[index].filterThickness,
          // meteringDeviceId: this.indoorEquipment.value[index].meteringDevice,
          nomicalAcCapacityId: outdoorValue.nominalACCapacity,
          outdoorMultiStageId: outdoorValue.multiStage,
          // refigerantTypeId: outdoorValue.refigerantType,
          seerId: outdoorValue.seer,
          outdoorEquipmentTypeId: outdoorValue.deviceType,
          isEquipmentId: outdoorValue.equipmentIs,
          deviceType: deviceTypeValue && deviceTypeValue.value,
          isOutdoorInverterId: outdoorValue.inverterCompresser
        },
        //  assetUnitParameters: [
        //     // {
        //     //    unitParameterName:Tonnage,
        //     //    unitParameterValue:100
        //     // },
        //     // {
        //     //    unitParameterName:CustomLabel,
        //     //    unitParameterValue:CustomValue
        //     // }
        //  ]
      }

    }
    // });
    // }

    return oudoorList;
  }


  handleParseHVACEquipment(isCreateButtonClicked: boolean) {
    let equipmentList = [];
    if (this.hvacEquipments && this.hvacEquipments.value &&
      this.hvacEquipments.value.length) {
      this.hvacEquipments.value.map((value, index) => {
        if (value && value.length) {
          equipmentList.push(this.handleParseIndoorEquipment(isCreateButtonClicked, value[0], index));
        }
        if (value && value.length > 1) {
          equipmentList.push(this.handleParseOutdoorEquipment(isCreateButtonClicked, value[1], value[0], index));
        }
      });
    }
    return equipmentList;
  }

  handleHasCustomerId() {
    
    if (this.isEdit) {
      if(this.editCustomerData && this.editCustomerData.consumerId){
        return this.editCustomerData && this.editCustomerData.consumerId;
      }
      
      if(this.editCustomerData && this.editCustomerData.customerId){
        return this.editCustomerData && this.editCustomerData.customerId;
      }
    } else if (this.currentCustomerId) {
      return this.currentCustomerId;
    }
    return null;
  }

  handleHasAwsCustomerId() {
    if (this.isEdit) {
      return this.editCustomerData && this.editCustomerData.awsConsumerId;
    } else if (this.currentAwsCustomerId) {
      return this.currentAwsCustomerId;
    }
    return null;
  }
  handleAssetId(){
    // debugger
    if (this.isEdit) {
     
       return this.editCustomerData && this.editCustomerData.assetId?this.editCustomerData.assetId:this.assetId;    
      
     
    }
    return null;
  
  }
  parseCreateCustomerPayload(isCreateButtonClicked: boolean, isModalNeedToBeClosed?: boolean) {
    const hvacData = this.handleParseHVACEquipment(!isCreateButtonClicked);
    // const indoorList = this.handleParseIndoorEquipment(!isCreateButtonClicked);
    // const outdoorList = this.handleParseOutdoorEquipment(!isCreateButtonClicked);

    // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
    // const outdoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
    const payload: any = {
      addresses: this.handleParseAddresses(),
      consumerFirstName: this.customerType==CUSTOMER_TYPES.RESIDENTIAL?this.createConsumer.value.consumerFirstName && this.createConsumer.value.consumerFirstName.trim():null,
      consumerLastName: this.customerType==CUSTOMER_TYPES.RESIDENTIAL?this.createConsumer.value.consumerLastName && this.createConsumer.value.consumerLastName.trim():null,
      companyName:this.customerType==CUSTOMER_TYPES.COMPANY?this.createConsumer.value.companyName&& this.createConsumer.value.companyName.trim():null,
      consumerType:this.customerType?this.customerType:CUSTOMER_TYPES.RESIDENTIAL,
     // email: [
      //   {
      //     email: this.createConsumer.getRawValue().primaryEmail,
      //     type: COMMON_CONSTANTS.EMAIL_PRIMARY
      //   }
      // ],
      phone: this.createConsumer.value.phone,
      // serialNo: this.createConsumer.value.serialNumber,
      // assetName: this.createConsumer.value.assetName,
      // model: {
      //   assetModelInfoId: null,
      //   modelNo: null
      // }
      consumerId: this.handleHasCustomerId(),
      awsConsumerId: this.handleHasAwsCustomerId(),
      assetId: this.handleAssetId(),
      assetDetails: [
        // ...indoorList,
        // ...outdoorList
        ...hvacData
      ]

    };

    if (this.createConsumer.getRawValue().primaryEmail) {
      if (payload && payload.email) {
        payload.email.push(
          {
            email: this.createConsumer.getRawValue().primaryEmail,
            type: COMMON_CONSTANTS.EMAIL_PRIMARY
          }
        );
      } else {
        payload['email'] = [
          {
            email: this.createConsumer.getRawValue().primaryEmail,
            type: COMMON_CONSTANTS.EMAIL_PRIMARY
          }
        ];
      }
    }

    if (this.createConsumer.value.secondaryEmail) {
      if (payload && payload.email) {
        payload.email.push(
          {
            email: this.createConsumer.value.secondaryEmail,
            type: COMMON_CONSTANTS.EMAIL_SECONDARY
          }
        );
      } else {
        payload['email'] = [
          {
            email: this.createConsumer.value.secondaryEmail,
            type: COMMON_CONSTANTS.EMAIL_SECONDARY
          }
        ];
      }
    }

    if (this.isEdit) {

      if (this.editCustomerData.phone && this.editCustomerData.phone.length) {
        this.editCustomerData.phone.map((value, index) => {
          // payload.phone[index].id = this.editCustomerData.phone[index].id;
          // payload.phone[index].countryCode = this.editCustomerData.phone[index].countryCode;
          payload.phone[index].phoneId = this.editCustomerData.phone[index].phoneId;
        });
      }

      const primaryEmailId = this.helperService.handleEmailSelection(
        this.editCustomerData && this.editCustomerData.email, COMMON_CONSTANTS.EMAIL_PRIMARY);

      const secondaryEmailId = this.helperService.handleEmailSelection(
        this.editCustomerData && this.editCustomerData.email, COMMON_CONSTANTS.EMAIL_SECONDARY);

      if (payload && payload.email && payload.email.length) {
        payload.email.map((value, index) => {
          if (value.type === COMMON_CONSTANTS.EMAIL_PRIMARY && primaryEmailId && primaryEmailId.emailId) {
            payload.email[index].emailId = primaryEmailId.emailId;
          } else if (value.type === COMMON_CONSTANTS.EMAIL_SECONDARY && secondaryEmailId && secondaryEmailId.emailId) {
            payload.email[index].emailId = secondaryEmailId.emailId;
          }
        });
      }

    }

    // payload.assetDetails[0].assetUnitParameters.push(
    //   {
    //     unitParameterName: 'fuel',
    //     unitParameterValue: this.indoorEquipment.value.fuel
    //   },
    //   {
    //     unitParameterName: 'coilOrHeatKit',
    //     unitParameterValue: this.indoorEquipment.value.coilOrHeatKit
    //   },
    //   {
    //     unitParameterName: 'meteringDevice',
    //     unitParameterValue: this.indoorEquipment.value.meteringDevice
    //   },
    //   {
    //     unitParameterName: 'type',
    //     // unitParameterValue: this.indoorEquipment.getRawValue().deviceType
    //   },
    //   {
    //     unitParameterName: 'make',
    //     unitParameterValue: this.indoorEquipment.value.make
    //   }
    // );

    // if (this.indoorCustomFieldsList && this.indoorCustomFieldsList.length) {

    //   this.indoorCustomFieldsList.map((keyName, index) => {
    //     payload.assetDetails[0].assetUnitParameters.push({
    //       unitParameterName: keyName,
    //       unitParameterValue: this.indoorEquipment.value[keyName]
    //     });
    //   });
    // }

    // payload.assetDetails[1].assetUnitParameters.push(
    //   {
    //     unitParameterName: 'tonnage',
    //     unitParameterValue: this.outdoorEquipment.value.tonnage
    //   },
    //   {
    //     unitParameterName: 'type',
    //     // unitParameterValue: this.outdoorEquipment.getRawValue().deviceType
    //   },
    //   {
    //     unitParameterName: 'make',
    //     unitParameterValue: this.outdoorEquipment.value.make
    //   }
    // );

    // if (this.outdoorCustomFieldsList && this.outdoorCustomFieldsList.length) {

    //   this.outdoorCustomFieldsList.map((keyName, index) => {
    //     payload.assetDetails[1].assetUnitParameters.push({
    //       unitParameterName: keyName,
    //       unitParameterValue: this.outdoorEquipment.value[keyName]
    //     });
    //   });
    // }

    // this.handleParsePhonePayload(this.createConsumer.value.phone)

    // if (this.isIndoorEquipmentCustomModal || this.isOutdoorEquipmentCustomModal) {
    //   if (this.isIndoorEquipmentCustomModal) {

    //     const assetModelPayload = [
    //       {
    //         // brochureLink: this.createConsumer.value.brochureLink,
    //         deviceType: this.indoorEquipment && this.indoorEquipment.value.deviceType,
    //         modelNo: this.indoorEquipment && this.indoorEquipment.value.modelNo
    //       }
    //     ];
    //     // this.createAssetModel(assetModelPayload, payload);
    //   }

    //   if (this.isOutdoorEquipmentCustomModal) {

    //     const assetModelPayload = [
    //       {
    //         // brochureLink: this.createConsumer.value.brochureLink,
    //         deviceType: this.outdoorEquipment && this.outdoorEquipment.value.deviceType,
    //         modelNo: this.outdoorEquipment && this.outdoorEquipment.value.modelNo
    //       }
    //     ];
    //     // this.createAssetModel(assetModelPayload, payload);
    //   }
    // } else {
    // payload.assetDetails[0].assetUnitModel.assetModelInfoId =
    //   this.selectedIndoorAssetModel && this.selectedIndoorAssetModel.id ?
    //     this.selectedIndoorAssetModel.id : '';
    // payload.assetDetails[0].assetUnitModel.modelNo = this.selectedIndoorAssetModel &&
    //   this.selectedIndoorAssetModel.modelNo;

    // payload.assetDetails[1].assetUnitModel.assetModelInfoId =
    //   this.selectedOutdoorAssetModel && this.selectedOutdoorAssetModel.id ?
    //     this.selectedOutdoorAssetModel.id : '';
    // payload.assetDetails[1].assetUnitModel.modelNo = this.selectedOutdoorAssetModel &&
    //   this.selectedOutdoorAssetModel.modelNo;


    if (!isCreateButtonClicked) {
      this.editCustomerDataCopy = payload;
    }

    if (isCreateButtonClicked) {
      this.createCustomer(payload, isModalNeedToBeClosed);
    }
    // }

    this.createCustomerPayload = payload;
  }

  handleFormValidation(flag: string) {
    if (flag === 'create-customer') {
      // if (this.createConsumer.valid) {
      //   this.handleModalType('customer-profile');
      //   // this.createCustomer();
      // } else {
      //   this.createConsumer.markAllAsTouched();
      //   this.helperService.scrollToFirstInvalidControl(this.createConsumer, this.elementRef.nativeElement);
      // }
   
    if (this.createConsumer.valid) {
      // this.isEnableSubmit=false
      if (this.createConsumer && this.createConsumer.get('primaryEmail') &&
        this.createConsumer.get('primaryEmail').value &&
        this.createConsumer.get('primaryEmail').value.trim()) {
        if (this.isEdit && this.editCustomerData && this.editCustomerData.email &&
          this.editCustomerData.email.length && this.editCustomerData.email[0].email ===
          this.createConsumer.get('primaryEmail').value) {
            if(this.createConsumer.get('secondaryEmail').value && this.createConsumer.get('secondaryEmail').value==this.createConsumer.get('primaryEmail').value)
                {
                  this.handleModalType('customer-emails-same');
                }else{
                  this.handleModalType('customer-profile');
                }
         
        } else {
               if(this.createConsumer.get('secondaryEmail').value!="" && this.createConsumer.get('secondaryEmail').value==this.createConsumer.get('primaryEmail').value)
                   {
                      this.handleModalType('customer-emails-same');
                    }
              else
              {
                let queryParams = {
                  email: this.createConsumer.get('primaryEmail').value.trim(),
                  userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
                };
                
                this.sharedService.checkIsEmailAlreadyExists(queryParams).subscribe(
                  (response: IsEmailAlreadyExistsResponse) => {
                    this.isCreateCustomerBtnLoading = true;
                    if (response && response.isEmailAlreadyExistsResponseDetails &&
                      response.isEmailAlreadyExistsResponseDetails.isExists) {
                        this.isCreateCustomerBtnLoading = false;
                      this.handleModalType('customer-exist-confimation');
                    } else {
                      // this.handleModalType('contractor-profile');
                    
                      this.handleModalType('customer-profile');

                    }
                    // this.isCreateCustomerBtnLoading = false;
                  }, (error) => {
                    this.isCreateCustomerBtnLoading = false;
                    const message = this.helperService.handleAPIErrors(error);
                    this.helperService.showToaster(ToasterType.ERROR, message);
                  }
                );
              }
         }
      }
      else
      {
        this.handleModalType('customer-profile');
      }
    } else {
      this.createConsumer.markAllAsTouched();
      this.helperService.scrollToFirstInvalidControl(this.createConsumer, this.elementRef.nativeElement);
    }
  }
  }

  handleAutoSaveCustomer() {
    if (this.createConsumer.get('consumerFirstName').valid &&
      this.createConsumer.get('consumerLastName').valid &&
      this.createConsumer.get('primaryEmail').valid &&
      this.createConsumer.get('secondaryEmail').valid &&
      this.phone.valid && this.serviceAddress.valid &&
      this.billingAddress.valid
    ) {
      this.parseCreateCustomerPayload(true);
    }
  }

  handleCancelCreateCustomer() {
    if (this.createCustomerSubscription) {
      this.createCustomerSubscription.unsubscribe();
    }
  }

  handleResetCustomerId() {
    this.currentCustomerId = null;
  }
handleEmailValidity(){
  
}
  createCustomer(payload, isModalNeedToBeClosed?: boolean) {
    this.handleCancelCreateCustomer();
    this.isCreateCustomerBtnLoading = true;
    let queryParams = {
      email: this.createConsumer.get('primaryEmail').value.trim(),
      userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
    };
      this.createCustomerSubscription = this.contractorSharedService.createConsumer(payload).subscribe(
            (response: CreateCustomerResponse) => {
              // this.router.navigate(['/customers']);
              // this.helperService.setSelectedTab('customers');
              this.isCreateCustomerBtnLoading = false;
              if (this.currentCustomerId || this.isEdit || isModalNeedToBeClosed) {
                this.closeModal();
              }
              this.currentCustomerId = response && response.createCustomer &&
                response.createCustomer.customerId;
              this.currentAwsCustomerId = response && response.createCustomer &&
                response.createCustomer.awsConsumerId;
              this.helperService.showToaster(ToasterType.SUCCESS, response.statusInfo);
              this.emitHandleRefreshPage.emit();
            }, (error) => {
              // this.closeModal();
              this.isCreateCustomerBtnLoading = false;
              const message = this.helperService.handleAPIErrors(error);
              this.helperService.showToaster(ToasterType.ERROR, message);
            }
          );
       
  }

  createAssetModel(payload, createCustomerPayload) {
    // this.contractorSharedService.createAssetModel(payload).subscribe(
    //   (response: CreateAssetModelResponse) => {
    //     createCustomerPayload.model.assetModelInfoId = response.AssetModelList[0].id;
    //     createCustomerPayload.model.modelNo = response.AssetModelList[0].modelNo;
    //     this.createCustomerPayload = createCustomerPayload;
    //     this.createCustomer(createCustomerPayload);
    //   }, (error) => {
    //     const message = this.helperService.handleAPIErrors(error);
    //     this.helperService.showToaster(ToasterType.ERROR, message);
    //   }
    // );
  }

  getAssetModalList() {
    // this.contractorSharedService.getAssetModalList().subscribe(
    //   (response: AssetModelResponse) => {
    //     this.assetModelList = response.AssetModelList;
    //     // this.setDefaultValueForModel(response.AssetModelList);
    //     this.handleDeviceTypeList();
    //   }, (error) => {

    //   }
    // );
  }

  handleDeviceTypeList() {
    // if (this.assetModelList && this.assetModelList.length) {
    //   this.assetModelList.map((value, index) => {
    //     if (this.deviceTypeList.indexOf(value.deviceType) === -1) {
    //       this.deviceTypeList.push(value.deviceType);
    //     }
    //   });
    // }
  }

  // setDefaultValueForModel(assetModelList: AssetModel[]) {

  //   if (assetModelList && assetModelList.length) {
  //     // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //     if (this.indoorEquipment) {
  //       (this.indoorEquipment).controls.model.setValue(assetModelList[0].id);
  //       (this.indoorEquipment).controls.deviceType.setValue(assetModelList[0].deviceType);
  //       // (this.indoorEquipment).controls.make.setValue(assetModelList[0].deviceType);
  //     }
  //     this.selectedIndoorAssetModel = assetModelList[0];

  //     // const outdoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //     if (this.outdoorEquipment) {
  //       (this.outdoorEquipment).controls.model.setValue(assetModelList[0].id);
  //       (this.outdoorEquipment).controls.deviceType.setValue(assetModelList[0].deviceType);
  //       // (this.outdoorEquipment).controls.make.setValue(assetModelList[0].deviceType);
  //     }
  //     this.selectedOutdoorAssetModel = assetModelList[0];
  //   }
  // }

  // handleModelOnChange(flag: string) {

  //   if (flag === 'indoorEquipment') {
  //     const selectedAssetModelId = this.createConsumer.value.indoorEquipment.model;
  //     if (selectedAssetModelId !== 'Custom' && this.assetModelList && this.assetModelList.length) {
  //       this.assetModelList.map((value, index) => {
  //         if (value.id.toString() === selectedAssetModelId) {
  //           this.selectedIndoorAssetModel = value;
  //         }
  //       });
  //       this.isIndoorEquipmentCustomModal = false;
  //       this.handleFormValidators();
  //     } else if (selectedAssetModelId) {
  //       this.isIndoorEquipmentCustomModal = true;
  //       this.selectedIndoorAssetModel = null;
  //       this.handleFormValidators();
  //     }
  //   } else if (flag === 'outdoorEquipment') {
  //     const selectedAssetModelId = this.createConsumer.value.outdoorEquipment.model;
  //     if (selectedAssetModelId !== 'Custom' && this.assetModelList && this.assetModelList.length) {
  //       this.assetModelList.map((value, index) => {
  //         if (value.id.toString() === selectedAssetModelId) {
  //           this.selectedOutdoorAssetModel = value;
  //         }
  //       });
  //       this.isOutdoorEquipmentCustomModal = false;
  //       this.handleFormValidators();
  //     } else if (selectedAssetModelId) {
  //       this.isOutdoorEquipmentCustomModal = true;
  //       this.selectedOutdoorAssetModel = null;
  //       this.handleFormValidators();
  //     }

  //   }

  //   this.setTypeAndModelValue(flag);

  // }


  handleAddCustomFieldsButton(flag: string) {

    // if (flag === 'indoorEquipment') {
    //   this.toggleIndoorEquipmentCustomField();
    // } else if (flag === 'outdoorEquipment') {
    //   this.toggleOutdoorEquipmentCustomField();
    // }

  }

  // handleCreateCustomFields(flag: string) {

  //   if (flag === 'indoorEquipment') {
  //     const isExist = this.handleIsAlreadyFieldExist(flag, this.createIndoorEquipmentCustomFields.value);
  //     if (!isExist) {
  //       this.toggleIndoorEquipmentCustomField();

  //       // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //       if (this.indoorEquipment) {
  //         (this.indoorEquipment).addControl(this.createIndoorEquipmentCustomFields.value,
  //           new FormControl('', Validators.required));
  //       }

  //       const controlName = this.createIndoorEquipmentCustomFields.value;
  //       this.indoorCustomFieldsList.push(controlName);
  //       this.isIndoorFieldAlreadyExist = false;
  //       this.handleClearCreateIndoorEquipmentCustomFieldValue();
  //     }
  //   } else if (flag === 'outdoorEquipment') {
  //     const isExist = this.handleIsAlreadyFieldExist(flag, this.createOutdoorEquipmentCustomFields.value);
  //     if (!isExist) {
  //       this.toggleOutdoorEquipmentCustomField();

  //       // const outdoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //       if (this.outdoorEquipment) {
  //         (this.outdoorEquipment ).addControl(this.createOutdoorEquipmentCustomFields.value,
  //           new FormControl('', Validators.required));
  //       }

  //       const controlName = this.createOutdoorEquipmentCustomFields.value;
  //       this.outdoorCustomFieldsList.push(controlName);
  //       this.isOutdoorFieldAlreadyExist = false;
  //       this.handleClearCreateOutdoorEquipmentCustomFieldValue();
  //     }
  //   }
  // }


  // handleDeleteCustomFields(flag: string, index: number) {
  //   if (flag === 'indoorEquipment' && this.indoorCustomFieldsList && this.indoorCustomFieldsList.length) {
  //     const name = this.indoorCustomFieldsList[index];
  //     this.indoorCustomFieldsList.splice(index, 1);
  //     if (name) {
  //       // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
  //       if (this.indoorEquipment) {
  //         (this.indoorEquipment ).removeControl(name);
  //       }
  //     }
  //   } else if (flag === 'outdoorEquipment' && this.outdoorCustomFieldsList && this.outdoorCustomFieldsList.length) {
  //     const name = this.outdoorCustomFieldsList[index];
  //     this.outdoorCustomFieldsList.splice(index, 1);
  //     if (name) {
  //       // const outdoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
  //       if (this.outdoorEquipment) {
  //         (this.outdoorEquipment ).removeControl(name);
  //       }
  //     }
  //   }
  // }

  handleIsAlreadyFieldExist(flag: string, currentFieldName) {
    // if (flag === 'indoorEquipment') {
    //   // const indoorAbstractControl: AbstractControl = this.createConsumer.get('indoorEquipment');
    //   if (this.indoorEquipment) {
    //     let keys = Object.keys((this.indoorEquipment).value);
    //     keys = keys.map((value) => value.toLowerCase());
    //     if (keys.includes(currentFieldName.toLowerCase())) {
    //       this.isIndoorFieldAlreadyExist = true;
    //       return true;
    //     }
    //   }
    // } else if (flag === 'outdoorEquipment') {
    //   // const outdoorAbstractControl: AbstractControl = this.createConsumer.get('outdoorEquipment');
    //   if (this.outdoorEquipment) {
    //     let keys = Object.keys((this.outdoorEquipment).value);
    //     keys = keys.map((value) => value.toLowerCase());
    //     if (keys.includes(currentFieldName.toLowerCase())) {
    //       this.isOutdoorFieldAlreadyExist = true;
    //       return true;
    //     }
    //   }
    // }
    // return false;
  }

  handleOnchangeCustomField(flag: string) {
    // if (flag === 'indoorEquipment') {
    //   this.isIndoorFieldAlreadyExist = false;
    // } else if (flag === 'outdoorEquipment') {
    //   this.isOutdoorFieldAlreadyExist = false;
    // }
  }

  toggleIndoorEquipmentCustomField() {
    this.isShowIndoorEquipmentCustomField = !this.isShowIndoorEquipmentCustomField;
  }

  handleClearCreateIndoorEquipmentCustomFieldValue() {
    this.createIndoorEquipmentCustomFields.patchValue('');
  }

  toggleOutdoorEquipmentCustomField() {
    this.isShowOutdoorEquipmentCustomField = !this.isShowOutdoorEquipmentCustomField;
  }

  handleClearCreateOutdoorEquipmentCustomFieldValue() {
    this.createOutdoorEquipmentCustomFields.patchValue('');
  }

  handlePatchPhoneCodeDropdown() {
    this.createConsumer.patchValue({
      phone: [{
        countryCode: this.helperService.handleSelectDefaultValueForPhoneCodeDropdown(this.selectedCountryForPhoneCode, 'name')
      }]
    });
  }


  handleParsePhonePayload(phoneFormData) {
    // const payload = [];
    // if (phoneFormData && phoneFormData.length) {
    //   phoneFormData.map((value, index) => {
    //     payload.push({
    //       phone: this.helperService.handleSelectDefaultValueForPhoneCodeDropdown(value.countryCode, 'code') + '-' + value.phone,
    //       type: value.type
    //     });
    //   });
    // }
    // return payload;
  }

  handlePatchEmailValues(flag: string) {
    // let email;
    // this.editConsumerData.email.find((value, index) => {
    //   if (value.type === flag) {
    //     email = value.email;
    //     return email;
    //   }
    // });
    // return email;
  }

  handleResetFormFields() {
    this.billingAddress.get('addressLine1').clearValidators();
    // this.billingAddress.get('addressLine2').clearValidators();
    this.billingAddress.get('city').clearValidators();
    this.billingAddress.get('state').clearValidators();
    this.billingAddress.get('zipcode').clearValidators();
    this.createConsumer.reset();
    this.hvacEquipments.clear();
    // this.outdoorEquipment.clear();
    // if (this.indoorEquipment && this.indoorEquipment.length) {
    //   this.indoorEquipment.controls.map((value: FormGroup, index) => {
    //     this.handleOnChangeIndoorEquipment(value);
    //   });
    // }
    // this.indoorEquipment.clear();
    // // this.createConsumer.patchValue({
    // //   consumerName: '',
    // //   assetName: ''
    // // });
    // this.createConsumer.reset();
    // if (this.isEdit) {
    //   this.createConsumer.patchValue({
    //     primaryEmail: this.handlePatchEmailValues(COMMON_CONSTANTS.EMAIL_PRIMARY)
    //   });
    // }
    // this.handleRemoveFormFields('phone', null, true);
    // // this.address.patchValue({
    // //   street: '',
    // //   city: '',
    // //   state: '',
    // //   zipcode: ''
    // // });
    // // this.indoorEquipment.patchValue({
    // //   serialNumber: '',
    // //   assetUnitName: '',
    // //   fuel: '',
    // //   coilOrHeatKit: '',
    // //   meteringDevice: ''
    // // });
    // // this.outdoorEquipment.patchValue({
    // //   serialNumber: '',
    // //   assetUnitName: '',
    // //   tonnage: ''
    // // });
    // this.setDefaultValueForModel(this.assetModelList);
    // this.isIndoorEquipmentCustomModal = false;
    // this.isOutdoorEquipmentCustomModal = false;
    // if (this.indoorCustomFieldsList && this.indoorCustomFieldsList.length) {
    //   this.indoorCustomFieldsList.map((value, index) => {
    //     this.indoorEquipment.removeControl(value);
    //   });
    // }
    // this.indoorCustomFieldsList = [];
    // if (this.outdoorCustomFieldsList && this.outdoorCustomFieldsList.length) {
    //   this.outdoorCustomFieldsList.map((value, index) => {
    //     this.outdoorEquipment.removeControl(value);
    //   });
    // }
    // this.outdoorCustomFieldsList = [];
    // // this.handleFormValidators('initial-render');
    // // this.createIndoorEquipmentCustomFields = new FormControl('', Validators.required);
    // // this.createOutdoorEquipmentCustomFields = new FormControl('', Validators.required);
  }

  handleCreateCustomerForm() {
    let flag = false;
    if (this.isEdit) {
      let currentFormValues = this.createConsumer.getRawValue();
      console.log( this.prevFormValues)
      if (JSON.stringify(currentFormValues) !== JSON.stringify(this.prevFormValues)) {
        flag = true;
      }
    } else {
      if (this.createConsumer.get('consumerFirstName').value ||
        this.createConsumer.get('consumerLastName').value ||
        this.createConsumer.get('primaryEmail').value ||
        this.createConsumer.get('secondaryEmail').value) {
        flag = true;
      }

      if (this.billingAddress.get('addressLine1').value ||
        this.billingAddress.get('addressLine2').value ||
        this.billingAddress.get('city').value ||
        this.billingAddress.get('state').value ||
        this.billingAddress.get('zipcode').value) {
        flag = true;
      }

      if (this.serviceAddress.get('addressLine1').value ||
        this.serviceAddress.get('addressLine2').value ||
        this.serviceAddress.get('city').value ||
        this.serviceAddress.get('state').value ||
        this.serviceAddress.get('zipcode').value) {
        flag = true;
      }
    }

    if (this.createConsumer && !this.createConsumer.dirty) {
      flag = false;
    }

    return flag;
  }

  handleModalType(type: string, index?: number) {
    this.infoModalType = type;
    if (type === 'customer-profile') {
      this.infoModalTitle = 'Customer Profile';
      this.parseCreateCustomerPayload(true, true);
    } else if (type === 'close-modal') {
      if (this.handleCreateCustomerForm()) {
        this.infoModalType = 'close-create-customer-modal';
        this.confirmationModalTitle = 'Warning!';
        this.confirmationModalLine1Content = `Entered information will be lost.`;
        this.confirmationModalLine2Content = `Do you want to continue?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.toggleConfirmationModal();
      } else {
        this.closeModal();
      }
    } else if (type === 'delete-hvac') {
      this.infoModalType = 'delet-hvac';
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = `This will permanently delete the 
      equipment currently assigned to a customer.`;
      this.confirmationModalLine2Content = `Do you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = true;
      this.hvacDeleteIndex = index;
      this.toggleConfirmationModal();
    } else if (type === 'delete-customer') {
      this.emitHandleDeleteCustomer.emit();
    } else if (type === 'customer-exist-confimation') {
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = 'Email address already exists. Please enter a different email or contact Support at +1 (833) 543-0107.';
      // this.confirmationModalLine2Content = `Are you sure you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = false;
      this.toggleConfirmationModal();
    }else if (type=== 'customer-emails-same'){
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = 'Secondary email address cannot be the same as primary. Please enter a different email or contact Support at +1 (833) 543-0107.';
      // this.confirmationModalLine2Content = `Are you sure you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = false;
      this.toggleConfirmationModal();
    }
    else if(type==="resend-invitation")
    {
      this.reInviteCustomer()
    }
  }


reInviteCustomer() {
  
  let message = 'Please add the primary email and save to resend the email invitation'
  if(this.editCustomerData.email.length<=0 || this.editCustomerData.email==0)
  {
    this.helperService.showToaster(ToasterType.ERROR, message);
  }
  else{
    let email = this.editCustomerData.email.length>0&&this.editCustomerData.email.find(data=>data.type=="primary");
  console.log(email)
    if(email==undefined)
  {
    this.helperService.showToaster(ToasterType.ERROR, message);
  }
  else{
    const queryParams = {
      consumerId: this.editCustomerData.customerId?this.editCustomerData.customerId:this.editCustomerData.consumerId
    };
    this.isResendBtnLoading=true;
    this.contractorSharedService.activateCustomer(queryParams).subscribe(
      (response: CommonSuccessResponse) => {
        this.helperService.showToaster(ToasterType.SUCCESS, response && response.statusInfo);
        this.closeModal()
             this.emitHandleRefreshPage.emit();
             this.isResendBtnLoading=false;
      }, (error) => {
         this.closeModal()
             this.emitHandleRefreshPage.emit();
        this.isResendBtnLoading=false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    )
  }}
  }
  handleButtonsOnChanges() {
    if (this.handleCreateCustomerForm()) {
      this.isToggleButtons = true;
    } else {
      this.isToggleButtons = false;
    }
  }

  handleButtonsOnLengthChanges() {
    this.isToggleButtons = false;
    if (this.prevFormValues && (this.phone.length !== this.prevFormValues.phone.length || this.handleCreateCustomerForm())) {
      this.isToggleButtons = true;
    }
  }

  handleInfoModalEmitter() {
    if (this.infoModalType === 'close-create-customer-modal') {
      this.closeModal();
    } else if (this.infoModalType === 'delet-hvac') {
      this.handleDeleteHVACInformation(this.hvacDeleteIndex);
    }
  }

  toggleCustomerInformationModal() {
    this.isShowCustomerInfoModal = !this.isShowCustomerInfoModal;
  }

  // isEditModal(isEdit){
  //   this.isSHowEdit = isEdit;
  // }

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
  }

  closeModal() {
    this.handleResetCustomerId();
    this.emitCloseModal.emit();
  }

  trackById(index: number, item: any): string {
    return item ? item.id : '';
  }

}
