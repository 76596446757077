import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { SharedModule } from 'src/app/shared/shared.module';

// Components
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CookieService } from 'ngx-cookie-service';

const Components = [
  HeaderComponent,
  FooterComponent
];

@NgModule({
  declarations: [ ...Components ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [ ...Components ],
  providers: [
    CookieService
  ],
})
export class CoreModule { }
