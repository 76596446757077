import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Services
import { SharedService } from 'src/app/shared/services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ContractorSharedService } from 'projects/contractor/src/app/shared-contractor/services/shared.service';

// Models
import { Technician, TechnicianInfo } from 'src/app/shared/models/technician.model';
import { Alert } from 'src/app/shared/models/alerts.model';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { TechnicianService } from 'src/app/modules/technician/services/technician.service';

@Component({
  selector: 'app-assign-technician-modal',
  templateUrl: './assign-technician-modal.component.html',
  styleUrls: ['./assign-technician-modal.component.scss']
})
export class AssignTechnicianModalComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Input() alertItem: Alert;
  @Output() emitHandleRefreshPage = new EventEmitter<any>();

  technicianList: Technician[];
  selectedTechnician: Technician;
  isTechnicianListLoading = false;
  isActionBtnLoading = false;
  boardLocation: any;

  constructor(
    private sharedService: SharedService,
    private helperService: HelperService,
    private contractorSharedService: ContractorSharedService,
    private technicianService: TechnicianService,
  ) { }

  ngOnInit() {
    this.getTechnicianList();
    this.getLocation();
    console.log(this.alertItem)
  }

  getTechnicianList() {
    const queryParams = {
      serialNumber: this.alertItem.meliSerialNo
    };
    this.isTechnicianListLoading = true;
    this.technicianService.getTechnicianList(queryParams).subscribe(
      (response: TechnicianInfo) => {
        this.technicianList = response.responseData.technicianList;
        this.isTechnicianListLoading = false;
        if (this.technicianList.length > 0) {
          //this.selectedTechnician = this.technicianList[0];
          this.assignSelectedTechnician();
        } else {
          const message = 'No Technicians Found!';
          this.helperService.showToaster(ToasterType.ERROR, message);
        }
      }, (error) => {
        this.isTechnicianListLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  getLocation() {
    const queryParams = {
      meliSerialNumber: this.alertItem.meliSerialNo
    };

    this.sharedService.getLocation(queryParams).subscribe(
      (response) => {
        this.boardLocation = response.responseData[0].locations.title;
      }, (error) => {
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleAssignTechnicianForAlerts() {
    console.log(this.selectedTechnician)
    const payload = [{
      description: '',
      technicianId: this.selectedTechnician.technicianId,
      meliSerialNumber: this.alertItem.meliSerialNo,
      alertId: this.alertItem.alertId,
      awsTechnicianId: this.selectedTechnician.awsTechnicianId,
      jobType: 'Defective',
      jobId: this.alertItem.jobTechnicianInfo && this.alertItem.jobTechnicianInfo.jobId,
      alertName: this.alertItem.alertName,
      alertTime: this.alertItem.timeStamp
    }];
    this.isActionBtnLoading = true;
    let index = 0;
    if (payload[0]['jobId'] != null) {
      index = payload[0]['jobId']
    }
    this.sharedService.assignTechnician(payload).subscribe(
      (response) => {
        this.isActionBtnLoading = false;
        this.closeModal();
        this.emitHandleRefreshPage.emit('current-alerts');
        this.helperService.showToaster(ToasterType.SUCCESS, response["responseData"][index]);
      }, (error) => {
        this.isActionBtnLoading = false;
        this.closeModal();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  handleOnChangeTechnician(event) {
    console.log(event)
    const selctedIndex = event.target.selectedIndex;
    this.selectedTechnician = this.technicianList[selctedIndex];
  }

  assignSelectedTechnician() {
    if (this.alertItem && this.alertItem.jobTechnicianInfo) {
      this.technicianList.forEach(tech => {
        if (this.alertItem.jobTechnicianInfo.technicianId === tech.technicianId) {
          return this.selectedTechnician = tech;
        }
        else {
          return null
        }

      });
    }
    return this.selectedTechnician = this.technicianList[0];
  }
  handleAlreadyAssginedTechnician(currentTechnician: Technician) {
    if (this.alertItem && this.alertItem.jobTechnicianInfo) {
      if (this.alertItem.jobTechnicianInfo.technicianId === currentTechnician.technicianId) {
        return currentTechnician;
      }
    }
    return null;
  }

  trackByTechnicianId(index: number, item: any): string {
    return item ? item.technicianId : '';
  }

}
