export class AssetModel {
    id: number;
    modelNo: string;
    deviceType: string;
    brochureLink: string;

    constructor(data) {
        this.id = data.id;
        this.modelNo = data.modelNo;
        this.deviceType = data.deviceType;
        this.brochureLink = data.brochureLink;
    }
}

export class AssetModelResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    AssetModelList: AssetModel[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.AssetModelList = data.responseData && data.responseData.length &&
            data.responseData.map((value) => new AssetModel(value));
    }
}

export class CreateAssetModelResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    AssetModelList: AssetModel[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.AssetModelList = data.responseData && data.responseData.length &&
            data.responseData.map((value) => new AssetModel(value));
        this.requestData = data.requestData;
    }
}
