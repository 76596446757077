import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-read-more-modal',
  templateUrl: './read-more-modal.component.html',
  styleUrls: ['./read-more-modal.component.scss']
})
export class ReadMoreModalComponent implements OnInit {


  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Input() line1Content: any;
  @Input() readMoreData:any;
  time:string;
  constructor() { }

  ngOnInit() {
    console.log(this.readMoreData);
    this.time=this.readMoreData.readMore.updatedTime.toString();
    this.time=this.time.substring(2,10);
    console.log(this.time);
  }
ngOnChange(){
  console.log(this.readMoreData);
}

  closeModal() {
    // if (this.isEmergencyShutoff) {
    //   this.emitHandleButtonAction.emit();
    // } else {
      this.emitCloseModal.emit();
    // }
  }

 

}
