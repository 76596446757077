export class cardDetails {
    card_number: string
    exp_month: number
    exp_year: number
    name: string
    postal_code: string
    security_code: string
    constructor(data) {
        this.card_number = data.card_number;
        this.exp_month = data.exp_month;
        this.exp_year = data.exp_year
        this.name = data.name
        this.postal_code = data.postal_code
        this.security_code = data.security_code

    }
}

export class bankDetails {
    account_holder_name: string
    account_holder_type: string
    account_number: string
    routing_number: string
    constructor(data) {
        this.account_holder_name = data.account_holder_name;
        this.account_holder_type = data.account_holder_type;
        this.account_number = data.account_number
        this.routing_number = data.routing_number
    }
}



export class accountDetails {
    cardDetails: cardDetails
    bankDetails: bankDetails
    constructor(data) {
        this.cardDetails = data.cardDetails && new cardDetails(data.cardDetails);
        this.bankDetails = data.bankDetails && new bankDetails(data.bankDetails);;
    }
}


export class BillingCardResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: accountDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData =
            data.responseData && new accountDetails(data.responseData);
        this.requestData = data.requestData;
    }
}



export class successResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: any;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}