import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import AnnotationModule from 'highcharts/modules/annotations';
import Breaks from 'highcharts/modules/broken-axis';
import highchartsTreemap from 'highcharts/modules/treemap';
import HC_exporting from 'highcharts/modules/exporting';
import { COMMON_CONSTANTS, CHART_TYPES, CHART_UNIT_TYPES } from 'src/app/shared/constants/commom.constant';
HC_exporting(Highcharts);
highchartsTreemap(Highcharts);
AnnotationModule(Highcharts);
Breaks(Highcharts);
@Component({
  selector: 'app-alert-discovery-chart',
  templateUrl: './alert-discovery-chart.component.html',
  styleUrls: ['./alert-discovery-chart.component.scss'],
})
export class AlertDiscoveryChartComponent implements OnInit {
  @Input() chartData: any;
  @Input() chartCommonData: any;
  @Input() unit: any;
  @Input() unitName: any;
  @Input() heightStyle: any;
  @Output() emitHandleConvertion = new EventEmitter<any>();
  @Input() fromReport: boolean = false;
  @ViewChild('chartContainer', { static: true }) chartContainer: ElementRef;
  a: any;
  options: any;
  message: any;
  display: boolean=false;

  constructor() {
     window.innerWidth<=480?this.display=false:this.display=true;
  }
  ngOnInit() {
    console.log(this.unitName, this.chartData, this.chartCommonData, this.unit);
    this.drawSplineChart(this.chartData, this.chartCommonData);
  }

  handleExportingButtons(obj, obj1) {
    const self = this;
    return {
      // buttonSpacing: 100,
      enabled: true,
      buttons: [{
        text: obj1 && obj1.convertparam ? obj1.convertparam === 'TEMPERATURE' ? 'Fahrenheit' : '“ WC' : '',
        // buttonSpacing: 100,
        // selected: true,
        // disableHover: true,
        onclick: function () {
          // alert('clicked');
          if (obj1.convertparam === 'TEMPERATURE') {
            self.emitHandleConvertion.emit(COMMON_CONSTANTS.FAHRENHEIT);
          } else {
            self.emitHandleConvertion.emit(COMMON_CONSTANTS.WC);
          }
        },
        x: -70,
        // style: {
        //   fontSize: '100px',
        //   width: '18px'
        // },
        theme: {
          /* 'stroke-width': 1, */
          /* stroke: 'silver', */
          fill: obj1 && ((obj1.convertparam === 'TEMPERATURE' && (self.unitName === COMMON_CONSTANTS.FAHRENHEIT || self.unitName == "null")) ||
            (obj1.convertparam === 'PRESSURE' && (self.unitName === COMMON_CONSTANTS.WC || self.unitName === "null"))) ? '#00B7A5' : 'none',
          // fill: 'none',
          r: 18,
          padding: 10,
          // width: 60,
          // textAlign: 'center',
          /* fontSize: '200px', */
          style: {
            // fontSize: '10px',
            color: obj1 && ((obj1.convertparam === 'TEMPERATURE' && (self.unitName === COMMON_CONSTANTS.FAHRENHEIT || self.unitName == "null")) ||
              (obj1.convertparam === 'PRESSURE' && (self.unitName === COMMON_CONSTANTS.WC || self.unitName == "null"))) ? '#242A34' : '#FFFFFF',
            fontSize: '1.3rem',
            // fontFamily: 'cursive',
            fontFamily: 'SourceSansPro-Regular',
            fontWeight: 700,
            // textAlign: 'center',

            // states: {
            //   hover: {
            //     // fill: '#a4edba'
            //     fill: '#71CECA',
            //     color: '#FFFFFF',
            //   },
            // }
          },
          /* height: 40, */
          /* width: 48,
          symbolSize: 24, */
          /* textAlign: 'center', */
          states: {
            hover: {
              // fill: '#a4edba'
              fill: '#00B7A5',
              color: '#FFFFFF',
            },
            // select: {
            //   // stroke: '#039',
            //   // fill: '#a4edba'
            //   fill: '#71CECA',
            // }
          }
        }
      },
      {
        text: obj1 && obj1.convertparam ? obj1.convertparam === 'TEMPERATURE' ? 'Celsius' : 'Pascal' : '',
        onclick: function () {
          // alert('clicked');
          if (obj1.convertparam === "TEMPERATURE") {
            self.emitHandleConvertion.emit(COMMON_CONSTANTS.CELCIUS);
          } else {
            self.emitHandleConvertion.emit(COMMON_CONSTANTS.PASCAL);
          }
        },
        x: -10,
        theme: {
          /* 'stroke-width': 1, */
          /* stroke: 'silver', */
          /* fill: '#71CECA', */
          padding: 10,
          // x: -10,
          r: 18,
          // margin: '100px',
          // color: false ? '#242A34' : '#FFFFFF',
          // fill: 'none',
          fill: obj1 && ((obj1.convertparam === 'TEMPERATURE' && self.unitName === COMMON_CONSTANTS.CELCIUS) ||
            (obj1.convertparam === 'PRESSURE' && self.unitName === COMMON_CONSTANTS.PASCAL)) ? '#00B7A5' : 'none',
          style: {
            // color: '#FFFFFF',
            color: obj1 && ((obj1.convertparam === 'TEMPERATURE' && self.unitName === COMMON_CONSTANTS.CELCIUS) ||
              (obj1.convertparam === 'PRESSURE' && self.unitName === COMMON_CONSTANTS.PASCAL)) ? '#242A34' : '#FFFFFF',
            // fontSize: '10px',
            fontSize: '1.3rem',
            // margin: '100px',
            // fontFamily: 'cursive',
            fontFamily: 'SourceSansPro-Regular',
            fontWeight: 700,
            textAlign: 'center'
          },
          states: {
            hover: {
              // fill: '#a4edba'
              // fill: 'none'
              fill: '#00B7A5',
              color: '#FFFFFF',
            },
            // select: {
            //   stroke: '#039',
            //   fill: '#a4edba'
            // }
          }
        }
      }
      ]
    };
  }

  drawSplineChart(obj, obj1) {
    // let minValue = obj.data[0].data[0][1];
    // let maxValue = obj.data[0].data[0][1];
    // obj.data[0].data.map((item) => {
    //   minValue = (item[1] < minValue) ? item[1] : minValue;
    //   maxValue = (item[1] > maxValue) ? item[1] : maxValue;
    // });

    // let maxValue = null;
    // let tickInterval = 10;
    // if (
    //   (this.chartData &&
    //     this.chartData.title &&
    //     this.chartData.title === 'Cooling') ||
    //   this.chartData.title === 'Fan/Heat Pump' ||
    //   this.chartData.title === 'Heating Furnace'
    // ) {
    //   maxValue = 1;
    //   tickInterval = 1;
    // }

    const self = this;
    Highcharts.setOptions({
      lang: {
        thousandsSep: '',
      },
      time: {
        useUTC: false,
      },

    });
    this.options = {
      chart: {
        renderTo: 'chart',
        type: 'spline',
        backgroundColor: '#2A3343',
        height: this.heightStyle

      },

      legend: {
        itemStyle: {
          color: 'rgba(255,255,255,0.85)',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '1.3rem',
          fontWeight: 100
        },

        labelFormatter() {
          return '<span style="color: ' + this.color + '">' + this.name + '</span>';

        }
      },

      credits: {
        enabled: false,
      },

      title: {
        text: obj1.title,
        style: {
          color: 'rgba(255,255,255,0.85)',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '2rem',
        },
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        // type: 'datetime',
        // breaks: [
        //   {
        //     from: Highcharts.dateFormat('%H:%M %p', obj.data[3][0]),
        //     to: Highcharts.dateFormat('%H:%M %p', obj.data[5][0]),
        //     breakSize: 1,
        //   },
        // ],

        plotBands: obj.bonds,
        // plotBands: [
        //   {
        //     color: '#FCFFC5',
        //     from: 3,
        //     to: 5,
        //     id: 'plotband-1',
        //   },
        // ],
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: 'rgba(255,255,255,0.85)',
        // tickWidth: 1.5,
        tickWidth: 1,
        // tickColor: 'black',
        tickColor: 'rgba(255,255,255,0.85)',
        tickLength: 7,
        // categories: obj.xdata,
        labels: {
          // step: 15,
          // step: 30,

          style: {
            color: 'rgba(255,255,255,0.85)',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.1rem',
          },
          formatter() {
            let output = '';
            if (this.isFirst) {
              output = Highcharts.dateFormat(
                '%l:%M %p  <br> %m-%d-%Y',
                this.value
              );
              obj.emptydata = Highcharts.dateFormat(
                '%d-%m-%Y',
                this.value
              ).toString();
            } else {
              if (Highcharts.dateFormat('%d %m %Y', this.value).toString() === obj.emptydata) {
                output = Highcharts.dateFormat('%l:%M %p ', this.value);
              } else {
                obj.emptydata = Highcharts.dateFormat('%d-%m-%Y', this.value).toString();
                output = Highcharts.dateFormat(
                  '%l:%M %p  <br> %m-%d-%Y',
                  this.value
                );
              }

            }

            return output;
          },
        },

        // dateTimeLabelFormats: { // don't display the dummy year
        //   hour: '%I %p',
        //   minute: '%I:%M %p',
        //   second: '%I:%M:%S %p',
        // }
        // dateTimeLabelFormats: {
        //   second: '%Y-%m-%d<br/>%I:%M:%S %p',
        //   minute: '%Y-%m-%d<br/>%I:%M %p',
        //   hour: '%Y-%m-%d<br/>%I:%M %p',
        //   day: '%Y<br/>%m-%d',
        //   week: '%Y<br/>%m-%d',
        //   month: '%Y-%m',
        //   year: '%Y',
        // },
        title: {
          // text: 'Time Duration',

          style: {
            color: 'rgba(255,255,255,0.85)',
            // fontWeight: 'bold',
            fontFamily: 'SourceSansPro-Regular',
            // fontSize: '1.2rem'
          },
        },
      },
      yAxis: {
        visible: obj1.yAxisTitle != null ? true : false,
        allowDecimals: false,
        max: this.unit === '' ? 1 : null,
        title: {
          text: obj1.yAxisTitle,
          useHTML: true,

          style: {
            color: 'rgba(255,255,255,0.85)',
            fontWeight: 100,
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.3rem',
            textAlign: 'center'
          },

        },
        // min: 0,
        // max: maxValue,
        // tickInterval: tickInterval,
        gridLineWidth: 0, // Hide Horizontal line
        // tickColor: 'black',
        tickColor: 'rgba(255,255,255,0.85)',
        tickLength: 7,
        // tickWidth: 1.5,
        tickWidth: 1,
        // lineWidth:1.5,
        lineWidth: 1,
        // lineColor:'black',
        lineColor: 'rgba(255,255,255,0.85)',
        tickPosition: 'outside',

        labels: {
          style: {
            color: 'rgba(255,255,255,0.85)',
            fontFamily: 'SourceSansPro-Regular',
            fontSize: '1.2rem',
          },
        },
        plotLines: obj.threshold,
      },

      // tooltip: {
      //   headerFormat: '<b>{series.name}</b><br>',
      //   pointFormat: '{point.x:.2f} {point.y:.2f}'
      // },

      tooltip: {
        //split: false,
        //shared: true,
        // crosshairs: {
        //   enabled: false
        // },
        //useHTML: true,
        formatter: function () {
          const dateInUTC = Date.UTC(
            new Date(this.x).getFullYear(),
            new Date(this.x).getMonth(),
            new Date(this.x).getDate(),
            new Date(this.x).getHours(),
            new Date(this.x).getMinutes(),
           
          );
          const date: any = new Date(dateInUTC);
          let ambient;
          let ambientUnit;
          if (this.point.z != null) {
            if (self.unit == null || self.unit == "Pa" || self.unit == '"WC' || self.unit == '°C' || self.unit == "") {
              ambientUnit = '°C'
            }
            else {
              ambientUnit = "°F"
            }
            ambient = this.point.z + '' + ambientUnit
          }
          else {
            ambient = this.point.z
          }
          if (obj1.convertparam === 'PRESSURE') {
            return (
              'Time: <b>' + Highcharts.dateFormat('%l:%M %p', this.x) +
              '<b> <br> Value: <b>' + this.y.toFixed(0) + '<b>' +
              ' ' + self.unit + '<br>Ambient Temp: <b>' + ambient + '</b>' +
              '<br>' +
              'Supply Pressure:' +
              '<b>' +
              this.point.supply +
              '<b>' +
              ' ' +
              self.unit +
              '<br>' +
              'Return Pressure:' +
              '<b>' +
              this.point.return +
              '<b>' +
              ' ' +
              self.unit
            );

          }
          return (
            'Time: <b>' + Highcharts.dateFormat('%l:%M %p', this.x) +
            '<b> <br> Value: <b>' + this.y.toFixed(0) + '<b>' +
            ' ' + self.unit + '<br>Ambient Temp: <b>' + ambient + '</b>'
          );

        },
      },

      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },

      // colors: [
      //   '#71CECA',
      //   '#F89308',
      //   '#F24766',
      //   '#529AD5',
      //   '#06C',
      //   '#036',
      //   '#000',
      // ],

      // series: [
      //   {
      //     name: obj.yAxisTitle,
      //     data: obj.data[0].data,
      //   },
      // ]

      series: [
        {
          // data: [
          //   [1588606630000, 212],
          //   [1588606570000, 213.8],
          // ],
          type: obj1.charttype,
          data: obj.data,
          name: obj1.xAxisTitle,
          color: obj1.color,
        },

        // {
        //   type: 'column',
        //   data: obj.data[0].data,
        //   color: obj.linecolor,
        // },
      ],
      // exporting: {
      //   sourceWidth: 1200,
      //   sourceHeight: 400,
      // },

      exporting: this.fromReport||!this.display ? "" : this.handleExportingButtons(obj, obj1),
      annotations: [
        {
          labels: [
            {
              point: {
                xAxis: 0,
                yAxis: 0,
                x: obj.alerttime,
                y: obj.alertvalue ? obj.alertvalue : '',
              },
              text: Highcharts.dateFormat('%d %b %Y %l:%M %p ', obj.alerttime),
              height: 250,
              width: 10,
              backgroundColor: '#2A3343',
              color: '#ffffff',
              borderColor: '#898989',
            },
          ],
        },
      ],
      

      // series: [
      //  obj.data.map((value) => {
      //    debugger
      //    return value;
      //  })
      // ]

      // series: obj.supplyAirTemperature
    };
    if (obj.data.length === 0) {
      this.message = (chart) => {
        const rec = chart.renderer
          .rect(300, 125, 650, 40, 1)
          .attr({
            'stroke-width': 1,
            stroke: '#898989',

            zIndex: 3,
          })
          .add();
        const text = chart.renderer
          .text(
            'No Communication from Static Pressure Differential Sensor for last 48 hours ',
            600,
            150
          )
          .css({
            color: '#ffffffd9',
            fontSize: '12px',
            lineHeight: 20 * 1.3,
            border: '1px solid black',
            padding: '5px',
          })
          .attr({
            zIndex: 15,
            'text-anchor': 'middle',
          })
          .add();
      };
    } else {
      this.message = '';

    }
    // Highcharts.chart(obj.element, this.options);
    Highcharts.chart(
      this.chartContainer.nativeElement,
      this.options,
      this.message
    );
  }
}

// // var supplyAirTemp;

// drawSupplyAirTemprature(data,fdate,tdate) {
//   var supplyAirTemp;
//     let supplyAirTemperature=[];
//     let returnAirTemp=[];
// data.alerts.forEach(info=>{
//     let a={};
//     a['x'] = new Date(info.timestamp);
//     a['y'] = info.SupplyAirTemp;
//     supplyAirTemperature.push(a);
//     let b={};
//     b['x'] = new Date(info.timestamp);
//     b['y']= info.ReturnAirTemp;
//     returnAirTemp.push(b);
// })

// console.log('supp',supplyAirTemperature);
// if(fdate=='empty')
// {
//     fdate = this.getYesterdayDate();
//     tdate = this.getTodayDate();
// }
// console.log(data,supplyAirTemperature,returnAirTemp);
//     supplyAirTemp = new CanvasJS.Chart('supplyAirTemp', {
//         theme: 'dark2',
//         width: 700,

//         title: {
//             text: 'Air Temperature',
//         },
//         toolTip:{
// 			content: '{x}: {y}°F'
//         },
//         axisX : {
//             title: 'Time(Dated '+fdate+' - '+tdate+')',
//             labelangle:90,
//             // interval:4,
//         //intervalType: 'hour',
//         valueFormatString: 'DD MMM,HH:mm',
//         },
//         zoomEnabled:true,
//         axisY : {
//             title: 'Temperature',
//             suffix: ' °F'
//         },
//         //xValueType: 'dateTime',
//         // xValueFormatString:'DD-MMM hh:mm tt',
//         data: [{
//                     type:'spline',
//                     name: 'Supply Air Temp',
//                     showInLegend: true,
//                     markerSize: 0,
//                     xValueFormatString: 'YYYY-MM-DD hh:mm:ss',
//                     dataPoints: supplyAirTemperature
//                 },
//                 {
//                     type: 'spline',
//                     name: 'Return Air Temp',
//                     showInLegend: true,
//                     markerSize: 0,
//                     // xValueFormatString: 'After',
//                     // yValueFormatString: '°F',
//                     xValueFormatString: 'YYYY-MM-DD hh:mm:ss',
//                     dataPoints: returnAirTemp
//                 }
//             ],

//     });
//     supplyAirTemp.render();
// }
