export class Email {
    emailId: number;
    email: string;
    type: string;

    constructor(data) {
        this.emailId = data.emailId;
        this.email = data.email;
        this.type = data.type;
    }
}

export class Phone {
    phoneId: number;
    phoneNumber: string;
    type: string;
    countryCode?: any;

    constructor(data) {
        this.phoneId = data.phoneId;
        this.phoneNumber = data.phoneNumber;
        this.type = data.type;
        this.countryCode = data.countryCode;
    }
}

export class Address {
    addressId: number;
    address: string;
    addressLine2: string;
    city: string;
    state: string;
    stateCode: string;
    zipcode: any;
    type: string;
    placeId: string;
    formattedAddress: string;
    propertyUnitLocation:string;
    latitude:any;
    longitude:any;
    propertyName:string;
    unitAptNo:any;

    constructor(data) {
        this.addressId = data.addressId;
        this.address = data.address;
        this.addressLine2 = data.addressLine2;
        this.city = data.city;
        this.state = data.state;
        this.stateCode = data.stateCode;
        this.zipcode = data.zipcode;
        this.type = data.type;
        this.placeId = data.placeId;
        this.formattedAddress = data.formattedAddress;
        this.propertyUnitLocation= data.propertyUnitLocation;
        this.latitude= data.latitude;
        this.longitude= data.longitude;
        this.propertyName= data.propertyName;
        this.unitAptNo= data.unitAptNo;
    }
}

export class IsEmailAlreadyExistsResponseDetails {
    isExists: boolean;

    constructor(data) {
        this.isExists = data.isExists || data.emailExist;
    }

}

export class IsEmailAlreadyExistsResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    isEmailAlreadyExistsResponseDetails: IsEmailAlreadyExistsResponseDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.isEmailAlreadyExistsResponseDetails = data.responseData &&
            new IsEmailAlreadyExistsResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }

}

export class CommonSuccessResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo: string;
    responseData?: any;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}

export class CommonSuccessResponseList {
    statusCode: string;
    statusMessage: string;
    statusInfo: string;
    responseData?: any;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new BoardTypeCount(data.responseData);
        this.requestData = data.requestData;
    }
}

export class BoardTypeCount {
    cellular: number;
    wifi: number;

    constructor(data) {
        this.cellular = data.Cellular;
        this.wifi = data['Wi-Fi'];
    }

}
