import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() modalHeaderName: string;
  @Input() isShowSubTitle = false;
  @Output() emitCloseModal = new EventEmitter();
  @Input() rightHeaderName: string;
  @Input() isShowRightHeader = false;
  @Input() isShowCrossMark = true;
  @Input() isPendingReportModal:boolean;
  @Input() connectivity:boolean;
  
  constructor() { }

  ngOnInit() {
  }
  
  closeModal() {
    this.emitCloseModal.emit();
  }
  
}
