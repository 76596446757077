import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

// Services
import { ApiService } from 'src/app/shared/services/api.service';
import { HelperService } from 'src/app/shared/services/helper.service';

// Models
import { LoginResponseDetails, ForgotPasswordResponseDetails, ResetPasswordResponseDetails } from 'src/app/shared/models/auth.model';

// Constants
import { API_CONSTANTS } from 'src/app/shared/constants/api.constant';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { CommonSuccessResponse } from 'src/app/shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private BASE_URL;

  constructor(
    private apiService: ApiService,
    private helperService: HelperService,
  ) {
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LOGIN);
  }

  handleSignUp(payload: object): Observable<CommonSuccessResponse> {
    const baseUrl = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMMON_BASE);
    const url = baseUrl + API_CONSTANTS.SIGN_UP;
    return this.apiService.post(url, payload, {}, false, false).pipe(map(response => new CommonSuccessResponse(response)));
  }

  handleLogin(payload: object): Observable<LoginResponseDetails> {
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LOGIN);
    const url = this.BASE_URL + API_CONSTANTS.LOGIN;
    return this.apiService.post(url, payload, {}, false, false).pipe(map(response => new LoginResponseDetails(response)));
  }

  handleRegistrationStatus(queryParams: object): Observable<LoginResponseDetails>{
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMMON_BASE);
    const url = this.BASE_URL + API_CONSTANTS.REGISTRATION_STATUS;
    return this.apiService.get(url, queryParams, false, false).pipe(map(response => new LoginResponseDetails(response)));
  }

  handleResetPassword(queryParams): Observable<ResetPasswordResponseDetails> {
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LOGIN);
    const url = this.BASE_URL + API_CONSTANTS.RESET_PASSWORD;
    return this.apiService.post(url, queryParams, {}, false, false).pipe(map(response => new ResetPasswordResponseDetails(response)));
  }

  handleForgotPassword(queryParams): Observable<ForgotPasswordResponseDetails> {
    // queryParams.userName= encodeURI(queryParams.userName);
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LOGIN);
    const url = this.BASE_URL + API_CONSTANTS.FORGOT_PASSWORD+"?userName="+queryParams.userName+"&userPoolId="+queryParams.userPoolId+"&userRole="+queryParams.userRole;
    return this.apiService.get(url, '',false, false).pipe(map(response => new ForgotPasswordResponseDetails(response)));
  }



}
