import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { Observable } from 'rxjs';

// Services
import { ApiService } from 'src/app/shared/services/api.service';

// Constants
import { CONTRACTOR_API_CONSTANTS } from 'projects/contractor/src/app/shared-contractor/constants/api.constant';

// Models
import { CreateTechnicianResponse, DeleteTechnicianResponse, EditTechnicianResponse, TechnicianBulkUploadResponse, TechnicianInfo, TechnicianJobHistoryResponse } from 'src/app/shared/models/technician.model';
import { TechnicianPropertiesResponse, UploadProperyListResponse } from 'src/app/shared/models/properties.model';
import { MDU_API_CONSTANTS } from 'projects/mdu/src/app/shared-mdu/constants/api.constants';
import { HelperService } from 'src/app/shared/services/helper.service';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { API_CONSTANTS } from 'src/app/shared/constants/api.constant';
import { CommonSuccessResponse } from 'src/app/shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  private BASE_URL;

  constructor(
    private apiService: ApiService,
    private helperService: HelperService
  ) {
    this.BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMMON_BASE);
  }

  getTechnicianList(queryParams): Observable<TechnicianInfo> {
    const url = this.BASE_URL + API_CONSTANTS.GET_TECHNICIANS_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new TechnicianInfo(response)));
  }

  createTechnician(payload): Observable<CreateTechnicianResponse> {
    const url = this.BASE_URL + API_CONSTANTS.CREATE_TECHNICIAN;
    return this.apiService.post(url, payload).pipe(map(response => new CreateTechnicianResponse(response)));
  }

  // uploadTechnicianList(payload,filedata) {
  //   const url = this.BASE_URL + API_CONSTANTS.UPLOAD_TECHNICIAN_LIST;
  //   return this.apiService.post(url,filedata,payload).pipe(map(response => new UploadProperyListResponse(response)));
  // }

  deleteTechnicians(queryParams): Observable<DeleteTechnicianResponse> {
    const url = this.BASE_URL + API_CONSTANTS.DELETE_TECHNICIAN;
    return this.apiService.delete(url, queryParams).pipe(map(response => new DeleteTechnicianResponse(response)));
  }

  getTechnicianJobHistoryList(queryParams): Observable<TechnicianJobHistoryResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.TECHNICIAN_JOB_HISTORY_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new TechnicianJobHistoryResponse(response)));
  }

  getTechnicianProperties(queryParams): Observable<TechnicianPropertiesResponse> {
    const url = this.BASE_URL + MDU_API_CONSTANTS.GET_TECHNICIAN_PROPERTIES;
    return this.apiService.get(url, queryParams).pipe(map(response => new TechnicianPropertiesResponse(response)));
  }

  editTechnician(queryParams): Observable<EditTechnicianResponse> {
    const url = this.BASE_URL + API_CONSTANTS.EDIT_TECHNICIAN;
    return this.apiService.get(url, queryParams).pipe(map(response => new EditTechnicianResponse(response)));
  }

  deactivateTechnician(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.DE_ACTIVATE_TECHNICIAN;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }

  activateTechnician(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.ACTIVATE_TECHNICIAN;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }

  reInviteTechnician(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.RE_INVITE_TECHNICIAN;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }
//  uploadCustomerListStatus(queryParams) {
//     const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_UPLOAD_STATUS;
//     return this.apiService.get(url, queryParams).pipe(map(response => new UploadProperyListResponse(response)));
//   }

//   uploadCustomerListStatusReset(queryParams) {
//     const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.UPLOAD_STATUS_RESET;
//     return this.apiService.put(url, "",queryParams).pipe(map(response => new UploadProperyListResponse(response)));
//   }
}
