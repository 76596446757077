import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { SharedService } from 'src/app/shared/services/shared.service';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Model
import { CommonSuccessResponse, IsEmailAlreadyExistsResponse } from 'src/app/shared/models/common.model';
import { Router } from '@angular/router';
import { window } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-delete-account',
  templateUrl: './transfer-delete-account.component.html',
  styleUrls: ['./transfer-delete-account.component.scss']
})
export class TransferDeleteAccountComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  transferAccForm: FormGroup;
  deleteAccForm: FormGroup;
  selectedTab = 'transfer-account';
  isTransferAccLoading = false;
  isDeleteAccLoading = false;
  isShowEmailAlreadyExistsMsg = false;

  constructor(
    private helperService: HelperService,
    private sharedService: SharedService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.transferAccForm = new FormGroup(
      {
        reciepientsName: new FormControl('', Validators.required),
        reciepientsEmail: new FormControl('', [Validators.required, this.helperService.validateIsEmail]),
      }
    );
    this.deleteAccForm = new FormGroup(
      {
        confirmDelete: new FormControl(''),
      }
    );
  }

  handleTransferAccount() {
    const queryParam = {
      email: this.transferAccForm.get('reciepientsEmail') &&
        this.transferAccForm.get('reciepientsEmail').value,
      recipientName: this.transferAccForm.get('reciepientsName') &&
        this.transferAccForm.get('reciepientsName').value,
    };
    this.isTransferAccLoading = true;
    this.sharedService.transferAccount(queryParam).subscribe(
      (response: IsEmailAlreadyExistsResponse) => {
        this.isTransferAccLoading = false;
        if (response && response.isEmailAlreadyExistsResponseDetails &&
          response.isEmailAlreadyExistsResponseDetails.isExists) {
            this.isShowEmailAlreadyExistsMsg = true;
        } else {
          this.helperService.showToaster(ToasterType.SUCCESS, response && response.statusInfo);
          this.closeModal();
          this.handleLogout();
        }
      }, (error) => {
        this.isTransferAccLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleDeleteAccount() {
    this.isDeleteAccLoading = true;
    this.sharedService.deleteAccount().subscribe(
      (response: CommonSuccessResponse) => {
        this.isDeleteAccLoading = false;
        this.helperService.showToaster(ToasterType.SUCCESS, response && response.statusInfo);
        this.closeModal();
        this.handleLogout();
      }, (error) => {
        this.isDeleteAccLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleLogout() {
    this.helperService.setValueForIsAlreadyIdleTimeRunning(false);
    // localStorage.clear();
    // this.router.navigate(['/login']);
    // const queryParams = {};
    const queryParams = this.helperService.handleParseLogoutQueryParam();
    // this.sharedService.handleLogout(queryParams).subscribe(
    //   (response) => {
        this.helperService.handleLogoutAPISuccessResponse();
    //   }, (error) => {
    //     const message = this.helperService.handleAPIErrors(error);
    //     this.helperService.showToaster(ToasterType.ERROR, message);
    //   }
    // );
  }

  resetEmailErrorMsg() {
    this.isShowEmailAlreadyExistsMsg = false;
  }

  handleTabOnChange(value: string) {
    this.selectedTab = value;
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}

