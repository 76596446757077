import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  takeUntil,
} from 'rxjs/operators';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

// Enviroment
import { VERSION as channelVersion } from 'projects/channel/src/environments/version';
import { VERSION as contractorVersion } from 'projects/contractor/src/environments/version';
import { VERSION as mduVersion } from 'projects/mdu/src/environments/version';

// Service
import { SharedService } from 'src/app/shared/services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';

// Models
import { ContractorDetails, ContractorDetailsResponse } from 'src/app/shared/models/contractor.modal';
import {
  SearchResponse,
  SearchResponseDetails,
} from 'src/app/shared/models/search.model';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Constants
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { CHANNEL_API_CONSTANTS } from 'projects/channel/src/app/shared-channel/constants/api.constants';
import { CONTRACTOR_API_CONSTANTS } from 'projects/contractor/src/app/shared-contractor/constants/api.constant';
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { Customer } from 'src/app/shared/models/inventory.model';
import { MDU_API_CONSTANTS } from 'projects/mdu/src/app/shared-mdu/constants/api.constants';
import { CookieService } from 'ngx-cookie-service';
import { cardDetails } from 'src/app/shared/models/billingaccount.model';
import { SITE_NAME_CONSTANTS } from 'src/app/shared/constants/site-name.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  contractorLogoWhite = require('src/assets/images/Current-Contractor-White.svg');
  contractorLogoBlue = require('src/assets/images/Current-Contractor-Blue.svg');
  currentContractorBlack = require('src/assets/images/Current-Contractor-Black.svg');
  defaultLogo = require('src/assets/images/defaultLogo.png');
  currentContractorLogo = '';
  @ViewChild('search', { static: true }) inputSearch: ElementRef;
  isShowProfileDropdown = false;
  isShowUploadListModal = false;
  isShowGuidePopUp = false;
  contractorInformation: ContractorDetails;
  mduInformation: Customer;
  channelInformation: Customer;
  lastSearchedValue: any;
  isShowSearchPopUp = false;
  searchList: SearchResponseDetails[];
  isShowSearchLoading = false;
  portalName: string;
  searchBoxPlaceholder: string;
  roleConstants = ROLE_CONSTANTS;
  siteConstants = SITE_NAME_CONSTANTS;
  version: string;
  // profileImageUrl: string;
  isShowPasswordModal = false;
  modalTitle: string;
  commonConstants = COMMON_CONSTANTS;
  siteTourSubscription: Subscription;
  searchListSubscription: Subscription;
  selectedSearchValue: SearchResponseDetails;
  selectedSearchIndex: number = -1;
  siteTourTitle: any;
  siteTourContent: any;
  isShowBillingModal = false;
  fromPage: any;
  isUpdateCard: boolean = false;
  isOnlogin: boolean = false;
  res: any;
  billingDetails: any;
  isUpdateAch: boolean;
  url: string;
  modaltext: string = "For the best experience, download the Customer Template and upload your entire customer list. You can always add new customers manually by visiting the Customers tab.";
  isShowTransferOrDeleteAccModal = false;
  isConfirmationModalHasYesOrNoButton = false;
  confirmationModalLine1Content: string;
  confirmationModalEmitterFlag: string;
  isShowConfirmationModal = false;
  isConfirmationModalActionBtnLoading = false;
  isShowWelcomeModal: boolean = false;
  siteNameText: any;
  isShowCreateContractorModal = false;
  contractorDetails: any;
  userEmail: string;
  helperLogo: Subscription;
  // userExist: any = {
  //   consumerExist: true,
  //   technicianExist: true,
  //   sitename: ''
  // }
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.userEmail = localStorage.getItem('userName');
    const siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
    this.siteNameText = siteName;
    // if (siteName === SITE_NAME_CONSTANTS.FERGUSON_CONTRACTOR ||
    //   siteName === SITE_NAME_CONSTANTS.FERGUSON_CHANNEL ||
    //   siteName === SITE_NAME_CONSTANTS.FERGUSON_MDU) {
    //   this.currentContractorLogo = this.contractorLogoBlue;
    if (siteName === SITE_NAME_CONSTANTS.KEENHOME_CHANNEL) {
      this.currentContractorLogo = this.defaultLogo;
    }
    else if (siteName === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR  ||
      siteName === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      this.getContractorInformation();
    }
    // this.getListStatus();
    // this.isShowUploadListModal = true;
    this.setListenerForWelcomeScreen();

    this.helperService.selectedTab.subscribe(data => {
      //this.getFromPage(data);
      if (data && !data.includes('meli')) {
        let page = data;
        this.fromPage = page[0].toUpperCase() + page.substr(1).toLowerCase();
      }
      console.log(this.fromPage)
    })

    this.setListenerForSiteTour();

    fromEvent(this.inputSearch.nativeElement, 'blur').subscribe((event: any) => {
      this.inputSearch.nativeElement.value = null;
      this.searchListSubscription && this.searchListSubscription.unsubscribe();
      this.hideSearchResultsDropdown;
    });

    fromEvent(this.inputSearch.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          // this.isShowSearchLoading = true;
          if (event.keyCode === 13) {
            const searchValue = event.target.value;
            if (this.selectedSearchIndex >= 0 && this.selectedSearchValue) {
              this.navigateToRelaventPage(this.selectedSearchValue);
            } else if (searchValue.length > 0) {
              // this.isShowRecentSearches = false;
              // this.updateRecentSearchesList(searchValue);
              this.getSearchResults(searchValue);
              // this.emitSaveSearchQuery.emit(searchValue);
              // return;
            }
          } else if (event.keyCode === 40 && this.searchList &&
            this.searchList.length) {
            if (this.selectedSearchIndex === (this.searchList.length - 1)) {
              this.selectedSearchIndex = 0;
            } else {
              this.selectedSearchIndex += 1;
            }
            this.selectedSearchValue = this.searchList[this.selectedSearchIndex];
          } else if (event.keyCode === 38 && this.searchList &&
            this.searchList.length) {
            if (this.selectedSearchIndex <= 0) {
              this.selectedSearchIndex = (this.searchList.length - 1);
            } else {
              this.selectedSearchIndex -= 1;
            }
            this.selectedSearchValue = this.searchList[this.selectedSearchIndex];
          } else if (event.keyCode === 27) {
            this.selectedSearchIndex = -1;
            this.selectedSearchValue = null;
          }
          return event.target.value;
        }, this),
        debounceTime(1000),
        // distinctUntilChanged()
      )
      .subscribe((text: string) => {
        if (text.length > 0) {
          // && !this.isShowRecentSearches
          if (this.lastSearchedValue !== text) {
            // this.isShowRecentSearches = false;
            this.lastSearchedValue = '';
            this.getSearchResults(text);
          }
        } else {
          this.lastSearchedValue = '';
          this.hideSearchResultsDropdown();
          this.helperService.setSelectedSearchData('', 'customer', true);
          this.helperService.setSelectedSearchData('', 'technician', true);
          this.helperService.setSelectedSearchData('', 'property', true);
          // this.isShowRecentSearches = true;
        }
      });

    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
        if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
          this.version = contractorVersion.version;
          this.searchBoxPlaceholder =
            'Search for Boards, Customers and Technicians';
          this.url = 'alerts';
          this.setListenerForContractorDetails();
        } else if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
          this.version = channelVersion.version;
          this.searchBoxPlaceholder =
            'Search for Performance Boards or Dealers';
          this.url = 'installations';
        }
        else if (data.portalName === ROLE_CONSTANTS.MDU) {
          this.version = mduVersion.version;
          this.searchBoxPlaceholder =
            'Search for Performance Boards, Properties, Technicians';
          this.url = 'alerts';
          this.setListenerForMduDetails();

        }
      }
    });

    this.helperLogo = this.helperService.setListenerForUploadLogo().subscribe((res)=>{
      console.log(res,"logo")
if (res=="true") {
         this.getContractorInformation();
       }
    })
  }

  // getListStatus() {
  //   this.sharedService.getUserExistStatus().subscribe(
  //     (response: any) => {
  //       if (response['responseData']) {
  //         console.log(response['responseData']);
  //         this.userExist.consumerExist = response['responseData']['consumerExist'];
  //         this.userExist.technicianExist = response['responseData']['technicianExist'];
  //         if (this.userExist.consumerExist == false || this.userExist.technicianExist == false) {
  //           this.isShowUploadListModal = true
  //         }
  //       }
  //     })

  // }
  //get welcomescreen
  setListenerForWelcomeScreen(siteName?) {
    this.sharedService.setListenerForLastLoginSubject().subscribe(
      (response) => {
        //this.setListenerForWelcome();
        this.helperService.setSiteTourEnableorNot(response);
        if (response.responseData && response.responseData.siteTour == 'Enabled') {
          this.portalName !== ROLE_CONSTANTS.CHANNEL ? this.setListenerForWelcome() : '';
          // this.helperService.setSiteTourEnableorNot(response);
        }
        // else {

        //   this.checkUploadList();

        // }


        // }
      }, (error) => {

      }
    );
  }

  setListenerForWelcome() {
    this.modalTitle = "Welcome";
    this.toggleWelcomeModal();
  }

  checkUploadList() {
    if (this.siteNameText !== SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR && this.siteNameText !== SITE_NAME_CONSTANTS.KEENHOME_CHANNEL && this.siteNameText !== SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      if (this.portalName !== ROLE_CONSTANTS.CHANNEL) {
        this.checkBillingInformation('fromLogin');
      }
      // } else if (this.userExist.sitename === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR || this.userExist.sitename === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      //this.getListStatus();
      //   //this.isShowUploadListModal = true;
      //   // this.modaltext="For the best experience, download the Customer Template and upload your entire customer list. You can always add new customers manually by visiting the Customers tab."
      //
    }
  }
  getSearchResults(searchText) {
    this.selectedSearchIndex = -1;
    this.selectedSearchValue = null;
    const queryParams = {
      key: searchText,
    };

    this.isShowSearchLoading = true;
    // const urlEndPoint =
    //   this.portalName === ROLE_CONSTANTS.CONTRACTOR
    //     ? CONTRACTOR_API_CONSTANTS.GET_SEARCH_LIST
    //     : CHANNEL_API_CONSTANTS.GET_SEARCH_LIST;
    let urlEndPoint;
    if (this.portalName === ROLE_CONSTANTS.CONTRACTOR) {
      urlEndPoint = CONTRACTOR_API_CONSTANTS.GET_SEARCH_LIST;
    } else if (this.portalName === ROLE_CONSTANTS.CHANNEL) {
      urlEndPoint = CHANNEL_API_CONSTANTS.GET_SEARCH_LIST;
    } else if (this.portalName === ROLE_CONSTANTS.MDU) {
      urlEndPoint = MDU_API_CONSTANTS.GET_SEARCH_LIST;
    }

    this.searchListSubscription = this.sharedService.getSearchList(queryParams, urlEndPoint).subscribe(
      (response: SearchResponse) => {
        if (response.searchList) {
          this.searchList = response.searchList;
        } else {
          this.searchList = [];
          const message = response.statusInfo;
          this.helperService.showToaster(ToasterType.ERROR, message);
        }
        this.isShowSearchPopUp = true;
        this.isShowSearchLoading = false;
      },
      (error) => {
        this.isShowSearchLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  hideSearchResultsDropdown() {
    this.isShowSearchPopUp = false;
  }

  navigateToRelaventPage(currentValue: SearchResponseDetails) {
    if (currentValue.store === 'Customer') {
      this.helperService.setSelectedSearchData(currentValue, 'customer', true);
      this.router.navigate(['/customers']);
      this.helperService.setSelectedTab('customers');
    } else if (currentValue.store === 'Technician') {
      this.helperService.setSelectedSearchData(
        currentValue,
        'technician',
        true
      );
      this.router.navigate(['/technicians']);
      this.helperService.setSelectedTab('technicians');
    } else if (currentValue.store === 'Performance Board') {
      this.helperService.setSelectedSearchData(currentValue, 'meli', true);
      // localStorage.setItem('meliSerialNo', currentValue.name);
      this.router.navigate(['/meli/' + currentValue.name], { queryParams: { fromPage: this.fromPage, isShowTabs: false } });
      this.helperService.setSelectedTab('meli');
    } else if (currentValue.store === 'Dealer') {
      this.helperService.setSelectedSearchData(
        currentValue,
        'contractor',
        true
      );
      this.router.navigate(['/dealers']);
      this.helperService.setSelectedTab('dealers');
    } else if (currentValue.store === 'Property') {
      this.helperService.setSelectedSearchData(currentValue, 'property', true);
      this.router.navigate(['/properties']);
      this.helperService.setSelectedTab('properties');
    }
    // this.helperService.setSelectedSearchData(currentValue, true);
    this.hideSearchResultsDropdown();
  }

  // getContractorInformation() {
  //   console.log('Cont', 'HEAD')
  //   this.sharedService.getContractorInformation((response, isError) => {
  //     if (!isError) {
  //       this.contractorInformation = response;
  //       // sessionStorage.setItem('firmname', response.firmName);
  //       // if (response.logoUrls && response.logoUrls.length) {
  //       //   this.profileImageUrl = response.logoUrls[0];
  //       // }
  //     }
  //   });
  // }

  setListenerForContractorDetails() {
    this.sharedService.setListenerForContractorDetails().subscribe(
      (response: ContractorDetails) => {
        this.contractorInformation = response;
      }, (error) => {

      }
    );
  }

  // getMduInformation() {
  //   this.sharedService.getMduInformation().subscribe((response) => {
  //     this.mduInformation = response.responseData;
  //     console.log(this.mduInformation)
  //   });
  // }

  setListenerForMduDetails() {
    this.sharedService.setListenerForMduDetails().subscribe(
      (response) => {
        this.mduInformation = response;
      }, (error) => {

      }
    );
  }
  handleRefreshPage(){
  // this.getContractorInformation();  
  }
  getContractorInformation() {
    // debugger
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        this.contractorDetails = response;
        console.log("logo", this.contractorDetails);
        // this.handleModalType('contractor-profile');
        if (response && response.logoUrls && response.logoUrls[0]) {
          this.currentContractorLogo = response.logoUrls[0];
        }
        else {
          this.currentContractorLogo = this.defaultLogo;
        }
      }

    });
  }

  handleConfirmationModalEmitter() {
    if (this.confirmationModalEmitterFlag === 'logout') {
      this.hadleLogout();
    }
  }

  handleModalType(type: string) {
    if (type === 'logout') {
      this.isConfirmationModalHasYesOrNoButton = true;
      this.modalTitle = 'Warning!';
      this.confirmationModalLine1Content = `Do you want to logout?`;
      this.confirmationModalEmitterFlag = 'logout';
      this.toggleConfirmationModal();
    } else if (type === 'contractor-profile') {
      this.modalTitle = 'Profile';
      //this.getContractorInformation();
      this.toggleCreateContractorModal();
    }
    else if (type === 'technicians') {
      this.helperService.setSelectedSearchData('', 'technician', true);
  }
  }
  hadleLogout() {
    const queryParams = this.helperService.handleParseLogoutQueryParam();
    this.isConfirmationModalActionBtnLoading = true;
    // this.sharedService.handleLogout(queryParams).subscribe(
    //   (response) => {
    this.helperService.handleLogoutAPISuccessResponse();
    this.sharedService.setContractorInformation();
    this.isConfirmationModalActionBtnLoading = false;
    this.closeConfirmationModal();
    //   }, (error) => {
    //     this.isConfirmationModalActionBtnLoading = false;
    //     const message = this.helperService.handleAPIErrors(error);
    //     this.helperService.showToaster(ToasterType.ERROR, message);
    //   }
    // );
    // localStorage.removeItem('token');
    // localStorage.removeItem('disableBillingAfterRefresh');
    // // sessionStorage.clear();
    // this.sharedService.setContractorInformation();
    // this.router.navigate(['/login']);
  }

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
  }

  toggleWelcomeModal() {
    this.isShowWelcomeModal = !this.isShowWelcomeModal;
    if (!this.isShowWelcomeModal) {
      this.checkUploadList();
    }


  }

  closeConfirmationModal() {
    this.isShowConfirmationModal = false;
  }

  toggleProfileDropdown(event?: any) {
    event && event.preventDefault();
    event && event.stopPropagation();
    this.isShowProfileDropdown = !this.isShowProfileDropdown;
    this.hideSearchResultsDropdown();

  }

  hideProfileDropdown() {
    this.isShowProfileDropdown = false;
  }

  toggleBillingModal(data) {
    this.isShowBillingModal = !this.isShowBillingModal;
    if (this.isShowBillingModal) {
      this.checkBillingInformation(data)
    }
  }
  toggleUploadListModal() {
    this.isShowUploadListModal = !this.isShowUploadListModal;
  }

  closeGuidePopUp(type?: string) {
    this.isShowGuidePopUp = false;
    if (type != 'previous') {
      this.helperService.setSiteTourFinised(true);
    }

  }

  handleSupport() {

    if (this.siteNameText === SITE_NAME_CONSTANTS.KEENHOME_CHANNEL ||
      this.siteNameText === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR ||
      this.siteNameText === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      window.open('https://prosupport.keenhome.io');
    } else {
      window.open('https://coolcloudhvac.zendesk.com/hc/en-us');
    }

  }

  togglePasswordModal() {
    this.isShowPasswordModal = !this.isShowPasswordModal;
    this.modalTitle = 'Change Password';
  }

  toggleTransferOrDeleteAccModal() {
    this.isShowTransferOrDeleteAccModal = !this.isShowTransferOrDeleteAccModal;
  }

  toggleCreateContractorModal() {
    this.isShowCreateContractorModal = !this.isShowCreateContractorModal;
  }

  handleSiteTourNavigation() {
    this.helperService.setSelectedSiteTour('');
    this.closeGuidePopUp('previous');
  }

  setListenerForSiteTour() {
    this.siteTourSubscription = this.helperService.setListenerForSiteTour().subscribe(
      (response) => {
        console.log(response)
        this.siteTourTitle = response['locator'];
        this.siteTourContent = response['description'];
        this.isShowGuidePopUp = true;
      }
    );
  }


  //check if card details avaible for the logined in user
  checkBillingInformation(fromLogin) {
    this.billingDetails = '';
    this.isUpdateAch = false;
    this.isUpdateCard = false;
    fromLogin == "fromLogin" ? this.isOnlogin = true : this.isOnlogin = false;
    this.sharedService.getBillingDetails().subscribe((result) => {
      this.billingDetails = result.responseData;
      this.checkBillingValidity(this.billingDetails);
      if (this.isOnlogin) {
        localStorage.getItem('disableBillingAfterRefresh') == 'true' ? "" : this.checkBillingValidityonLogin(this.billingDetails);
        localStorage.setItem('disableBillingAfterRefresh', 'true');
      }
    }, err => {
      this.billingDetails = ''
      this.isUpdateAch = false;
      this.isUpdateCard = false;
      this.isShowBillingModal = !this.isShowBillingModal;
    })
  }

  //checking validity of billing cards on menu click
  checkBillingValidity(data) {
    this.modalTitle = "Billing"
    data.forEach(card => {
      if (card.type == "card") {
        this.isUpdateCard = true;
      }
      else if (card.type == "account") {
        this.isUpdateAch = true;
      }
    });
  }
  //validate the save cards on pageload
  checkBillingValidityonLogin(data) {
    if (this.billingDetails.length != 0) {
      this.modalTitle = "Billing"
      let date = new Date();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      console.log(month, data)
      data.forEach(card => {
        //card.exp_month < 10 ? month = "0" + month : ""
        if (card.type == "card") {
          if (card.exp_year < year || (card.exp_year == year && card.exp_month < month)) {
            // const message = "Your card has been expired. Update your card details"
            // this.helperService.showToaster(ToasterType.ERROR, message);
            this.isUpdateCard = true;
            this.isShowBillingModal = !this.isShowBillingModal;
          }

          if (card.type == "account") {
            this.isUpdateAch = true;
          }
        }
      });
    }
    else {
      this.modalTitle = "Add Your Billing Info"
      this.isShowBillingModal = !this.isShowBillingModal
    }
  }

  //redirect to page
  redirect() {
    if (this.portalName == ROLE_CONSTANTS.CHANNEL) {
      this.router.navigate(['/installations']);
    }
    else {
      this.router.navigate(['/alerts']);
    }
  }
  // getFromPage(data) {
  //   if(data=="Alerts")
  // }
  ngOnDestroy() {
    this.helperLogo.unsubscribe()
    this.siteTourSubscription.unsubscribe();

  }

}
