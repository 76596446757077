import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Modules
import { CoreModule } from 'src/app/core/core.module';
import { SharedModule } from 'src/app/shared/shared.module';

// Components
import { AdminComponent } from './admin/admin.component';
import { CookieService } from 'ngx-cookie-service';

const Components = [
  AdminComponent
];

@NgModule({
  declarations: [ Components ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    SharedModule
  ],
  exports: [ Components ],
  providers: [
    CookieService
  ],
})
export class LayoutModule { }
