import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BoardResponseDetails, Customer, InstallationReportResponse, Inventory, meliBoardResponse } from 'src/app/shared/models/inventory.model';
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Address } from 'src/app/shared/models/common.model';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FirmwareResponse, SensorCurrentStatusResponse } from '../../models/meli.model';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { ToasterType } from '../../constants/toaster.enum';
@Component({
  selector: 'app-performance-board-details',
  templateUrl: './performance-board-details.component.html',
  styleUrls: ['./performance-board-details.component.scss']
})
export class PerformanceBoardDetailsComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  boardDetails: BoardResponseDetails;
  @Input() fromMeliPage = false;
   firmwareVersion:any;
  @Input() connectivityStatus:any;
   signalStrength:any;
  @Input() meliSerialNo:any;
  commonConstants = COMMON_CONSTANTS;
  roleConstants = ROLE_CONSTANTS
  portalName: any;
  loading: boolean;
  equipmentId: number;
  selectedInventoryDetails: BoardResponseDetails;
  customerDetails: Customer;
  constructor(
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });
    this.getInventoryDetails();
    this.getFirmwareVersion();
    this.getSensorCurrentStatus();
    this.getSensorInstalltionStatus()

    console.log(this.boardDetails)
  }

  //get board details
  getInventoryDetails() {
    const queryParams = {
      meliSerialNumber: this.meliSerialNo,
      timeZone: momenttimezone.tz.guess()
    };
    this.loading = true;
    this.sharedService.getBoardInformation(queryParams).subscribe(
      (response: meliBoardResponse) => {
        if (response && response.responseData) {
          this.boardDetails = response.responseData;
        }
        this.loading = false;
      }, (error) => {
        this.loading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  //get firmaware information
    getFirmwareVersion() {
    const queryParams = { meliSerialNumber: this.meliSerialNo };
    this.sharedService.getFirmware(queryParams).subscribe(
      (response: FirmwareResponse) => {
        if (response && response.responseData &&
          response.responseData.firmwareVersion) {
          this.firmwareVersion = response.responseData.firmwareVersion;
        }
      });
  }

  //connectivity status
   getSensorCurrentStatus() {
    const queryParams = {
      meliSerialNumber: this.meliSerialNo
    };
    this.loading = true;
    this.sharedService.getSensorCurrentStatus(queryParams).subscribe(
      (response: SensorCurrentStatusResponse) => {
        this.loading = false;
        this.connectivityStatus = response.responseData[0].status;
      }, (error) => {
        this.loading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    )
  }

   getSensorInstalltionStatus() {
    this.loading = true;
    const queryParams = {
      meliSerialNumber: this.meliSerialNo,
      timeZone: momenttimezone.tz.guess()
    };
    this.sharedService.getSensorInstallationStatus(queryParams).subscribe(
      (response: InstallationReportResponse) => {
        this.signalStrength = response.responseData.signalStrength;
        this.loading = false;
      }, (error) => {
        this.loading = false;
      }
    );
  }

  handleAddressSelection(addressList: Address[], requiredType: string) {
    let address = this.helperService.handleAddressSelection(addressList, requiredType);
    if (address) {
      if (this.portalName == this.roleConstants.CHANNEL) {
        let fullAddress = address.city ? address.city + ', ' : null;
        fullAddress += address.zipcode ? address.zipcode : null;
        return fullAddress;
      }
      let fullAddress = this.helperService.handleRemoveAddressLinesCombinator(address && address.address) + ', ';
      fullAddress += address.city ? address.city + ', ' : null;
      fullAddress += address.stateCode ? address.stateCode + ' ' : null;
      fullAddress += address.zipcode ? address.zipcode : null;
      return fullAddress;
    }
    return null;
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}
