import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reportsPreseasonInArray'
})
export class ReportsPreseasonInArrayPipe implements PipeTransform {

  transform(preseasonScheduledArr: any, currentValue): any {
    //console.log(currentValue)
    let flag = false;
    flag = preseasonScheduledArr.includes(currentValue)
    return flag;
  }

}
