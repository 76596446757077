export const CHANNEL_API_CONSTANTS = {
    ADD_MELI_TO_CHANNEL: '/channel/melis',
    ASSIGN_MELI_TO_CONTRACTOR: '/channel/contractor/melis',
    GET_CONTRACTOR_LIST: '/contractors',
    GET_CHANNEL_INVENTORY_LIST: '/melidetails',
    CONTRACTOR_LOGO: '/contractorLogo',
    UNASSIGN_MELI_FROM_CONTRACTOR: '/unAssignMeliFromContractor',
    GET_SEARCH_LIST: '/channel/search',
    ADD_DEALER: '/addDealer',
    GET_INSTALLATION_LIST: '/melidetails',
    GET_DEALER_LIST: '/getDealer',
    UPLOAD_DEALER_LIST: '/uploadDealerData'
};
