import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import * as moment from 'moment';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { SharedService } from 'src/app/shared/services/shared.service';

// Constants
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Models
import { HVACCycleResponse, Sensor } from 'src/app/shared/models/graph.model';
import { SITE_NAME_CONSTANTS } from '../../constants/site-name.constant';
import { BASE_URL_TYPES } from '../../constants/base-url-types.constant';

import * as momenttimezone from 'moment-timezone';
@Component({
  selector: 'app-advance-diagnostic-modal',
  templateUrl: './advance-diagnostic-modal.component.html',
  styleUrls: ['./advance-diagnostic-modal.component.scss']
})
export class AdvanceDiagnosticModalComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  // @Input() chartData: any;
  // @Input() advanceDiagnosticSelectedTab: string;
  // @Output() emitHandleAdvaceDiagnosticTabOnChange = new EventEmitter<any>();
  // @Output() emitHandleDateOnChange = new EventEmitter<any>();
  // @Input() isLoading: boolean;
  @Input() boardNumber;
  @Input() isShowCrossMark = false
  @Input() alertFromTime = ''
  @Input() alertToTime = ''
  @Input() alertspdStatus='';
  // @Output() emitHandleTemperatureConvertion = new EventEmitter<any>();
  isAllCheckboxChecked = false;
  isFanChecked = false;
  isCoolingChecked = false;
  isHeatingChecked = false;
  commonConstants = COMMON_CONSTANTS;
  isLoading = false;
  chartData: any;
  advanceDiagnosticSelectedTab = 'Investigate';
  fromTime;
  toTime;
  currentTemeperatureConvertion = COMMON_CONSTANTS.FAHRENHEIT;
  currentPressureConvertion = COMMON_CONSTANTS.WATER_COLUMN;
  TemperaturecheckedValue: boolean = false;
  PressureCheckedValue: boolean = false;
  HVACCycleData: any;
  HVACCycleLoading = false;
  chartDataLastTime: any;
  chartDataFirstTime: any;
  defaultFromDate: any;
  defaultToDate: any;
  // maxDate = moment(new Date()).add(1, 'day').toDate();
  maxDate =  new Date().setHours(23,59,59,999);
  isShowFromDate = false;
  isShowToDate = false;
  isHVACCycleHasData = true;
  siteName: any;
  siteConstants = SITE_NAME_CONSTANTS;
  @Input() spdStatus :any;
  tominDate: any;
  tomaxDate:any;
  dateError: boolean=true;
  hvacFtime: any;
  hvacTtime: any;
  constructor(
    private helperService: HelperService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    console.log(this.spdStatus)
    let arr=['dat','rat','slt','llt','ambt'];
    arr.forEach(ele=>{
 localStorage.removeItem(ele);
    })
    this.siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
    if (this.alertFromTime && this.alertToTime) {
      this.defaultFromDate = moment(this.alertFromTime).format('MM-DD-YYYY');
      this.defaultToDate = moment(this.alertToTime).format('MM-DD-YYYY');
      this.fromTime = this.alertFromTime;
      this.toTime = this.alertFromTime;
      //this.spdStatus = this.alertspdStatus;
      console.log(this.spdStatus)
      this.getSensorRead(this.alertFromTime, this.alertToTime);
      this.handleOnchangeCheckbox('all')
      //this.getHVACCycleData(this.alertFromTime, this.alertToTime);
    }
    else {
      this.getSensorCurrentRead();
      //this.getHVACCycleData();
    }
  }


  closeModal() {
    this.emitCloseModal.emit();
    this.handleResetFromAndToDate();
  }

  handleTabOnChange(value) {
    // this.emitHandleAdvaceDiagnosticTabOnChange.emit(value);
    this.advanceDiagnosticSelectedTab = value;
  }

  // handleTemperatureConvertion(event: any) {
  //   this.emitHandleTemperatureConvertion.emit(event);
  // }

  getSensorCurrentRead() {
    const queryParams = {
      meliSerialNo: this.boardNumber,
      timeZone:momenttimezone.tz.guess()
    };
    this.handleResetChartDate();
    this.isLoading = true;
     this.HVACCycleLoading=true;
    this.sharedService.getSensorCurrentRead(queryParams).subscribe(
      (response: Sensor) => {
        this.isLoading = false;
         this.HVACCycleLoading=false;
        this.chartData = this.helperService.handleChartData(
          response.responseData,
          'advance-diagnostic'
        );
        this.chartDataFirstTime = response && response.responseData && response.responseData.alerts &&
          response.responseData.alerts[0] &&
          response.responseData.alerts[0].timeStamp;
        this.chartDataFirstTime =  moment.utc(this.chartDataFirstTime).toDate();
        this.chartDataLastTime = response && response.responseData && response.responseData.alerts &&
          response.responseData.alerts.length &&
          response.responseData.alerts[(response.responseData.alerts.length - 1)] &&
          response.responseData.alerts[(response.responseData.alerts.length - 1)].timeStamp;
        this.chartDataLastTime =  moment.utc(this.chartDataLastTime).toDate();
        this.gethandleTemperatureConvertion();
        this.gethandlePressureConvertion();
                this.HVACCycleData = this.helperService.handleHVACCycleData(response.responseData.HVACCycleDetails, COMMON_CONSTANTS.FAHRENHEIT);
      },
      (error) => {
        this.isLoading = false;
         this.HVACCycleLoading=false;
        //this.handleHVACLoading();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  getSensorRead(fromTime, toTime) {
    const queryParams = {
      meliSerialNo: this.boardNumber,
      fromTime,
      toTime,
       timeZone:momenttimezone.tz.guess()
    };
    this.chartDataFirstTime = fromTime;
    this.chartDataLastTime = toTime;
    this.handleResetChartDate();
    this.isLoading = true;
    this.HVACCycleLoading=true;
    this.sharedService.getSensorRead(queryParams).subscribe(
      (response: Sensor) => {
        this.isLoading = false;
        this.HVACCycleLoading=false;
        this.chartData = this.helperService.handleChartData(
          response.responseData,
          'advance-diagnostic'
        );
        this.gethandleTemperatureConvertion();
        this.gethandlePressureConvertion();
        this.HVACCycleData = this.helperService.handleHVACCycleData(response.responseData.HVACCycleDetails, COMMON_CONSTANTS.FAHRENHEIT);
      },
      (error) => {
        this.isLoading = false;
        this.HVACCycleLoading=false;
        //this.handleHVACLoading();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  getHVACCycleData(fromTime?, toTime?) {
     //debugger
    // const queryParams = {
    //   meliSerialNo: this.boardNumber,
    //   // fromTime,
    //   // toTime,
    //   // meliSerialNo: 'CM-MC01-KA2019-01-000097',
    //   // fromTime : '2020-09-01',
    //   // toTime: '2020-10-08',
    // };
    let queryParams;
    if (fromTime && toTime) {
      // debugger
      fromTime = moment(fromTime).format(
        'YYYY-MM-DD'
      );

      toTime = moment(toTime).format(
        'YYYY-MM-DD'
      );
        // debugger
      queryParams = {
        meliSerialNo: this.boardNumber,
        fromTime,
        toTime
      };
    } else {
      queryParams = {
        meliSerialNo: this.boardNumber
      };
    }
    // this.handleResetChartDate();
    this.HVACCycleLoading = true;
    this.sharedService.getHVACCycleData(queryParams).subscribe(
      (response: HVACCycleResponse) => {
        // debugger
        // this.HVACCycleLoading = false;
        // this.chartData = this.helperService.handleChartData(
        //   response.responseData,
        //   'advance-diagnostic'
        // );
        // this.gethandleTemperatureConvertion();
        // this.gethandlePressureConvertion();
        if (!response || !response.HVACCycleDetails || !response.HVACCycleDetails.length) {
          this.isHVACCycleHasData = false;
        }
        this.HVACCycleData = this.helperService.handleHVACCycleData(response.HVACCycleDetails, COMMON_CONSTANTS.FAHRENHEIT);
        // debugger
        this.handleHVACLoading();
      },
      (error) => {
        this.HVACCycleLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleHVACLoading() {
    if (!this.isLoading) {
      this.HVACCycleLoading = false;
    }
  }

  handleTemperatureConvertion(event?: any) {
    // this.TemperaturecheckedValue = event == undefined ? false : event.target.checked;
    this.TemperaturecheckedValue = !this.TemperaturecheckedValue;
    this.gethandleTemperatureConvertion();
  }
  gethandleTemperatureConvertion() {
    if (this.TemperaturecheckedValue) {
      this.currentTemeperatureConvertion = COMMON_CONSTANTS.CELCIUS;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.CELCIUS);
      this.chartData.temperature = data.temperature.celciusData;
      this.HVACCycleData = this.helperService.handleReturnHVACCycleData(COMMON_CONSTANTS.CELCIUS);
      this.handleParsePlotBandsData();
    } else {
      this.currentTemeperatureConvertion = COMMON_CONSTANTS.FAHRENHEIT;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.FAHRENHEIT);
      this.chartData.temperature = data.temperature.fahrenheitData;
      this.HVACCycleData = this.helperService.handleReturnHVACCycleData(COMMON_CONSTANTS.FAHRENHEIT);
      this.handleParsePlotBandsData();
    }
  }

  handlePressureConvertion(event?: any) {
    // this.PressureCheckedValue = event == undefined ? false : event.target.checked;
    this.PressureCheckedValue = !this.PressureCheckedValue;
    this.gethandlePressureConvertion();
  }
  gethandlePressureConvertion() {
    if (this.PressureCheckedValue) {
      this.currentPressureConvertion = COMMON_CONSTANTS.PASCAL;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.PASCAL);
      this.chartData.diffStaticPressure = data.diffStaticPressure.pascalData;
      this.handleParsePlotBandsData();
    } else {
      this.currentPressureConvertion = COMMON_CONSTANTS.WATER_COLUMN;
      const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.WATER_COLUMN);
      this.chartData.diffStaticPressure = data.diffStaticPressure.waterColumnData;
      this.handleParsePlotBandsData();
    }
  }

  handleParsePlotBandsData() {
    console.log(this.chartData)
    this.chartData.temperature&&this.chartData.temperature.data.forEach(element => {
      if(localStorage.getItem(element.checkBoxClassName))
      {
        element.selected = localStorage.getItem(element.checkBoxClassName)=="true"?true:false
        element.visible = localStorage.getItem(element.checkBoxClassName)=="true"?true:false
      }
    });
    this.isLoading = true;
    this.HVACCycleLoading = true;
    if (this.chartData && this.chartData.plotBandsData) {
      this.chartData.plotBandsData = [];
    }
    const data = this.helperService.handleReturnChartData(COMMON_CONSTANTS.WATER_COLUMN);
    const chartData: any = data.thermostatReads;
    chartData.plotBandsData = [];
    if (this.isFanChecked && chartData.fanSpeed) {
      chartData.plotBandsData.push(
        ...chartData.fanSpeed
      );
    }
    if (this.isCoolingChecked && chartData.cooling) {
      chartData.plotBandsData.push(
        ...chartData.cooling
      );
    }
    if (this.isHeatingChecked) {
      if (chartData.heatPump) {
        chartData.plotBandsData.push(
          ...chartData.heatPump
        );
      }

      if (chartData.heatFurnace) {
        chartData.plotBandsData.push(
          ...chartData.heatFurnace
        );
      }
      // chartData.plotBandsData.push(
      //   ...chartData.heatPump, ...chartData.heatFurnace
      // );
    }
    if (this.chartData && this.chartData.plotBandsData) {
      this.chartData.plotBandsData = JSON.parse(JSON.stringify(chartData.plotBandsData));
    }
    setTimeout(() => {
      this.isLoading = false;
      this.HVACCycleLoading = false;
    }, 400);
  }

  handleOnchangeCheckbox(flag: string) {
    if (flag === 'all') {
      this.isAllCheckboxChecked = !this.isAllCheckboxChecked;
      this.handleAllCheckboxValue(this.isAllCheckboxChecked);
    } else if (flag === 'fan') {
      this.isFanChecked = !this.isFanChecked;
    } else if (flag === 'cooling') {
      this.isCoolingChecked = !this.isCoolingChecked;
    } else if (flag === 'heating') {
      this.isHeatingChecked = !this.isHeatingChecked;
    }
    this.handleParsePlotBandsData();
    this.isAllCheckboxChecked = this.handleIsAllCheckboxChecked();
  }

  handleAllCheckboxValue(value: boolean) {
    this.isFanChecked = value;
    this.isCoolingChecked = value;
    this.isHeatingChecked = value;
  }

  handleIsAllCheckboxChecked() {
    if (this.isFanChecked && this.isCoolingChecked && this.isHeatingChecked) {
      return true;
    }
    return false;
  }

  // handleDateOnChange(event: any, type) {
  //   const data = {
  //     event,
  //     type
  //   };
  //   // this.emitHandleDateOnChange.emit(data);
  // }

  toggleDatePicker(value: boolean, flag: string) {
    if (flag === 'from-time') {
      this.isShowFromDate = value;
    } else if (flag === 'to-time') {
      this.isShowToDate = value;
    }
  }
  handleDateOnChange(event: any, type) {
   
    if (type === 'from-time') {
      if (event) {
        this.hvacFtime= event;
        let Ftime = new Date(event).setHours(0, 0, 0, 0 )
        this.fromTime = moment.utc(new Date(Ftime)).format('YYYY-MM-DDTHH:mm:ss');
        this.defaultFromDate = moment(event).format('MM-DD-YYYY');
      } else {
        this.fromTime = undefined;
      }
    } else if (type === 'to-time') {
      if (event) {
        this.hvacTtime=event ;
          let Ttime = new Date(event).setHours(23, 59, 59, 999 )
          this.toTime = moment.utc(new Date(Ttime)).format('YYYY-MM-DDTHH:mm:ss');
        this.defaultToDate = moment(event).format('MM-DD-YYYY');
      } else {
        this.toTime = undefined;
      }
    }
    //console.log(hvacTtime)
    // debugger
    if (this.fromTime && this.toTime) {
      console.log(this.fromTime<this.toTime)
      this.dateError = this.fromTime<=this.toTime
      if(this.dateError)
      {
        this.chartDataFirstTime==this.fromTime && this.chartDataLastTime==this.toTime?'':
      this.getSensorRead(this.fromTime, this.toTime);
      //this.getHVACCycleData(this.hvacFtime, this.hvacTtime); 
      }
    }

          // this.isShowFromDate = false;
       // if (event && event && event.target && event.target.value) {
          //seminute: new Date().getMinutes(), second: new Date().getSeconds(), millisecond: 0 });
            // this.fromTime = event;
                  // this.isShowToDate = false;
       // if (event && event && event.target && event.target.value) {
              // if (moment(event).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
        //   this.toTime = moment.utc(new Date(Ttime)).format(
        //     'YYYY-MM-DDTHH:mm:ss'
        //   );
        // } else {
        //   this.toTime = new Date(event).setHours(23, 59, 59, 999);
        //   this.toTime = moment(this.toTime).format(
        //     'YYYY-MM-DDTHH:mm:ss'
        //   );
        // }
        // this.toTime = event;
  }

  handleOnClickNextBtn() {
    const startTime = moment(this.chartDataLastTime).format('YYYY-MM-DDTHH:mm:ss');
    let endTime: any = moment(this.chartDataLastTime).add(1, 'hour').toDate();
    endTime = moment(endTime).format('YYYY-MM-DDTHH:mm:ss');
    this.defaultFromDate = moment(startTime).format('MM-DD-YYYY');
    this.defaultToDate = moment(endTime).format('MM-DD-YYYY');
   // this.getSensorRead(startTime, endTime);
    //this.getHVACCycleData(startTime, endTime);
  }

  handleOnClickPrevBtn() {
    let startTime: any = moment(this.chartDataFirstTime).subtract(1, 'hour').toDate();
    startTime = moment(startTime).format('YYYY-MM-DDTHH:mm:ss');
    const endTime: any = moment(this.chartDataFirstTime).format('YYYY-MM-DDTHH:mm:ss');
    this.defaultFromDate = moment(startTime).format('MM-DD-YYYY');
    this.defaultToDate = moment(endTime).format('MM-DD-YYYY');
   // this.getSensorRead(startTime, endTime);
    //this.getHVACCycleData(startTime, endTime);
  }

  handleResetChartDate() {
    this.chartData = undefined;
  }

  handleResetFromAndToDate() {
    this.fromTime = undefined;
    this.toTime = undefined;
  }

}
