import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Constants
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() skip: number;
  @Input() limit: number;
  @Input() page: number;
  @Input() totalCount: number;
  @Input() paginationFor: string;
  @Input() placeHolder: string;
  @Input() isLightBackground = false;
  @Output() emitHandlePagination = new EventEmitter<any>();

  Math: any;
  commonConstants = COMMON_CONSTANTS;

  constructor() {
    this.Math = Math;
  }

  ngOnInit() {
  }

  handlePagination(flag: string) {
    const payload = {
      paginationType: flag,
      paginationFor: this.paginationFor
    };
    this.emitHandlePagination.emit(payload);
  }

}
