import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import * as moment_ from 'moment';

const moment = moment_;
declare var $: any;

@Component({
  selector: 'app-datetimepicker',
  templateUrl: './datetimepicker.component.html',
  styleUrls: ['./datetimepicker.component.scss']
})
export class DatetimepickerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() placeholder = '';
  @Input() elementId = 'default';
  @Input() disableInput = false;
  @Input() isResetField = false;
  @Input() format = 'YYYY-MM-DD HH:mm:ss A';
  @Output() handleSelectedDate = new EventEmitter<any>();
  @Output() emitCloseScheduling = new EventEmitter<any>();
  @Input() defaultDate;
  @Input() minDate: Date;
  @Input() maxDate = '';
  @Input() iconClassName: string;
  @Input() datePickerClassName: string;
  @Input() datePickerInputClassName = 'font-regular font-15 header-no-dark';
  @Input() defaultOpen = false;
  @Input() closeOnChange = false;
  @Input() endDate = Date();
  @Input() disabledHours = false;
  @Input() rightArrow = false;
  @Input() showArrow = false;
  currentDate = new Date();

  constructor() { }

  ngOnInit() {
    console.log(this.minDate)
    if (!this.datePickerClassName) {
      this.datePickerClassName = 'date-picker';
    }
    if (!this.iconClassName) {
      this.iconClassName = 'icon-calendar font-16';
    }

  }

  ngOnChanges(SimpleChanges) {

    if (this.isResetField) {
      this.initializeDatePicker('clear_end_date');
    }

    if (SimpleChanges.isResetField) { }
  }

  ngAfterViewInit() {
    this.initializeDatePicker(this.defaultDate);
  }

  ngOnDestroy() {
    const elementId = '#' + this.elementId;
    if ($(elementId) && $(elementId).data('DateTimePicker')) {
      $(elementId).data('DateTimePicker').hide();
    }
  }

  initializeDatePicker(defaultDate) {
    const elementId = '#' + this.elementId;
    const self = this;
    let maxDate: any = this.maxDate;
    if (!maxDate) {
      maxDate = new Date((this.currentDate.getFullYear()) + 1,
        this.currentDate.getMonth(),
        this.currentDate.getDate());
    }

    // moment.updateLocale('en', {
    //   weekdaysMin : ["S", "M", "T", "W", "T", "F", "S"]
    // });
    // debugger
    const minDate: any = this.minDate;
    // const minDate: any = new Date();

    $(elementId).datetimepicker({
      // container: $(elementId).parent(),
      // icons: {
      //   time: 'icon-timer',
      //   previous: 'icon-keyboard_arrow_left',
      //   next: 'icon-keyboard_arrow_right',
      //   date: 'icon-date_range',
      //   up: 'icon-keyboard_arrow_up',
      //   down: 'icon-keyboard_arrow_down',
      //   today: 'icon-today',
      //   clear: 'icon-close1',
      //   close: 'icon-close1'
      // },
      format: this.format,
      defaultDate,
      maxDate,
      minDate,
      keepOpen: true,
      useCurrent: false,
      ignoreReadonly: true,
      // disabledHours: this.disabledHours,
      // inline: true,
      // sideBySide: true,
      debug: true,
      // pickTime: false
    });

    // if (defaultDate === 'clear_end_date') {
    //   $(elementId).val('');
    // }
    if (this.defaultOpen) {
      $(elementId).data('DateTimePicker').show();
    }
    $(elementId).on('dp.change', (e) => {
      self.handleDatePickerChange(e);
      if (this.closeOnChange) {
        $(elementId).data('DateTimePicker').hide();
      }
    });
    // $(elementId).on('dp.hide', (e) => {
    //   if (elementId === '#schedule_date') {
    //     self.handleDatePickerChange(e);
    //   }
    // });
  }

  handleDatePickerChange(e) {
    const date = e.date ? moment(e.date).format('YYYY-MM-DDTHH:mm:ssZ') : '';
    this.handleSelectedDate.emit(date);
  }

  closeScheduleLater() {
    this.emitCloseScheduling.emit();
  }

  closeDateTimePicker() {
    const elementId = '#' + this.elementId;
    $(elementId).data('DateTimePicker').hide();
  }

  openDateTimePicker() {
    const elementId = '#' + this.elementId;
    $(elementId).data('DateTimePicker').show();
  }

}
