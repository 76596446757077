import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: any;
  headers: HttpHeaders;

  constructor(
    private httpClient: HttpClient
  ) { }

  handleHeaders() {
    // const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');
    // if (isRememberMeChecked === 'true') {
      this.token = localStorage.getItem('token');
    // } else {
    //   this.token = sessionStorage.getItem('token');
    // }
    this.headers = new HttpHeaders().append('Authorization', this.token);

    if (!this.token) {
      window.location.reload();
    }

  }

  get(url: string, params: any = {}, useRawParams: boolean = false, hasHeader: boolean = true): Observable<any> {
    let httpParams: HttpParams = new HttpParams();

    if (!useRawParams) {

      // let httpParams: HttpParams = new HttpParams();
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });

      params = httpParams;
    }

    if (hasHeader) {
      this.handleHeaders();
      return this.httpClient.get(url, { params, headers: this.headers });
    } else {
      return this.httpClient.get(url, { params });
    }
  }

  post(url: string, payload, params: any = {}, useRawParams: boolean = false, hasHeader: boolean = true) {
    let httpParams: HttpParams = new HttpParams();
    if (!useRawParams) {
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });
      params = httpParams;
    }

    if (hasHeader) {
      this.handleHeaders();
      return this.httpClient.post(url, payload, { params, headers: this.headers });
    } else {
      return this.httpClient.post(url, payload, { params });
    }
  }

  put(url: string, payload, params: any = {}, useRawParams: boolean = false, hasHeader: boolean = true) {
    let httpParams: HttpParams = new HttpParams();
    if (!useRawParams) {
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });
      params = httpParams;
    }

    if (hasHeader) {
      this.handleHeaders();
      return this.httpClient.put(url, payload, { params, headers: this.headers });
    } else {
      return this.httpClient.put(url, payload, { params });
    }
  }

  delete(url: string, params: any = {}, useRawParams: boolean = false, hasHeader: boolean = true) {
    let httpParams: HttpParams = new HttpParams();
    if (!useRawParams) {
      Object.keys(params).forEach(key => {
        httpParams = httpParams.append(key, params[key]);
      });
      params = httpParams;
    }

    if (hasHeader) {
      this.handleHeaders();
      return this.httpClient.delete(url, { params, headers: this.headers });
    } else {
      return this.httpClient.delete(url, { params });
    }
  }

}
