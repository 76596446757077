import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  @Input() type: string;
  @Input() message: string;
  // showToaster = true;
  toasterClass = 'toaster_success';

  constructor() { }

  ngOnInit() {
    // const self = this;

    if (this.type === 'success') {
      this.toasterClass = 'toaster_success';
    } else if (this.type === 'warning') {
      this.toasterClass = 'toaster_warning';
    } else if (this.type === 'error') {
      this.toasterClass = 'toaster_error';
    }

    // setTimeout(() => {
    //   self.showToaster = false;
    // }, 5000);
  }

}
