export class MeliAssetInfo {
    meliSerialNumber: string;
    modelNo: string;
    deviceType: string;
    brochureLink: string;
    assetSerialNumber: string;
    assetId: number;

    constructor(data) {
        this.meliSerialNumber = data.meliSerialNumber;
        this.modelNo = data.modelNo;
        this.deviceType = data.deviceType;
        this.brochureLink = data.brochureLink;
        this.assetSerialNumber = data.assetSerialNumber;
        this.assetId = data.assetId;
    }
}

export class MeliAssetInfoResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    MeliAssetInfoResponse: MeliAssetInfo;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.MeliAssetInfoResponse = data.responseData && new MeliAssetInfo(data.responseData);
        this.requestData = data.requestData;
    }
}

export class AddMeliToContractorDetails {
    meliSerialNumber: string;
    description: string;
    status: boolean;

    constructor(data) {
        this.meliSerialNumber = data.meliSerialNumber;
        this.description = data.description;
        this.status = data.status;
    }
}

export class AddMeliToContractorResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    AddMeliToContractorDetails: AddMeliToContractorDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.AddMeliToContractorDetails = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new AddMeliToContractorDetails(value));
        this.requestData = data.requestData;
    }
}

export class UpdateRelayStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: string;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}

export class DeleteMeliResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    deleteMeliDetails: string;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.deleteMeliDetails = data.responseData;
        this.requestData = data.requestData;
    }
}

export class UpdateMeliStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: string;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}
export class FirmwareResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: FirmwareResponseDetail;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData &&
        new FirmwareResponseDetail(data.responseData);
        this.requestData = data.requestData;
    }
}
export class FirmwareResponseDetail{
    meliSerialNumber?: any;
    firmwareVersion?:any;

constructor(data){
    this.meliSerialNumber=data.meliSerialNumber;
    this.firmwareVersion=data.firmwareVersion;
}}
// export class SensorStatus {
//     sensorInstallStatusId: number;
//     DATStatus?: any;
//     RATStatus?: any;
//     SLTStatus?: any;
//     LLTStatus?: any;
//     SPDStatus?: any;
//     CDSStatus?: any;
//     serialNumber?: any;

//     constructor(data) {
//         this.sensorInstallStatusId = data.sensorInstallStatusId;
//         this.DATStatus = data.datStatus;
//         this.RATStatus = data.ratStatus;
//         this.SLTStatus = data.sltStatus;
//         this.SPDStatus = data.spdStatus;
//         this.CDSStatus = data.cdsStatus;
//         this.serialNumber = data.serialNumber;
//     }
// }

export class CommandValueList {
    command: string;
    value: number;

    constructor(data) {
        this.command = data.command;
        this.value = data.value;
    }
}

export class SensorStatus {
    boardSensorTypeId: number;
    status: string;
    sensorType?: string;
    commandValueList: CommandValueList[];

    constructor(data) {
        this.boardSensorTypeId = data.boardSensorTypeId;
        this.status = data.status;
        this.sensorType = data.sensorType;
        this.commandValueList = data.commandValueList && data.commandValueList.length &&
            data.commandValueList.map((value, index) => new CommandValueList(value));
    }
}

export class SensorStatusResponseDetail {
    type: string;
    sensorStatus: SensorStatus[];

    constructor(data) {
        this.type = data.type;
        this.sensorStatus = data.info && data.info.length &&
            data.info.map((value, index) => new SensorStatus(value));
    }
}

export class SensorStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    sensorStatusResponseDetail: SensorStatusResponseDetail[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.sensorStatusResponseDetail = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new SensorStatusResponseDetail(value));
        this.requestData = data.requestData;
    }
}

export class ScheduleEmergencyShutoffResponseDetails {
    scheduleTime: string;
    boardSerialNumber: string;
    message: string;
    status: string;

    constructor(data) {
        this.scheduleTime = data.scheduleTime;
        this.boardSerialNumber = data.boardSerialNumber;
        this.message = data.message;
        this.status = data.status;
    }
}

export class ScheduleEmergencyShutoffResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    scheduleEmergencyShutoffResponseDetails: ScheduleEmergencyShutoffResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusInfo = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.scheduleEmergencyShutoffResponseDetails = data.responseData &&
            new ScheduleEmergencyShutoffResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class EmergencyShutoffStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    emergencyShutoffStatusResponse: ScheduleEmergencyShutoffResponseDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.emergencyShutoffStatusResponse = data.responseData &&
            new ScheduleEmergencyShutoffResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class CanScheduleEmergencyShutoffResponseDetails {
    boardSerialNumber: string;
    message: string;
    status: boolean;

    constructor(data) {
        this.boardSerialNumber = data.boardSerialNumber;
        this.message = data.message;
        this.status = data.status;
    }
}

export class CanScheduleEmergencyShutoffResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    canScheduleEmergencyShutoffResponse: CanScheduleEmergencyShutoffResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.canScheduleEmergencyShutoffResponse = data.responseData &&
            new CanScheduleEmergencyShutoffResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class CancelEmergencyShutoffResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    cancelEmergencyShutoffResponse: ScheduleEmergencyShutoffResponseDetails;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.cancelEmergencyShutoffResponse = data.responseData &&
            new ScheduleEmergencyShutoffResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}

export class SensorLastActiveInfo {
    sensorName: string;
    sensorStatus: string;
    sensorValue: number;
    timestamp: string;

    constructor(data) {
        this.sensorName = data.sensorName;
        this.sensorStatus = data.sensorStatus;
        this.sensorValue = data.sensorValue;
        this.timestamp = data.timestamp;
    }
}

export class SensorCurrentStatusResponseDetails {
    //sensorLastActiveInfo: SensorLastActiveInfo[];
    datTimestamp: string;
    dischargeAirTemperature: string
    fanStatus: string
    liquidLineTemperature: string
    lltTimestamp: string
    meliSerialNumber: string
    pressure: string
    ratTimestamp: string
    returnAirTemperature: string
    sltTimestamp: string
    spdTimestamp: string
    status: string
    suctionLineTemperature: string

    constructor(data) {
        //this.sensorLastActiveInfo = data.sensorLastActiveInfo && data.sensorLastActiveInfo.length &&
        // data.sensorLastActiveInfo.map((value, index) => new SensorLastActiveInfo(value));

        this.dischargeAirTemperature = data.dischargeAirTemperature;
        this.datTimestamp = data.datTimestamp;
        this.returnAirTemperature = data.returnAirTemperature;
        this.ratTimestamp = data.ratTimestamp;
        this.suctionLineTemperature = data.suctionLineTemperature;
        this.sltTimestamp = data.sltTimestamp;
        this.liquidLineTemperature = data.liquidLineTemperature;
        this.lltTimestamp = data.lltTimestamp;
        this.pressure = data.pressure;
        this.spdTimestamp = data.spdTimestamp;
        this.meliSerialNumber = data.meliSerialNumber;
        this.status = data.status;
        this.fanStatus = data.fanStatus;

    }
}

export class SensorCurrentStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: SensorCurrentStatusResponseDetails[];
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new SensorCurrentStatusResponseDetails(value));
        this.requestData = data.requestData;
    }
}

export class HVACStatusResponseDetails {
    meliSerialNumber: string;
    status: string;

    constructor(data) {
        this.meliSerialNumber = data.meliSerialNumber;
        this.status = data.status;
    }
}

export class HVACStatusResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: HVACStatusResponseDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new HVACStatusResponseDetails(value));
        this.requestData = data.requestData;
    }
}
