import { Email, Phone, Address } from 'src/app/shared/models/common.model';
import { InstallationReportDetails } from 'src/app/shared/models/inventory.model';
import { Context } from 'vm';
import { meliInfo } from 'src/app/shared/models/control.model';
// export class Address {
//   addressId: number;
//   addressType: string;
//   street: string;
//   city: string;
//   state: string;
//   zipcode: string;
//   constructor(data) {
//     this.addressId = data.addressId;
//     this.addressType = data.addressType;
//     this.street = data.street;
//     this.city = data.city;
//     this.state = data.state;
//     this.zipcode = data.zipcode;
//   }
// }

// export class PhoneAndEmail {
//   mobile: any[];
//   home: any[];
//   office: string[];

//   constructor(data) {
//     this.mobile = data.mobile;
//     this.home = data.home;
//     this.office = data.office;
//   }
// }

export class Meli {
  meliSerialNumbers: string;

  constructor(data) {
    this.meliSerialNumbers = data.meliSerialNumbers;
  }
}

export class Technician {
  technicianId: number;
  awsTechnicianId: string;
  technicianName?: any;
  technicianFirstName?: any;
  technicianLastName?: any;
  status: string;
  melis: Meli[];
  phone: Phone[];
  email: Email[];
  addresses: Address[];

  constructor(data) {
    this.technicianId = data.technicianId;
    this.awsTechnicianId = data.awsTechnicianId;
    this.technicianName = data.technicianName;
    this.technicianFirstName = data.firstName;
    this.technicianLastName = data.lastName;
    this.status = data.status;
    this.melis = data.melis && data.melis.length
      && data.melis.map((value) => new Meli(value));
    this.phone = data.phone && data.phone.length &&
      data.phone.map((value, index) => new Phone(value));
    this.email = data.email && data.email.length &&
      data.email.map((value, index) => new Email(value));
    this.addresses = data.addresses && data.addresses.length &&
      data.addresses.map((value, index) => new Address(value));
  }
}

export class TechniciansDetail {
  page: number;
  size: number;
  technicianList: Technician[];
  currentPageNumber: number;
  noOfElementsInCurrentPage: number;
  totalPage: number;
  totalNumberOfElements: number;

  constructor(data) {
    this.page = data.page;
    this.size = data.size;
    this.technicianList = data.content && data.content.length
      && data.content.map((value) => new Technician(value));
    this.currentPageNumber = data.currentPageNumber;
    this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
    this.totalPage = data.totalPage;
    this.totalNumberOfElements = data.totalNumberOfElements;
  }
}

export class TechnicianInfo {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: TechniciansDetail;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData = data.responseData && new TechniciansDetail(data.responseData);
    this.requestData = data.requestData;
  }
}

export class DeleteTechnicianDetails {
  technicianId: number;
  technicianName: string;
  status: string;
  description: string;

  constructor(data) {
    this.technicianId = data.tech_id;
    this.technicianName = data.tech_name;
    this.status = data.status;
    this.description = data.desc;
  }
}

export class DeleteTechnicianResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  deleteTechnicianDetails: DeleteTechnicianDetails[];
  requestData: number[];

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.deleteTechnicianDetails = data.responseData && data.responseData.length &&
      data.responseData.map((value, index) => new DeleteTechnicianDetails(value));
    this.requestData = data.requestData;
  }
}

export class CreateTechnicianDetails {
  message: string;
  role: string;
  id: string;
  awsTechnicianId: string;

  constructor(data) {
    this.message = data.msg;
    this.role = data.role;
    this.id = data.id;
    this.awsTechnicianId = data.aws_technician_id;
  }
}

export class CreateTechnicianResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  createTechnicianDetails: CreateTechnicianDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.createTechnicianDetails = data.responseData && new CreateTechnicianDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class JobDetails {
  jobId: number;
  jobType: string;
  jobDescription?: string;
  jobState: string;
  jobProgress: number;
  creationTime: string;
  resolvedTime?: string;
  alertId?: string;
  alertName?: string;
  alertTime?: string;
  meliSerialNumber: string;
  technician: string;
  contractor: string;
  consumer?: string;
  consumerAddress?: string;
  phone?: Phone;
  email?: Email;
  comments: string[];
  context:string;
  installationReports: InstallationReportDetails;
   submitinstallationReport?:any;
  totalCompletedJobs: number;
  totalInstalledJobs: number;
  totalAlertInvestigationJobs: number;
  constructor(data) {
    this.jobId = data.job_id;
    this.jobType = data.jobType;
    this.jobDescription = data.job_description;
    this.jobState = data.job_state;
    this.jobProgress = data.jobProgress;
    this.creationTime = data.creation_time;
    this.resolvedTime = data.resolved_time;
    this.alertId = data.alert_id;
    this.alertName = data.alert_name;
    this.alertTime = data.alert_time;
    this.meliSerialNumber = data.meliSerialNumber;
    this.technician = data.technician;
    this.contractor = data.contractor;
    this.consumer = data.consumer;
    this.consumerAddress = data.consumer_address;
    this.phone = data.phone && new Phone(data.phone);
    this.email = data.email && new Email(data.email);
    this.context=data.context && data.context.meliInfo && data.context.meliInfo.meliType;
    this.comments = data.comments;
    this.installationReports = data.installationReport && new InstallationReportDetails(data.installationReport);
    this.submitinstallationReport = data.installationReport ;
    this.totalCompletedJobs = data.totalCompletedJobs;
    this.totalInstalledJobs = data.totalInstalledJobs;
    this.totalAlertInvestigationJobs = data.totalAlertInvestigationJobs;
  }
}

export class TechnicianJobHistoryDetails {
  page: number;
  size: number;
  jobList: JobDetails[];
  currentPageNumber: number;
  noOfElementsInCurrentPage: number;
  totalPage: number;
  totalNumberOfElements: number;

  constructor(data) {
    this.page = data.page;
    this.size = data.size;
    this.jobList = data.content && data.content.length &&
      data.content.map((value, index) => new JobDetails(value));
    this.currentPageNumber = data.currentPageNumber;
    this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
    this.totalPage = data.totalPage;
    this.totalNumberOfElements = data.totalNumberOfElements;
  }
}

export class TechnicianJobHistoryRequestData {
  page: number;
  size: number;
  technicianId: string;
  status?: any;
  fromDate?: any;
  toDate?: any;

  constructor(data) {
    this.page = data.page;
    this.size = data.size;
    this.technicianId = data.technicianId;
    this.status = data.status;
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
  }
}

export class TechnicianJobHistoryResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  technicianJobHistoryDetails: TechnicianJobHistoryDetails;
  technicianJobHistoryRequestData: TechnicianJobHistoryRequestData;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.technicianJobHistoryDetails = data.responseData && new TechnicianJobHistoryDetails(data.responseData);
    this.technicianJobHistoryRequestData = data.requestData && new TechnicianJobHistoryRequestData(data.requestData);
  }
}

// export class Email {
//   id: number;
//   type: string;
//   email: string;

//   constructor(data) {
//     this.id = data.id;
//     this.type = data.type;
//     this.email = data.email;
//   }
// }

// export class Phone {
//   id: number;
//   type: string;
//   phone: string;
//   countryCode?: any;

//   constructor(data) {
//     this.id = data.id;
//     this.type = data.type;
//     this.phone = data.phone;
//     this.countryCode = data.countryCode;
//   }
// }

export class EditTechnicianDetails {
  technicianId: number;
  awsTechnicianId: string;
  technicianName: string;
  technicianFirstName?: any;
  technicianLastName?: any;
  melis: any[];
  phone: Phone[];
  email: Email[];
  addresses: any[];

  constructor(data) {
    this.technicianId = data.technicianId;
    this.awsTechnicianId = data.awsTechnicianId;
    this.technicianName = data.technicianName;
    this.technicianFirstName = data.firstName;
    this.technicianLastName = data.lastName;
    this.melis = data.melis;
    this.phone = data.phone && data.phone.length &&
      data.phone.map((value, index) => new Phone(value));
    this.email = data.email && data.email.length &&
      data.email.map((value, index) => new Email(value));
    this.addresses = data.addresses && data.addresses.length &&
      data.addresses.map((value, index) => new Address(value));
  }
}

export class EditTechnicianResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  editTechnicianResponse: EditTechnicianDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.editTechnicianResponse = new EditTechnicianDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class TechnicianBulkUploadResponseDetails {
  message: string;
  validRecordsCount: number;
  inValidRecordsCount: number;
  info: any[];

  constructor(data) {
    this.message = data.Message;
    this.validRecordsCount = data.valid_records_count;
    this.inValidRecordsCount = data.in_valid_records_count;
    this.info = data.info;
  }

}

export class TechnicianBulkUploadResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  technicianBulkUploadResponseDetails: TechnicianBulkUploadResponseDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.technicianBulkUploadResponseDetails = data.responseData && 
      new TechnicianBulkUploadResponseDetails(data.responseData);
    this.requestData = data.requestData;
  }

}
