import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guards
import { AuthGuard } from 'src/app/core/guards/auth.guard';

// Components
import { AdminComponent } from 'src/app/layout/admin/admin.component';
//import { AdvancediagnosticComponent } from 'src/app/modules/alertdiscovery/pages/advancediagnostic/advancediagnostic.component';
import { UnsubscribeComponent } from 'src/app/modules/unsubscribe/pages/unsubscribe/unsubscribe.component';

// Constants
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/alerts',
    pathMatch: 'full'
  },
   {
    path: 'unsubscribe',
    component:UnsubscribeComponent,
    //loadChildren: () => import('src/app/modules/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
    //loadChildren: './modules/auth/auth.module#AuthModule'
    data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
  },
  {
    path: '',
    component: AdminComponent,
    data: { portalName: ROLE_CONSTANTS.CONTRACTOR },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'alerts',
        loadChildren: () => import('src/app/modules/alerts/alerts.module').then(m => m.AlertsModule),
        //loadChildren: './modules/alerts/alerts.module#AlertsModule',
        data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      },
      // {
      //   path: 'inventory',
      //   loadChildren: './modules/inventory/inventory.module#InventoryModule',
      //   data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      // },
      {
        path: 'customers',
        loadChildren: './modules/customer/customer.module#CustomerModule',
        data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      },
      {
        path: 'technicians',
        loadChildren: () => import('src/app/modules/technician/technician.module').then(m => m.TechnicianModule),
        data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      },
      {
        path: 'meli/:meliSerialNumber',
        loadChildren: () => import('src/app/modules/meli/meli.module').then(m => m.MeliModule),
        //loadChildren:'src/app/modules/meli/meli.module#MeliModule',
        data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      },
      // {
      //   path: 'inventory',
      //   loadChildren: () => import('src/app/modules/inventory/inventory.module').then(m => m.InventoryModule),
      //   data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      // },
      {
        path: 'reports',
        loadChildren: () => import('src/app/modules/reports/reports.module').then(m => m.ReportsModule),
        //loadChildren: './modules/alerts/alerts.module#AlertsModule',
        data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('src/app/modules/auth/auth.module').then(m => m.AuthModule),
    //loadChildren: './modules/auth/auth.module#AuthModule'
    data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
  },
  {
    path: 'alertdiscovery',
    loadChildren: () => import('src/app/modules/alertdiscovery/alertdiscovery.module').then(m => m.AlertdiscoveryModule),
    //loadChildren: './modules/auth/auth.module#AuthModule'
    data: { portalName: ROLE_CONSTANTS.CONTRACTOR }
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)], // available app routes
  // exporting RouterModule makes router directives available for use in the AppModule components
  exports: [RouterModule]
})
export class ContractorRoutingModule { }
