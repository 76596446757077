import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';

// Constants
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandlePrimaryActionButton = new EventEmitter();
  @Output() emitHandleSecondaryActionButton = new EventEmitter();
  @Output() emitHandleEdit = new EventEmitter();
  @Input() modalTitle: string;
  @Input() modalType: string;
  @Input() details: any;
  @Input() isModalFromCreatePage = false;
  @Input() isLoading = false;
  @Input() isActionButtonLoading = false;
  @Input() isActionButtonDisabled = false;
  @Input() isShowDelete = true;
  @Input() isSHowEdit = true;
  @Input() isShowCrossMark = true;
  @Input() portalName: string;
  commonConstants = COMMON_CONSTANTS;
  roleConstants = ROLE_CONSTANTS;
  currentHVACInfoViewIndex: number;

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit() {
  }

  handleHVACInfoView(index: number) {
    this.currentHVACInfoViewIndex = index;
  }

  handleHVACIndex(index: number, arr: Array<object>) {
    const array = JSON.parse(JSON.stringify(arr));
    const splitArray = array.splice(0, (index + 1));
    const count = splitArray.filter(value => value['assetUnitType'] === 'indoor').length;

    return count;
  }

  handlePrimaryActionButton() {
    this.emitHandlePrimaryActionButton.emit();
  }

  handleSecondaryActionButton() {
    this.emitHandleSecondaryActionButton.emit();
  }

  handleEdit() {
    // debugger
    this.emitHandleEdit.emit();
    // this.emitHandleEdit.emit(this.isSHowEdit);
  }

  handleRemoveAddressLinesCombinator(address: string) {
    return this.helperService.handleRemoveAddressLinesCombinator(address);
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  trackByAssetUnitTypeId(index: number, item: any): string {
    return item ? item.assetUnitTypeId : '';
  }

}
