import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Constants
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';

@Component({
  selector: 'app-guide-modal',
  templateUrl: './guide-modal.component.html',
  styleUrls: ['./guide-modal.component.scss']
})
export class GuideModalComponent implements OnInit {

  @Input() title: string;
  @Input() content: string;
  @Input() isFirstSiteTour: boolean;
  @Input() isLastSiteTour: boolean;
  @Output() emitHandleSiteTourAction = new EventEmitter<string>();
  @Output() emitCloseModal = new EventEmitter<any>();
  commonConstants = COMMON_CONSTANTS;

  constructor() { }

  ngOnInit() {
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  handleSiteTourAction(type: string) {
    this.emitHandleSiteTourAction.emit(type);
  }

}
