import { Component, Input, OnInit } from '@angular/core';
//import { environment as mduEnvironment } from 'projects/mdu/src/environments/environment';
import * as mapboxgl from 'mapbox-gl';
import { SharedService } from '../../services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from '../../constants/toaster.enum';
import { COMMON_CONSTANTS, MAP_STATUS } from '../../constants/commom.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert, JobTechnicianInfo } from 'src/app/shared/models/alerts.model';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import * as momenttimezone from 'moment-timezone';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { environment as channelEnvironment } from 'projects/channel/src/environments/environment';
import { environment as mduEnvironment } from 'projects/mdu/src/environments/environment';
import { InstallationReportDetails, InstallationReportResponse } from '../../models/inventory.model';
import * as moment from 'moment';
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
  map: mapboxgl.Map;
  @Input() mapAddress: any;  //getting the value from which page we are sending like alerts ,controls,properties
  @Input() checkedValue: any; //getting values based  on checkbox clicked in alert and control pages
  geojson = {
    "type": "FeatureCollection",
    "features": []
  };
  markers: any = [];
  tempdata: any;
  point: any = "points"
  mouse: any;
  isShowAssignTechnicianModal: boolean;
  isShowReportViewModal: boolean;
  modalTitle: string;
  alertItem: Alert;
  preseasonViewId: any;
  installationReportDetails: InstallationReportDetails;
  isInstallationReportModal: boolean;
  portalName: string;
  roleConstants = ROLE_CONSTANTS;
  mapinfo: any;
  boardTypeInfo: any;
  isShowLoading: boolean;
  isShowPerformanceBoardDetailsModal: boolean;
  meliSerialNo: any;
  constructor(private sharedService: SharedService, public helperService: HelperService, private router: Router, private activatedRoute: ActivatedRoute) { }
  ngOnInit() {

    this.getAddressForMap();
    this.isShowLoading=true;
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });
     if (this.portalName === ROLE_CONSTANTS.CONTRACTOR) {
           mapboxgl.accessToken = contractorEnvironment.mapboxKey
     }
     else if (this.portalName === ROLE_CONSTANTS.CHANNEL)
     {
      mapboxgl.accessToken = channelEnvironment.mapboxKey
     }
     else if (this.portalName === ROLE_CONSTANTS.MDU)
     {
      mapboxgl.accessToken = mduEnvironment.mapboxKey
     }
    this.map = new mapboxgl.Map({
      container: 'mapContainer',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-100.5635578, 36.2104063],
      zoom: 4
    });
    this.map.on('load', this.showMapdata);
    this.map.addControl(new mapboxgl.NavigationControl());
  }

  //on changing the checkbox value the function will be called and excuted for the related checkbox value sent from alert page or from controlpage
  ngOnChanges() {
    console.log(this.checkedValue)
    this.geojson.features = [];
    let a = [];
    let copy = JSON.parse(this.tempdata);
    this.mapinfo = copy;
    console.log(copy)
    if (this.checkedValue == MAP_STATUS.ALL) {
      if (this.portalName == ROLE_CONSTANTS.CONTRACTOR && this.mapAddress == "alerts") {
        this.mapinfo.features.forEach(data => {
          data.properties = {}
          data.properties.data = [];
          data.propertiesList.forEach(element => {
            data.properties.data.push(element);
          });
        });
        this.geojson.features = this.mapinfo.features
      }
      else {
        this.geojson.features = this.mapinfo ? this.mapinfo.features : [];
      }
      this.updateMap();
    }
    else if (this.checkedValue == MAP_STATUS.MINOR) {
      if (this.portalName == ROLE_CONSTANTS.CONTRACTOR && this.mapAddress == "alerts") {
        for (let i in this.mapinfo.features) {
          const allEqual = this.mapinfo.features[i].propertiesList.every(v => v.type === "Major")
          if (allEqual) {
            this.mapinfo.features[i].propertiesList = []
          }
          if (this.mapinfo.features[i].propertiesList.length == 0) {
            this.mapinfo.features[i] = []
          }
        }
        console.log(this.mapinfo)
        this.mapinfo.features.forEach(data => {
          data.properties = {}
          data.properties.data = [];
          data.alertTypeIcon = "Minor"
          data.propertiesList && data.propertiesList.forEach(element => {
            if (element.type == MAP_STATUS.MINOR || element.type == MAP_STATUS.FAULTS_DETECTED) {
              data.properties.data.push(element);
            }
          });
        });
        this.geojson.features = this.mapinfo.features
      }
      else if (this.portalName == ROLE_CONSTANTS.MDU && this.mapAddress == "alerts") {
        this.mapinfo.features.forEach(data => {
          if (data.properties.minorAlertCount != 0) {
            data.properties.type = "Minor";
            data.properties.count = data.properties.minorAlertCount
            data.properties.description = `${data.properties.minorAlertCount} Minor Alerts`
            a.push(data);
          }
        });
        this.geojson.features = a ? a : []
      }
      else {
        this.mapinfo.features.forEach(data => {
          if (data.properties.type == MAP_STATUS.MINOR || data.properties.type == MAP_STATUS.FAULTS_DETECTED) {
            a.push(data);
          }
        });
        this.geojson.features = a ? a : []
      }
      this.updateMap();
    }
    else if (this.checkedValue == MAP_STATUS.MAJOR) {
      if (this.portalName == ROLE_CONSTANTS.CONTRACTOR && this.mapAddress == "alerts") {
        var index = this.mapinfo.features.findIndex(function (o) {
          return o.alertTypeIcon === 'Minor';
        })
        if (index !== -1) this.mapinfo.features.splice(index, 1);
        //console.log(this.mapinfo)
        this.mapinfo.features.forEach(data => {
          data.properties = {}
          data.properties.data = [];
          if (data.alertTypeIcon != "Minor") {
            data.alertTypeIcon = "Major"
            data.propertiesList.forEach(element => {
              if (element.type == MAP_STATUS.MAJOR || element.type == MAP_STATUS.CRIICAL_FAULTS_DETECTED) {
                data.properties.data.push(element);
              }
            });
          }

        });
        this.geojson.features = this.mapinfo.features;
        //console.log(this.geojson.features)
      }
      else if (this.portalName == ROLE_CONSTANTS.MDU && this.mapAddress == "alerts") {
        this.mapinfo.features.forEach(data => {
          if (data.properties.majorAlertCount != 0) {
            data.properties.type = "Major";
            data.properties.count = data.properties.majorAlertCount
            data.properties.description = `${data.properties.majorAlertCount} Major Alerts`
            a.push(data);
          }
        });
        this.geojson.features = a ? a : []
      }
      else {
        this.mapinfo.features.forEach(data => {
          if (data.properties.type == MAP_STATUS.MAJOR || data.properties.type == MAP_STATUS.CRIICAL_FAULTS_DETECTED) {
            a.push(data);
          }
        });
        this.geojson.features = a ? a : [];
      }
      this.updateMap();

    }

    else {
      this.geojson.features = [];
      this.updateMap();

    }
  }

  //function to get the map data and assign it to the variable as geojson format for the map 
  getAddressForMap() {
    let queryParams: any = {
      type: this.mapAddress,
      timeZone: momenttimezone.tz.guess()
    };
    this.sharedService.mapList(queryParams).subscribe(res => {
      //console.log(res);
      this.isShowLoading=false;
      this.tempdata = JSON.stringify(res.responseData);
      if (res.responseData.features.length > 0) {
        res.responseData.features.forEach(element => {
          if (this.portalName == ROLE_CONSTANTS.CONTRACTOR) {
            if ((this.mapAddress == "alerts" || this.mapAddress =="customers") && element.geometry && element.alertTypeIcon != null) {
              element.propertiesList.forEach(edata => {
              });
              element.properties = {}
              element.properties.data = element.propertiesList
            }
          }
        });
      }
      this.geojson.features = res.responseData.features;
      this.showMapdata();
    }, (error) => {
      //this.showMap();
      const message = this.helperService.handleAPIErrors(error);
      this.helperService.showToaster(ToasterType.ERROR, message);
    });
  }

  //function to create a map and display a blank map without any data
  // showMap() {
  //   mapboxgl.accessToken = 'pk.eyJ1IjoidmVtdWxha2lzaGFua3VtYXIiLCJhIjoiY2tmcGhkZDZqMGw5ZTJwbGgzdGF5cWM5dyJ9.83dEdQ52eWr30X0rRoUcsA';
  //   this.map = new mapboxgl.Map({
  //     container: 'mapContainer',
  //     style: 'mapbox://styles/mapbox/streets-v11',
  //     center: [-100.5635578, 36.2104063],
  //     zoom: 4
  //   });
  //   this.map.on('load', this.showMapdata);
  // }

  //function to create a layers on the map and creating mouse hover an mouse leave effects on the mapicon on hover
  showMapdata() {
    this.map.addSource('points', {
      type: 'geojson',
      data: this.geojson,
      generateId: true
    })
    this.map.addLayer({
      'id': "points-azx",
      'type': 'circle',
      'source': 'points',
      'paint': {
        'circle-stroke-color': '#000',
        'circle-stroke-width': 1,
        'circle-color': '#000'
      }
    });
    this.showIcon()
    this.mouse = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: 25,
      className: 'popups'
    })
    this.map.on('mousemove', "points-azx", (e) => {
      //console.log(e)
      //let result = this.map.queryRenderedFeatures(e.point, { layers: ["points"] })
      //console.log(result, e.features)
      this.map.getCanvas().style.cursor = 'pointer';
      var coordinates = e.features[0].geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
      if (e.features && this.mapAddress !== 'installation') {
        const text = this.getPopupInfo(e.features)
        this.mouse.setLngLat(e.lngLat).setHTML(text).addTo(this.map)
        this.getClicked(e.features);
      }
    })
  }
  showIcon() {
    console.log(this.geojson)
    this.markers = []
    this.geojson.features.forEach(element => {
      if ((element.alertTypeIcon != null || element.properties.type != null) && element.geometry != null) {
        let icon = 'url(../../assets/images/mapicon_property.png)'
        if (element.alertTypeIcon == MAP_STATUS.CRIICAL_FAULTS_DETECTED || 
          element.alertTypeIcon == MAP_STATUS.MAJOR || 
          element.properties&&element.properties.type == MAP_STATUS.CRIICAL_FAULTS_DETECTED ||
           element.properties&&element.properties.type == MAP_STATUS.MAJOR) {
             console.log(element.properties)
             if(element.properties.data.some(value => value.meliType=='Cellular'))
             {
               if(element.properties.data[0].signalStrength=='BAD')
               {icon = 'url(../../assets/images/mapicon_cellular_critical_low.png)'
               }
              //  else if(element.properties.data[0].signalStrength.includes('Excellent'))
              //  {icon = 'url(../../assets/images/mapicon_cellular_critical_excellent.png)'
              //  }
                else if(element.properties.data[0].signalStrength=='GOOD')
               {icon = 'url(../../assets/images/mapicon_cellular_critical_excellent.png)'
               }
               else
               {icon = 'url(../../assets/images/mapicon_cellular_critical_low.png)'}
             }
             else{
                  icon = 'url(../../assets/images/mapicon_criticals.png)'
             }
          
        }
        else if (element.alertTypeIcon == MAP_STATUS.FAULTS_DETECTED || 
          element.alertTypeIcon == MAP_STATUS.MINOR || 
          element.properties&&element.properties.type == MAP_STATUS.FAULTS_DETECTED || 
          element.properties&&element.properties.type == MAP_STATUS.MINOR) {
             if(element.properties.data.some(value => value.meliType=='Cellular'))
             {
               if(element.properties.data[0].signalStrength=='BAD')
               {icon = 'url(../../assets/images/mapicon_cellular_critical_low.png)'
               }
              //  else if(element.properties.data[0].signalStrength.includes('Excellent'))
              //  {icon = 'url(../../assets/images/mapicon_cellular_critical_excellent.png)'
              //  }
                else if(element.properties.data[0].signalStrength=='GOOD')
               {icon = 'url(../../assets/images/mapicon_cellular_critical_excellent.png)'
               }
               else
               {icon = 'url(../../assets/images/mapicon_cellular_critical_low.png)'}
             }
             else{
                  icon = 'url(../../assets/images/mapicon_fault.png)'
             }
        }
        else if(element.alertTypeIcon == MAP_STATUS.DEFAULT)
        {
          if(this.mapAddress=='customers')
          {
            if(element.propertiesList[0].meliType=='Cellular'&& element.propertiesList[0].signalStrength=='GOOD'){
              icon = 'url(../../assets/images/mapicon_excellent.png)'
            }
            // if(element.propertiesList[0].meliType=='Cellular'&& element.propertiesList[0].signalStrength.includes('Good')){
            //   icon = 'url(../../assets/images/mapicon_good.png)'
            // }
            if(element.propertiesList[0].meliType=='Cellular'&& (element.propertiesList[0].signalStrength=='BAD'||element.propertiesList[0].signalStrength==''||element.propertiesList[0].signalStrength==null)){
              icon = 'url(../../assets/images/mapicon_weak.png)'
            }
          }
        }
        const el = document.createElement('div');
        el.className = 'marker';
        el.style.backgroundImage = icon;
        el.style.textAlign = 'center';
        if (this.mapAddress == 'installation' || this.mapAddress == 'properties' || (this.mapAddress == 'alerts' && this.portalName != this.roleConstants.CONTRACTOR)) {
          el.style.marginTop = "10px"
          el.innerHTML = "<p style='margin-top:12px;font-weight:bold;color:#ffffff'>" + element.properties.count + "</p>";
        }
        if ((this.mapAddress == "alerts" && this.portalName == ROLE_CONSTANTS.CONTRACTOR)|| this.mapAddress=="customers") {
          el.style.width = element.propertiesList[0].iconSize[0] + 'px';
          el.style.height = element.propertiesList[0].iconSize[1] + 'px';
        }
        else {
          el.style.width = element.properties.iconSize[0] + 'px';
          el.style.height = element.properties.iconSize[1] + 'px';
        }
        let marker = new mapboxgl.Marker(el)
          .setLngLat(element.geometry.coordinates)
          .addTo(this.map);
        this.markers.push(marker);
      }
    });
  }

  //updating the map based on the checkbox values
  updateMap() {
    console.log(this.markers);
    if (this.markers) {
      for (let i = this.markers.length - 1; i >= 0; i--) {
        this.markers[i].remove();
        this.mouse.remove();
        this.map.getSource("points").setData(this.geojson);
      }
    }
    this.showIcon();
  }

  //getclick 
  getClicked(data) {
    if (this.mapAddress == 'alerts') {
      if (this.portalName == ROLE_CONSTANTS.CONTRACTOR) {
        let clickdata = JSON.parse(data[0].properties.data);
        clickdata.forEach((info, index) => {
          document.getElementById('view-dashboard' + index).addEventListener('click', () => {
            this.navigateToRelaventPage(info.meliSerialNumber)
          })
          document.getElementById('view-dashboard' + index).addEventListener('click', () => {
            this.navigateToRelaventPage(info.meliSerialNumber)
          })
          document.getElementById('view-dashboards' + index).addEventListener('click', () => {
            //this.navigateToRelaventPage(info.meliSerialNumber)
            this.handleModalType('boardInfo', info.meliSerialNumber)
          })
          // document.getElementById('assign-tech' + index).addEventListener('click', () => {
          //   this.handleModalType('assign-technician', info)
          // })
        })

      }
    }
    else if (this.mapAddress == 'reports') {
      document.getElementById('test-report').addEventListener('click', () => {
        this.handleModalType('test-report', data)
      })
      document.getElementById('view-dashboard').addEventListener('click', () => {
        this.navigateToRelaventPage(data[0].properties.meliSerialNumber)
      })
      document.getElementById('install-report').addEventListener('click', () => {
        this.handleModalType('installation-report', data)
      })
    }
    else if (this.mapAddress == 'customers') {
      let clickdata = JSON.parse(data[0].properties.data);
        clickdata.forEach((info, index) => {
      document.getElementById('view-dashboard'+index).addEventListener('click', () => {
        this.navigateToRelaventPage(info.meliSerialNumber)
      })
      // document.getElementById('un-assign').addEventListener('click', () => {
      //   this.handleModalType('un-assign', info)
      // })
    })
    }


  }

  //get relavent popup information based on the pages
  getPopupInfo(result) {
    console.log("ds", result)
    if (this.mapAddress == "alerts") {

      
      if (this.portalName == ROLE_CONSTANTS.CONTRACTOR) {
        
        let popupdata = JSON.parse(result[0].properties.data);
        console.log(popupdata);
        let data = `<div class=' default ${popupdata[0].type}' >`;
        popupdata.forEach((info, index) => {
          let icon='';
          if(info.meliType=='Cellular'&& info.signalStrength=='GOOD'){
             icon="<img src='assets/images/excellent.png'/>";
            }
            // if(info.meliType=='Cellular'&& info.signalStrength.includes('Good')){
            //   icon = "<img src='assets/images/good.png'/>";
            // }
            if(info.meliType=='Cellular'&& (info.signalStrength=='BAD'||info.signalStrength==''||info.signalStrength==null)){
              icon = "<img src='assets/images/weak.png'/>"
            }
          data += `<div class="mt20"><p style='font-weight:bold'> ${info.description} 
        <span style='float:right;font-size:0.9rem;font-weight:100'> ${info.displayTime}</span></p> 
          <p style='cursor:pointer;text-decoration:underline' id='view-dashboards${index}'> ${info.meliSerialNumber}</p>
          <div style='display:flex;justify-content: space-between'>
          <p style='cursor:pointer;text-decoration:underline' id='view-dashboard${index}'>Dashboard</p>
            <span>${icon}</span>
          </div></div>`
        });
        data += "</div>"
        //console.log(data)
        // <p style='cursor:pointer;text-decoration:underline' id='assign-tech${index}'>Re/Asssign Techinician</p></div>`;

        return data;
      }
      else {
        return `<div class='default ${result[0].properties.type}'><p style='font-weight:bold'> ${result[0].properties.description} </p> 
        <p> ${result[0].properties.propertyName}</p>`
      }

    }
    else if (this.mapAddress == 'reports') {
      //<p style='cursor:pointer;text-decoration:underline' id='test-report'>Test Report</p>-- add this to 487 line when preseason is enabled
      return `<div class='default ${result[0].properties.type}'><p style='font-weight:bold'> ${result[0].properties.description} </p> 
        <p style='cursor:pointer;text-decoration:underline' id='view-dashboard'> ${result[0].properties.meliSerialNumber}</p>
        <div style='display:flex;justify-content: space-between'>
         
        <p style='cursor:pointer;text-decoration:underline' id='install-report'>Installation Report</p></div>`;
    }
    else if (this.mapAddress == 'customers') {
     
       let popupdata = JSON.parse(result[0].properties.data);
       let data=`<div class='default ${popupdata[0].type}'>`;
        popupdata.forEach((info, index) => {
           let icon='';
          if(info.meliType=='Cellular'&& info.signalStrength=='GOOD'){
             icon="<img src='assets/images/excellent.png'/>";
            }
            // if(info.meliType=='Cellular'&& info.signalStrength.includes('Good')){
            //   icon = "<img src='assets/images/good.png'/>";
            // }
            if(info.meliType=='Cellular'&& (info.signalStrength=='BAD'||info.signalStrength==''|| info.signalStrength==null)){
              icon = "<img src='assets/images/weak.png'/>"
            }
       data += `<div class='mb10 mt10'><p style='font-weight:bold' class='text-overflow_ellipsis max-width_140'> ${info.description} </p> 
        <p style='cursor:pointer'>Installed: ${info.installedTime || '-'}</p>
        <p style='cursor:pointer;text-decoration:underline;display:flex; justify-content:space-between'>
        <span id='view-dashboard${index}'>${info.meliSerialNumber}</span>
        <span>${icon}</span>
        </p> </div>`;
        });
        data +="</div>"
      // if (result[0].properties.installedTime != null) {
      //   data += `<p style='cursor:pointer;text-decoration:underline' id='un-assign'>Un-Asssign Board</p></div>`;
      // }

      return data;
    }

    else if (this.mapAddress == 'properties') {
      return `<div class='default ${result[0].properties.type}'><p style='font-weight:bold'>  ${result[0].properties.propertyName}</p>`
    }

    else if (this.mapAddress == 'installation') {
      return '';
    }
    else {
      return `<div class='default ${result[0].properties.type}'><p style='font-weight:bold'>  ${result[0].properties.description}</p> 
      <p style='cursor:pointer;text-decoration:underline'> ${result[0].properties.propertyName}</p>`
    }
  }

  //redirect to relavent page on click of dashboard 
  navigateToRelaventPage = (value) => {
    const meliSerialNo = value;
    localStorage.setItem('meliSerialNo', meliSerialNo);
    if (this.mapAddress == 'alerts') {
      this.router.navigate(['/meli/' + meliSerialNo], { queryParams: { fromPage: 'Alerts', isShowTabs: false } });
    }
    else {
      this.router.navigate(['/meli/' + meliSerialNo], { queryParams: { fromPage: 'Customers', isShowTabs: false } });
    }

  }

  //assign reassign fnctionality for alerts




  //togglefunctions for all
  toggleAssignTechnicianModal() {
    this.isShowAssignTechnicianModal = !this.isShowAssignTechnicianModal;
  }
  toggleReportViewModal() {
    this.isShowReportViewModal = !this.isShowReportViewModal;
  }
  toggleInstallationReportModal() {
    this.isInstallationReportModal = !this.isInstallationReportModal;
  }
  togglePerformanceBoardDetailsModal()
  {
   this.isShowPerformanceBoardDetailsModal=!this.isShowPerformanceBoardDetailsModal 
  }

  //handle functions
  handleModalType(type: string, value?: any) {
    if (type === 'assign-technician') {
      this.modalTitle = 'Assign Technician';

      let jobinfo = new JobTechnicianInfo(value);
      jobinfo.jobId = value.jobId;
      let data = new Alert(value);
      data.meliSerialNo = value.meliSerialNumber;
      data.alertId = value.alertId;
      data.timeStamp = value.timestamp;
      data.jobTechnicianInfo = jobinfo
      this.alertItem = data;
      console.log(this.alertItem)
      this.toggleAssignTechnicianModal();
    }
    else if (type === 'test-report') {
      this.preseasonViewId = value[0].properties.indoorEquipmentId;
      console.log(this.preseasonViewId)
      this.toggleReportViewModal();
    }
    else if (type === 'installation-report') {
      this.modalTitle = 'Installation Report';
      this.getInstallationReport(value);
    }
    else if(type =="boardInfo")
    {
        this.meliSerialNo = value;
        this.togglePerformanceBoardDetailsModal()
    }
    else if (type == 'un-assign') {
      this.unAssignBoard(value)
    }
  }


  //call installationreport api
  getInstallationReport(info) {
    let queryParams = { meliSerialNumber: info[0].properties.meliSerialNumber,timeZone: momenttimezone.tz.guess() }
    this.sharedService.getInstallationReport(queryParams).subscribe(
      (res:InstallationReportResponse) => {
        this.installationReportDetails = res.responseData;
        if(this.installationReportDetails.meliType)
        {
          this.boardTypeInfo = this.installationReportDetails.meliType
        }
        else{
          if(this.installationReportDetails.wifiModel)
          {
            this.installationReportDetails.wifiModel.ssid?this.boardTypeInfo = 'Wi-Fi':this.boardTypeInfo="Cellular";
          }
          else{
            this.boardTypeInfo="Cellular"
          }
        }
        console.log(this.installationReportDetails);
        this.toggleInstallationReportModal();
      }
    ), (error) => {
      const message = this.helperService.handleAPIErrors(error);
      this.helperService.showToaster(ToasterType.ERROR, message);
    }
  }

  unAssignBoard(info) {
    this.helperService.setUnassignBoard(info);
  }

}


    //this.map.removeLayer("points");
    //this.map.removeSource("points");
    //"points" = Math.random().toString(5);
    // this.map.addControl(
    //   new MapboxGeocoder({
    //     accessToken: mapboxgl.accessToken,
    //     mapboxgl: mapboxgl
    //   })
    // );
  //img_p = require('src/assets/images/mapicon_property.png');
  // img_c = require('src/assets/images/mapicon_critical.png');
  //img_f = require('src/assets/images/mapicon_fault.png');


    //code for normal map
    // var mapboxClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
    // this.geojson.forEach(element => {
    //   mapboxClient.geocoding.forwardGeocode({
    //     query: element,
    //     autocomplete: false,
    //     limit: 1
    //   }).send().then((response) => {
    //     if (
    //       response &&
    //       response.body &&
    //       response.body.features &&
    //       response.body.features.length
    //     ) {

    //       let feature = response.body.features[0];
    //       new mapboxgl.Marker().setLngLat(feature.center).addTo(this.map);
    //     }

    //   });
    // });

    //   type: 'FeatureCollection',
    //   features: [
    // {

    //   properties: {
    //     description: '3 Critical Faults Detected',
    //     iconSize: [32, 44],
    //     count: '3',
    //     type: 'critical'
    //   },
    //   geometry: {
    //     type: 'Point',
    //     coordinates: [-66.324462890625, -16.024695711685304]
    //   }
    // },
    // {

    //   properties: {
    //     description: '39 Fault Detected',
    //     iconSize: [32, 44],
    //     count: '39',
    //     type: 'fault'
    //   },
    //   geometry: {
    //     type: 'Point',
    //     coordinates: [-61.2158203125, -15.97189158092897]
    //   }
    // },
    // {

    //   properties: {
    //     description: '22 Critical Faults Detected',
    //     iconSize: [32, 44],
    //     count: '22',
    //     type: 'critical'
    //   },
    //   geometry: {
    //     type: 'Point',
    //     coordinates: [-63.29223632812499, -18.28151823530889]
    //   }
    // }
    //   ]
    // };

    // this.mapAddress = [];
    // this.propertiesList.forEach(element => {
    //   let address = this.handleAddressSelection(element.addresses, this.commonConstants.ADDRESS_TYPE_SERVICE, 'address');
    //   let info = address + ',' + element.addresses.city + ',' + element.addresses.state + ',' + element.addresses.zipcode
    //   this.mapAddress.push(info)
    // });