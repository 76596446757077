export enum SortingTypes {
    ASCENDING = 'ASC',
    DESCENDING = 'DESC'
}

export enum AlertSortingKeys {
    RESOLVED_TIME = 'RESOLVED_TIME',
    ALERT_TYPE = 'ALERT_TYPE',
    ALERT_NAME = 'ALERT_SEVERITY',
    TIME = 'OCCURED_TIME',
    STREET = 'ADDRESS',
    CITY = 'CITY',
    STATE = 'STATE_CODE',
    ASSIGN = 'JOB_ID',
    TECHNICIAN_NAME = 'TECHNICIAN_NAME'
}

export enum AlertSortingKeysMDU {
    RESOLVED_TIME = 'CLEARED_TIME',
    ALERT_TYPE = 'ALERT_TYPE',
    ALERT_NAME = 'ALERT_NAME',
    TIME = 'OCCURED_TIME',
    STREET = 'STREET',
    CITY = 'CITY',
    STATE = 'STATE',
    ASSIGN = 'ASSIGN'
}

export enum InvestigateSortingKeys {
    SERIAL_NUMBER = 'meli_serial_number',
    INSTALLED_ON = 'j.resolved_time',
    STATUS = 'ms.status',
    IN_INVENTORY_DATE = 'in_inventory_date',
    ASSIGNED_ON = 'created_time',
    TYPE = 'type'
}

export enum InstallationSortingKeys {
    SERIAL_NUMBER = 'meli_serial_number',
    INSTALLED_ON = 'j.resolved_time',
    STATUS = 'ms.status',
    TYPE = 'meli_type',
    CITY = 'ads.city',
    STATE = 'ads.state'
}

export enum PreSeasonSortingKeys {
    TEST_TIME = 'testScheduledTime',
    SEASON = 'season',
    TYPE = 'testType',
}

export enum propertySortingKeys {
    PROPERTY_NAME = "h.home_name",
    STREET = 'address',
    UNIT = 'unit_number',
    CITY = 'city',
    STATE = 'state_code',
    TYPE = 'm.meli_type',
    INSTALLED_ON = 'asm.installed_date',
    HVAC_INFO = 'ami.device_type',
    STATUS = 'STATUS',
}

export enum controlSortingKeys {
    PROPERTY_NAME = 'home_name',
    STREET = 'address',
    UNIT = 'unit_number',
    CITY = 'city',
    STATE = 'state_code',
    TEST_DATE = 'pre_season_test_date',
    TEST_STATUS = 'pre_season_test_result'
}

export enum MDUSortingKeys {
    TEST = 'test',
    TIME = 'OCCURED_TIME',
    RESOLVED_TIME = 'CLEARED_TIME',
    STREET = 'STREET',
    CITY = 'CITY',
    STATE = 'STATE',
    INSTALLED_ON = 'INSTALLED_ON'

}

export enum reportSortingKeys {
    PROPERTY_NAME = 'home_name',
    STREET = 'address',
    UNIT = 'unit_number',
    CITY = 'city',
    STATE = 'state_code',
    INSTALLED_ON = 'installed_date',
    TEST_DATE = 'pre_season_test_date',
    TEST_STATUS = 'pre_season_test_result',
    CUSTOMER = 'consumer_name'
}

export enum SortingConstants {
    TECHNICIAN = 'technician_name',
    STATUS = 'status'
}