import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, EventEmitter, ElementRef, QueryList } from '@angular/core';
import { FormArray, FormGroup, Validators, FormControl } from '@angular/forms';

// Services
import { SharedService } from 'src/app/shared/services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ChannelSharedService } from 'projects/channel/src/app/shared-channel/services/shared.service';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Constants
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';

// Models
import { CreateContractorResponse, ContractorDetails } from 'src/app/shared/models/contractor.modal';
import { ActivatedRoute } from '@angular/router';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { CommonSuccessResponse, IsEmailAlreadyExistsResponse } from 'src/app/shared/models/common.model';
import { BASE_URL_TYPES } from '../../constants/base-url-types.constant';

@Component({
  selector: 'app-create-contractor',
  templateUrl: './create-contractor.component.html',
  styleUrls: ['./create-contractor.component.scss']
})
export class CreateContractorComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Input() editContractorData: ContractorDetails;
  @Input() isEdit = false;
  @Input() isFromChannel = false;
  @Input() modalTitle: string;
  @Input() primaryEmail: any;
  @Output() emitHandleRefreshPage = new EventEmitter();
  @Output() emitHandleConfirmationSuccessModal = new EventEmitter();
  // @ViewChild('contractorRegisteredStreetAddress', { static: false }) contractorRegisteredStreetAddress: any;
  // @ViewChild('contractorBranchStreetAddress', { static: false }) contractorBranchStreetAddress: QueryList<ElementRef>;
  createContractor: FormGroup;
  companyLogoName: string;
  selectedCompanyLogo: File;
  isCreateContractorBtnLoading = false;
  isShowConfirmationModal = false;
  isShowContractorInfoModal = false;
  infoModalTitle = '';
  infoModalType = '';
  createContractorPayload: any;
  confirmationModalTitle = '';
  isConfirmationModalHasYesOrNoButton = false;
  confirmationModalLine1Content: any;
  confirmationModalLine2Content: any;
  document1Name: string;
  selectedDocument1: File;
  document2Name: string;
  selectedDocument2: File;
  inBusinessSinceValues = [];
  isCreateContractorFormReseted = false;
  commonConstants = COMMON_CONSTANTS;
  selectedBillingTab = 'credit-card';
  portalName: string;
  roleConstants = ROLE_CONSTANTS;
  isEmailAlreadyExistLoading = false;
  isToggleButtons = false;
  prevFormValues;
  @ViewChild('selectedImage', { static: false }) selectedImage: ElementRef;
  isLogoExist: boolean = false;
  logoName: any;
  isEnableSubmit: boolean=true;
  @Input() isFromdeepLink:boolean=false;
  isResendBtnLoading: boolean=false;
  isMajorChecked: boolean=true;
  isMinorChecked: boolean=true;
  isEmailChecked:boolean=true;
  isSMSChecked:boolean=true;
  alertNotification:any={
    majorAlertsEmail:true,
majorAlertsSms:true,
minorAlertsEmail:false,
minorAlertsSms:true
  };
  constructor(
    private sharedService: SharedService,
    private helperService: HelperService,
    private channelSharedService: ChannelSharedService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    console.log(this.primaryEmail, this.isFromdeepLink,"email");
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
        if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {

        } else if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
        } else if (data.portalName === ROLE_CONSTANTS.MDU) {

        }
      }
    });
    this.handleInBusinessSinceArray();
    // {
    //   value: this.primaryEmail ? this.primaryEmail : '',
    //   disabled: (this.primaryEmail || this.isEdit) ? true : false,
    // },
    this.createContractor = new FormGroup(
      {
        contractorName: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
        website: new FormControl(''),
        companyLogo: new FormControl(''),
        primaryEmail: new FormControl('', [Validators.required, this.helperService.validateIsEmail]),
        secondaryEmail: new FormControl('', [this.helperService.validateIsEmail]),
        contactPerson: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
        dealerType: new FormControl(
          {
            value: this.portalName === ROLE_CONSTANTS.CONTRACTOR ?
              ROLE_CONSTANTS.CONTRACTOR : ROLE_CONSTANTS.MDU,
            disabled: this.isEdit ? true : false,
          },
          Validators.required
        ),
        phone: new FormArray(
          [new FormGroup(
            {
              // countryCode: new FormControl('', Validators.required),
              phoneNumber: new FormControl('', [Validators.required, this.helperService.validatePhoneNumber]),
              type: new FormControl('Office', Validators.required)
            }
          )]
        ),
        notificatonsStatusModel: new FormGroup(
          {
          majorAlertsEmail:new FormControl(true),
          majorAlertsSms:new FormControl(true),
          minorAlertsEmail:new FormControl(true),
          minorAlertsSms:new FormControl(true)
        }
        ),
        registeredAddress: new FormGroup(
          {
            addressLine1: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            addressLine2: new FormControl(''),
            city: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            state: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            zipcode: new FormControl('', [Validators.required, this.helperService.validateOnlySpaces]),
            placeId: new FormControl(''),
            formattedAddress: new FormControl(''),
          }
        ),
        branchAddress: new FormArray(
          [new FormGroup(
            {
              addressLine1: new FormControl(''),
              addressLine2: new FormControl(''),
              city: new FormControl(''),
              state: new FormControl(''),
              zipcode: new FormControl(''),
              placeId: new FormControl(''),
              formattedAddress: new FormControl(''),
            }
          )]
        ),
        // creditCard: new FormGroup(
        //   {
        //     cardName: new FormControl(''),
        //     cardNumber: new FormControl(''),
        //     securityCode: new FormControl(''),
        //     zipcode: new FormControl('')
        //   }
        // ),
        // ach: new FormGroup(
        //   {
        //     accHolderName: new FormControl(''),
        //     accType: new FormControl(''),
        //     routing: new FormControl(''),
        //     accNumber: new FormControl('')
        //   }
        // ),
        inBusinessSince: new FormControl(''),
        noOfTechnicians: new FormControl(''),
        // document1: new FormControl(''),
        // document2: new FormControl('')
      }
    );

    if (this.isFromChannel) {
      this.createContractor.controls.dealerType.setValidators(Validators.required);
    }

    if (this.isEdit && this.editContractorData) {
      this.handleAutoFillForm();
    }

    if(this.isFromdeepLink)
    {
      if(this.primaryEmail)
      this.transferdeeplinkAutoFillForm();
    }
    else
    {
      this.primaryEmail=""
    }

    if(!this.isEdit)
    {
this.handlePersonalInfoAddButton('phone')
    }
  }

  transferdeeplinkAutoFillForm()
  {
    this.createContractor.patchValue({
      contractorName:this.primaryEmail.recipientName,
      primaryEmail:this.primaryEmail.email.email
    })
  }

  handleInBusinessSinceArray() {
    for (let index = COMMON_CONSTANTS.IN_BUSINESS_SINCE_START_YEAR;
      index <= new Date().getFullYear();
      index++) {
      this.inBusinessSinceValues.push(index);
    }
  }

  get phone() {
    return this.createContractor.get('phone') as FormArray;
  }

  get registeredAddress() {
    return this.createContractor.get('registeredAddress') as FormGroup;
  }

  get branchAddress() {
    return this.createContractor.get('branchAddress') as FormArray;
  }

  get creditCard() {
    return this.createContractor.get('creditCard') as FormGroup;
  }

  get ach() {
    return this.createContractor.get('ach') as FormGroup;
  }

   get notificatonsStatusModel() {
    return this.createContractor.get('notificatonsStatusModel') as FormGroup;
  }

  // handleTypeOnChange(value) {
  //   debugger
  // }

  handlePersonalInfoAddButton(type: string, defaultValue?: any) {
    //  debugger
    if (type === 'phone') {
       console.log(defaultValue)
      this.phone.push(new FormGroup({
        // countryCode: new FormControl(
        //   this.helperService.handleSelectDefaultValueForPhoneCodeDropdown(this.selectedCountryForPhoneCode, 'name'),
        //   Validators.required
        // ),
       
        phoneNumber: new FormControl(
          defaultValue && defaultValue.phoneNumber ? defaultValue.phoneNumber : null,[this.helperService.validatePhoneNumber]
        ),
        type: new FormControl(
           'Mobile',
          Validators.required
        )
      }));
    } else if (type === 'branch-address' && this.branchAddress.length < 3) {
      let addressLines;
      if (defaultValue) {
        addressLines = this.helperService.handleAddressLinesSplit(defaultValue.address);
      }
      this.branchAddress.push(new FormGroup({
        addressLine1: new FormControl(
          addressLines && addressLines[0] ? addressLines[0] : '',Validators.required
        ),
        addressLine2: new FormControl(
          addressLines && addressLines[1] ? addressLines[1] : ''
        ),
        city: new FormControl(
          defaultValue && defaultValue.city ? defaultValue.city : '',Validators.required
        ),
        state: new FormControl(
          defaultValue && defaultValue.state ? defaultValue.state : '',Validators.required
        ),
        zipcode: new FormControl(
          defaultValue && defaultValue.zipcode ? defaultValue.zipcode : '',Validators.required
        ),
        placeId: new FormControl(
          defaultValue && defaultValue.placeId ? defaultValue.placeId : ''
        ),
        formattedAddress: new FormControl(
          defaultValue && defaultValue.formattedAddress ? defaultValue.formattedAddress : ''
        )
      }));
       this.handleButtonsOnLengthChanges();
    }
   
  }

  handlePersonalInfoDeleteButton(type: string, index?: number, isAll = false) {
    if (type === 'phone') {
      if (isAll && this.phone) {
        this.phone.clear();
        this.handlePersonalInfoAddButton('phone');
        if (this.editContractorData && this.editContractorData.phone) {
          this.editContractorData.phone = [];
        }
      } else {
        this.phone.removeAt(index);
        if (this.editContractorData && this.editContractorData.phone) {
          this.editContractorData.phone.splice(index, 1);
        }
      }
    } else if (type === 'branch-address') {
      if (isAll && this.branchAddress) {
        this.branchAddress.clear();
        this.handlePersonalInfoAddButton('branch-address');
        if (this.editContractorData && this.editContractorData.addresses &&
          this.editContractorData.addresses.length) {
          this.editContractorData.addresses.map((value, addressIndex) => {
            if (value.type === 'branch') {
              this.editContractorData.addresses.splice(addressIndex, 1);
            }
          });
        }
      } else {
        this.branchAddress.removeAt(index);
        if (this.editContractorData && this.editContractorData.addresses &&
          this.editContractorData.addresses.length) {
          this.editContractorData.addresses.splice(index + 1, 1);
        }
      }
    }
    this.handleButtonsOnLengthChanges();
  }

  handlePatchEmailValues(flag: string) {
    let email;
    if (this.editContractorData && this.editContractorData.email && this.editContractorData.email.length) {
      this.editContractorData.email.find((value) => {
        if (value.type === flag) {
          email = value.email;
          return email;
        }
      });
    }
    return email;
  }


  handleAutoFillForm() {
    this.createContractor.patchValue({
      contractorName: this.editContractorData.contractorName,
      website: this.editContractorData.website,
      primaryEmail: this.handlePatchEmailValues(COMMON_CONSTANTS.EMAIL_PRIMARY),
      secondaryEmail: this.handlePatchEmailValues(COMMON_CONSTANTS.EMAIL_SECONDARY),
      contactPerson: this.editContractorData.contactPerson,
      dealerType: this.editContractorData.dealerType,
      inBusinessSince: this.editContractorData.operatingSince,
      noOfTechnicians: this.editContractorData.numOfTechnicians
    });

    if (this.editContractorData.logoName && this.editContractorData.logoName.length) {
      this.isLogoExist = true;
      console.log('patchlogo', this.editContractorData.logoName)
      this.logoName = this.editContractorData.logoName
    }
    if (this.handlePatchEmailValues(COMMON_CONSTANTS.EMAIL_PRIMARY)) {
      this.createContractor.controls.primaryEmail.disable();
    }

    if (this.editContractorData.phone && this.editContractorData.phone.length) {
      if(this.editContractorData.phone.length==1)
      {
        this.handlePersonalInfoAddButton('phone')
      }
      this.editContractorData.phone.map((value, index) => {
        if (index==1) {
           this.handlePersonalInfoAddButton('phone',value)
       
        } else if(index==0) {
          this.phone.patchValue([{
            phoneNumber: this.helperService.handleFormatPhoneNumber(value.phoneNumber),
            type: value.type
          }]);
        }
      }, this);
    }

    if (this.editContractorData.addresses && this.editContractorData.addresses.length) {
      this.editContractorData.addresses.map((value, index) => {
        if (index > 1) {
          this.handlePersonalInfoAddButton('branch-address', value);
        } else {
          if (value.type === COMMON_CONSTANTS.ADDRESS_TYPE_REGISTERED_CORPORATE) {
          
            this.registeredAddress.patchValue({
              addressLine1:value.address,
              addressLine2: value.addressLine2,
              city: value.city? value.city : '',
              state: value.state ? value.state : '',
              zipcode: value.zipcode ? value.zipcode : '' ,
              placeId: value.placeId,
              formattedAddress: value.formattedAddress,
            },);
          } else if (value.type === COMMON_CONSTANTS.ADDRESS_TYPE_BRANCH_ADDRESS) {
            // const addressLines = this.helperService.handleAddressLinesSplit(value.address);
            this.branchAddress.patchValue([{
              addressLine1:value.address,
              addressLine2: value.addressLine2,
              city: value.city ? value.city : '',
              state: value.state ? value.state : '',
              zipcode: value.zipcode ? value.zipcode : '',
              placeId: value.placeId,
              formattedAddress: value.formattedAddress,
            }]);
          }
        }
      }, this);
    }

    if (this.editContractorData && this.editContractorData.logoUrls &&
      this.editContractorData.logoUrls.length) {
      this.companyLogoName = this.editContractorData.logoUrls[0];
    }

    if (this.editContractorData && this.editContractorData.docUrl &&
      this.editContractorData.docUrl.length) {
      this.document1Name = this.editContractorData.docUrl[0];
      this.document2Name = this.editContractorData.docUrl[1] ? this.editContractorData.docUrl[1] : '';
    }

    if(this.editContractorData&&this.editContractorData.notificatonsStatusModel)
    {
      console.log(this.editContractorData)
      this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:this.editContractorData.notificatonsStatusModel.majorAlertsEmail,
          majorAlertsSms:this.editContractorData.notificatonsStatusModel.majorAlertsSms,
          minorAlertsEmail:this.editContractorData.notificatonsStatusModel.minorAlertsEmail,
          minorAlertsSms:this.editContractorData.notificatonsStatusModel.minorAlertsSms
      })

      this.editAlertNotification()
    }

    // debugger
    this.prevFormValues = this.createContractor.getRawValue();

  }

  // ngAfterViewInit() {
  //   // this.handlePlacesAutoComplete();
  // }

  handlePlacesAutoComplete(element: ElementRef, type: string, index?: number) {
    this.helperService.handleAddress(element, type, index);
    const addressListener = this.helperService.setListenerForAddressObject().subscribe(
      (response: any) => {
        if (response.type === 'registered-address') {
          this.registeredAddress.patchValue({
            addressLine1: response.street,
            city: response.city,
            state: response.state,
            zipcode: response.zipcode,
            placeId: response.placeId,
            formattedAddress: response.formattedAddress,
          });
        } else if (response.type === 'branch-address') {
          this.branchAddress.at(response.index).patchValue({
            addressLine1: response.street,
            city: response.city,
            state: response.state,
            zipcode: response.zipcode,
            placeId: response.placeId,
            formattedAddress: response.formattedAddress,
          });
        }
        addressListener.unsubscribe();
      }, (error) => {
        addressListener.unsubscribe();
      }
    );

  }

  handleOnChangeBranchAddress(currentBranchAddress: FormGroup) {
    if (currentBranchAddress.get('addressLine1').value ||
      currentBranchAddress.get('addressLine2').value ||
      currentBranchAddress.get('city').value ||
      currentBranchAddress.get('state').value ||
      currentBranchAddress.get('zipcode').value) {
      currentBranchAddress.get('addressLine1').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      // currentBranchAddress.get('addressLine2').setValidators(Validators.required);
      currentBranchAddress.get('city').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBranchAddress.get('state').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBranchAddress.get('zipcode').setValidators([Validators.required, this.helperService.validateOnlySpaces]);
      currentBranchAddress.patchValue({
        addressLine1: currentBranchAddress.get('addressLine1').value ? currentBranchAddress.get('addressLine1').value : '',
        // addressLine2: currentBranchAddress.get('addressLine2').value ? currentBranchAddress.get('addressLine2').value : '',
        city: currentBranchAddress.get('city').value ? currentBranchAddress.get('city').value : '',
        state: currentBranchAddress.get('state').value ? currentBranchAddress.get('state').value : '',
        zipcode: currentBranchAddress.get('zipcode').value ? currentBranchAddress.get('zipcode').value : ''
      });
      currentBranchAddress.markAllAsTouched();
    } else {
      currentBranchAddress.get('addressLine1').clearValidators();
      // currentBranchAddress.get('addressLine2').clearValidators();
      currentBranchAddress.get('city').clearValidators();
      currentBranchAddress.get('state').clearValidators();
      currentBranchAddress.get('zipcode').clearValidators();
      currentBranchAddress.reset();
    }
  }

  handleOnSelectFiles(event, flag: string) {
    if (flag === 'company-logo') {
      this.isLogoExist = false;
      // console.log(event.target.files[0].type)
      if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {

        this.selectedCompanyLogo = event.target.files[0] as File;
        this.companyLogoName = event.target.files[0].name;

        const reader = new FileReader();

        reader.onload = (e) => {
          this.selectedImage.nativeElement.src = e && e['target'] && e['target']['result'];
        }
        reader.readAsDataURL(event.target.files[0]); // convert to base64 string
      }
      else {
        const message = "Selected image should be PNG/JPEG format";
        this.helperService.showToaster(ToasterType.ERROR, message);
        this.selectedCompanyLogo = null;
        this.companyLogoName = '';
        this.createContractor.get('companyLogo').patchValue('');
      }

    } else if (flag === 'document-1') {
      this.selectedDocument1 = event.target.files[0] as File;
      this.document1Name = event.target.files[0].name;
    } else if (flag === 'document-2') {
      this.selectedDocument2 = event.target.files[0] as File;
      this.document2Name = event.target.files[0].name;
    }
  }

  handleRemoveSelectedCompanyLogo() {
    if(this.isEdit && this.editContractorData.logoUrls[0]!=null)
    {
        this.toggleConfirmationModal();
    let payload={
      contractorId:this.editContractorData.contractorId
    }
    this.sharedService.deleteLogo(payload).subscribe(
      (response: any) => {
       
         if(this.portalName == ROLE_CONSTANTS.CONTRACTOR)
        {
           this.sharedService.setContractorInformation();
          this.helperService.setUploadLogo("true");
          this.selectedCompanyLogo = undefined;
          this.companyLogoName = '';
          this.createContractor.get('companyLogo').patchValue('');
          this.isLogoExist = false;
        }
      },error=>{
 const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      })
    }
   
    else{
       this.toggleConfirmationModal();
        this.selectedCompanyLogo = undefined;
          this.companyLogoName = '';
          this.createContractor.get('companyLogo').patchValue('');
          this.isLogoExist = false;
    }
      
    
    
   
  }

  handleParseAddresses() {

    let registeredAddressId;
    let billingAddressId;
    if (this.isEdit && this.editContractorData && this.editContractorData.addresses) {
      registeredAddressId = this.helperService.handleAddressSelection(
        this.editContractorData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_REGISTERED_CORPORATE);
      billingAddressId = this.helperService.handleAddressSelection(
        this.editContractorData.addresses, COMMON_CONSTANTS.ADDRESS_TYPE_BILLING);
    }

    const address = [];
    let badd1 =this.createContractor.value.registeredAddress.addressLine1;
        let badd2 = this.createContractor.value.registeredAddress.addressLine2;
        let addrLine1=badd1.replace(/,/g, ' ');
        let addrLine2=badd2.replace(/,/g, ' ');
    address.push({
      address:addrLine1,
      addressLine2: addrLine2,
      city: this.createContractor.value.registeredAddress.city,
      state: this.createContractor.value.registeredAddress.state,
      zipcode: this.createContractor.value.registeredAddress.zipcode,
      type: COMMON_CONSTANTS.ADDRESS_TYPE_REGISTERED_CORPORATE,
      addressId: this.isEdit ? registeredAddressId && registeredAddressId.addressId : null,
      placeId: this.createContractor.value.registeredAddress.placeId,
      formattedAddress: this.createContractor.value.registeredAddress.formattedAddress,
    });


    this.createContractor.value.branchAddress.map((value, index) => {
      if (value.addressLine1) {
        let badd1 = value.addressLine1;
        let badd2 = value.addressLine2;
        let addrLine1=badd1.replace(/,/g, ' ');
        let addrLine2=badd2.replace(/,/g, ' ');
        address.push({
          address: addrLine1,
          addressLine2: addrLine2,
          city: value.city,
          state: value.state,
          zipcode: value.zipcode,
          type: COMMON_CONSTANTS.ADDRESS_TYPE_BRANCH_ADDRESS,
          addressId: this.isEdit ? billingAddressId && billingAddressId.addressId : null,
          placeId: value.placeId,
          formattedAddress: value.formattedAddress,
        });
      }
    });
    return address;
  }

  handleParseCreateContractorPayload() {
    const payload: any = {
      contractorName: this.createContractor.value.contractorName && this.createContractor.value.contractorName.trim(),
      website: this.createContractor.value.website,
      contactPerson: this.createContractor.value.contactPerson && this.createContractor.value.contactPerson.trim(),
      permissionTypeId: 1,
      resourceTypeId: 1,
      // dealerType: this.portalName === ROLE_CONSTANTS.CONTRACTOR ? 'contractor': 'mdu',
      dealerType: this.createContractor.get('dealerType') &&
        this.createContractor.get('dealerType').value,
      // role: this.portalName === ROLE_CONSTANTS.CONTRACTOR ? 'contractor': 'mdu',
      addresses: this.handleParseAddresses(),
      email: [
        {
          email: this.createContractor.get('primaryEmail') &&
            this.createContractor.get('primaryEmail').value,
          type: COMMON_CONSTANTS.EMAIL_PRIMARY
        }
      ],
      phone: this.createContractor.value.phone,
      notificatonsStatusModel:this.createContractor.value.notificatonsStatusModel,
      operatingSince: this.createContractor.value.inBusinessSince,
      numOfTechnicians: this.createContractor.value.noOfTechnicians
    };

    if (!this.isFromChannel) {
      payload.role = this.portalName === ROLE_CONSTANTS.CONTRACTOR ?
        ROLE_CONSTANTS.CONTRACTOR : ROLE_CONSTANTS.MDU;
    }

    // if (this.isFromChannel) {
    //   payload.dealerType = this.createContractor.get('dealerType') &&
    //     this.createContractor.get('dealerType').value;
    // } else {
    //   payload.dealerType = this.portalName === ROLE_CONSTANTS.CONTRACTOR ?
    //     ROLE_CONSTANTS.CONTRACTOR : ROLE_CONSTANTS.MDU;
    //   payload.role = this.portalName === ROLE_CONSTANTS.CONTRACTOR ?
    //     ROLE_CONSTANTS.CONTRACTOR : ROLE_CONSTANTS.MDU;
    // }

    if (this.createContractor.value.secondaryEmail) {
      payload.email.push(
        {
          email: this.createContractor.value.secondaryEmail,
          type: COMMON_CONSTANTS.EMAIL_SECONDARY
        }
      );
    }
    if (this.isEdit && this.editContractorData) {
      payload.awsContractorId = this.editContractorData.awsContractorId;
      payload.contractorId = this.editContractorData.contractorId;
     

      if (this.editContractorData.phone && this.editContractorData.phone.length) {
        this.editContractorData.phone.map((value, index) => {
          // payload.phone[index].id = this.editContractorData.phone[index].id;
          // payload.phone[index].countryCode = this.editContractorData.phone[index].countryCode;
          if(index!==2)
          {
  payload.phone[index].phoneId = this.editContractorData.phone[index].phoneId;
          }
        
        });
      }

      const primaryEmailId = this.helperService.handleEmailSelection(
        this.editContractorData && this.editContractorData.email, COMMON_CONSTANTS.EMAIL_PRIMARY);

      const secondaryEmailId = this.helperService.handleEmailSelection(
        this.editContractorData && this.editContractorData.email, COMMON_CONSTANTS.EMAIL_SECONDARY);

      if (payload && payload.email && payload.email.length) {
        payload.email.map((value, index) => {
          if (value.type === COMMON_CONSTANTS.EMAIL_PRIMARY && primaryEmailId && primaryEmailId.emailId) {
            payload.email[index].emailId = primaryEmailId.emailId;
          } else if (value.type === COMMON_CONSTANTS.EMAIL_SECONDARY && secondaryEmailId && secondaryEmailId.emailId) {
            payload.email[index].emailId = secondaryEmailId.emailId;
          }
        });
      }

    }

    this.createContractorPayload = JSON.parse(JSON.stringify(payload));
    this.createContractorPayload.logoUrls = [];
    this.createContractorPayload.logoUrls.push(this.createContractor.value.companyLogo);
    this.createContractorPayload.docUrl = [];
    this.createContractorPayload.docUrl.push(this.createContractor.value.document1,
      this.createContractor.value.document2);

    if (!this.selectedCompanyLogo && !this.isCreateContractorFormReseted
      && this.editContractorData) {
      this.createContractorPayload.logoUrls = this.editContractorData.logoUrls;
    }

    if (!this.selectedDocument1 && !this.isCreateContractorFormReseted
      && this.editContractorData && this.editContractorData.docUrl) {
      this.createContractorPayload.docUrl[0] = this.editContractorData.docUrl[0] ?
        this.editContractorData.docUrl[0] : null;
    }

    if (!this.selectedDocument2 && !this.isCreateContractorFormReseted
      && this.editContractorData && this.editContractorData.docUrl) {
      this.createContractorPayload.docUrl[1] = this.editContractorData.docUrl[1] ?
        this.editContractorData.docUrl[1] : null;
    }

    return payload;
  }

  handleCreateContractor() {
    const payload = this.handleParseCreateContractorPayload();
    this.isCreateContractorBtnLoading = true;
    this.sharedService.createContractor(payload, this.isFromChannel).subscribe(
      (response: CreateContractorResponse) => {
        this.sharedService.setContractorInformation()
        this.closeModal();
       
       
        // if (this.selectedCompanyLogo || this.selectedDocument1 || this.selectedDocument2)
        if (this.selectedCompanyLogo) {
          this.handleButtonsOnChanges();
          this.closeModal();
          // if (this.companyLogoName) {
          //   this.uploadFile(response.createContractorResponse.contractorId, 'company-logo');
          // }
          // if (this.document1Name) {
          //   this.uploadFile(response.createContractorResponse.contractorId, 'document-1');
          // }
          // if (this.document2Name) {
          //   this.uploadFile(response.createContractorResponse.contractorId, 'document-2');
          this.uploadFile(response);
          // }
        } else {
           if(this.portalName == ROLE_CONSTANTS.CONTRACTOR)
        {
 this.helperService.setUploadLogo("true");
        }
          this.handleButtonsOnChanges();
          this.closeModal();
          // if(this.isEdit){
          //   this.isCreateContractorBtnLoading = false;
          // }
         
          if (!this.isEdit) { 
            
            this.emitHandleConfirmationSuccessModal.emit(this.helperService.handleEmailSelection(
              response.requestData && response.requestData.email, COMMON_CONSTANTS.EMAIL_PRIMARY
            ));
            this.isCreateContractorBtnLoading = false;
            this.closeModal();
          }
          else {
            this.isCreateContractorBtnLoading = false;
            const message = response.createContractorResponse.msg;
            this.helperService.showToaster(ToasterType.SUCCESS, message);
            
            this.closeModal();
           
          }
          // const message = response.createContractorResponse.msg;
          // this.helperService.showToaster(ToasterType.SUCCESS, message);
        }
        this.emitHandleRefreshPage.emit();
      }, (error) => {
        this.isCreateContractorBtnLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  uploadFile(createContractorRes) {
    const queryParams = {
      contractorId: createContractorRes.createContractorResponse.contractorId
    };
    const filedata = new FormData();
    // if (flag === 'company-logo') {
    //   const imageName = COMMON_CONSTANTS.COMPANY_LOGO_COMMON_NAME +
    //     this.selectedCompanyLogo.name.substr(
    //     this.selectedCompanyLogo.name.lastIndexOf('.')
    //   );
    //   filedata.append('logo', this.selectedCompanyLogo, imageName);
    // } else if (flag === 'document-1') {
    //   filedata.append('documents', this.selectedDocument1);
    // } else if (flag === 'document-2') {
    //   filedata.append('documents', this.selectedDocument2);
    // }

    if (this.selectedCompanyLogo) {
      const imageName = COMMON_CONSTANTS.COMPANY_LOGO_COMMON_NAME +
        this.selectedCompanyLogo.name.substr(
          this.selectedCompanyLogo.name.lastIndexOf('.')
        );
      filedata.append('logo', this.selectedCompanyLogo, imageName);
    }
    // if (this.selectedDocument1) {
    //   filedata.append('documents', this.selectedDocument1);
    // }
    // if (this.selectedDocument2) {
    //   filedata.append('documents', this.selectedDocument2);
    // }
    this.sharedService.uploadCompanyLogo(filedata, queryParams, false).subscribe(
      (response) => {
        this.closeModal();
        this.isCreateContractorBtnLoading = false;
        if (!this.isEdit) {
          this.emitHandleConfirmationSuccessModal.emit(this.helperService.handleEmailSelection(
            createContractorRes.requestData && createContractorRes.requestData.email, COMMON_CONSTANTS.EMAIL_PRIMARY
          ));
        } else {
          const message = createContractorRes.createContractorResponse.msg;
          this.helperService.showToaster(ToasterType.SUCCESS, message);
        }
        // this.helperService.showToaster(ToasterType.SUCCESS, 'Contractor created successfully');
  if(this.portalName == ROLE_CONSTANTS.CONTRACTOR)
        {
          this.helperService.setUploadLogo("true");
        }
        
      },
      (error) => {
        this.isCreateContractorBtnLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleFormValidation(flag: string) {
   
    console.log(this.isEnableSubmit,"submit")
    if (flag === 'create-contractor' && this.isEnableSubmit) {

      if (this.createContractor.valid) {
        this.isEnableSubmit=false
        if (this.createContractor && this.createContractor.get('primaryEmail') &&
          this.createContractor.get('primaryEmail').value &&
          this.createContractor.get('primaryEmail').value.trim()) {
          if (this.isEdit && this.editContractorData && this.editContractorData.email &&
            this.editContractorData.email.length && this.editContractorData.email[0].email ===
            this.createContractor.get('primaryEmail').value) {
              if(this.createContractor.get('secondaryEmail').value && this.createContractor.get('secondaryEmail').value==this.createContractor.get('primaryEmail').value)
            // this.handleModalType('contractor-profile');
          {
            this.isEnableSubmit=true
            this.handleModalType('contractor-emails-same');
          }else{
            this.handleCreateContractor();
          }
           
          } 
          else {
                if(this.createContractor.get('secondaryEmail').value && this.createContractor.get('secondaryEmail').value==this.createContractor.get('primaryEmail').value)
                     {
                      this.isEnableSubmit=true
                       this.handleModalType('contractor-emails-same');}
                else{      
                    let queryParams = {
                      email: this.createContractor.get('primaryEmail').value.trim(),
                      userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
                    };
                    this.sharedService.checkIsEmailAlreadyExists(queryParams).subscribe(
                      (response: IsEmailAlreadyExistsResponse) => {
                        this.isCreateContractorBtnLoading = true;
                        if (response && response.isEmailAlreadyExistsResponseDetails &&
                          response.isEmailAlreadyExistsResponseDetails.isExists) {
                            this.isCreateContractorBtnLoading = false;
                              this.isEnableSubmit=true;
                          this.handleModalType('contractor-exist-confimation');
                        } else {
                          // this.handleModalType('contractor-profile');
                        
                          this.handleCreateContractor();

                        }
                        // this.isCreateContractorBtnLoading = false;
                      }, (error) => {
                        this.isCreateContractorBtnLoading = false;
                        const message = this.helperService.handleAPIErrors(error);
                        this.helperService.showToaster(ToasterType.ERROR, message);
                      }
                    );
                   }
      
          }
        }
      } else {
        this.createContractor.markAllAsTouched();
        this.helperService.scrollToFirstInvalidControl(this.createContractor, this.elementRef.nativeElement);
      }
      
    }
  }

  handleCreateContractorForm() {
    let flag = false;
    if (this.isEdit) {
      let currentFormValues = this.createContractor.getRawValue();
      console.log(JSON.stringify(currentFormValues),JSON.stringify(this.prevFormValues))
      if (JSON.stringify(currentFormValues) !== JSON.stringify(this.prevFormValues)) {

        flag = true;
      }
    }
    else {
      if (this.createContractor.get('contractorName').value ||
        this.createContractor.get('primaryEmail').value ||
        this.createContractor.get('secondaryEmail').value ||
        this.createContractor.get('website').value ||
        this.createContractor.get('companyLogo').value ||
        this.createContractor.get('inBusinessSince').value ||
        this.createContractor.get('noOfTechnicians').value ||
        (this.createContractor.get('document1') && this.createContractor.get('document1').value) ||
        (this.createContractor.get('document2') && this.createContractor.get('document2').value)) {
        flag = true;
      }

      this.phone.controls.map((value, index) => {
        if (value && value.value && value.value.phoneNumber) {
          flag = true;
        }
      });

      this.branchAddress.controls.map((value, index) => {
        if (value.get('addressLine1').value ||
          value.get('addressLine2').value ||
          value.get('city').value ||
          value.get('state').value ||
          value.get('zipcode').value) {
          flag = true;
        }
      });

      if (this.registeredAddress.get('addressLine1').value ||
        this.registeredAddress.get('addressLine2').value ||
        this.registeredAddress.get('city').value ||
        this.registeredAddress.get('state').value ||
        this.registeredAddress.get('zipcode').value) {
        flag = true;
      }
    }

    if (this.createContractor && !this.createContractor.dirty) {
      flag = false;
    }

    return flag;
  }

  handleTabOnChange(value: string) {
    this.selectedBillingTab = value;
  }

  handleRadioBtnValue(value: any) {
    this.createContractor.controls.dealerType.patchValue(value);
  }

  handleModalType(type: string) {
    // this.infoModalType = type;
    if (type === 'contractor-profile') {
      this.infoModalTitle = 'Dealer Profile';
      this.handleParseCreateContractorPayload();
      this.toggleContractorInformationModal();
    } else if (type === 'confirmation-modal') {
      if (this.handleCreateContractorForm()) {
        this.infoModalType = 'close-create-contractor-modal';
        this.confirmationModalTitle = 'Warning!';
        this.confirmationModalLine1Content = `Entered information will be lost.`;
        this.confirmationModalLine2Content = `Do you want to continue?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.toggleConfirmationModal();
      } else {
        this.closeModal();
      }
    } else if (type === 'contractor-exist-confimation') {
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = 'Email address already exists. Please enter a different email or contact Support at +1 (833) 543-0107.';
      // this.confirmationModalLine2Content = `Are you sure you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = false;
      this.toggleConfirmationModal();
    }else if (type=== 'contractor-emails-same'){
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = 'Secondary email address cannot be the same as primary. Please enter a different email or contact Support at +1 (833) 543-0107.';
      // this.confirmationModalLine2Content = `Are you sure you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = false;
      this.toggleConfirmationModal();
    }
    else if(type=='resend-invitation')
    {
      this.reInviteContractor()
    }
    else if(type=='remove-logo')
    {
      this.infoModalType = 'remove-logo';
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = 'Are you sure you want to continue?';
      // this.confirmationModalLine2Content = `Are you sure you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = true;
      this.toggleConfirmationModal();
    }
  }


  
reInviteContractor() {
    const queryParams = {
      contractorId: this.editContractorData.contractorId
    };
    this.isResendBtnLoading=true;
    this.sharedService.activateContractor(queryParams).subscribe(
      (response: CommonSuccessResponse) => {
         this.isResendBtnLoading=false;
        this.helperService.showToaster(ToasterType.SUCCESS, response && response.statusInfo);
        this.closeModal()
             this.emitHandleRefreshPage.emit();
      }, (error) => {
         this.closeModal()
             this.emitHandleRefreshPage.emit();
         this.isResendBtnLoading=false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    )
  }
  handleButtonsOnChanges() {
    if (this.handleCreateContractorForm()) {
      this.isToggleButtons = true;
    } else {
      this.isToggleButtons = false;
    }
    // if(this.branchAddress.length !==  this.prevFormValues&&this.prevFormValues.branchAddress&&this.prevFormValues.branchAddress.length)
    // {

    // }
  }

  handleButtonsOnLengthChanges() {
    console.log(this.phone)
    this.isToggleButtons = false;
    if ((this.phone.length !== this.prevFormValues.phone.length || this.handleCreateContractorForm())
      || (this.branchAddress.length !== this.prevFormValues.branchAddress.length || this.handleCreateContractorForm())) {
      this.isToggleButtons = true;
    }
  }

  handleFormatPhoneNumber(phoneGroup: FormGroup, phoneNumber: any) {
    const number = this.helperService.handleFormatPhoneNumber(phoneNumber);
    phoneGroup.patchValue({
      phoneNumber: number?number:null
    });
  }

  //handlealerts enable or disable
  handleAlerts(data)
  {
    if(data=='major')
    {
      this.isMajorChecked= !this.isMajorChecked
      this.handleScreen();
      this.handleFormData();
    }
    else if(data=='minor')
    {
      this.isMinorChecked = !this.isMinorChecked 
       this.handleScreen(); 
      this.handleFormData();
             
    }
    else if(data=='email')
    {
        this.isEmailChecked=!this.isEmailChecked
         console.log(this.isEmailChecked)
        this.handleToggleScreen();
        this.handleFormData();
    }
    else if(data=='sms')
    {
      this.isSMSChecked=!this.isSMSChecked
      this.handleToggleScreen();
      this.handleFormData();
    }

  }

  handleScreen()
  {
    if(!this.isMajorChecked&&!this.isMinorChecked)
    {
      this.isSMSChecked=this.isEmailChecked=false;
    }
    else if(this.isMajorChecked||this.isMinorChecked)
    {
      this.isSMSChecked = this.isSMSChecked
      this.isEmailChecked=this.isEmailChecked
       if(!this.isEmailChecked&&!this.isSMSChecked)
      {
         this.isEmailChecked=this.isSMSChecked=true;
      }
    }
  }
  handleToggleScreen()
  {
    if(!this.isEmailChecked&&!this.isSMSChecked)
    {
      this.isMinorChecked=this.isMajorChecked=false;
    }
    else if(this.isEmailChecked||this.isSMSChecked)
    {
      this.isMinorChecked= this.isMinorChecked;
      this.isMajorChecked=this.isMajorChecked;
      if(!this.isMajorChecked&&!this.isMinorChecked)
      {
         this.isMinorChecked=this.isMajorChecked=true;
      }
    }
    
  }
  handleFormData()
  {
    if(this.isEdit)
    {
      this.createContractor.markAsDirty();
    }
    
    if((!this.isMajorChecked && !this.isMinorChecked && !this.isSMSChecked && !this.isEmailChecked)||
       (!this.isMajorChecked && !this.isMinorChecked && this.isSMSChecked && this.isEmailChecked)||
       (this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && !this.isEmailChecked)||
       (!this.isMajorChecked && !this.isMinorChecked && this.isSMSChecked && !this.isEmailChecked)||
       (!this.isMajorChecked && !this.isMinorChecked && !this.isSMSChecked && this.isEmailChecked)||
       (!this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && !this.isEmailChecked)||
       (this.isMajorChecked && !this.isMinorChecked && !this.isSMSChecked && !this.isEmailChecked)){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:false,
           minorAlertsEmail:false,
          minorAlertsSms:false
        })
      }
  
      else if(this.isMajorChecked && this.isMinorChecked && this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:true,
          majorAlertsSms:true,
           minorAlertsEmail:true,
          minorAlertsSms:true
        })
      }
      else if(this.isMajorChecked && this.isMinorChecked && this.isSMSChecked && !this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:true,
           minorAlertsEmail:false,
          minorAlertsSms:true
        })
      }
      else if(this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:true,
          majorAlertsSms:false,
           minorAlertsEmail:true,
          minorAlertsSms:false
        })
      }
      else if(!this.isMajorChecked && this.isMinorChecked && this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:false,
           minorAlertsEmail:true,
          minorAlertsSms:true
        })
      }
       else if(!this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:false,
           minorAlertsEmail:true,
          minorAlertsSms:false
        })
      }

      else if(!this.isMajorChecked && this.isMinorChecked && this.isSMSChecked && !this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:false,
           minorAlertsEmail:false,
          minorAlertsSms:true
        })
      }
      else if(this.isMajorChecked && !this.isMinorChecked && this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:true,
          majorAlertsSms:true,
           minorAlertsEmail:false,
          minorAlertsSms:false
        })
      }
      else if(this.isMajorChecked && !this.isMinorChecked && !this.isSMSChecked && this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:true,
          majorAlertsSms:false,
           minorAlertsEmail:false,
          minorAlertsSms:false
        })
      }
      else if(this.isMajorChecked && !this.isMinorChecked && this.isSMSChecked && !this.isEmailChecked){
          this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:false,
          majorAlertsSms:true,
           minorAlertsEmail:false,
          minorAlertsSms:false
        })
      }

      // console.log("majoremail",this.notificatonsStatusModel.get('majorAlertsEmail').value.value);
      //  console.log("majorsms",this.notificatonsStatusModel.get('majorAlertsSms').value.value);
      //   console.log("minoremail",this.notificatonsStatusModel.get('minorAlertsEmail').value.value);
      //    console.log("minorsms",this.notificatonsStatusModel.get('minorAlertsSms').value.value);
      this.alertNotification.majorAlertsEmail=this.notificatonsStatusModel.get('majorAlertsEmail').value.value;
      this.alertNotification.majorAlertsSms=this.notificatonsStatusModel.get('majorAlertsSms').value.value;
      this.alertNotification.minorAlertsEmail=this.notificatonsStatusModel.get('minorAlertsEmail').value.value;
      this.alertNotification.minorAlertsSms=this.notificatonsStatusModel.get('minorAlertsSms').value.value;
      
      // if(this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && this.isEmailChecked){
      //     this.notificatonsStatusModel.patchValue({
      //      majorAlertsEmail:new FormControl(true),
      //     majorAlertsSms:new FormControl(false),
      //      minorAlertsEmail:new FormControl(true),
      //     minorAlertsSms:new FormControl(false)
      //   })
      // }
      //  if(this.isMajorChecked && this.isMinorChecked && this.isSMSChecked && !this.isEmailChecked){
      //     this.notificatonsStatusModel.patchValue({
      //      majorAlertsEmail:new FormControl(false),
      //     majorAlertsSms:new FormControl(true),
      //      minorAlertsEmail:new FormControl(false),
      //     minorAlertsSms:new FormControl(true)
      //   })
      // }
      // if(this.isMajorChecked && this.isMinorChecked && !this.isSMSChecked && !this.isEmailChecked){
      //     this.notificatonsStatusModel.patchValue({
      //      majorAlertsEmail:new FormControl(false),
      //     majorAlertsSms:new FormControl(false),
      //      minorAlertsEmail:new FormControl(false),
      //     minorAlertsSms:new FormControl(false)
      //   })
      // }
       
      // // if{
      // //     this.notificatonsStatusModel.patchValue({
      // //      majorAlertsEmail:new FormControl(false),
      // //     majorAlertsSms:new FormControl(false),
      // //      minorAlertsEmail:new FormControl(false),
      // //     minorAlertsSms:new FormControl(false)
      // //   })
      // // }


      // // else if(!this.isMajorChecked && this.isMinorChecked)
      // // {
      // //   this.notificatonsStatusModel.patchValue({
      // //      majorAlertsEmail:new FormControl(false),
      // //     majorAlertsSms:new FormControl(false),
      // //      minorAlertsEmail:new FormControl(true),
      // //     minorAlertsSms:new FormControl(true)
      // //   })
      // //   this.isSMSChecked=true;
      // //   this.isEmailChecked=true;
      // // }
      // // else if(!this.isMajorChecked && !this.isMinorChecked){
      // //   this.notificatonsStatusModel.patchValue({
      // //      majorAlertsEmail:new FormControl(true),
      // //     majorAlertsSms:new FormControl(true),
      // //      minorAlertsEmail:new FormControl(true),
      // //     minorAlertsSms:new FormControl(true)
      // //   })
      // //   this.isSMSChecked=false;
      // //   this.isEmailChecked=false;
      // // }
      // //   if(this.isMajorChecked && this.isMinorChecked)
      // // {
      // //   console.log(this.notificatonsStatusModel.value)
      // //   this.notificatonsStatusModel.patchValue({
      // //      majorAlertsEmail:new FormControl(true),
      // //     majorAlertsSms:new FormControl(true),
      // //      minorAlertsEmail:new FormControl(true),
      // //     minorAlertsSms:new FormControl(true)
      // //   })
      // //   this.isSMSChecked=this.isSMSChecked?this.isSMSChecked:!this.isSMSChecked;
      // //   this.isEmailChecked=this.isEmailChecked?this.isEmailChecked:!this.isEmailChecked;
      // // }
  }

  editAlertNotification()
  
  {
    //console.log(this.editContractorData.notificatonsStatusModel)
    if(this.editContractorData.notificatonsStatusModel.majorAlertsEmail||this.editContractorData.notificatonsStatusModel.majorAlertsSms)
    {
      this.isMajorChecked=true;
    }
     if(!this.editContractorData.notificatonsStatusModel.majorAlertsEmail&&!this.editContractorData.notificatonsStatusModel.majorAlertsSms)
    {this.isMajorChecked=false;
    }
    if(this.editContractorData.notificatonsStatusModel.minorAlertsEmail||this.editContractorData.notificatonsStatusModel.minorAlertsSms)
    {
      this.isMinorChecked=true;
    }
     if(!this.editContractorData.notificatonsStatusModel.minorAlertsEmail&&!this.editContractorData.notificatonsStatusModel.minorAlertsSms)
    {
      this.isMinorChecked=false;
    }
    if(this.editContractorData.notificatonsStatusModel.majorAlertsEmail||this.editContractorData.notificatonsStatusModel.minorAlertsEmail)
    {
      this.isEmailChecked=true;
    }
     if(!this.editContractorData.notificatonsStatusModel.majorAlertsEmail&&!this.editContractorData.notificatonsStatusModel.minorAlertsEmail)
    {
      this.isEmailChecked=false;
    }
    if(this.editContractorData.notificatonsStatusModel.majorAlertsSms||this.editContractorData.notificatonsStatusModel.minorAlertsSms)
    {
      this.isSMSChecked=true;
    }
   if(!this.editContractorData.notificatonsStatusModel.majorAlertsSms&&!this.editContractorData.notificatonsStatusModel.minorAlertsSms)
    {
      this.isSMSChecked=false;
    }

    


  }

  onEditProfile()
  {

  }
 handleAlertToggle()
 {
    if(!this.isEmailChecked&&!this.isSMSChecked)
        {
           this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:new FormControl(false),
          majorAlertsSms:new FormControl(false),
           minorAlertsEmail:new FormControl(false),
          minorAlertsSms:new FormControl(false)
        })
        this.isMajorChecked=false
        this.isMinorChecked=false
        }
        else if(this.isEmailChecked && this.isSMSChecked)
        {
           this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:new FormControl(true),
           minorAlertsEmail:new FormControl(true),
            majorAlertsSms:new FormControl(true),
           minorAlertsSms:new FormControl(true)
        })
        
      }
       else if(!this.isEmailChecked && this.isSMSChecked)
        {
           this.notificatonsStatusModel.patchValue({
           majorAlertsEmail:new FormControl(false),
           minorAlertsEmail:new FormControl(false),
            majorAlertsSms:new FormControl(true),
           minorAlertsSms:new FormControl(true)
        })
      }
       
        else if(!this.isSMSChecked&&this.isEmailChecked)
        {
          this.notificatonsStatusModel.patchValue({
             majorAlertsEmail:new FormControl(true),
           minorAlertsEmail:new FormControl(true),
           majorAlertsSms:new FormControl(false),
           minorAlertsSms:new FormControl(false)
        })
        }

 }

  handleConfirmationModalEmitter() {
    if (this.infoModalType === 'close-create-contractor-modal') {
      this.closeModal();
    }
    if(this.infoModalType=="remove-logo")
    {
      this.handleRemoveSelectedCompanyLogo()
    }
  }

  handleResetFormFields() {
    // this.isCreateContractorFormReseted = true;
    // this.createContractor.reset();
    // this.companyLogoName = null;
    // this.document1Name = null;
    // this.document2Name = null;
    // this.selectedCompanyLogo = null;
    // this.selectedDocument1 = null;
    // this.selectedDocument2 = null;
    // if (this.isEdit) {
    //   this.createContractor.patchValue({
    //     primaryEmail: this.handlePatchEmailValues(COMMON_CONSTANTS.EMAIL_PRIMARY)
    //   });
    // }
    // this.handlePersonalInfoDeleteButton('phone', null, true);
    // this.handlePersonalInfoDeleteButton('branch-address', null, true);
    // this.phone.patchValue([{
    //   type: 'Mobile'
    // }]);
    // this.createContractor.patchValue({
    //   inBusinessSince: ''
    // });
  }

  toggleContractorInformationModal() {
    this.isShowContractorInfoModal = !this.isShowContractorInfoModal;
  }

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}
