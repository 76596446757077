import { Directive, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appClosePopOver]'
})
export class ClosePopOverDirective {

  @Output() public closePopOver = new EventEmitter<MouseEvent>();

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closePopOver.emit(event);
    }
  }

}
