import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'projects/contractor/src/environments/environment';
import { Observable, Subject } from 'rxjs';

// Services
import { HelperService } from './helper.service';
import { ApiService } from './api.service';

// Constants
import { API_CONSTANTS } from 'src/app/shared/constants/api.constant';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';

// Models
import { AlertsResponse } from 'src/app/shared/models/alerts.model';
import {
  Sensor,
  AlertDiscoverySensor, HVACCycleResponse
} from 'src/app/shared/models/graph.model';
import {
  ContractorDetails,
  ContractorDetailsResponse,
  CreateContractorResponse,
} from 'src/app/shared/models/contractor.modal';
import { FirmwareResponse, MeliAssetInfoResponse, SensorCurrentStatusResponse, UpdateMeliStatusResponse } from 'src/app/shared/models/meli.model';
import { SearchResponse } from 'src/app/shared/models/search.model';
import { CustomerListResponse, AssetDetail, AssetDetailResponse, SubscriptionPlanListResponse, CellularPlanListResponse, BillingListResponse, CurrentCellularStatusResponse, HVACInformationResponse } from 'src/app/shared/models/customer.model';
import { ConsumerResponse, Customer, InstallationReportResponse, InventoryResponse, meliBoardResponse } from 'src/app/shared/models/inventory.model';
import { SystemResponse, GFResponse, ACResponse, HPResponse, ComfortBridgeResponse } from 'src/app/shared/models/dataac.model';


// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { PreSeasonListResponse, PreSeasonResponse, emailResponse } from '../models/preseason.model';
import { env } from 'process';
import { CommonSuccessResponse, CommonSuccessResponseList, IsEmailAlreadyExistsResponse } from '../models/common.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ControlListResponse } from '../models/control.model';
import { CHANNEL_API_CONSTANTS } from 'projects/channel/src/app/shared-channel/constants/api.constants';
import { successResponse } from 'src/app/shared/models/billingaccount.model';
import { UploadProperyListResponse } from '../models/properties.model';
import {  TechnicianBulkUploadResponse, TechnicianInfo, TechnicianJobHistoryResponse } from 'src/app/shared/models/technician.model';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // private BASE_URL = environment.baseUrl;
  // private PHONE_CODES_REST_API_BASE_URL = environment.phoneCodesRestAPIBaseUrl;
  // private AUTH_BASE_URL = environment.loginBaseUrl;
  private BASE_URL;
  private PHONE_CODES_REST_API_BASE_URL = environment.phoneCodesRestAPIBaseUrl;
  private AUTH_BASE_URL;
  contractorInfo: ContractorDetails;
  mduInfo: Customer;
  phoneCodesList: any;
  private PASSWORD_BASE_URL = environment.loginBaseUrl;
  private RELAY_BASE_URL;
  private LAMBDA_BASE_URL;
  private PRE_SEASON_BASE_URL;
  private COMFORTBRIDGE_BASE_URL;
  private PRESEASON_URL;
  private UPDATE_RELAY_BASE_URL;
  contractorDetailsSubject: Subject<ContractorDetails> = new Subject();
  mduDetailsSubject: Subject<any> = new Subject();
  lastLoginDetailsSubject: Subject<any> = new Subject();
  constructor(
    private helperService: HelperService,
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {
    this.BASE_URL = this.helperService.handleBaseUrl(
      BASE_URL_TYPES.COMMON_BASE
    );
    this.AUTH_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LOGIN);
    this.LAMBDA_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.LAMBDA);
    this.PRE_SEASON_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.PRE_SEASON_TEST);
    this.RELAY_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.UPDATE_RELAY);
    this.COMFORTBRIDGE_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.COMFORTBRIDGE);
    this.PRESEASON_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.PRE_SEASON_TEST);
    this.UPDATE_RELAY_BASE_URL = this.helperService.handleBaseUrl(BASE_URL_TYPES.UPDATE_RELAY);
  }

  getAlertsList(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.ALERTS_LIST;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new AlertsResponse(response)));
  }
  getUserExistStatus(): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.USER_EXIST;
    return this.apiService
      .get(url)
      .pipe(map(response => response));
  }
  getAlertsListMDU(queryParams): Observable<any> {
    const url = this.BASE_URL + '/mdu/alerts';
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new AlertsResponse(response)));
  }
  getConsumerData(queryParams): Observable<CustomerListResponse> {
    const url = this.BASE_URL + API_CONSTANTS.CUSTOMER_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new CustomerListResponse(response)));

  }
  getFirmware(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.FIRMWARE_VERSION;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new FirmwareResponse(response)));
  }
  // // setAlertsList(data) {
  // //   this.alertsList = data;
  // // }

  // // getAlertsListLocal() {
  // //   return this.alertsList;
  // // }

  getSensorCurrentRead(queryParams): Observable<Sensor> {
    const url = this.BASE_URL + API_CONSTANTS.SENSOR_CURRENT_READ;
    return this.apiService
      .get(url, queryParams,false,false)
      .pipe(map((response) => new Sensor(response)));
  }

  getSensorRead(queryParams): Observable<Sensor> {
    const url = this.BASE_URL + API_CONSTANTS.SENSOR_READ;
    return this.apiService
      .get(url, queryParams,false,false)
      .pipe(map((response) => new Sensor(response)));
  }
  getComfortBridgeStatus(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.GET_COMFORTBRIDGE_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => (response)));
  }
  getHVACCycleData(queryParams): Observable<HVACCycleResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_HVAC_CYCLE_DATA;
    return this.apiService.get(url, queryParams).pipe(map((response) => new HVACCycleResponse(response)));
  }

  getAlertDiscoverySensorRead(queryParams): Observable<AlertDiscoverySensor> {
    const url = this.BASE_URL + API_CONSTANTS.ALERT_DISCOVERY_READ;
    return this.apiService
      .get(url, queryParams, false,false)
      .pipe(map((response) => new AlertDiscoverySensor(response)));
  }

  setContractorInformation() {
    this.contractorInfo = null;
    this.mduInfo = null;
  }

  getMduInformation(callback) {
    if (this.mduInfo) {
      callback(this.mduInfo, false);
      return;
    }

    this.getMduInformationAPI().subscribe(
      (response) => {
        this.mduInfo = response.responseData;
        this.mduDetailsSubject.next(response.responseData);
        callback(response.responseData, false);
      },
      (error) => {
        callback(null, true);
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  getMduInformationAPI(): Observable<ConsumerResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_MDU_INFORMATION;
    return this.apiService.get(url).pipe(map((response) => new ConsumerResponse(response)));
  }

  setListenerForMduDetails() {
    return this.mduDetailsSubject.asObservable();
  }

  getContractorInformation(callback) {
    if (this.contractorInfo) {
      callback(this.contractorInfo, false);
      return;
    }

    this.getContractorInformationAPI().subscribe(
      (response: ContractorDetailsResponse) => {
        this.contractorInfo = response.responseData;
        this.contractorDetailsSubject.next(response.responseData);
        callback(response.responseData, false);
      },
      (error) => {
        callback(null, true);
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  getContractorInformationAPI(
    queryParams?: any
  ): Observable<ContractorDetailsResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_CONTRACTOR_INFORMATION;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new ContractorDetailsResponse(response)));
  }

  setListenerForContractorDetails() {
    return this.contractorDetailsSubject.asObservable();
  }

  getMeliAssetInfo(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.GET_MELI_ASSET_INFO;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new MeliAssetInfoResponse(response)));
  }

  // getPhoneCodesList(callback) {
  //   if (this.phoneCodesList) {
  //     return callback(this.phoneCodesList, false);
  //   } else {
  //     this.getPhoneCodesListAPI().subscribe(
  //       (response) => {
  //         this.phoneCodesList = response;
  //         callback(response, false);
  //       }, (error) => {
  //         callback(null, true);
  //         const message = `Error in getting phone codes`;
  //         this.helperService.showToaster(ToasterType.ERROR, message);
  //       }
  //     );
  //   }
  // }

  // getPhoneCodesListAPI() {
  //   const url = this.PHONE_CODES_REST_API_BASE_URL;
  //   return this.apiService.get(url, {}, false, false).pipe(map(response => response));
  // }

  // // getInstallationReport(queryParams) {
  // //   const url = this.BASE_URL + API_CONSTANTS.INSTALLATION_REPORT;
  // //   return this.apiService.get(url, queryParams).pipe(map(response => new InstallationReportResponse(response)));
  // // }

  handleLogout(queryParams) {
    const url = this.AUTH_BASE_URL + API_CONSTANTS.LOGOUT;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => response));
  }

  getSearchList(queryParams, urlEndPoint): Observable<SearchResponse> {
    const url = this.BASE_URL + urlEndPoint;
    return this.apiService
      .get(url, queryParams)
      .pipe(map((response) => new SearchResponse(response)));
  }

  getCustomerList(queryParams): Observable<CustomerListResponse> {
    const url = this.BASE_URL + API_CONSTANTS.CUSTOMER_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new CustomerListResponse(response)));
  }

  passwordReset(queryParams) {
    const url = this.AUTH_BASE_URL + API_CONSTANTS.CHANGE_PASSWORD;
    return this.apiService.post(url, queryParams);

  }

  getHvacList(queryParams): Observable<AssetDetailResponse> {
    const url = this.BASE_URL + API_CONSTANTS.HVAC_ASSET_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new AssetDetailResponse(response)));
  }

  getInventoryList(queryParams): Observable<InventoryResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_INVENTORY_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new InventoryResponse(response)));
  }

  getBoardInformation(queryParams): Observable<meliBoardResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_BOARDDATA_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new meliBoardResponse(response)));
  }

  updateMeliStatus(payload): Observable<UpdateMeliStatusResponse> {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_MELI_STATUS;
    return this.apiService.post(url, payload).pipe(map(response => new UpdateMeliStatusResponse(response)));
  }
  updateInstallationReport(payload): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_INSTALL_REPORT;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }

  getLastLoggedInTime(payload): Observable<any> {
    const url = this.LAMBDA_BASE_URL + API_CONSTANTS.GET_LAST_LOGGED_IN;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }

  setLastLoginDetailsSubject(value) {
    this.lastLoginDetailsSubject.next(value);
  }

  setListenerForLastLoginSubject() {
    return this.lastLoginDetailsSubject.asObservable();
  }

  getSiteTourData(payload): Observable<any> {
    const url = this.LAMBDA_BASE_URL + API_CONSTANTS.GET_SITE_TOUR_DATA;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }
  SiteTourComplete(payload): Observable<any> {
    const url = this.LAMBDA_BASE_URL + API_CONSTANTS.COMPLETE_SITE_TOUR;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }

  getPreSeasonList(queryParams): Observable<PreSeasonListResponse> {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.PRESEASON_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new PreSeasonListResponse(response)));

  }
  getPreSeasonReport(queryParams): Observable<PreSeasonResponse> {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.PRESEASON_REPORT;
    return this.apiService.get(url, queryParams).pipe(map(response => new PreSeasonResponse(response)));
  }
  sendAttachmentMail(queryParams): Observable<emailResponse> {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.SEND_PRESEASON_REPORT_MAIL;
    return this.apiService.post(url, queryParams).pipe(map(response => new emailResponse(response)));
  }
  getComfortbridgeData(id) {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.COMFORTT_BRIDGE_INFO + '/' + id;
    return this.apiService.get(url).pipe(map(response => (response)));
  }

  getSystemSetting(id): Observable<SystemResponse> {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.SYSTEM_INFO + '/' + id;
    return this.apiService.get(url).pipe(map(response => new SystemResponse(response)));
  }

  getFuranceInfo(id): Observable<GFResponse> {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.GAS_FURANCE_INFO + '/' + id;
    return this.apiService.get(url).pipe(map(response => new GFResponse(response)));
  }
  getAcInfo(id): Observable<ACResponse> {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.AC_INFO + '/' + id;
    return this.apiService.get(url).pipe(map(response => new ACResponse(response)));
  }
  getHpInfo(id): Observable<HPResponse> {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.HEAT_PUMP_INFO + '/' + id;
    return this.apiService.get(url).pipe(map(response => new HPResponse(response)));
  }
  mapList(payload) {
    const url = this.BASE_URL + API_CONSTANTS.GET_PROPERTIES_MAP_LIST;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }

  checkIsEmailAlreadyExists(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.CHECK_EMAIL;
    return this.apiService.get(url, queryParams, false, false).pipe(map(response => new IsEmailAlreadyExistsResponse(response)));
  }

  createContractor(payload, isFromChannel): Observable<CreateContractorResponse> {
    const endPoint = isFromChannel ? CHANNEL_API_CONSTANTS.ADD_DEALER : API_CONSTANTS.CREATE_CONTRACTOR;
    const hasHeader = isFromChannel ? true : false;
    const url = this.BASE_URL + endPoint;
    return this.apiService.post(url, payload, {}, false, hasHeader).pipe(map(response => new CreateContractorResponse(response)));
  }

  deleteLogo(payload){
const url = this.BASE_URL + API_CONSTANTS.DELETE_LOGO;
    return this.apiService.delete(url,payload).pipe(map(response => response));
  }
  editHvac(payload, queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.EDIT_ASSET_UNIT;
    return this.apiService.post(url, payload, queryParams).pipe(map(response => response));
  }

  downloadTemplate(queryParams) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = this.BASE_URL + API_CONSTANTS.DOWNLOAD_TEMPLATE;
    return this.httpClient.get(url, { params: queryParams, headers: headers, responseType: 'blob' }).pipe(map(response => response));
  }

  getLocation(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.GET_ASSET_UNITLOCATION;
    return this.apiService.get(url, queryParams).pipe(map(response => (response)));
  }

  getControlList(queryParams, isContractorPortal?): Observable<ControlListResponse> {
    let url;
    if (isContractorPortal) {
      url = this.BASE_URL + API_CONSTANTS.GET_CONTRACTOR_CONTROL_LIST;
    } else {
      url = this.BASE_URL + API_CONSTANTS.GET_CONTROL_LIST;
    }
    return this.apiService.get(url, queryParams).pipe(map(response => new ControlListResponse(response)));
  }

  downloadPreseasonReports(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const url = this.PRESEASON_URL + API_CONSTANTS.DOWNLOAD_BULK_PRESEASON_REPORTS;
    //return this.apiService.post(url, payload,{}).pipe(map(response => (response)));
    return this.httpClient.post(url, payload, { headers: headers, responseType: 'blob' }).pipe(map(response => (response)));
  }


  downloadInstallationReports(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const url = this.BASE_URL + API_CONSTANTS.DOWNLOAD_BULK_INSTALLATION_REPORT;
    return this.httpClient.post(url, payload, { headers: headers, responseType: 'blob' }).pipe(map(response => (response)));
  }

  cancelPreseasonTest(payload) {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.CANCEL_PRE_SEASON_TEST;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }
  getInstallationReport(payload):Observable<InstallationReportResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_INSTALLATION_REPORT;
    return this.apiService.get(url, payload).pipe(map(response => new InstallationReportResponse(response)));
  }

  assignTechnician(payload): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.ASSIGN_TECHNICIAN;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }
  getCustomerKey() {
    const url = this.BASE_URL + API_CONSTANTS.GET_CUSTOMER_SECRETKEY;
    return this.apiService.get(url).pipe(map(response => response));
  }
  getSubscriptionPlanList(queryParams): Observable<SubscriptionPlanListResponse> {
    const url = this.BASE_URL + API_CONSTANTS.SUBSCRIPTION_PLAN;
    return this.apiService.get(url, queryParams).pipe(map(response => new SubscriptionPlanListResponse(response)));
  }

  updateSubscriptionPlan(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_SUBSCRIPTION_PLAN;
    return this.apiService.get(url, queryParams).pipe(map(response => (response)));
  }

  // getCellularPlanList(queryParams): Observable<CellularPlanListResponse> {
  //   const url = this.BASE_URL + API_CONSTANTS.GET_CELLULAR_PLAN_LIST;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new CellularPlanListResponse(response)));
  // }

  getCurrentCellularStatus(boardSerialNumber): Observable<CurrentCellularStatusResponse> {
    const url = this.RELAY_BASE_URL + API_CONSTANTS.GET_CELLULAR_CURRENT_STATUS + `/${boardSerialNumber}`;
    return this.apiService.get(url).pipe(map(response => new CurrentCellularStatusResponse(response)));
  }

  updateCellularPlan(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_CELLULAR_PLAN;
    return this.apiService.get(url, queryParams).pipe(map(response => (response)));
  }
  sendCreditCardToken(payload): Observable<successResponse> {
    const url = this.BASE_URL + API_CONSTANTS.SEND_CARD_TOKEN;
    return this.apiService.post(url, payload).pipe(map(response => new successResponse(response)));
  }
  sendAchToken(payload) {
    const url = this.BASE_URL + API_CONSTANTS.SEND_ACH_TOKEN;
    return this.apiService.get(url, payload).pipe(map(response => response));
  }
  getBillingDetails() {
    const url = this.BASE_URL + API_CONSTANTS.IS_CARD_AVAILABLE;
    return this.apiService.get(url).pipe(map(response => response));
  }
  updateCreditCard(payload) {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_CREDIT_CARD;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }
  updateAch(payload) {
    const url = this.BASE_URL + API_CONSTANTS.UPDATE_ACH;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }
  getBillingList(): Observable<BillingListResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_BILLING_LIST;
    return this.apiService.get(url).pipe(map(response => new BillingListResponse(response)));
  }

  deleteBillingInfo(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.DELETE_BILLING_INFO;
    return this.apiService.delete(url, queryParams).pipe(map(response => response));
  }
  makeDefaultPayment(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.MAKE_DEFAULT_PAYMENT_METHOD;
    return this.apiService.get(url, queryParams).pipe(map(response => response));
  }
  verifyAch(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.VERIFY_ACH;
    return this.apiService.get(url, queryParams).pipe(map(response => response));
  }

  transferAccount(queryParams): Observable<IsEmailAlreadyExistsResponse> {
    const url = this.BASE_URL + API_CONSTANTS.TRANSFER_ACCOUNT;
    return this.apiService.post(url, null, queryParams).pipe(map(response => new IsEmailAlreadyExistsResponse(response)));
  }

  deleteAccount(): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.DELETE_ACCOUNT;
    return this.apiService.delete(url).pipe(map(response => new CommonSuccessResponse(response)));
  }

  downloadErrorTemplateFile(queryParams) {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const url = this.BASE_URL + API_CONSTANTS.GET_UPLOAD_ERROR_FILE;
    return this.httpClient.get(url, { params: queryParams, responseType: 'blob' }).pipe(map(response => response));
  }

  downloadPreseasonReport(queryParams) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    })
    const url = this.PRESEASON_URL + API_CONSTANTS.DOWNLOAD_PRESEASON_REPORT;
    //return this.apiService.post(url, payload,{}).pipe(map(response => (response)));
    return this.httpClient.get(url, { params: queryParams, headers: headers, responseType: 'blob' }).pipe(map(response => (response)));
  }

  uploadCompanyLogo(payload, queryParams, hasHeader = true) {
    const url = this.BASE_URL + CHANNEL_API_CONSTANTS.CONTRACTOR_LOGO;
    return this.apiService.post(url, payload, queryParams, false, hasHeader).pipe(map(response => response));
  }

   uploadExcelList(payload,filedata) {
    const url = this.BASE_URL + API_CONSTANTS.UPLOAD_CUSTOMER_LIST;
    return this.apiService.post(url,filedata, payload).pipe(map(response => new UploadProperyListResponse(response)));
  }

  uploadExcelListStatus(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.GET_UPLOAD_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => new UploadProperyListResponse(response)));
  }

  uploadExcelListStatusReset(queryParams) {
    const url = this.BASE_URL + API_CONSTANTS.UPLOAD_STATUS_RESET;
    return this.apiService.put(url, "",queryParams).pipe(map(response => new UploadProperyListResponse(response)));
  }

   getHVACInformationList(): Observable<HVACInformationResponse> {
    const url = this.UPDATE_RELAY_BASE_URL + API_CONSTANTS.GET_HVAC_INFORMATION;
    return this.apiService.get(url).pipe(map(response => new HVACInformationResponse(response)));
  }

   unsubscribeEmail(payload): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.UNSUBSCRIBE;
    return this.apiService.put(url,payload,{}, false, false).pipe(map(response => response));
  }

 activateContractor(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.ACTIVATE_CONTRACTOR;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }

   activateTechnician(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + API_CONSTANTS.RE_INVITE_TECHNICIAN;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }

  updateInstallationCDS(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.UPDATESWITCH;
    return this.apiService.put(url, '',queryParams).pipe(map(response => response));
  }

  getCustomerCount():Observable<CommonSuccessResponseList>{
    const url = this.BASE_URL + API_CONSTANTS.GET_CUSTOMER_COUNT;
    return this.apiService.get(url).pipe(map(response => new CommonSuccessResponseList(response)));

  }
   getSensorInstallationStatus(payload: object): Observable<InstallationReportResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_INSTALLATION_REPORT;
    return this.apiService.get(url, payload).pipe(map(response => new InstallationReportResponse(response)));
  }
   getSensorCurrentStatus(queryParams): Observable<SensorCurrentStatusResponse> {
    const url = this.BASE_URL + API_CONSTANTS.GET_SENSOR_CURRENT_STATUS;
    return this.apiService.get(url, queryParams).pipe(map(response => new SensorCurrentStatusResponse(response)));
  }
   getPreSeasonTestStatus(payload: object): Observable<any> {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.PRE_SEASON_TEST_STATUS;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }
  handleCancelPreSeasonTest(payload: object): Observable<any> {
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.CANCEL_PRE_SEASON_TEST;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }

  resloveAlert(queryParams): Observable<any> {
    const url = this.BASE_URL + API_CONSTANTS.RESOLVE_ALERT;
    return this.apiService.put(url,queryParams,'').pipe(map(response => response));
  }

   getTechnicianList(queryParams): Observable<TechnicianInfo> {
    const url = this.BASE_URL + API_CONSTANTS.GET_TECHNICIANS_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new TechnicianInfo(response)));
  }
  getAlertShortLink(payload:any):Observable<any>{
    const url = this.BASE_URL + API_CONSTANTS.GENERATE_SHORT_LINK;
    return this.apiService.get(url, payload).pipe(map(response => (response)));
  }
   shareAlerttoEmails(payload:any):Observable<any>{
    const url = this.BASE_URL + API_CONSTANTS.SHARE_ALERT;
    return this.apiService.post(url, payload).pipe(map(response => (response)));
  }

   preSeasonEnable(queryParams:any): Observable<any> {
     console.log(queryParams)
    const url = this.PRE_SEASON_BASE_URL + API_CONSTANTS.IS_PRESEASON_ENABLE;
    return this.apiService.get(url,queryParams).pipe(map(response => (response)));

  }
}
