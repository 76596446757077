import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Constants
import { BOARDTYPES, SUBSCRIPTION_PLAN_ID } from 'src/app/shared/constants/commom.constant';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';

// Enums
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { SharedService } from 'src/app/shared/services/shared.service';

// Models
import {
  AssetDetail,
  SubscriptionPlanListResponse,
  SubscriptionPlanListResponseDetails, Customer, CellularPlanListResponse, CellularPlansStatusList, CurrentCellularStatusResponse
} from 'src/app/shared/models/customer.model';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandleRefreshPage = new EventEmitter();
  @Input() isSubscriptionPlan: boolean;
  @Input() assetDetails: AssetDetail;
  @Input() customerDetails: Customer;
  subscriptionModalTitle: string;
  selectedSubscriptionTab = 'subsciption-plan';
  selectedSubscriptionType: string;
  boardTypes = BOARDTYPES;
  roleConstants = ROLE_CONSTANTS;
  subscriptionPlanList: SubscriptionPlanListResponseDetails[];
  isSubscriptionPlanLoading = false;
  currentSelectedPlanId: any;
  currentSelectedPlanName: any;
  currentSelectedPlanNameCopy: any;
  currentSelectedPlanIdCopy: any;
  isActionBtnLoading = false;
  modalType: string;
  modalTitle: string;
  confirmationModalLine1Content: string;
  confirmationModalLine2Content: string;
  confirmationModalFlag: string;
  isConfirmationModalHasYesOrNoButton: boolean;
  isShowConfirmationModal = false;
  isCellularPlanLoading = false;
  cellularPlanList: any;
  defaultCellularPlan: number;
  currentSelectedCellularPlan: number;
  currentSelectedCellularPlanCopy: number;
  portalName: string;
  subscriptionPlanId = SUBSCRIPTION_PLAN_ID;
  currentSelectedCellularStatus: any;

  constructor(
    private sharedService: SharedService,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {

    this.cellularPlanList = [
      {
        title: 'Active',
        subTitle: 'Currently Sending Data'
      },
      // {
      //   title: 'Suspend',
      //   subTitle: 'Put Data Activity On Hold'
      // },
      // {
      //   title: 'Deactivate',
      //   subTitle: 'De-activate This Plan'
      // }
    ]

    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });
    if (this.portalName === ROLE_CONSTANTS.CONTRACTOR) {
      this.getSubscriptionPlanList();
      this.subscriptionModalTitle = (this.customerDetails &&
        this.customerDetails.customerName ? this.customerDetails.customerName :
        this.customerDetails.customerFirstName + ' ' + this.customerDetails.customerLastName) + `'s ` + 'Subscription';
    } else {
      this.subscriptionModalTitle = 'Cellular Plan';
    }
    // this.getCellularPlanList();
    if (!this.isSubscriptionPlan &&
      (this.assetDetails && this.assetDetails.deviceType === BOARDTYPES.Celluar)) {
      this.selectedSubscriptionTab = 'cellular-plan';
    }
    if (this.assetDetails && this.assetDetails.deviceType === BOARDTYPES.Celluar) {
      this.getCurrentCellularStatus();

    }
  }

  getSubscriptionPlanList() {
    const queryParams = {
      category: this.assetDetails && this.assetDetails.deviceType
    };
    this.isSubscriptionPlanLoading = true;
    this.sharedService.getSubscriptionPlanList(queryParams).subscribe(
      (response: SubscriptionPlanListResponse) => {
        this.isSubscriptionPlanLoading = false;
        this.subscriptionPlanList = response && response.subscriptionPlanList;
      }, (error) => {
        this.isSubscriptionPlanLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  // getCellularPlanList() {
  //   const queryParams = {
  //     meliSerialNumber: this.assetDetails && this.assetDetails.meliSerialNumber
  //   };

  //   this.isCellularPlanLoading = true;
  //   this.sharedService.getCellularPlanList(queryParams).subscribe(
  //     (response: CellularPlanListResponse) => {
  //       this.isCellularPlanLoading = false;
  //       this.cellularPlanList = response && response.cellularPlanDetails && 
  //         response.cellularPlanDetails.cellularPlanList;
  //       this.defaultCellularPlan = response && response.cellularPlanDetails.currecntcellularPlan;
  //     }, (error) => {
  //       this.isCellularPlanLoading = false;
  //       const message = this.helperService.handleAPIErrors(error);
  //       this.helperService.showToaster(ToasterType.ERROR, message);
  //     }
  //   );
  // }

  getCurrentCellularStatus() {
    const boardSerialNumber = this.assetDetails && this.assetDetails.meliSerialNumber;

    this.isCellularPlanLoading = true;
    this.sharedService.getCurrentCellularStatus(boardSerialNumber).subscribe(
      (response: CurrentCellularStatusResponse) => {
        this.currentSelectedCellularStatus = response.responseData && response.responseData.simCardStatus;

        if (this.currentSelectedCellularStatus === 'ACTIVE') {
          this.currentSelectedCellularStatus = 'Active';
        } else if (this.currentSelectedCellularStatus === 'SUSPENDED') {
          this.currentSelectedCellularStatus = 'Suspend';
        } else {
          this.currentSelectedCellularStatus = 'Deactivate';
        }

        this.isCellularPlanLoading = false;
      }, (error) => {
        this.isCellularPlanLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    )
  }

  updateSubscriptionPlan() {
    const queryParams = {
      meliSerialNumber: this.assetDetails && this.assetDetails.meliSerialNumber,
      subscriptionPlanId: this.currentSelectedPlanId
    };

    this.isActionBtnLoading = true;
    this.sharedService.updateSubscriptionPlan(queryParams).subscribe(
      (response) => {
        this.isActionBtnLoading = false;
        this.handleRefreshPage();
        this.closeModal();
        this.helperService.showToaster(ToasterType.SUCCESS, response && response.responseData);
      }, (error) => {
        this.isActionBtnLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  updateCellularPlan() {
    const queryParams = {
      meliSerialNumber: this.assetDetails && this.assetDetails.meliSerialNumber,
      subscriptionPlanStatusId: this.currentSelectedCellularPlan
    };

    this.isActionBtnLoading = true;
    this.sharedService.updateCellularPlan(queryParams).subscribe(
      (response) => {
        this.isActionBtnLoading = false;
        this.handleRefreshPage();
        this.closeModal();
        this.helperService.showToaster(ToasterType.SUCCESS, response && response.responseData);
      }, (error) => {
        this.isActionBtnLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handleSubscriptionModalActionBtn() {
    if (this.confirmationModalFlag === 'upgrade-subscription') {
      this.updateSubscriptionPlan();
    } else if (this.confirmationModalFlag === 'change-cellular-plan') {
      this.updateCellularPlan();
    }
  }

  handleConfirmationModalAction() {
    if (this.confirmationModalFlag === 'upgrade-subscription') {
      this.currentSelectedPlanId = this.currentSelectedPlanIdCopy;
      this.currentSelectedPlanName = this.currentSelectedPlanNameCopy;
      this.closeConfirmationModal();
    } else if (this.confirmationModalFlag === 'change-cellular-plan') {
      this.currentSelectedCellularPlan = this.currentSelectedCellularPlanCopy;
      this.closeConfirmationModal();
    }
  }

  handleModalType(type: string, confirmationFlag?: string, value?: any) {
    this.modalType = type;
    if (type === 'confirmation-modal') {
      this.modalTitle = 'Warning!';
      if (confirmationFlag === 'change-subscription') {
        this.confirmationModalFlag = 'upgrade-subscription';
        this.confirmationModalLine1Content = `Are you sure you want to 
          ${value && value.planSubscriptionName === SUBSCRIPTION_PLAN_ID.PREMIUM_ID ? 'downgrade' : 'upgrade'} 
          the subscription plan for ${(this.customerDetails &&
            this.customerDetails.customerName ? this.customerDetails.customerName :
            this.customerDetails.customerFirstName + ' ' + this.customerDetails.customerLastName)}?`;
        this.confirmationModalLine2Content = `This 
        ${value && value.planSubscriptionName === SUBSCRIPTION_PLAN_ID.PREMIUM_ID ? 'downgrade' : 'upgrade'} will begin a new 12 month subscription period.`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.currentSelectedPlanNameCopy = value.planSubscriptionName;
        this.currentSelectedPlanIdCopy = value.subscriptionPlanId;
      } else if (confirmationFlag === 'change-cellular-plan') {
        this.confirmationModalFlag = 'change-cellular-plan';
        this.confirmationModalLine1Content = `Are you sure you want to change 
          the cellular plan for ${(this.customerDetails &&
            this.customerDetails.customerName ? this.customerDetails.customerName :
            this.customerDetails.customerFirstName + ' ' + this.customerDetails.customerLastName)}?`;
        this.isConfirmationModalHasYesOrNoButton = true;
        this.currentSelectedCellularPlanCopy = value;
      }
      this.showConfirmationModal();
    }
  }

  showConfirmationModal() {
    this.isShowConfirmationModal = true;
  }

  closeConfirmationModal() {
    this.isShowConfirmationModal = false;
  }

  handleTabOnChange(value: string) {
    this.selectedSubscriptionTab = value;
  }

  handleRefreshPage() {
    this.emitHandleRefreshPage.emit();
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}
