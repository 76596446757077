import { Component, OnInit } from '@angular/core';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';

declare const branch:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isShowToaster = false;
  toasterDetails = {
    type: '',
    message: ''
  };
  theme = contractorEnvironment.theme;

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit() {
    this.setListenerForToaster();
    // load Branch
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
    // init Branch
    branch.init(`${contractorEnvironment.branchKey}`);
  }

  setListenerForToaster() {
    this.helperService.setListenerForToaster().subscribe(
      (data) => {
        this.showToaster(data.type, data.message);
      }
    );
  }

  showToaster(type: string, message: string) {
    this.isShowToaster = true;
    this.toasterDetails = {
      type,
      message
    };

    setTimeout(() => {
      this.isShowToaster = false;
    }, 5000);
  }
}
