export const environment = {
  production: false,
  loginBaseUrl: 'http://18.116.149.129:8080/cognito' ||
    'https://k2zn8ojkke.execute-api.us-east-2.amazonaws.com/v1',
  baseUrl: 'http://18.116.149.129:8081/yantra' || 'https://prod-cpgoodman.ihvac.io/yantra',
  updateRelayBaseUrl: 'http://18.216.111.167:8088/yantra' || 'https://prod-yantraconsole.ihvac.io/yantra',
  phoneCodesRestAPIBaseUrl: 'https://restcountries.eu/rest/v2/all',
  lambdaBaseUrl: 'https://ohfvsknqw1.execute-api.us-east-2.amazonaws.com/dev',
  comfortBridgeUrl: 'https://n0korjfy58.execute-api.us-east-2.amazonaws.com/v1/model',
  userPoolId: 'us-east-2_vfhbHVuAz' || 'us-east-2_DSgKB2NiH',
  minCelciusValue: -18 || -18,
  maxCelciusValue: 93 || 93,
  minWaterColumnValue: 0 || 0,
  maxWaterColumnValue: 0.9 || 0.9,
  sensorMaxNegativeValue: -150 || -150,
  sensorMaxPositiveValue: 150 || 150,
  theme: 'keenhome-theme' || 'keenhome-theme',
  siteName: 'keenhome-channel',
  mapboxKey: 'pk.eyJ1IjoiY29ubmVjdG0iLCJhIjoiY2tmOG9xMzhmMGVlODJ5bzBrZGM3dzNpeSJ9.gZfzhnzROQ_NDyJSCR_8Xg',
  stripeKey: 'pk_test_MLqe6uLxvPk209PiEgblk9oq00uyn3cgbJ',
  branchKey: 'key_test_ee8Fm0vzc9rXRmGaNH5rhochwEcwLlq0',
  termsOfUse: 'https://keenhome.io/pages/terms' || 'https://www.coolcloudhvac.com/terms-of-use',
  privacyPolicy: 'https://keenhome.io/pages/privacy' || 'https://www.coolcloudhvac.com/privacy-policy'
};




// CODES DEFAULTLY PRESENT IN ENVIRONMENT.TS file under environments folder.



// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   baseUrl: 'https://cp-goodman.ihvac.io/yantra',
//   // baseUrl: 'http://18.189.81.204:8081/yantra',
//   loginBaseUrl: 'https://q1w3ryo4sc.execute-api.us-east-2.amazonaws.com/dev/',
//   updateRelayBaseUrl: 'http://3.19.77.212:8088/yantra'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

