export const BASE_URL_TYPES = {
    LOGIN: 'login',
    COMMON_BASE: 'common-base',
    UPDATE_RELAY: 'update-relay',
    USER_POOL_ID: 'user-pool-id',
    PRE_SEASON_TEST: 'pre-season-test',
    LAMBDA: 'lambda',
    COMFORTBRIDGE:'comfortbridge',
    SITE_NAME: 'site-name'
};
