import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import {
  SortingTypes,
  AlertSortingKeys,
} from 'src/app/shared/constants/sorting-enum';
import { SharedService } from '../../services/shared.service';
import { AlertsResponse } from '../../models/alerts.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { COMMON_CONSTANTS } from '../../constants/commom.constant';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
@Component({
  selector: 'app-alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss']
})
export class AlertsTableComponent implements OnInit, OnChanges {
  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandleEdit = new EventEmitter();
  @Output() emitHandleModalType = new EventEmitter<{ $eventtype, index, value }>();
  @Input() modalTitle: string;
  @Input() meliSerialNumber: string;
  @Input() type: string;
  @Input() isShowCrossMark = true;
  @Input() portalName: string;
  @Input() alertModalRefresh = true;
  AlertSkip = 0;
  AlertLimit = 50;
  AlertPage = 1;
  AlertTotalCount = 0;
  AlertTotalPage = 0;
  paginationFor: string;
  AlertsList: any;
  placeHolder: string;
  roleConstants = ROLE_CONSTANTS;
  AlertCurrentSortingField = AlertSortingKeys.TIME;
  AlertCurrentSortingType = SortingTypes.DESCENDING;
  isShowAlertsLoading: boolean = true;
  commonConstants = COMMON_CONSTANTS;
  sortingConstants = AlertSortingKeys;
  isTimeColumnSort: boolean = false;
  sortingTypes = SortingTypes;
  constructor(private sharedService: SharedService, private helperService: HelperService) { }

  ngOnInit() {
    this.getAlertsList();
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.alertModalRefresh)
    if (this.alertModalRefresh == true) {
      this.getAlertsList()
    }
  }
  getAlertsList() {
    const queryParams: any = {
      size: this.AlertLimit,
      index: this.AlertPage,
      meliSerialNo: this.meliSerialNumber,
      type: this.type,
      timeZone: momenttimezone.tz.guess()
    };
    if (this.AlertCurrentSortingField) {
      queryParams.sortingField = this.AlertCurrentSortingField;
      queryParams.sortingType = this.AlertCurrentSortingType;
    }
    this.isShowAlertsLoading = true;
    this.sharedService.getAlertsList(queryParams).subscribe(
      (response: AlertsResponse) => {
        this.AlertsList = response.alertsResonse.alertsList;
        this.AlertTotalCount = response.alertsResonse.totalNumberOfElements;
        this.AlertTotalPage = response.alertsResonse.totalPage;
        // this.sharedService.setAlertsList(response.alertsResonse.alertsList);
        // this.handleInitialSorting('pastAlert');
        this.isShowAlertsLoading = false;
        // if (this.pastAlertsList && this.pastAlertsList.length) {
        //   this.handleCustomerDetails(this.pastAlertsList);
        // }
      },
      (error) => {
        this.isShowAlertsLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  handlePagination(payload) {
    const type = payload.paginationType;
    if (type === this.commonConstants.PAGINATION_NEXT) {
      this.AlertPage += 1;
      this.AlertSkip =
        this.AlertPage * this.AlertLimit - this.AlertLimit;
    } else if (type === this.commonConstants.PAGINATION_PREVIOUS) {
      this.AlertPage -= 1;
      this.AlertSkip =
        this.AlertPage * this.AlertLimit - this.AlertLimit;
    } else if (type === this.commonConstants.PAGINATION_FIRST) {
      this.AlertLimit = 50;
      this.AlertPage = 1;
      this.AlertSkip = 0;
    } else if (type === this.commonConstants.PAGINATION_LAST) {
      this.AlertPage = this.AlertTotalPage;
      this.AlertSkip =
        this.AlertPage * this.AlertLimit - this.AlertLimit;
    }
    this.getAlertsList();
  }
  handleModelRequest($eventtype: string, index?: number, value?: any) {
    console.log({ $eventtype, index, value })
    this.emitHandleModalType.emit({ $eventtype, index, value })
  }
  handleSorting(tableType: string, key: string, key1?: string) {

    if (key === this.sortingConstants.TIME) {
      // this.resetPreviousSorting(tableType, key);
      this.isTimeColumnSort = !this.isTimeColumnSort;
      // this.pastAlertsList = this.helperService.handleDateSorting(this.pastAlertsList,
      //   this.isPastTimeColumnSort, key);
      this.AlertCurrentSortingField = this.sortingConstants.TIME;
      this.AlertCurrentSortingType = this.isTimeColumnSort
        ? this.sortingTypes.ASCENDING
        : this.sortingTypes.DESCENDING;
      //this.LastSortedColumn = this.sortingConstants.TIME;
    }

    this.getAlertsList();
  }

  trackByAlertId(index: number, item: any): string {
    return item ? item.alertId : '';
  }

}
