import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/shared/services/helper.service';
//import { loadStripe } from '@stripe/stripe-js/pure';
// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BillingListResponse, BillingListResponseDetails } from 'src/app/shared/models/customer.model';
import * as moment from 'moment';
import { successResponse } from '../../models/billingaccount.model';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { environment as channelEnvironment } from 'projects/channel/src/environments/environment';
import { environment as mduEnvironment } from 'projects/mdu/src/environments/environment';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import { ActivatedRoute } from '@angular/router';
declare var Stripe: any
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  @Output() emitCloseModal = new EventEmitter();
  @Input() isUpdateCard = false;
  @Input() isUpdateAch = false;
  @Input() isOnlogin = false;
  @Input() cardDetails;
  @Input() modalTitle: string;
  stripe: any;
  elements: any;
  card: any;
  selectedBillingTab = 'credit-card';
  creditCardForm: FormGroup;
  achForm: FormGroup;
  editcreditCardForm: FormGroup;
  achVerifyForm: FormGroup;
  billingAddressForm: FormGroup;
  cardNumberValid: boolean = false;
  cvcValid: boolean = false;
  cardExpiryValid: boolean = false;
  postalCodeValid: boolean = false;
  isCreditButton: boolean = true;
  isAchButton: boolean = false;
  isBillingButton: boolean = true
  customerKey: any;
  isLoading: boolean = false;
  billingList: BillingListResponseDetails[];
  isBillingTabLoading = false;
  editingAccountData: any;
  storeCardDetails: any;
  storeAchDetails: any;
  isCreditCardPrimary: boolean = false;
  isAccountPrimary: boolean = false;
  billingDetails: any;
  isShowConfirmationModal: boolean = false;
  confirmationModalLine1Content: string;
  operation: any;
  cardType: any;
  isVerificationModal: boolean = false;
  isAchVerified: boolean;
  isPrimaryActionBtnLoading: boolean = false;
  portalName: any;
  stripeKey: any;
  isShowCrossMark: any = false;
  constructor(private helperService: HelperService, private elementRef: ElementRef,
    private sharedService: SharedService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });
    this.creditCardForm = new FormGroup({ cardName: new FormControl('', Validators.required), });
    this.editcreditCardForm = new FormGroup({
      editcardName: new FormControl({ value: '', disabled: true }, Validators.required,),
      editcardNumber: new FormControl({ value: '', disabled: true }, Validators.required),
      editcardCvc: new FormControl({ value: '', disabled: true }, Validators.required),
      editcardExpiry: new FormControl('', [Validators.required, this.helperService.validateExpiry]),
      editcardZipCode: new FormControl({ value: '', disabled: true }, Validators.required)
    })
    this.billingAddressForm = new FormGroup({
      address: new FormControl('', Validators.required),
      unitAptNo: new FormControl(''),
      city: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
      state: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z \-\']+')]),
      zipcode: new FormControl('', Validators.required),
    })
    this.achForm = new FormGroup({
      accNumber: new FormControl('', Validators.required),
      accType: new FormControl('', Validators.required),
      routing: new FormControl('', Validators.required),
      accHolderName: new FormControl('', Validators.required),
    });

    this.achVerifyForm = new FormGroup({
      firstDeposit: new FormControl('', Validators.required),
      secondDeposit: new FormControl('', Validators.required),
    });
    if (this.isUpdateCard || this.isUpdateAch) {
      this.getCardEdit();
    }
    this.getClientKey();
    this.getBillingList();
    //this.editCards();
  }

  ngOnChanges() {
    if (this.isUpdateCard || this.isUpdateAch) {
      this.getCardEdit();
    }

  }
  ngAfterViewInit() {
    this.createCreditCardForm();
  }

  handleTabOnChange(value: string) {
    this.selectedBillingTab = value;
    if (value == 'credit-card') {
      this.creditCardForm.reset();
      this.isCreditButton = true;
      this.isAchButton = false;
      setTimeout(() => {
        this.createCreditCardForm()
      }, 1000)
    }
    else if (value == 'ach') {
      if (!this.isUpdateAch) {
        this.achForm.reset();
      }
      this.isAchButton = true;
      this.isCreditButton = false;
    }
    else if (value == 'billing-history') {
      this.isAchButton = false;
      this.isCreditButton = false;
    }
  }

  //create stripe credit card form for creditcard
  createCreditCardForm() {
    if (this.portalName === ROLE_CONSTANTS.CONTRACTOR) {
      this.stripeKey = contractorEnvironment.stripeKey;
    }
    if (this.portalName === ROLE_CONSTANTS.CHANNEL) {
      this.stripeKey = channelEnvironment.stripeKey;
    }
    if (this.portalName === ROLE_CONSTANTS.MDU) {
      this.stripeKey = mduEnvironment.stripeKey;
    }
    //loadStripe.setLoadParameters({ advancedFraudSignals: false })
    this.stripe = Stripe(this.stripeKey);
    //this.stripe = await loadStripe('pk_test_MLqe6uLxvPk209PiEgblk9oq00uyn3cgbJ');
    this.elements = this.stripe.elements();
    let formClasses = {
      base: 'width_100-percent p10 background-color_white-secondary border_transparent_01 outline_none line-height_14 border-radius_5 font-size_13',
      invalid: 'error',
    }
    let style = {
      base: {
        '::placeholder': {
          color: '#A5A5A5',
        },
      }
    }
    this.card = this.elements.create('cardNumber', { classes: formClasses, style: style, placeholder: 'XXXX-XXXX-XXXX-2986' });
    this.card.mount("#cnumber");
    this.card = this.elements.create('cardCvc', { classes: formClasses, style: style, placeholder: 'XXX' });
    this.card.mount("#cvc");
    this.card = this.elements.create('cardExpiry', { classes: formClasses, style: style, placeholder: '00 / 00' });
    this.card.mount("#cexpiry");
    // this.card = this.elements.create('postalCode', { classes: formClasses });
    // this.card.mount("#postalcode");
    this.creditCardEventHandling();
  }

  /* event handing for creditcard fields*/
  creditCardEventHandling() {
    this.elements.getElement('cardNumber').on('change', (event) => {
      var displayError = document.getElementById('number-card-error');
      console.log(event)
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
        this.cardNumberValid = true;
      }
    });

    this.elements.getElement('cardCvc').on('change', (event) => {
      var displayError = document.getElementById('cvc-card-error');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
        this.cvcValid = true;
      }
    });

    this.elements.getElement('cardExpiry').on('change', (event) => {
      var displayError = document.getElementById('expiry-card-error');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
        this.cardExpiryValid = true;
      }
    });

    // this.elements.getElement('postalCode').on('change', (event) => {
    //   var displayError = document.getElementById('postal-card-error');
    //   if (event.error) {
    //     displayError.textContent = event.error.message;
    //   } else {
    //     displayError.textContent = '';
    //     this.postalCodeValid = true;
    //   }
    // });

  }

  /*Validating the credit card details*/
  validateCreditCardForm() {
    if (this.isUpdateCard) {
      if (this.editcreditCardForm.valid && this.billingAddressForm.valid) {
        this.updateCreditCard()
      }
      else {
        this.editcreditCardForm.markAllAsTouched();
        this.billingAddressForm.markAllAsTouched();
        this.helperService.scrollToFirstInvalidControl(this.editcreditCardForm, this.elementRef.nativeElement);

      }
    }
    else {
      if (this.creditCardForm.valid && this.cardNumberValid && this.cvcValid && this.cardExpiryValid && this.billingAddressForm.valid) {
        if (this.customerKey) {
          this.submitCreditCard();
        }
        else {
          this.helperService.showToaster(ToasterType.ERROR, 'User key not found');
        }
      }
      else {
        this.creditCardForm.markAllAsTouched();
        this.billingAddressForm.markAllAsTouched();
        this.helperService.scrollToFirstInvalidControl(this.creditCardForm, this.elementRef.nativeElement);
        let Errorclasses = {
          empty: 'error'
        }
        if (!this.cardNumberValid) {
          this.elements.getElement('cardNumber').update({ classes: Errorclasses });
          let displayError = document.getElementById('number-card-error');
          displayError.textContent = 'Required'
        }
        if (!this.cvcValid) {
          this.elements.getElement('cardCvc').update({ classes: Errorclasses });
          let displayError = document.getElementById('cvc-card-error');
          displayError.textContent = 'Required'
        }
        if (!this.cardExpiryValid) {
          this.elements.getElement('cardExpiry').update({ classes: Errorclasses });
          let displayError = document.getElementById('expiry-card-error');
          displayError.textContent = 'Required'
        }
        // if (!this.postalCodeValid) {
        //   this.elements.getElement('postalCode').update({ classes: Errorclasses });
        //   let displayError = document.getElementById('postal-card-error');
        //   displayError.textContent = 'Required'
        // }
      }
    }
  }



  //get client Key for creditcard
  getClientKey() {
    this.sharedService.getCustomerKey().subscribe(res => {
      if (res.responseData) {
        this.customerKey = res.responseData.clientSecret;
        //this.submitCreditCard();
      }
    }, err => {
      // let message= err?err.error.statusInfo:'Client S'
      //this.helperService.showToaster(ToasterType.ERROR, err.error.statusInfo);
    })
  }

  //submitting the creditcard details for stripe account
  submitCreditCard() {
    this.isLoading = true;
    const name = this.creditCardForm.get('cardName').value;
    if (this.customerKey) {
      this.stripe.confirmCardSetup(this.customerKey, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: name,
          }
        },
      }).then((result) => {
        if (result) {
          console.log(result);
          this.sendToken(result);
        } else if (result.error) {
          console.log(result.error.message);
          const message = this.helperService.handleAPIErrors(result.error.message);
          this.helperService.showToaster(ToasterType.ERROR, message);
          this.isLoading = false;
        }
      }).catch(error => { console.log(error) });
    }
    else {
      this.isLoading = false;
      const message = 'Internal error has been occured .Please try again later';
      this.helperService.showToaster(ToasterType.ERROR, message);
    }

  }


  sendToken(data) {
    let queryparams = {
      paymentMethodKey: data.setupIntent.payment_method,
      address: this.billingAddressForm.value,
    }
    this.sharedService.sendCreditCardToken(queryparams).subscribe((res: successResponse) => {
      this.helperService.showToaster(ToasterType.SUCCESS, res.responseData);
      this.emitCloseModal.emit();
      this.isLoading = false;

    }, err => {
      this.helperService.showToaster(ToasterType.ERROR, 'Something went wrong');
      this.isLoading = false;
    })
  }

  /*submitting the ach detaisl*/
  validateAchForm() {
    if (this.achForm.valid) {
      this.isUpdateAch ? this.updateAch() : this.submitAchCard();
    }
    else {
      this.achForm.markAllAsTouched();
      this.helperService.scrollToFirstInvalidControl(this.achForm, this.elementRef.nativeElement);

    }
  }

  submitAchCard() {
    this.isLoading = true;
    const bankAccountData = {
      country: 'us',
      currency: 'usd',
      account_number: this.achForm.get('accNumber').value,
      account_holder_type: this.achForm.get('accType').value,
      routing_number: this.achForm.get('routing').value,
      account_holder_name: this.achForm.get('accHolderName').value,
    };
    this.stripe.createToken('bank_account', bankAccountData).then((result) => {
      if (result.token) {
        console.log(result.token)
        const successmessage = result.token.id;
        //this.helperService.showToaster(ToasterType.SUCCESS, successmessage);
        this.sendAchToken(successmessage);
      } else if (result.error) {
        // Error creating the token
        console.log(result.error.message);
        const message = result.error.message;
        //this.helperService.handleAPIErrors(result.error.message);
        this.helperService.showToaster(ToasterType.ERROR, message);
        this.isLoading = false;
      }
    });
  }

  sendAchToken(data) {
    let queryparams = {
      accountToken: data
    }
    this.sharedService.sendAchToken(queryparams).subscribe(res => {
      this.helperService.showToaster(ToasterType.SUCCESS, res.responseData);
      this.emitCloseModal.emit();
      this.isLoading = false;
    }, err => {
      this.helperService.showToaster(ToasterType.ERROR, 'Something went wrong');
      this.isLoading = false;
    })

  }


  //validate AChverifyForm
  validateAchVerificationForm() {
    if (this.achVerifyForm.valid) {
      this.submitAchVerification();
    }
    else {
      this.achVerifyForm.markAllAsTouched();
      this.helperService.scrollToFirstInvalidControl(this.achVerifyForm, this.elementRef.nativeElement);
    }
  }

  submitAchVerification() {
    this.isLoading = true;
    let queryparams = {
      paymentMethodId: this.storeAchDetails.db_id,
      firstDeposit: this.achVerifyForm.get('firstDeposit').value,
      secondDeposit: this.achVerifyForm.get('secondDeposit').value
    }
    this.sharedService.verifyAch(queryparams).subscribe(res => {
      this.helperService.showToaster(ToasterType.SUCCESS, res.responseData);
      this.emitCloseModal.emit();
      this.isLoading = false;
    }, err => {
      this.helperService.showToaster(ToasterType.ERROR, err.error.statusInfo);
      this.isLoading = false;
    })
  }


  // get the creditcard details
  getCardEdit() {
    let data = this.cardDetails.some(v => v.type == "card")
    data ? this.handleTabOnChange('credit-card') : this.handleTabOnChange('ach')
    this.storeAchDetails = "";
    this.storeAchDetails = "";
    console.log(this.cardDetails);
    this.cardDetails.forEach(card => {
      if (card.type == 'card') {
        card.is_default ? this.isCreditCardPrimary = true : this.isCreditCardPrimary = false;
        this.storeCardDetails = card;
        this.editCards(card);
      }
      else if (card.type == "account") {
        card.is_default ? this.isAccountPrimary = true : this.isAccountPrimary = false;
        this.storeAchDetails = card;
        this.storeAchDetails.verified == 'verified' ? this.isAchVerified = true : this.isAchVerified = false;
        this.editAch(card);
      }
    }
    );

  }


  //set the stored values to the form to update
  editCards(data) {

    this.editcreditCardForm.patchValue({
      editcardName: data.name,
      editcardNumber: data.card_number,
      editcardCvc: data.security_code,
      editcardExipry: '',
      editcardZipCode: data.postal_code,
    });

    let M = data.exp_month;
    let M1 = moment(M, "MM");
    let M2 = M1.format('MM')
    let Y = data.exp_year;
    let Y1 = moment(Y, "YYYY");
    let Y2 = Y1.format('YY')
    let fullyear = (M2 + Y2).toString()
    console.log(Y2);
    this.handleFormatExpiry(fullyear);


    this.billingAddressForm.patchValue({
      address: data.address.address,
      unitAptNo: data.address.unitAptNo,
      city: data.address.city,
      state: data.address.state,
      zipcode: data.address.zipcode,
    })
  }

  editAch(data) {
    this.achForm.patchValue({
      accNumber: data.account_number,
      accType: data.account_holder_type,
      routing: data.routing_number,
      accHolderName: data.account_holder_name,
    });
    this.achForm.controls['accNumber'].disable();
    this.achForm.controls['routing'].disable();
  }

  handleFormatExpiry(phoneNumber: any) {
    console.log(phoneNumber)
    const number = this.helperService.handleFormatExpiry(phoneNumber);
    this.editcreditCardForm.patchValue({
      editcardExpiry: number
    });
  }
  handleFormatAmount(amount: any, field) {
    const number = this.helperService.validateAmount(amount);
    if (field == 'firstfield') {
      this.achVerifyForm.patchValue({
        firstDeposit: number
      });
    }
    else {
      this.achVerifyForm.patchValue({
        secondDeposit: number
      });
    }
  }
  //updating the ach and credit card
  updateCreditCard() {
    let date = new Date();
    let currentyear = date.getFullYear();
    let givenyear = this.editcreditCardForm.get('editcardExpiry').value.split("/");
    let year = moment(givenyear[1], "YY");
    let fullyear = year.format('YYYY');
    let updateaddress = this.billingAddressForm.value;
    updateaddress.addressId = this.storeCardDetails.address.addressId

    this.isLoading = true;
    const creditCardData = {
      dbId: this.storeCardDetails.db_id,
      expierMonth: givenyear[0],
      expierYear: fullyear,
      address: updateaddress
    };
    this.sharedService.updateCreditCard(creditCardData).subscribe((result) => {
      this.helperService.showToaster(ToasterType.SUCCESS, result['responseData']);
      this.emitCloseModal.emit();

      this.isLoading = false;
    }, error => {
      let err = error.statusInfo ? error.statusInfo : 'Error';
      this.helperService.showToaster(ToasterType.ERROR, err);
      this.isLoading = false;
    });
  }
  updateAch() {
    this.isLoading = true;
    const bankAccountData = {
      dbId: this.storeAchDetails.db_id,
      accountHolderType: this.achForm.get('accType').value,
      accountHolderName: this.achForm.get('accHolderName').value,
    };
    this.sharedService.updateAch(bankAccountData).subscribe((result) => {
      this.helperService.showToaster(ToasterType.SUCCESS, result['responseData']);
      this.emitCloseModal.emit();
      this.isLoading = false;
    }, error => {
      //let err = error.statusInfo;
      this.helperService.showToaster(ToasterType.ERROR, 'Error');
      this.isLoading = false;
    });
  }

  getBillingList() {
    this.isBillingTabLoading = true;
    this.sharedService.getBillingList().subscribe(
      (response: BillingListResponse) => {
        this.billingList = response && response.billingList;
        this.isBillingTabLoading = false;
      }, (error) => {
        this.isBillingTabLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
  }

  handleConformation(operation, card) {
    if (operation == 'removecard') {
      if (card == 'CARD') {
        this.confirmationModalLine1Content = 'Do you want to remove Credit Card information?';
        this.operation = operation;
        this.cardType = card;
      } else if (card == 'ACH') {
        this.confirmationModalLine1Content = 'Do you want to remove ACH information?';
        this.operation = operation;
        this.cardType = card;
      }
      this.toggleConfirmationModal();
    }
    else if (operation == 'makecardprimary') {
      if (card == 'CARD') {
        this.confirmationModalLine1Content = 'Do you want to make credit card as your primary funding source?';
        this.operation = operation;
        this.cardType = card;
      } else if (card == 'ACH') {
        this.confirmationModalLine1Content = 'Do you want to make ACH as your primary funding source?';
        this.operation = operation;
        this.cardType = card;
      }
      this.toggleConfirmationModal();
    }
    else if (operation == 'verifyaccount') {
      this.isVerificationModal = true;
    }
  }

  handleConfirmationModalEmitter() {
    if (this.operation == 'removecard') {
      this.removeCard(this.cardType)
    }
    else if (this.operation = 'makecardprimary') {
      this.makeCardPrimary(this.cardType)
    }

  }

  removeCard(type: string) {
    this.isPrimaryActionBtnLoading = true;
    const queryParam = {
      paymentMethodId: ''
    };
    if (type == "ACH") {
      queryParam.paymentMethodId = this.storeAchDetails.db_id;
    }
    else if (type == "CARD") {
      queryParam.paymentMethodId = this.storeCardDetails.db_id;
    }

    //this.isBillingTabLoading = true;
    this.sharedService.deleteBillingInfo(queryParam).subscribe(
      (response) => {
        const message = response['responseData'];
        this.helperService.showToaster(ToasterType.SUCCESS, message);
        this.hideResetForm(type);
        this.toggleConfirmationModal();
        // this.checkBillingInformation();
        this.isPrimaryActionBtnLoading = false;
      }, (error) => {
        //this.isBillingTabLoading = false;
        this.isPrimaryActionBtnLoading = false;
        // this.hideResetForm(type);
        // this.checkBillingInformation();
        this.toggleConfirmationModal();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  hideResetForm(data) {
    if (data == 'CARD') {
      this.isUpdateCard = false;
      this.handleTabOnChange('credit-card');
      this.billingAddressForm.reset();
    }
    else {
      this.isUpdateAch = false;
      this.achForm.controls['accNumber'].enable();
      this.achForm.controls['routing'].enable();
      this.handleTabOnChange('ach')
    }

  }


  makeCardPrimary(type) {
    this.isPrimaryActionBtnLoading = true;
    const queryParam = {
      paymentMethodId: ''
    };
    if (type == "ACH") {
      queryParam.paymentMethodId = this.storeAchDetails.db_id;
    }
    else if (type == "CARD") {
      queryParam.paymentMethodId = this.storeCardDetails.db_id;
    }

    //this.isBillingTabLoading = true;
    this.sharedService.makeDefaultPayment(queryParam).subscribe(
      (response) => {
        const message = response.responseData;
        this.helperService.showToaster(ToasterType.SUCCESS, message);
        this.checkBillingInformation();
        this.toggleConfirmationModal();
        //this.hideResetForm(type);
        // this.checkBillingInformation();
        this.isPrimaryActionBtnLoading = false;

      }, (error) => {
        //this.isBillingTabLoading = false;
        this.isPrimaryActionBtnLoading = false;

        //this.hideResetForm(type);
        // this.checkBillingInformation();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  checkBillingInformation() {
    this.sharedService.getBillingDetails().subscribe((result) => {
      this.cardDetails = result.responseData;
      this.getCardEdit()
      // this.checkBillingValidity(this.billingDetails);
    })
  }

  // checkBillingValidity(data) {
  //   data.forEach(card => {
  //     if (card.type == "card") {
  //       this.isUpdateCard = true;
  //     }
  //     else if (card.type == "account") {
  //       this.isUpdateAch = true;
  //     }
  //   });
  // }

  handlePlacesAutoComplete(element: ElementRef, type: string, index?: number) {
    this.helperService.handleAddress(element, type, index);
    const addressListener = this.helperService.setListenerForAddressObject().subscribe(
      (response: any) => {
        if (response.type === 'billing-address') {
          this.billingAddressForm.patchValue({
            address: response.street,
            city: response.city,
            state: response.state,
            zipcode: response.zipcode
          });
        }
        addressListener.unsubscribe();
      }, (error) => {
        addressListener.unsubscribe();
      }
    );

  }

  closeModal() {
    this.emitCloseModal.emit();
  }

}
