import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
unsubscribeForm: FormGroup;
  emailList: any = [];
  data: any;
  isShowInfoModal: boolean=false;
  confirmationModalLine1Content: string="";
  isButtonDisabled=false;
  confirmationModalLine2Content: string;
  isSuccessTosterModal:boolean=false;
  modalTitle: string;
  show: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router:Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.show=false;
    //console.log(this.initNavSettings(history.state.navSettings););
    if(localStorage.getItem('unsubscribe'))
    {
        this.data = JSON.parse(localStorage.getItem('unsubscribe'))
        this.show=true;
    }
    else
    {
      this.show=false;
      this.router.navigate(['/login'])
    }
     //this.data = JSON.parse(localStorage.getItem('unsubscribe'))
   
    this.emailList = this.data.email;
     this.unsubscribeForm = this.formBuilder.group({
      emails: this.formBuilder.array([], [Validators.required])
    })
     const emails: FormArray = this.unsubscribeForm.get('emails') as FormArray;
    this.emailList.forEach(element => {
       emails.push(new FormControl(element));
    });

    this.submitData();
  }

  // onCheckboxChange(e) {
  //   e.preventDefault();
  //   const emails: FormArray = this.unsubscribeForm.get('emails') as FormArray;
  //   if (e.target.checked) {
  //     emails.push(new FormControl(e.target.value));
  //   } else {
  //      const index = emails.controls.findIndex(x => x.value === e.target.value);
  //      emails.removeAt(index);
  //   }
  //   emails.length>0? this.isButtonDisabled=false: this.isButtonDisabled=true;
  // }

  toggleunsubscribeModal()
  {
    this.isShowInfoModal = !this.isShowInfoModal;
  }
  closeModal()
  {
     this.helperService.handleLogoutAPISuccessResponse();
    this.sharedService.setContractorInformation();
 this.router.navigate(['/login'])
  }
  handleModal(type)
  {
    if(type=="showpopup")
    {
      this.confirmationModalLine1Content="Please confirm that you would like to unsubscribe";
      this.toggleunsubscribeModal();
    }
  }
  submitData(){
    let selectedEmail = this.unsubscribeForm.get('emails').value;
    selectedEmail.forEach(email => {
       let payload: any = {
      userId:this.data.userId,
      userRole:this.data.userRole, 
      email:email,
      subscription:false
    }

    console.log(payload)
    this.sharedService.unsubscribeEmail(payload).subscribe(res=>{
       this.confirmationModalLine1Content="You have successfully unsubscribed from all optional notifications.";
     this.confirmationModalLine2Content="Note that all account-related notifications will still continue to be sent.";
     this.isSuccessTosterModal=true;
     this.modalTitle="Warning";
     this.toggleunsubscribeModal();
   },err=>{
     if(err.error.statusInfo)
       {
         this.confirmationModalLine1Content=err.error.statusInfo;
          this.confirmationModalLine2Content="";
    }
     else{
         this.confirmationModalLine1Content="Issue in unsubscribe";
     this.confirmationModalLine2Content="Please try after sometime";
     }

     this.isSuccessTosterModal=false;
     this.modalTitle="Warning";
     this.toggleunsubscribeModal();
   });
   
   localStorage.removeItem('unsubscribe');
    });
     
  }
  
}
