import { Email, Phone, Address } from 'src/app/shared/models/common.model';
export class JobTechnicianInfo {
  alertId: string;
  meliSerialNo: string;
  contractorId: number;
  technicianId: number;
  technicianName: string;
  revenueTag?: string;
  jobId: number;
  resolvedStatus: boolean;
  resolvedTime?: string;
  remarks?: any;

  constructor(data) {
    this.alertId = data.alertId;
    this.meliSerialNo = data.meliSerialNo;
    this.contractorId = data.contractorId;
    this.technicianId = data.technicianId;
    this.technicianName = data.technicianName;
    this.revenueTag = data.revenueTag;
    this.jobId = data.jobId;
    this.resolvedStatus = data.resolvedStatus;
    this.resolvedTime = data.resolvedTime;
    this.remarks = data.remarks;
  }
}
export class ReadMore {
  alertId: string;
  comment: string;
  commentdId: number;
  createdTime: string;
  technicianAwsId: string;
  updatedTime: string;

  constructor(data) {
    this.alertId = data.alertId;
    this.comment = data.comment;
    this.commentdId = data.commentdId;
    this.createdTime = data.createdTime;
    this.technicianAwsId = data.technicianAwsId;
    this.updatedTime = data.updatedTime
  }
}

export class PhoneAndEmail {
  mobile: any[];
  home: any[];
  office: string[];

  constructor(data) {
    this.mobile = data.mobile;
    this.home = data.home;
    this.office = data.office;
  }
}

export class Consumer {
  customerId: number;
  customerName: string;
  addresses: Address[];
  phone: Phone[];
  email: Email[];
  consumerType:string;
  companyName:string;


  constructor(data) {
    this.customerId = data.customerId;
    this.customerName = data.customerName;
    this.addresses = data.addresses && data.addresses.length &&
      data.addresses.map((value) => new Address(value));
    this.phone = data.phone && data.phone.length &&
      data.phone.map((value) => new Phone(value));
    this.email = data.email && data.email.length &&
      data.email.map((value) => new Email(value));
      this.consumerType=data.consumerType;
      this.companyName= data.companyName;
  }
}

export class Alert {
  alertId?: string;
  alertCode?: string;
  alertName: string;
  alertStatus: string;
  deviceId: string;
  meliSerialNo?: string;
  meliStatus?: any;
  priority: string;
  boardType: string;
  timeStamp: string;
  displayTime: string;
  clearedTimestamp: string;
  consumer: Consumer;
  jobTechnicianInfo?: JobTechnicianInfo;
  readMore: ReadMore;
  minorAlerts: number;
  majorAlerts: number;
  indoorEquipmentLocation:string;
  shortLink:string;

  constructor(data) {
    this.alertId = data.alertId;
    this.alertCode = data.alertCode;
    this.alertName = data.alertName;
    this.alertStatus = data.alertStatus;
    this.deviceId = data.deviceId;
    this.meliSerialNo = data.meliSerialNo;
    this.meliStatus = data.meliStatus;
    this.priority = data.priority;
    this.boardType = data.boardtype;
    this.timeStamp = data.timestamp;
    this.displayTime = data.displayTime;
    this.clearedTimestamp = data.clearedTimestamp;
    this.consumer = data.consumer && new Consumer(data.consumer);
    this.jobTechnicianInfo =
      data.jobTechnicianInfo && new JobTechnicianInfo(data.jobTechnicianInfo);
    this.readMore = data.readMore && new ReadMore(data.readMore);
    this.minorAlerts = data.minorAlerts;
    this.majorAlerts = data.majorAlerts;
    this.indoorEquipmentLocation=data.indoorEquipmentLocation;
    this.shortLink=data.shortLink;
  }
}

export class AlertsDetail {
  page: number;
  size: number;
  alertsList: Alert[];
  currentPageNumber: number;
  noOfElementsInCurrentPage: number;
  totalPage: number;
  totalNumberOfElements: number;

  constructor(data) {
    this.page = data.page;
    this.size = data.size;
    this.alertsList =
      data.content &&
      data.content.length &&
      data.content.map((value) => new Alert(value));
    this.currentPageNumber = data.currentPageNumber;
    this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
    this.totalPage = data.totalPage;
    this.totalNumberOfElements = data.totalNumberOfElements;
  }
}

export class AlertsResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  alertsResonse: AlertsDetail;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.alertsResonse =
      data.responseData && new AlertsDetail(data.responseData);
    this.requestData = data.requestData;
  }
}
