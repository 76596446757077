import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AssetDetail, AssetModelInfo } from '../../models/customer.model';
import { SystemObject, ACObject, HEATPUMPObject, GasFurnaceObject } from '../../models/dataac.model';
import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';
import { HVAC_ID } from '../../constants/commom.constant';
@Component({
  selector: 'app-hvac-comfortbridge-modal',
  templateUrl: './hvac-comfortbridge-modal.component.html',
  styleUrls: ['./hvac-comfortbridge-modal.component.scss']
})
export class HvacComfortbridgeModalComponent implements OnInit {
  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Input() hvacDetails: AssetDetail;
  @Input() statusMessage: any;
  @Input() isShowRightHeader: boolean;
  @Input() isShowCrossMark: boolean;
  @Input() isLoading = true;
  systemInfo: any;
  acInfo: any;
  hpInfo: any;
  gfInfo: any;
  TypeId: any;
  readonly HVAC_ID = HVAC_ID;
  airHandlerInfo: any;
  constructor(private sharedService: SharedService, private helperService: HelperService) { }
  ngOnInit() {
    console.log(this.hvacDetails)
    this.isLoading = true;
    this.getComfortBridgeData(this.hvacDetails.meliSerialNumber);
    this.TypeId = this.hvacDetails.assetModelInfo.indoorEquipmentType.id;
    // this.TypeId=2;
    console.log(this.TypeId);
  }
  getComfortBridgeData(data) {
    this.isLoading = true;
    this.sharedService.getComfortbridgeData(data).subscribe(
      (response) => {
        console.log(response)
        this.systemInfo = response.responseData['system'] ? response.responseData['system'] : null;
        this.gfInfo = response.responseData['furnace'] ? response.responseData['furnace'] : null;
        this.acInfo = response.responseData['ac'] ? response.responseData['ac'] : null;
        this.hpInfo = response.responseData['heatPump'] ? response.responseData['heatPump'] : null;
        this.airHandlerInfo = response.responseData['airHandler'] ? response.responseData['airHandler'] : null;
        this.isLoading = false;
        console.log(this.systemInfo)
      },
      (error) => {
      }
    );

  }
  // getSystemData(data) {
  //   this.sharedService.getSystemSetting(data).subscribe(
  //     (response) => {

  //       this.systemInfo = response.responseData;
  //     },
  //     (error) => {
  //     }
  //   );
  //   this.getFuranceData(this.hvacDetails.meliSerialNumber);
  // }
  // getFuranceData(data) {
  //   this.sharedService.getFuranceInfo(data).subscribe(
  //     (response) => {
  //       this.gfInfo = response.responseData;
  //     },
  //     (error) => {
  //     }
  //   );
  //   this.getAcData(this.hvacDetails.meliSerialNumber);
  // }
  // getAcData(data) {
  //   this.sharedService.getAcInfo(data).subscribe(
  //     (response) => {
  //       this.acInfo = response.responseData;
  //     },
  //     (error) => {
  //     }
  //   );
  //   this.getHpData(this.hvacDetails.meliSerialNumber);
  // }
  // getHpData(data) {
  //   this.sharedService.getHpInfo(data).subscribe(
  //     (response) => {
  //       this.hpInfo = response.responseData;
  //       this.isLoading = false;
  //     },
  //     (error) => {
  //       this.isLoading = false;
  //     }
  //   );
  // }
  closeModal() {
    this.emitCloseModal.emit()
  }
}
