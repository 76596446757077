import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { Observable } from 'rxjs';

// Services
import { ApiService } from 'src/app/shared/services/api.service';

// Constants
import { CONTRACTOR_API_CONSTANTS } from 'projects/contractor/src/app/shared-contractor/constants/api.constant';

// Models
import { DeleteCustomerResponse } from 'src/app/shared/models/customer.model';
import { UploadProperyListResponse } from 'src/app/shared/models/properties.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private BASE_URL = contractorEnvironment.baseUrl;

  constructor(
    private apiService: ApiService
  ) { }

  deleteCustomers(queryParams): Observable<DeleteCustomerResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.DELETE_CUSTOMER;
    return this.apiService.delete(url, queryParams).pipe(map(response => new DeleteCustomerResponse(response)));
  }

  // uploadCustomerList(payload,filedata) {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.UPLOAD_CUSTOMER_LIST;
  //   return this.apiService.post(url,filedata, payload).pipe(map(response => new UploadProperyListResponse(response)));
  // }

  // uploadCustomerListStatus(queryParams) {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_UPLOAD_STATUS;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new UploadProperyListResponse(response)));
  // }

  // uploadCustomerListStatusReset(queryParams) {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.UPLOAD_STATUS_RESET;
  //   return this.apiService.put(url, "",queryParams).pipe(map(response => new UploadProperyListResponse(response)));
  // }

}
