import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination-limit-dropdown',
  templateUrl: './pagination-limit-dropdown.component.html',
  styleUrls: ['./pagination-limit-dropdown.component.scss']
})
export class PaginationLimitDropdownComponent implements OnInit {

  @Input() limit: any;
  @Input() isLightBackground = false;
  @Output() emitHandleLimitOnChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  handleLimitOnChange(selectedLimit: any) {
    this.emitHandleLimitOnChange.emit(selectedLimit);
  }

}
