import { Email, Phone, Address, BoardTypeCount } from 'src/app/shared/models/common.model';
import { AssetModelInfo } from 'src/app/shared/models/customer.model';

export class Customer {
  customerFirstName: string;
  customerLastName: string;
  contractorName: string;
  consumerId: number;
  addresses: Address[];
  email: Email[];
  phone: Phone[];
  companyName:string;
  consumerType:String;
awsConsumerId:string;
  constructor(data) {
    this.customerFirstName = data.firstName;
    this.customerLastName = data.lastName;
    this.contractorName = data.contractorName || data.consumerName;
    this.consumerId = data.consumerId;
    this.addresses = data.addresses && data.addresses.length &&
      data.addresses.map((value, index) => new Address(value));
    this.phone = data.phone && data.phone.length &&
      data.phone.map((value, index) => new Phone(value));
    this.email = data.email && data.email.length &&
      data.email.map((value, index) => new Email(value));
      this.awsConsumerId=data.awsConsumerId;
      this.companyName=data.companyName;
      this.consumerType=data.consumerType;

  }
}

export class RelayStatus {
  relay1: boolean;
  relay2: boolean;
  relay3: boolean;
  relay4: boolean;

  constructor(data) {
    this.relay1 = data.relay1;
    this.relay2 = data.relay2;
    this.relay3 = data.relay3;
    this.relay4 = data.relay4;
  }
}

export class Missingpart {
  part: string;

  constructor(data) {
    this.part = data.part;
  }
}

// export class BoardTypeCount {
//   cellular: number;
//   wifi: number;

//   constructor(data) {
//     this.cellular = data.Cellular;
//     this.wifi = data['Wi-Fi'];
//   }

// }

export class wifiModel{
  password: string;
securityMode: string;
ssid: string;
constructor(data) {
    this.password = data.password;
    this.securityMode = data.securityMode;
    this.ssid = data.ssid;}
}

export class InstallationReportDetails {
  jobId: number;
  meliSerialNumber: string;
  date: string;
  comments: string;
  datStatus: string;
  DATStatus: boolean;
  ratStatus: string;
  RATStatus: boolean;
  sltStatus: string;
  SLTStatus: boolean;
  lltStatus: string;
  LLTStatus: boolean;
  spdStatus: string;
  SPDStatus: boolean;
  cdsStatus: string;
  CDSStatus: boolean;
  heating: boolean;
  cooling: boolean;
  technician: string;
  timeTaken: number;
  missingPart: Missingpart[];
  connectivity: boolean;
  signalStrength: string;
  relayFunctionality: string;
  numAlarmAtInstallation: number;
  images: [];
  isComfortBridge: boolean;
  sensorStatus: boolean;
  meliType?:any;
  wifiModel?:wifiModel;
  esActivationSwitch?:boolean;

  constructor(data) {
    this.meliSerialNumber = data.meliSerialNumber;
    this.date = data.reportedTime;
    this.comments = data.comments;
    this.datStatus = data['datStatus'];
    this.DATStatus = data['deltaAirTemperature'];
    this.ratStatus = data['ratStatus'];
    this.RATStatus = data['returnAirTemperature'];
    this.sltStatus = data['sltStatus'];
    this.SLTStatus = data['suctionAirTemperature'] || data['suctionLineTemperature'];
    this.lltStatus = data['lltStatus'];
    this.LLTStatus = data['liquidAirTemperature'];
    this.spdStatus = data['spdStatus'];
    this.SPDStatus = data['staticPressureDifference'];
    this.cdsStatus = data['cdsStatus'];
    this.CDSStatus = data['condensateSwitch'];
    this.heating = data.heatingTest;
    this.cooling = data.coolingTest;
    this.technician = data.technicianName;
    this.timeTaken = data.time_taken;
    this.missingPart = data.missing_part && data.missing_part.length &&
      data.missing_part.map((value, index) => new Missingpart(value));
    this.connectivity = data.connectivity;
    this.signalStrength = data.signalStrength;
    this.relayFunctionality = data.relay_functionality;
    this.numAlarmAtInstallation = data.numAlarmAtInstallation;
    this.images = data.images;
    this.jobId = data.jobId;
    this.isComfortBridge = data.isComfortBridge;
    this.sensorStatus = data.sensorStatus;
    this.meliType=data.meliType;
    this.wifiModel=data.wifiModel;
    this.esActivationSwitch = data.esActivationSwitch
  }
}

export class InstallationReportResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: InstallationReportDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData = data.responseData && new InstallationReportDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class Inventory {
  meliId: number;
  meliSerialNumber: string;
  contractorId: number;
  awsContractorId: string;
  awsTechnicianId: string;
  contractorName: string;
  assetId: number;
  assetName?: string;
  assetUnitTypeId: number;
  assetModelInfo: AssetModelInfo;
  boardStatus: string;
  type: string;
  firmware: string;
  installedDate?: string;
  inInventoryDate: string;
  assignedOn?: string;
  status: string;
  installationReport?: InstallationReportDetails;
  jobState?: string;
  technicianName?: string;
  boardType: string[];
  sensors: string[];
  relayStatus: RelayStatus;
  alertsCount: number;
  deviceType: string;
  thingName: string;
  consumer: Customer;
  remarks?: string;
  dealerType: string;
  connectivityStatus?: string;

  constructor(data) {
    this.meliId = data.meliId;
    this.meliSerialNumber = data.meliSerialNumber;
    this.contractorId = data.contractorId;
    this.awsContractorId = data.awsContractorId;
    this.awsTechnicianId = data.awsTechnicianId;
    this.contractorName = data.contractorName;
    this.assetId = data.assetId;
    this.assetName = data.assetName;
    this.assetUnitTypeId = data.assetUnitTypeId;
    this.assetModelInfo = data.assetModelInfo && new AssetModelInfo(data.assetModelInfo);
    this.boardStatus = data.boardStatus;
    this.type = data.type;
    this.firmware = data.firmware;
    this.installedDate = data.installedDate;
    this.inInventoryDate = data.inInventoryDate;
    this.assignedOn = data.assignedOn;
    this.status = data.status;
    this.installationReport = data.report && new InstallationReportDetails(data.report);
    this.jobState = data.jobState;
    this.technicianName = data.technicianName;
    this.boardType = data.boardType;
    this.sensors = data.sensors;
    this.relayStatus = data.relayStatus && new RelayStatus(data.relayStatus);
    this.alertsCount = data.alertsCount;
    this.deviceType = data.deviceType;
    this.thingName = data.thingName;
    this.consumer = data.consumer && new Customer(data.consumer);
    this.remarks = data.remarks;
    this.connectivityStatus = data.connectivityStatus;
    this.dealerType = data.dealerType;
  }
}

export class InventoryContentDetails {
  channelId: number;
  awsChannelId: string;
  channelName: string;
  firmName: string;
  tag: string;
  contactPerson: string;
  operatingSince: number;
  website: string;
  inventory: Inventory[];
  boardTypeCount: BoardTypeCount;
  defective?: any;
  notAssigned: string;
  notInstalled?: any;
  installed?: any;

  constructor(data) {
    this.channelId = data.channelId;
    this.awsChannelId = data.awsChannelId;
    this.channelName = data.channelName;
    this.firmName = data.firmName;
    this.tag = data.tag;
    this.contactPerson = data.contactPerson;
    this.operatingSince = data.operatingSince;
    this.website = data.website;
    this.inventory = data.melis && data.melis.length
      && data.melis.map((value) => new Inventory(value));
    this.boardTypeCount = data.boardTypeCount && new BoardTypeCount(data.boardTypeCount);
    this.defective = data.Defective;
    this.notAssigned = data.Not_Assigned;
    this.notInstalled = data.Not_Installed;
    this.installed = data.Installed;
  }
}

export class InventoryResponseDetails {
  page: number;
  size: number;
  inventoryContentDetails: InventoryContentDetails;
  currentPageNumber: number;
  noOfElementsInCurrentPage: number;
  totalPage: number;
  totalNumberOfElements: number;

  constructor(data) {
    this.page = data.page;
    this.size = data.size;
    this.inventoryContentDetails = data.content && new InventoryContentDetails(data.content);
    this.currentPageNumber = data.currentPageNumber;
    this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
    this.totalPage = data.totalPage;
    this.totalNumberOfElements = data.totalNumberOfElements;
  }
}

export class InventoryResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: InventoryResponseDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData = data.responseData && new InventoryResponseDetails(data.responseData);
    this.requestData = data.requestData;
  }
}



//

export class BoardResponseDetails {
  assetId: number;
  assetIndoorUnitInfoId:number;
  channelId: number;
  consumer: Customer;
  channelName: number;
 dealerId: number;
dealerName: string;
dealerType: string;
indoorEquipmentType: string;
indoorHvacLocation: string;
outdoorEquipmentType:string;
installedOn: string;
meliId: number;
meliSerialNumber: string;
meliType: string;
meliStatus:string;

  constructor(data) {
    this.assetId = data.assetId;
    this.assetIndoorUnitInfoId=data.assetIndoorUnitInfoId;
    this.channelId = data.channelId;
    this.consumer = data.consumer && new Customer(data.consumer);
    this.channelName = data.channelName;
    this.dealerId= data.dealerId;
this.dealerName= data.dealerName;
this.dealerType= data.dealerType;
this.indoorEquipmentType= data.indoorEquipmentType;
this.indoorHvacLocation= data.indoorHvacLocation;
this.outdoorEquipmentType=data.outdoorEquipmentType;
this.installedOn= data.installedOn;
this.meliId= data.meliId;
this.meliSerialNumber= data.meliSerialNumber;
this.meliType= data.meliType;
this.meliStatus=data.meliStatus;
  }
}


export class meliBoardResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo?: any;
  responseData: BoardResponseDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.responseData = data.responseData && new BoardResponseDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class AssignMeliToCustomerDetails {
  consumerId: number;
  consumerName: string;
  assetName: string;
  description: string;
  meliSerialNumber: string;

  constructor(data) {
    this.consumerId = data.consumer_id;
    this.consumerName = data.consumer_name;
    this.assetName = data.asset_name;
    this.description = data.description;
    this.meliSerialNumber = data.meli_serial_number;
  }
}

export class AssignMeliToCustomerDetailsNew {

  consumerName: string;
  assetName?: string;
  description: string;
  meliSerialNumber: string;
  status: boolean;

  constructor(data) {

    this.consumerName = data.consumerName;
    // this.assetName = data.asset_name;
    this.description = data.description;
    this.meliSerialNumber = data.meliSerialNumber;
    this.status = data.status;
  }
}
export class AssignMeliToCustomerResponse {
  statusCode: string;
  statusMessage: string;
  statusInfo: AssignMeliToCustomerDetailsNew;
  assignMeliToCustomerDetails: AssignMeliToCustomerDetails;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.statusInfo = data.statusInfo;
    this.assignMeliToCustomerDetails = data.responseData
      && new AssignMeliToCustomerDetails(data.responseData);
    this.requestData = data.requestData;
  }
}

export class AssignMeliToContractorDetails {
  meliSerialNumber: string;
  description: string;
  status: boolean;

  constructor(data) {
    this.meliSerialNumber = data.meliSerialNumber;
    this.description = data.description;
    this.status = data.status;
  }
}

export class AssignMeliToContractorResponse {
  statusCode: string;
  statusMessage: string;
  assignMeliToContractorDetails: AssignMeliToContractorDetails[];
  responseData: any;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.responseData = data.responseData;
    this.assignMeliToContractorDetails = data.statusInfo && data.statusInfo.length &&
      data.statusInfo.map((value, index) => new AssignMeliToContractorDetails(value));
    this.requestData = data.requestData;
  }
}

export class ConsumerResponse {
  statusCode: string;
  statusMessage: string;
  assignMeliToContractorDetails: Customer;
  responseData: any;
  requestData?: any;

  constructor(data) {
    this.statusCode = data.statusCode;
    this.statusMessage = data.statusMessage;
    this.responseData = data.responseData && new Customer(data.responseData);
    this.requestData = data.requestData;
  }
}