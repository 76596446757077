import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { SharedService } from '../../services/shared.service';

// Constants
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { BASE_URL_TYPES } from '../../constants/base-url-types.constant';
import { CookieService } from 'ngx-cookie-service';
import { SITE_NAME_CONSTANTS } from '../../constants/site-name.constant';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnDestroy {

  @Input() currentPortal: string;
  selectedTab: string;
  // authTime: string;
  isShowGuidePopUp = false;
  roleConstants = ROLE_CONSTANTS;
  // firmName: string;
  siteTourTitle: string;
  siteTourContent: string;
  isFirstSiteTour = false;
  siteTourSlideNumber = 0;
  siteTourSubscription: Subscription;
  siteTourEnd: Subscription;
  showAlertIcon = false;
  siteTourData: any = [];
  sequence: any;
  searchCount = 5;
  SiteTourEnableorNot: Subscription;
  companyname: any;
  isShowUploadListModal = false;
  userExist: any = {
    consumerExist: true,
    technicianExist: true,
    sitename: ''
  }
  constructor(
    private router: Router,
    private helperService: HelperService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
  ) {

  }

  ngOnInit() {
    const siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
    this.userExist.sitename = siteName;
    if (siteName === SITE_NAME_CONSTANTS.KEENHOME_CHANNEL ||
      siteName === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR ||
      siteName === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      this.companyname = 'keenhome';
    }
    else {
      this.companyname = "goodman"
    }
    this.SiteTourEnableorNot = this.helperService.setListenerForSiteTourEnableorNot().subscribe(
      (response) => {
        console.log("k")
        if (response.responseData && response.responseData.siteTour == 'Enabled') {
          this.setListenerForSiteTour();
        }
        else {
          this.checkUploadList()
        }
      }
    );

    this.helperService.setListenerForSelectedTab().subscribe(
      (data) => {
        this.selectedTab = data;
      }
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.helperService.setSelectedTab(event && event.url && event.url.slice(1));
      }
    });

    // this.handleSiteTourNavigation();
    this.helperService.setSelectedTab(this.router.url.slice(1));
    //this.setListenerForLastLoginSubject();

    // this.activatedRoute.data.subscribe((data) => {
    //   if (data) {
    //     if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
    //       this.getContractorInformation();
    //     }
    //   }
    // });
    // this.getContractorInformation();
    this.getAlertNotifications();
    this.siteTourSubscription = this.helperService.setListenerForSiteTour().subscribe(
      (response) => {
        console.log(response);
        this.siteTourSlideNumber = this.siteTourData.length - 2;
        this.handleSiteTourNavigation();
      }
    );

    this.siteTourEnd = this.helperService.setListenerForSiteTourFinised().subscribe(response => {
      console.log(response)
      if (response == true) {
        this.closeGuidePopUp();
      }
    })
    this.helperService.BulkUploadPopupDisplay("true");
  }
  getAlertNotifications() {
    this.helperService.setListnerForAlertNotification().subscribe(data => {
      this.showAlertIcon = data
    })
  }
  // getContractorInformation() {
  //   this.sharedService.getContractorInformation((response, isError) => {
  //     if (!isError) {
  //       this.firmName = response.firmName ? response.firmName : response.contractorName;
  //       // sessionStorage.setItem('firmname', response.firmName);
  //       // if (response.logoUrls && response.logoUrls.length) {
  //       //   this.profileImageUrl = response.logoUrls[0];
  //       // }
  //     }
  //   });
  // }
  handleTabOnChange(value: string) {
    // this.selectedTab = value;
    if (value === 'customers') {
      this.helperService.setSelectedSearchData('', 'customer', true);
    } else if (value === 'technicians') {
      this.helperService.setSelectedSearchData('', 'technician', true);
    } else if (value === 'installations') {
      this.helperService.setSelectedSearchData('', 'meli', true);
    } else if (value === 'dealers') {
      this.helperService.setSelectedSearchData('', 'contractor', true);
    } else if (value === 'properties') {
      this.helperService.setSelectedSearchData('', 'property', true);
    }
    this.helperService.setSelectedTab(value);
    // this.router.navigate(['/' + value]);
  }

  setListenerForLastLoginSubject() {

    this.sharedService.setListenerForLastLoginSubject().subscribe(
      (response) => {
        // if (response && response.responseData && response.responseData.lastLoggedInTime) {
        //   this.handleCalculateLastLoggedTime(response.responseData.lastLoggedInTime);
        //this.setListenerForSiteTour();
        console.log(response)
        if (response.responseData && response.responseData.siteTour == 'Enabled') {
          this.setListenerForSiteTour();
        }
        // }
      }, (error) => {

      }
    );
  }

  // handleCalculateLastLoggedTime(time) {
  //   const lastLoggedInTime = moment.utc(time).local();
  //   const currentTime = moment(new Date());

  //   if (currentTime.diff(lastLoggedInTime, 'years') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'years') + ' year';
  //   } else if (currentTime.diff(lastLoggedInTime, 'years') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'years') + ' years';
  //   } else if (currentTime.diff(lastLoggedInTime, 'months') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'months') + ' month';
  //   } else if (currentTime.diff(lastLoggedInTime, 'months') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'months') + ' months';
  //   } else if (currentTime.diff(lastLoggedInTime, 'days') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'days') + ' day';
  //   } else if (currentTime.diff(lastLoggedInTime, 'days') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'days') + ' days';
  //   } else if (currentTime.diff(lastLoggedInTime, 'hours') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'hours') + ' hour';
  //   } else if (currentTime.diff(lastLoggedInTime, 'hours') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'hours') + ' hours';
  //   } else if (currentTime.diff(lastLoggedInTime, 'minutes') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'minutes') + ' minute';
  //   } else if (currentTime.diff(lastLoggedInTime, 'minutes') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'minutes') + ' minutes';
  //   } else if (currentTime.diff(lastLoggedInTime, 'seconds') === 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'seconds') + ' second';
  //   } else if (currentTime.diff(lastLoggedInTime, 'seconds') > 1) {
  //     this.authTime = currentTime.diff(lastLoggedInTime, 'seconds') + ' seconds';
  //   }
  // }

  handleSiteTourNavigation(type?: string) {
    if (type && type === COMMON_CONSTANTS.PAGINATION_PREVIOUS) {
      this.siteTourSlideNumber -= 1;
    } else if (type && type === COMMON_CONSTANTS.PAGINATION_NEXT) {
      this.siteTourSlideNumber += 1;
    }
    this.isShowGuidePopUp = true;
    this.isFirstSiteTour = false;
    //console.log(this.siteTourData[this.siteTourSlideNumber])
    this.siteTourTitle = this.siteTourData[this.siteTourSlideNumber].locator;
    this.siteTourContent = this.siteTourData[this.siteTourSlideNumber].description;
    this.sequence = this.siteTourData[this.siteTourSlideNumber].sequence;
    if (this.sequence == this.searchCount) {
      this.helperService.setSelectedSiteTour(this.siteTourData[this.siteTourSlideNumber]);
      this.closeGuidePopUp('next');
    }
    else if (this.sequence != this.searchCount) {
      let pageName = this.siteTourTitle.toLowerCase();
      this.handleTabOnChange(pageName);
      if (this.siteTourTitle == 'Alerts' || this.siteTourTitle == 'Installations') { this.isFirstSiteTour = true; }
    }



    // if (this.siteTourSlideNumber === 1) {
    //   this.siteTourTitle = 'Alerts';
    //   this.siteTourContent = COMMON_CONSTANTS.ALERTS_SITE_TOUR;
    //   this.isFirstSiteTour = true;
    //   this.handleTabOnChange('alerts');
    // } else if (this.siteTourSlideNumber === 2) {
    //   this.siteTourTitle = 'Customers';
    //   this.siteTourContent = COMMON_CONSTANTS.CUSTOMERS_SITE_TOUR;
    //   this.handleTabOnChange('customers');
    // } else if (this.siteTourSlideNumber === 3) {
    //   this.siteTourTitle = 'Inventory';
    //   this.siteTourContent = COMMON_CONSTANTS.INVENTORY_SITE_TOUR;
    //   this.handleTabOnChange('inventory');
    // } else if (this.siteTourSlideNumber === 4) {
    //   this.siteTourTitle = 'Technicians';
    //   this.siteTourContent = COMMON_CONSTANTS.TECHNICIANS_SITE_TOUR;
    //   this.handleTabOnChange('technicians');
    // } else if (this.siteTourSlideNumber === 5) {
    //   this.helperService.setSelectedSiteTour('Search');
    //   this.closeGuidePopUp();
    // }




  }

  setListenerForSiteTour() {
    let payload = {};
    const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');


    // if (isRememberMeChecked === 'true') {
    //   payload = {
    //     userName: this.cookieService.get('username'),
    //     userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID),
    //     portalTypeId: this.helperService.handlePortalType(),
    //     brandName: this.companyname
    //   };
    // } else {
      payload = {
        userName: localStorage.getItem('userName'),
        userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID),
        portalTypeId: this.helperService.handlePortalType(),
        brandName: this.companyname

      };
   // }
    this.sharedService.getSiteTourData(payload).subscribe(
      (response) => {
        this.siteTourData = response.responseData;
        this.handleSiteTourNavigation();
        this.searchCount = this.siteTourData.length;
        //console.log(this.searchCount)
      });
  }
  // setListenerForSiteTour() {
  //   this.siteTourSubscription = this.helperService.setListenerForSiteTour().subscribe(
  //     (response) => {
  //       console.log(response)
  //       debugger
  //       this.isShowGuidePopUp = true;
  //       this.siteTourSlideNumber = 4;
  //       this.handleSiteTourNavigation();
  //     }
  //   );
  // }

  closeGuidePopUp(type?: string) {
    if (type != 'next') {
      const payload = {
        userName: localStorage.getItem('userName'),
        userPoolId: this.helperService.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
      };
      this.sharedService.SiteTourComplete(payload).subscribe(
        response => {
          console.log(response);
          if (this.currentPortal == ROLE_CONSTANTS.CHANNEL) {
            this.helperService.setSelectedTab('installations');
            this.router.navigate(['/installations']);
          }
          else {
            this.helperService.setSelectedTab('alerts');
            this.router.navigate(['/alerts']);
          }

        }
      );
      this.checkUploadList();
    }

    this.isShowGuidePopUp = false;

  }

  toggleUploadListModal() {
    this.isShowUploadListModal = !this.isShowUploadListModal;
    if(this.isShowUploadListModal)
    {
          this.helperService.BulkUploadPopupDisplay("true");
    }
    else
    {
          this.helperService.BulkUploadPopupDisplay("false");

    }
  }

  checkUploadList() {
    if (this.userExist.sitename === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR || this.userExist.sitename === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      this.getListStatus();
      //this.isShowUploadListModal = true;
      // this.modaltext="For the best experience, download the Customer Template and upload your entire customer list. You can always add new customers manually by visiting the Customers tab."
    }
  }


  getListStatus() {
    this.sharedService.getUserExistStatus().subscribe(
      (response: any) => {
        if (response['responseData']) {
          console.log(response['responseData']);
          this.userExist.consumerExist = response['responseData']['consumerExist'];
          this.userExist.technicianExist = response['responseData']['technicianExist'];
          if (this.userExist.consumerExist == false || this.userExist.technicianExist == false) {
            this.isShowUploadListModal = true
            this.helperService.BulkUploadPopupDisplay("true");
          }
          else
          {
            this.helperService.BulkUploadPopupDisplay("false");
          }
        }
      })

  }
  ngOnDestroy() {
    this.siteTourSubscription.unsubscribe();
    this.siteTourEnd.unsubscribe();
    this.SiteTourEnableorNot.unsubscribe();
  }

}
