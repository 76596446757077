export const CONTRACTOR_API_CONSTANTS = {
    // CUSTOMER_LIST: '/v1/consumers',
    GET_CONTRACTOR_INFORMATION: '/contractor',
    // GET_TECHNICIANS_LIST: '/technician',
    GET_MELIS_DETAIL: '/melidetails',
    GET_ASSEST_MODEL_LIST: '/model',
    CREATE_CONSUMER: '/consumer',
    // CREATE_TECHNICIAN: '/technician',
    ASSIGN_TECHNICIAN: '/jobs',
    ASSIGN_CUSTOMER: '/consumer/meli',
    ACTIVATE_CUSTOMER:'/resendInvitationToConsumer',
    UNASSIGN_MELI: '/consumer/unAssignMeli',
    CREATE_ASSETS_MODEL: '/model',
    UPDATE_MELI_STATUS: '/contractor/meli/updateStatus',
    DELETE_CUSTOMER: '/consumer',
    ADD_MELI_TO_CONTRACTOR: '/contractor/melis',
    DELETE_MELI_FROM_CONTRACTOR: '/contractor/melis',
    GET_UNASSIGNED_CUSTOMER_LIST: '/consumer/unAssigned',
    UPDATE_RELAY_STATUS: '/relay',
    // DELETE_TECHNICIAN: '/technician',
    TECHNICIAN_JOB_HISTORY_LIST: '/jobs',
    INSTALLATION_REPORT: '/installationsReport',
    GET_SEARCH_LIST: '/search',
    // EDIT_TECHNICIAN: '/technicianForEdit',
    SCHEDULE_PRE_SEASON_TEST: '/scheduleTest',
    SCHEDULE_BULK_PRE_SEASON_TEST: '/scheduleTests',
    PRE_SEASON_TEST_STATUS: '/scheduledTestStatus',
    CANCEL_PRE_SEASON_TEST: '/cancelTest',
    //GET_HVAC_INFORMATION: '/getAllMakeModel',
    DELETE_HVAC_UNIT: '/assetUnit',
    GET_ALL_TECHNICIAN_NAMES: '/technicianNames',
    SCHEDULE_EMERGENCY_SHUTOFF: '/emergencyshutoff/schedule',
    CANCEL_EMERGENCY_SHUTOFF: '/emergencyshutoff/cancel',
    CAN_SCHEDULE_EMERGENCY_SHUTOFF: '/emergencyshutoff/canSchedule',
    GET_EMERGENCY_SHUTOFF_STATUS: '/emergencyshutoff/scheduleStatus',
    //UPLOAD_CUSTOMER_LIST: '/uploadConsumerData',
    // UPLOAD_CUSTOMER_LIST:'/uploadUserRecord',
    // GET_UPLOAD_STATUS:'/getUploadFileStatus',
    // UPLOAD_STATUS_RESET:'/userDataReport',

};
