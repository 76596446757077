import { Customer } from './inventory.model';
import { HVACInformation } from './customer.model';

export class PreSeasonList {
    reportId: string;
    reportLink: String;
    testTime: string;
    season: string;
    type: string;
    testResult:string;
    constructor(data) {
        this.reportId = data.reportId;
        this.reportLink = data.reportLink;
        this.testTime = data.testTime;
        this.season = data.season;
        this.type = data.type;
        this.testResult=data.testResult;
    }
}
export class PreSeasonListDetail {
    page: number;
    size: number;
    preseasonsList: PreSeasonList[];
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;

    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.preseasonsList =
            data.content &&
            data.content.length &&
            data.content.map((value) => new PreSeasonList(value));
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
    }
}
export class PreSeasonListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    ReportListResponse: PreSeasonListDetail;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.ReportListResponse =
            data.responseData && new PreSeasonListDetail(data.responseData);
        this.requestData = data.requestData;
    }
}
//report detail
export class CustomerInfo {
    customerName: string;
    customerFirstName: string;
    customerLastName:string;
    serviceAddress: string;
    phoneNumber: string;
    email: string;
    customerId?:number;
    constructor(data) {
        this.customerName = data.customerName;
        this.customerFirstName = data.customerFirstName;
        this.customerLastName = data.customerLastName;
        this.serviceAddress = data.serviceAddress;
        this.phoneNumber = data.phoneNumber;
        this.email = data.email;
        this.customerId=data.customerId;
    }
}
export class Indoor {
    deviceType: string;
    make: string;
    model: string;
    serialNumber: string;
    unit: string;
    constructor(data) {
        this.deviceType = data.deviceType;
        this.make = data.make;
        this.model = data.model;
        this.serialNumber = data.serialNumber;
        this.unit = data.unit;
    }
}
export class Outdoor {
    deviceType: string;
    make: string;
    model: string;
    serialNumber: string;
    unit: string;
    constructor(data) {
        this.deviceType = data.deviceType;
        this.make = data.make;
        this.model = data.model;
        this.serialNumber = data.serialNumber;
        this.unit = data.unit;
    }
}
export class hvacInfo {
    performanceBoard: string;
    makeModel: string;
    serialNo: string;
    location: string;
    indoor: Indoor;
    outdoor: Outdoor;
    systemType: string;
    constructor(data) {
        this.performanceBoard = data.performanceBoard;
        this.makeModel = data.makeModel;
        this.serialNo = data.serialNo;
        this.location = data.installedLocation;
        this.indoor = data.indoor && new Indoor(data.indoor);
        this.outdoor = data.outdoor && new Outdoor(data.outdoor);
        this.systemType = data.systemType;
    }
}
export class sensorInfo {
    cdsSwitch: boolean;
    cooling: boolean;
    dat: boolean;
    fan: boolean;
    heating: boolean;
    llt: boolean;
    rat: boolean;
    signalStrength: string;
    slt: boolean;
    spd: boolean;
    totalStaticPressure: string;
    boardType: string;
    constructor(data) {
        this.cdsSwitch = data.cdsSwitch;
        this.cooling = data.cooling;
        this.dat = data.dat;
        this.fan = data.fan;
        this.heating = data.heating;
        this.llt = data.llt;
        this.rat = data.rat;
        this.signalStrength = data.signalStrength;
        this.slt = data.slt;
        this.spd = data.spd;
        this.totalStaticPressure = data.totalStaticPressure;
        this.boardType = data.boardType;


    }
}

export class PreSeasonDetail {
    testTitle: string;
    date: string;
    description: String;
    testType: string;
    testTime: string;
    ambientOutdoorTemp: string;
    ambientOutdoorTempF: string;
    testResult: string;
    customerInfo: CustomerInfo;
    hvacInfo: hvacInfo;
    installedSensors: any
    sensorInfo: any;
    alerts?: any;
    investigateData?: any;
    termsAndCondition: string;
    testedAndReportedBy: string;
    testCompletionTime: string;
    channelName:string;


    constructor(data) {
        this.testTitle = data.testTitle;
        this.date = data.date;
        this.description = data.description;
        this.testType = data.testType;
        this.testTime = data.testTime;
        this.ambientOutdoorTemp = data.ambientOutdoorTemp;
        this.ambientOutdoorTempF = data.ambientOutdoorTempF;
        this.testResult = data.testResult;
        this.customerInfo = data.customerInfo&&new CustomerInfo(data.customerInfo);
        this.hvacInfo = data.hvacInfo && new hvacInfo(data.hvacInfo);
        this.installedSensors = data.installedSensors;
        this.sensorInfo = data.sesorInfo;
        this.alerts = data.alerts;
        this.investigateData = data.investigateData;
        this.termsAndCondition = data.termsAndCondition;
        this.testedAndReportedBy = data.testedAndReportedBy;
        this.testCompletionTime = data.testCompletionTime;
        this.channelName=data.channelName;


    }
}

export class PreSeasonResponseData {
    reportId: any;
    reportData: any;
    reportLink: string;
    constructor(data) {
        this.reportId = data.reportId;
        this.reportLink = data.reportLink;
        this.reportData = data.reportData && new PreSeasonDetail(data.reportData);
    }
}

export class PreSeasonResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    Response: PreSeasonResponseData;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.Response = data.responseData && new PreSeasonResponseData(data.responseData);
        this.requestData = data.requestData;
    }
}

export class emailResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    Response: PreSeasonDetail;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.Response = data.responseData;
        this.requestData = data.requestData;
    }
}
