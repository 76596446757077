export const deltaSVG = {
    DELTA: '<svg xmlns="http://www.w3.org/2000/svg"width="20.8" height="15.773" viewBox="0 1 23.8 23.773">' +
        '<path id="Path_1218" data-name="Path 1218" d="M-1935.026-3000.077l15.682,9.054v-18.108Z" ' +
        'transform="translate(3181.646 1654.313) rotate(-30)" fill="none" stroke="#dfe1e3"' +
        'stroke-miterlimit="10" stroke-width="1"/></svg>',
};

export const cellularSignal={
    GOOD_SIGNAL:'<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.2656 6.1875H7.73438C7.42324 6.1875 7.17188 6.43887 7.17188 6.75V16.3125C7.17188 16.6236 7.42324 16.875 7.73438 16.875H10.2656C10.5768 16.875 10.8281 16.6236 10.8281 16.3125V6.75C10.8281 6.43887 10.5768 6.1875 10.2656 6.1875ZM15.6797 1.125H13.1484C12.8373 1.125 12.5859 1.37637 12.5859 1.6875V16.3125C12.5859 16.6236 12.8373 16.875 13.1484 16.875H15.6797C15.9908 16.875 16.2422 16.6236 16.2422 16.3125V1.6875C16.2422 1.37637 15.9908 1.125 15.6797 1.125ZM4.85156 11.25H2.32031C2.00918 11.25 1.75781 11.5014 1.75781 11.8125V16.3125C1.75781 16.6236 2.00918 16.875 2.32031 16.875H4.85156C5.1627 16.875 5.41406 16.6236 5.41406 16.3125V11.8125C5.41406 11.5014 5.1627 11.25 4.85156 11.25Z" fill="#333333"/></svg>',
    BAD_SIGNAL:'<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.2656 6.1875H7.73438C7.42324 6.1875 7.17188 6.43887 7.17188 6.75V16.3125C7.17188 16.6236 7.42324 16.875 7.73438 16.875H10.2656C10.5768 16.875 10.8281 16.6236 10.8281 16.3125V6.75C10.8281 6.43887 10.5768 6.1875 10.2656 6.1875ZM15.6797 1.125H13.1484C12.8373 1.125 12.5859 1.37637 12.5859 1.6875V16.3125C12.5859 16.6236 12.8373 16.875 13.1484 16.875H15.6797C15.9908 16.875 16.2422 16.6236 16.2422 16.3125V1.6875C16.2422 1.37637 15.9908 1.125 15.6797 1.125ZM4.85156 11.25H2.32031C2.00918 11.25 1.75781 11.5014 1.75781 11.8125V16.3125C1.75781 16.6236 2.00918 16.875 2.32031 16.875H4.85156C5.1627 16.875 5.41406 16.6236 5.41406 16.3125V11.8125C5.41406 11.5014 5.1627 11.25 4.85156 11.25Z" fill="#333333" fill-opacity="0.5"/><path d="M4.85156 11.0625H2.32031C2.00918 11.0625 1.75781 11.3139 1.75781 11.625V16.125C1.75781 16.4361 2.00918 16.6875 2.32031 16.6875H4.85156C5.1627 16.6875 5.41406 16.4361 5.41406 16.125V11.625C5.41406 11.3139 5.1627 11.0625 4.85156 11.0625Z" fill="#DD4B4B"/></svg>',
    FAIR_SIGNAL:'<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.2656 6.1875H7.73438C7.42324 6.1875 7.17188 6.43887 7.17188 6.75V16.3125C7.17188 16.6236 7.42324 16.875 7.73438 16.875H10.2656C10.5768 16.875 10.8281 16.6236 10.8281 16.3125V6.75C10.8281 6.43887 10.5768 6.1875 10.2656 6.1875ZM15.6797 1.125H13.1484C12.8373 1.125 12.5859 1.37637 12.5859 1.6875V16.3125C12.5859 16.6236 12.8373 16.875 13.1484 16.875H15.6797C15.9908 16.875 16.2422 16.6236 16.2422 16.3125V1.6875C16.2422 1.37637 15.9908 1.125 15.6797 1.125ZM4.85156 11.25H2.32031C2.00918 11.25 1.75781 11.5014 1.75781 11.8125V16.3125C1.75781 16.6236 2.00918 16.875 2.32031 16.875H4.85156C5.1627 16.875 5.41406 16.6236 5.41406 16.3125V11.8125C5.41406 11.5014 5.1627 11.25 4.85156 11.25Z" fill="#333333" fill-opacity="0.5"/><path d="M10.2656 6H7.73438C7.42324 6 7.17188 6.25137 7.17188 6.5625V16.125C7.17188 16.4361 7.42324 16.6875 7.73438 16.6875H10.2656C10.5768 16.6875 10.8281 16.4361 10.8281 16.125V6.5625C10.8281 6.25137 10.5768 6 10.2656 6ZM4.85156 11.0625H2.32031C2.00918 11.0625 1.75781 11.3139 1.75781 11.625V16.125C1.75781 16.4361 2.00918 16.6875 2.32031 16.6875H4.85156C5.1627 16.6875 5.41406 16.4361 5.41406 16.125V11.625C5.41406 11.3139 5.1627 11.0625 4.85156 11.0625Z" fill="#333333"/></svg>'
}