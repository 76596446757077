import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import {
  Sensor,
  SensorDetails,
  RelayStatus,
  AlertDiscoverySensor,
} from 'src/app/shared/models/graph.model';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { HelperService } from '../../services/helper.service';
import { COMMON_CONSTANTS } from '../../constants/commom.constant';
import * as moment from 'moment';
import * as momenttimezone from 'moment-timezone';
import { MeliService } from '../../../modules/meli/services/meli.service';
import { InstallationReportDetails, InstallationReportResponse } from '../../models/inventory.model';
@Component({
  selector: 'app-alert-discovery-modal',
  templateUrl: './alert-discovery-modal.component.html',
  styleUrls: ['./alert-discovery-modal.component.scss'],
})
export class AlertDiscoveryModalComponent implements OnInit {
  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Input() showToggleButton: string;
  // @Input() chartData: any;
  //@Input() isLoading: boolean;
  @Output() emitInvestigate = new EventEmitter();
  //@Input() chartType: any; // whether temperature or pressure
  @Input() isShowCrossMark = false
  @Input() details: any;
  isLoading = true;
  alertDescription: any;
  showToggle: any;
  chartData: any;
  conversion: string = null;
  sensorStatus: InstallationReportDetails;
  spdStatus: string;
  constructor(private sharedService: SharedService, private helperService: HelperService,private meliService: MeliService,) { }

  ngOnInit() {
    this.getAlertDiscovery(this.details);
    this.getSensorInstalltionStatus(this.details);
  }


  getAlertDiscovery(data) {
    console.log(data)
    const queryParams = {
      pb: data.meliSerialNo,
      alerttime: data.timeStamp,
      alertcode: data.alertCode,
      conversionUnit: this.conversion

    };
    this.sharedService.getAlertDiscoverySensorRead(queryParams).subscribe(
      (response: AlertDiscoverySensor) => {
        this.chartData = '';
        this.isLoading = false;
        this.alertDescription = response.responseData['alertDetails']['description'];
        this.showToggle = response.responseData['alertDetails']['convertParam'];
        this.conversion = response.responseData['deltaUnit']
        if (response.responseData['deltaUnit'] == null) {
          this.conversion = "null"
        }
        this.handleAlertDiscoveryChartData(response.responseData);
      },
      (error) => {
        this.chartData = '';
        this.isLoading = false;
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  getSensorInstalltionStatus(data) {
    const queryParams = {
      meliSerialNumber: data.meliSerialNo,
      timeZone: momenttimezone.tz.guess()
    };
    this.meliService.getSensorInstallationStatus(queryParams).subscribe(
      (response: InstallationReportResponse) => {
        this.sensorStatus = response.responseData;
         if (this.sensorStatus && this.sensorStatus.spdStatus == "Not_Installed") {
            this.spdStatus = COMMON_CONSTANTS.SENSOR_STATUS_NOT_INSTALLED;
          }
        //this.sensorSPDState = COMMON_CONSTANTS.SENSOR_STATUS_NOT_INSTALLED;
        //this.spdStatus = this.sensorStatus && this.sensorStatus.spdStatus ;

      }, (error) => {
      }
    );
  }

  handleAlertDiscoveryChartData(response) {
    this.chartData = this.helperService.handleAlertDiscoveryChartData(
      response,
      'alert-discovery'
    );
    console.log(this.chartData)
  }

  handleTemperatureConvertion(event: any, data) {
    this.handleAlertDiscoverTempConvertion({ event, data });
  }


  //convertion data on toggle change

  handleAlertDiscoverTempConvertion(event: any) {

    console.log(event);
    this.isLoading = true;
    if (event.data === 'temperature') {
      if (event.event.target.checked) {
        this.conversion = COMMON_CONSTANTS.FAHRENHEIT;
        this.getAlertDiscovery(this.details);
        // const data = this.helperService.handleReturnAlertDiscoverChartData(
        //   COMMON_CONSTANTS.CELCIUS, event.data
        // );
        // this.chartData = data;

      } else {
        this.conversion = COMMON_CONSTANTS.CELCIUS;
        this.getAlertDiscovery(this.details);
        // const data = this.helperService.handleReturnAlertDiscoverChartData(
        //   COMMON_CONSTANTS.FAHRENHEIT, event.data
        // );

        // this.chartData = data;
      }
    }
    else if (event.data === 'pressure') {
      if (event.event.target.checked) {
        this.conversion = COMMON_CONSTANTS.WC;
        this.getAlertDiscovery(this.details);
        // const data = this.helperService.handleReturnAlertDiscoverChartData(
        //   COMMON_CONSTANTS.PASCAL, event.data
        // );
        // this.chartData = data;
      } else {
        this.conversion = COMMON_CONSTANTS.PASCAL;
        this.getAlertDiscovery(this.details);
        // const data = this.helperService.handleReturnAlertDiscoverChartData(
        //   COMMON_CONSTANTS.WATER_COLUMN, event.data
        // );
        // this.chartData = data;
      }

    }
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 400);
  }

  investigateClick(info) {
    //console.log(this.chartData);
    let fTime:any;
    let tTime:any;
    if(this.chartData.chartcommondata.alertCode == "T704"||this.chartData.chartcommondata.alertCode == "T804C"||this.chartData.chartcommondata.alertCode == "T804H")
    {
      console.log("dsddsfdsfds")
      fTime = moment(this.details.timeStamp).subtract(25, 'm').toDate();
      tTime = moment(this.details.timeStamp).add(25, 'm').toDate();
    }
    else{
       console.log("dsddsfdsfds")
fTime = moment(this.details.timeStamp).subtract(20, 'm').toDate();
      tTime = moment(this.details.timeStamp).add(20, 'm').toDate();
    }
    console.log(fTime)
    const event = {
      fromdate:  fTime,
      todate: tTime,
      serialnumber: this.chartData.chartcommondata.serialno,
      flag: info,
      spdStatus:this.spdStatus,
    };
    this.emitInvestigate.emit(event);
  }

  handleTempConvertion(event) {
    this.isLoading = true;
    this.conversion = event;
    this.getAlertDiscovery(this.details);
  }

  closeModal() {
    this.emitCloseModal.emit();
  }
}
