import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// Services
import { SharedService } from 'src/app/shared/services/shared.service';

// Constants
import { COMMON_CONSTANTS, BOARDTYPES ,SIGNAL_STRENGTH_STATUS} from 'src/app/shared/constants/commom.constant';

// Models

import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { InstallationReportDetails } from 'src/app/shared/models/inventory.model';
import { BoardTypeCount } from 'src/app/shared/models/common.model';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from '../../constants/toaster.enum';
import { ContractorDetails } from '../../models/contractor.modal';

@Component({
  selector: 'app-installation-report',
  templateUrl: './installation-report.component.html',
  styleUrls: ['./installation-report.component.scss']
})
export class InstallationReportComponent implements OnInit {
  @Output() emitConfirmationModal = new EventEmitter();
  @Output() emitCloseModal = new EventEmitter();
  @Output() emitHandleAction = new EventEmitter();
  @Input() modalTitle: string;
  @Input() isLoading: boolean;
  @Input() installationReport: InstallationReportDetails;
  @Input() reportSubmitFormat:any;
  @Input() isShowCrossMark = false;
  @Input() isPendingReportModal: boolean;
  @Input() connectivity: boolean;
  @Input() awsTechnicianId: string;
  installationReportForm: FormGroup;
  infoModalType = '';
  infoModalTitle = '';
  isShowConfirmationModal = false;
  confirmationModalTitle = '';
  isConfirmationModalHasYesOrNoButton = false;
  confirmationModalLine1Content: any;
  resolvedTime: any;
  @Input() boardTypeInfo: string;
  skip = 0;
  limit = 1;
  page = 1;
  totalCount = 0;
  commonConstants = COMMON_CONSTANTS;
  Cellular = BOARDTYPES.Celluar;
  Wifi = BOARDTYPES.Wi_Fi;
  currentInstallationReport: InstallationReportDetails;
  emptyimage = require('src/assets/images/noimage.jpg');
  cclogo = require('src/assets/images/defaultLogo.png');
  wifiWeak = require('src/assets/images/wifiWeak.svg');
  wifiFair = require('src/assets/images/wifiFair.svg');
  wifiGood = require('src/assets/images/wifiGood.svg');
  wifiExcellent = require('src/assets/images/wifiExcellent.svg');
  cellWeak = require('src/assets/images/cellWeak.svg');
  cellFair = require('src/assets/images/cellFair.svg');
  cellGood = require('src/assets/images/cellGood.svg');
  cellExcellent = require('src/assets/images/cellExcellent.svg');
  imagePath:any='';
  // defaultLogo = require('src/assets/images/defaultLogo.png');
  // imagess = ['https://homepages.cae.wisc.edu/~ece533/images/airplane.png', 'https://homepages.cae.wisc.edu/~ece533/images/cat.png']

  //imagess1 = [];
  //prevclick = 2;
 isChecked:boolean=false;
  
  contractorLogo: string | string[];
  isShowButton: boolean;
  isCheckedES: any;
  isBtnLoading: boolean;
  constructor(
    private sharedService: SharedService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    console.log(this.installationReport)
    this.resolvedTime = moment().format('YYYY-MM-DD hh:mm:ss'); //2020-09-08 18:31:08
    this.installationReportForm = this.formBuilder.group(
      {
        comments: new FormControl('')
      });
      // this.imagePath=this.boardTypeInfo==this.Cellular?this.cellGood:this.wifiGood;
    this.connectivity = this.installationReport.connectivity;
    this.isChecked = this.installationReport.CDSStatus;
    this.isCheckedES =this.installationReport.esActivationSwitch;
    // this.connectivity=true;
    // if (this.installationReportList && this.installationReportList.length) {
    //   this.totalCount = this.installationReportList.length;
    //   this.installationReportList = this.installationReportList.reverse();
    // }
    // this.handleCurrentInstallationReport();
    console.log(this.boardTypeInfo);
    // this.imagess.forEach((element, i) => {
    //   if (i <= 2) {
    //     this.imagess1.push(element);
    //   }
    // });
    this.handleImage();
    this.getContractorInformation();
  }
handleImage(){
  if(this.boardTypeInfo==this.Cellular){
    if(this.installationReport.signalStrength.includes('Excellent')){
      this.imagePath=this.cellExcellent;
    }else if(this.installationReport.signalStrength.includes('Good')){
      this.imagePath=this.cellGood;
    }else if(this.installationReport.signalStrength.includes('Fair')){
      this.imagePath=this.cellFair;
    }else if(this.installationReport.signalStrength.includes('Weak')){
      this.imagePath=this.cellWeak;
    }else if(this.installationReport.signalStrength.includes('Poor')){
      this.imagePath=this.cellWeak;
    }
  }else if(this.boardTypeInfo==this.Wifi){
    if(this.installationReport.signalStrength.includes('Excellent')){
      this.imagePath=this.wifiExcellent;
    }else if(this.installationReport.signalStrength.includes('Good')){
      this.imagePath=this.wifiGood;
    }else if(this.installationReport.signalStrength.includes('Fair')){
      this.imagePath=this.wifiFair;
    }else if(this.installationReport.signalStrength.includes('Weak')){
      this.imagePath=this.wifiWeak;
    }else if(this.installationReport.signalStrength.includes('Poor')){
      this.imagePath=this.wifiWeak;
    }
  }
}
  getContractorInformation() {
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        console.log(response)
        this.contractorLogo = response.logoUrls ? response.logoUrls : '';
        if (this.contractorLogo.length > 0) {
          this.cclogo = this.contractorLogo[0]
        }

      }
    });
  }

  handleStarCount(comments) {
    if (comments != null && comments.length) {
      let lastValue = comments[(comments.length - 1)];
      if (lastValue != null) {
        lastValue = lastValue.substr(lastValue.length - 1);
        return Number(lastValue);
      }


    }
    return 0;
  }

  starArray(n: number): any[] {
    return Array(n);
  }

  handleRemoveStarCountInComments(comments) {
    let value = comments && comments.toString();
    value = value && value.substring(0, value.length - 2);
    return value;
  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  refreshList()
  {
    this.emitHandleAction.emit();
  }


  handleModalType(type: string) {
    this.infoModalType = type;
    if (type === 'confirmation-modal') {
      this.confirmationModalTitle = 'Warning!';
      this.confirmationModalLine1Content = `This completes the Installation process without capturing any HVAC images. Do you want to continue?`;
      this.isConfirmationModalHasYesOrNoButton = true;
      this.toggleConfirmationModal();
    }
  }
  // toggleConfirmModal(){
  //   this.emitConfirmationModal.emit();
  // }
  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
  }

  handleCompleteInstallation() {
    let comments = this.installationReportForm.value;

    let payload = [{
      jobId: this.installationReport.jobId,
      jobStateId: 4,
      jobType: "Installation",
      comments: this.installationReportForm.controls["comments"].value,
      resolvedTime: this.resolvedTime,
      awsTechnicianId: this.awsTechnicianId
    }]
    console.log(payload);

    this.sharedService.updateInstallationReport(payload).subscribe(
      (response) => {
        console.log(response);
        const successMessage = "Board installed successfully!";

        // const message = response['statusInfo'][this.installationReport.jobId];
        // this.router.navigate(['/technicians']);
        // this.helperService.setSelectedTab('technicians');
        this.closeModal();
        this.helperService.showToaster(ToasterType.SUCCESS, successMessage);
      }, (error) => {
        // this.closeModal();
        console.log(error);
        const failureMessage = "Failed to install board!";
        // const message = this.helperService.handleAPIErrors(error);
        const message = error ? error.error.status : '';
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  changeReport(value,value1)
  {
    if(value1 == 'cds')
    {
    this.isChecked=value;
    value==false?this.isCheckedES=value:'';
    console.log(this.isChecked,this.isCheckedES)
    }
    else if(value1=='es')
    {
      this.isCheckedES=value
       value==true?this.isChecked=value:'';
       console.log(this.isChecked,this.isCheckedES)
    }
    if(this.installationReport.CDSStatus !== this.isChecked || this.installationReport.esActivationSwitch!==this.isCheckedES)
    {
      this.isShowButton=true;
    }else{
      this.isShowButton=false;
    }
    //console.log(this.installationReport)
    //this.isChecked = value;
  }

  updateData()
  {
      // if(this.isChecked==false)
      // {
      //   this.reportSubmitFormat.cdsStatus = "Not_Installed";
      //   this.reportSubmitFormat.condensateSwitch=false
      // }
      // else if(this.isChecked==true)
      // {
      //   this.reportSubmitFormat.cdsStatus = "Installed";
      //   this.reportSubmitFormat.condensateSwitch=true
      // }

      // let date = moment.utc(new Date(this.reportSubmitFormat.reportedTime)).format('YYYY-MM-DD HH:mm:ss')
      // console.log(date);
      
      // this.reportSubmitFormat.reportedTime = date;
      this.submitData();
  }
  submitData()
  {
    this.isBtnLoading=true;
    let queryparams={
      jobId :this.reportSubmitFormat.jobId,
      cdsSwitch:this.isChecked,
      esActivationSwitch:this.isCheckedES

    };

     this.sharedService.updateInstallationCDS(queryparams).subscribe((res)=>{
        const successMessage = "updated successfully!";
        this.closeModal();
        this.refreshList();
        this.helperService.showToaster(ToasterType.SUCCESS, successMessage);
        this.isBtnLoading=false;
      }, (error) => {
        const failureMessage = "Failed to update!";
        // const message = this.helperService.handleAPIErrors(error);
        const message = error ? error.error.status : 'Failed to update!';
        this.helperService.showToaster(ToasterType.ERROR, message);
this.isBtnLoading=false;
     });
  }
  // handlePagination(payload) {
  //   const type = payload.paginationType;
  //   if (type === this.commonConstants.PAGINATION_NEXT) {
  //     this.page += 1;
  //     this.skip = (this.page * this.limit) - this.limit;
  //   } else {
  //     this.page -= 1;
  //     this.skip = (this.page * this.limit) - this.limit;
  //   }
  //   this.handleCurrentInstallationReport();
  // }

  // handleCurrentInstallationReport() {
  //   if (this.installationReportList && this.installationReportList.length) {
  //     this.currentInstallationReport = this.installationReportList[(this.page - 1)] &&
  //       this.installationReportList[(this.page - 1)];
  //   }
  // }

  // handleImage(data) {
  //   if (data == 'next') {
  //     console.log(this.prevclick)
  //     if (this.prevclick + 1 < this.imagess.length) {
  //       this.prevclick = this.prevclick + 1;
  //       this.imagess1.shift()
  //       this.imagess1.push(this.imagess[this.prevclick])
  //     }

  //   }


  //   if (data == 'prev') {
  //     console.log(this.prevclick, this.imagess.length)
  //     if (this.prevclick + 1 <= this.imagess.length) {
  //       this.prevclick = this.prevclick - 1;
  //       this.imagess1.pop()
  //       this.imagess1.push(this.imagess[this.prevclick])
  //     }


  //   }

  // }

}
