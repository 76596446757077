import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() buttonName: any;
  @Input() styleClass: string;
  @Input() isButtonDisabled = false;
  @Input() toolTip?: any;
  @Output() emitHandleButtonAction = new EventEmitter<any>();
  @Input() showLoadingSymbol?= false;

  constructor() { }

  ngOnInit() {
    console.log(this.buttonName)
    if(this.showLoadingSymbol){
      this.isButtonDisabled=true;
    }
  }

  handleButtonAction() {
    this.emitHandleButtonAction.emit();
  }

}
