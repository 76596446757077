import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Email, Phone, Address } from 'src/app/shared/models/common.model';
import { AssetDetail, HVACInformation, AssetModelInfo } from 'src/app/shared/models/customer.model';

export class typeInfo {
    id: boolean
    title: string
    constructor(data) {
        this.id = data.id;
        this.title = data.title;

    }
}
// export class HVACDetails {
//     boardSerialNo: string
//     connectivity: boolean
//     installedOn: string
//     type: typeInfo
//     assetInfo: AssetDetail
//     constructor(data) {
//         this.boardSerialNo = data.boardSerialNo;
//         this.connectivity = data.connectivity;
//         this.installedOn = data.installedOn;
//         this.type = data.type && new typeInfo(data.type);
//         this.assetInfo = data.assetInfo && new AssetDetail(data.assetInfo);
//     }

// }

export class propertyAddress {
    addressId: number;
    address: string;
    city: string;
    state: string;
    stateCode: string;
    zipcode: any;
    type: string;
    propertyName: string;
    propertyUnitLocation: string;
    boardSerialNumber?: any;
    deviceType?: any;
    unitAptNo: string;
    constructor(data) {
        this.addressId = data.addressId;
        this.address = data.address;
        this.city = data.city;
        this.state = data.state;
        this.stateCode = data.stateCode;
        this.zipcode = data.zipcode;
        this.type = data.type;
        this.propertyName = data.propertyName;
        this.propertyUnitLocation = data.propertyUnitLocation;
        this.boardSerialNumber = data.boardSerialNumber;
        this.deviceType = data.deviceType;
        this.unitAptNo = data.unitAptNo;
    }
}

export class propertiesInfo {
    addresses: propertyAddress;
    hvac: AssetDetail[];
    propertyName: string;
    propertyUnitLocation: string;
    constructor(data) {
        this.addresses = data.addresses && new propertyAddress(data.addresses);
        this.hvac = data.hvac && data.hvac.length &&
            data.hvac.map((value) => new AssetDetail(value));
        this.propertyName = data.propertyName;
        this.propertyUnitLocation = data.propertyUnitLocation;
    }
}

export class PropertiesContent {
    consumerId: number
    consumerName: string
    equipments?: number
    properties?: number
    propertiesInfo: propertiesInfo[];

    constructor(data) {
        this.consumerId = data.consumerId;
        this.consumerName = data.consumerName;
        this.equipments = data.equipments;
        this.properties = data.properties;
        this.propertiesInfo = data.propertiesInfo && data.propertiesInfo.length && data.propertiesInfo.map((value) => new propertiesInfo(value));
    }
}

export class PropertiesListResponseDetails {
    page: number;
    size: number;
    currentPageNumber: number;
    noOfElementsInCurrentPage: number;
    totalPage: number;
    totalNumberOfElements: number;
    content: PropertiesContent;

    constructor(data) {
        this.page = data.page;
        this.size = data.size;
        this.currentPageNumber = data.currentPageNumber;
        this.noOfElementsInCurrentPage = data.noOfElementsInCurrentPage;
        this.totalPage = data.totalPage;
        this.totalNumberOfElements = data.totalNumberOfElements;
        this.content = data.content && new PropertiesContent(data.content);
    }
}

export class PropertiesListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: PropertiesListResponseDetails;
    requestData: string;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData && new PropertiesListResponseDetails(data.responseData);
        this.requestData = data.requestData;
    }
}


export class uploadSuccessResponse {

    invalidDataFileLink: string;
    message: string;
    valid_records_count: number;
    in_valid_records_count: number;
    errorDescriptionFileLink: string;
    info: any;
  isSeen: boolean;
    constructor(data) {
        this.invalidDataFileLink = data.invalidDataFileLink;
        this.message = data.message;
        this.valid_records_count = data.valid_records_count;
        this.in_valid_records_count = data.in_valid_records_count;
        this.errorDescriptionFileLink = data.errorDescriptionFileLink;
        this.info = data.info;
        this.isSeen = data.isSeen
    }
}

export class UploadProperyListResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    responseData: uploadSuccessResponse;
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.responseData = data.responseData;
        this.requestData = data.requestData;
    }
}

export class MakeModelLocationInfo {
    boardSerialNumber: string;
    assetModelInfo: AssetModelInfo;

    constructor(data) {
        this.boardSerialNumber = data.boardSerialNumber;
        this.assetModelInfo = data.assetModelInfo && new AssetModelInfo(data.assetModelInfo);
    }

}

export class TechnicianPropertiesResponseDetails {
    propertyAddress: propertyAddress;
    hvac: any[];
    makeModelLocationInfo: MakeModelLocationInfo[];

    constructor(data) {
        this.propertyAddress = data.addresses && new propertyAddress(data.addresses);
        this.hvac = data.hvac;
        this.makeModelLocationInfo = data.makeModelLocationInfo && data.makeModelLocationInfo.length &&
            data.makeModelLocationInfo.map((value, index) => new MakeModelLocationInfo(value));
    }
}

export class TechnicianPropertiesResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    technicianPropertiesDetails: TechnicianPropertiesResponseDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.technicianPropertiesDetails = data.responseData && data.responseData.length &&
            data.responseData.map((value, index) => new TechnicianPropertiesResponseDetails(value));
        this.requestData = data.requestData;
    }

}


