import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { Observable } from 'rxjs';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { ApiService } from 'src/app/shared/services/api.service';

// Constants
import { CONTRACTOR_API_CONSTANTS } from 'projects/contractor/src/app/shared-contractor/constants/api.constant';

// Models
import { Alert, AlertsResponse } from 'src/app/shared/models/alerts.model';
import { Sensor } from 'src/app/shared/models/graph.model';
import {
  CustomerListResponse, UnassignedCustomersListResponse,
  CreateCustomerResponse, UnassignCustomerFromMeliResponse,
  HVACInformationResponse
} from 'src/app/shared/models/customer.model';
import { ContractorDetails, ContractorDetailsResponse } from 'src/app/shared/models/contractor.modal';
import {
  TechnicianInfo, CreateTechnicianResponse,
  EditTechnicianResponse
} from 'src/app/shared/models/technician.model';
import { AssetModelResponse, CreateAssetModelResponse } from 'src/app/shared/models/assest-model.model';

import { InventoryResponse, AssignMeliToCustomerResponse } from 'src/app/shared/models/inventory.model';
import { MeliAssetInfoResponse, UpdateMeliStatusResponse } from 'src/app/shared/models/meli.model';
import { SearchResponse } from 'src/app/shared/models/search.model';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { CommonSuccessResponse } from 'src/app/shared/models/common.model';

@Injectable({
  providedIn: 'root'
})
export class ContractorSharedService {

  private BASE_URL = contractorEnvironment.baseUrl;
  private UPDATE_RELAY_BASE_URL = contractorEnvironment.updateRelayBaseUrl;
  private PHONE_CODES_REST_API_BASE_URL = contractorEnvironment.phoneCodesRestAPIBaseUrl;
  private AUTH_BASE_URL = contractorEnvironment.loginBaseUrl;
  alertsList: Alert[];
  contractorInfo: ContractorDetails;
  phoneCodesList: any;

  constructor(
    private helperService: HelperService,
    private apiService: ApiService
  ) { }

  // getCustomerList(queryParams): Observable<CustomerListResponse> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.CUSTOMER_LIST;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new CustomerListResponse(response)));
  // }

  // getContractorInformation(callback) {
  //   if (this.contractorInfo) {
  //     callback(this.contractorInfo, false);
  //     return;
  //   }

  //   this.getContractorInformationAPI().subscribe(
  //     (response: ContractorDetailsResponse) => {
  //       this.contractorInfo = response.responseData;
  //       callback(response.responseData, false);
  //     }, (error) => {
  //       callback(null, true);
  //       const message = this.helperService.handleAPIErrors(error);
  //       this.helperService.showToaster(ToasterType.ERROR, message);
  //     }
  //   );
  // }

  // getContractorInformationAPI(): Observable<ContractorDetailsResponse> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_CONTRACTOR_INFORMATION;
  //   return this.apiService.get(url).pipe(map(response => new ContractorDetailsResponse(response)));
  // }

  // getTechnicianList(queryParams): Observable<TechnicianInfo> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_TECHNICIANS_LIST;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new TechnicianInfo(response)));
  // }

  getInventoryList(queryParams): Observable<InventoryResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_MELIS_DETAIL;
    return this.apiService.get(url, queryParams).pipe(map(response => new InventoryResponse(response)));
  }

  getAssetModalList(): Observable<AssetModelResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_ASSEST_MODEL_LIST;
    return this.apiService.get(url).pipe(map(response => new AssetModelResponse(response)));
  }

  createConsumer(payload): Observable<CreateCustomerResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.CREATE_CONSUMER;
    return this.apiService.post(url, payload).pipe(map(response => new CreateCustomerResponse(response)));
  }

  updateConsumer(payload): Observable<CreateCustomerResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.CREATE_CONSUMER;
    return this.apiService.put(url, payload).pipe(map(response => new CreateCustomerResponse(response)));
  }

   activateCustomer(queryParams): Observable<CommonSuccessResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.ACTIVATE_CUSTOMER;
    return this.apiService.put(url, null, queryParams).pipe(map(response => new CommonSuccessResponse(response)));
  }

  // createTechnician(payload): Observable<CreateTechnicianResponse> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.CREATE_TECHNICIAN;
  //   return this.apiService.post(url, payload).pipe(map(response => new CreateTechnicianResponse(response)));
  // }

  assignTechnician(payload): Observable<any> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.ASSIGN_TECHNICIAN;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }

  assignCustomer(payload): Observable<AssignMeliToCustomerResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.ASSIGN_CUSTOMER;
    return this.apiService.post(url, payload).pipe(map(response => new AssignMeliToCustomerResponse(response)));
  }

  unAssignMeli(payload): Observable<UnassignCustomerFromMeliResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.UNASSIGN_MELI;
    return this.apiService.post(url, payload).pipe(map(response => new UnassignCustomerFromMeliResponse(response)));
  }

  createAssetModel(payload): Observable<CreateAssetModelResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.CREATE_ASSETS_MODEL;
    return this.apiService.post(url, payload).pipe(map(response => new CreateAssetModelResponse(response)));
  }

  getUnassignedCustomerList(): Observable<UnassignedCustomersListResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_UNASSIGNED_CUSTOMER_LIST;
    return this.apiService.get(url).pipe(map(response => new UnassignedCustomersListResponse(response)));
  }

  // updateMeliStatus(payload): Observable<UpdateMeliStatusResponse> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.UPDATE_MELI_STATUS;
  //   return this.apiService.post(url, payload).pipe(map(response => new UpdateMeliStatusResponse(response)));
  // }

  updateRelayStatus(payload) {
    const url = this.UPDATE_RELAY_BASE_URL + CONTRACTOR_API_CONSTANTS.UPDATE_RELAY_STATUS;
    return this.apiService.post(url, payload).pipe(map(response => response));
  }

  getPhoneCodesList(callback) {
    if (this.phoneCodesList) {
      return callback(this.phoneCodesList, false);
    } else {
      this.getPhoneCodesListAPI().subscribe(
        (response) => {
          this.phoneCodesList = response;
          callback(response, false);
        }, (error) => {
          callback(null, true);
          const message = `Error in getting phone codes`;
          this.helperService.showToaster(ToasterType.ERROR, message);
        }
      );
    }
  }

  getPhoneCodesListAPI() {
    const url = this.PHONE_CODES_REST_API_BASE_URL;
    return this.apiService.get(url, {}, false, false).pipe(map(response => response));
  }

  // getInstallationReport(queryParams) {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.INSTALLATION_REPORT;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new InstallationReportResponse(response)));
  // }

  // handleLogout(queryParams) {
  //   const url = this.AUTH_BASE_URL + CONTRACTOR_API_CONSTANTS.LOGOUT;
  //   return this.apiService.get(url, queryParams).pipe(map(response => response));
  // }

  getSearchList(queryParams): Observable<SearchResponse> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_SEARCH_LIST;
    return this.apiService.get(url, queryParams).pipe(map(response => new SearchResponse(response)));
  }

  // editTechnician(queryParams): Observable<EditTechnicianResponse> {
  //   const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.EDIT_TECHNICIAN;
  //   return this.apiService.get(url, queryParams).pipe(map(response => new EditTechnicianResponse(response)));
  // }

  // getHVACInformationList(): Observable<HVACInformationResponse> {
  //   const url = this.UPDATE_RELAY_BASE_URL + CONTRACTOR_API_CONSTANTS.GET_HVAC_INFORMATION;
  //   return this.apiService.get(url).pipe(map(response => new HVACInformationResponse(response)));
  // }

  handleDeleteHVACUnit(queryParams): Observable<any> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.DELETE_HVAC_UNIT;
    return this.apiService.delete(url, queryParams).pipe(map(response => (response)));
  }

  getAllTechniciansNames(): Observable<any> {
    const url = this.BASE_URL + CONTRACTOR_API_CONSTANTS.GET_ALL_TECHNICIAN_NAMES;
    return this.apiService.get(url).pipe(map(response => (response)));
  }

}
