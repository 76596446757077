import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Modules
import { SharedModule } from 'src/app/shared/shared.module';

// Components
// import { AssignCustomerModalComponent } from './components/assign-customer-modal/assign-customer-modal.component';
//import { AssignTechnicianModalComponent } from './components/assign-technician-modal/assign-technician-modal.component';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
// import { CreateTechnicianComponent } from './components/create-technician/create-technician.component';

const Components = [
  // AssignCustomerModalComponent,
  //AssignTechnicianModalComponent,
  CreateCustomerComponent,
  // CreateTechnicianComponent
];

@NgModule({
  declarations: [...Components],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule
  ],
  exports: [...Components]
})
export class SharedContractorModule { }
