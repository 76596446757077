import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BASE_URL_TYPES } from '../../constants/base-url-types.constant';
import { SITE_NAME_CONSTANTS } from '../../constants/site-name.constant';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent implements OnInit {
  @Output() emitCloseModal = new EventEmitter();
  contractorLogoBlack = require('src/assets/images/Current-Contractor-Black.svg');
  siteName: any;
  siteHeading: any;
  showLogoImage: boolean;
  constructor(private helperService: HelperService) { }

  ngOnInit() {
    this.siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
    if (this.siteName === SITE_NAME_CONSTANTS.KEENHOME_CHANNEL ||
      this.siteName === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR) {
      this.showLogoImage = false;
      this.siteHeading = "Welcome to your Keen Home Pro Portal! From here, you'll be able to view equipment alerts, run Pre-Season Tests, and generate valuable reports. To get started, please upload your customer list."
    }
    else if (this.siteName === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      this.showLogoImage = false;
      this.siteHeading = "Welcome to your Keen Home Pro MDU Portal! From here, you'll be able to view equipment alerts, run Pre-Season Tests, and generate valuable reports. To get started, please enter your billing info."
    }
    else {
      this.showLogoImage = true;
      this.siteHeading = "Welcome to your CoolCloud Portal! From here, you'll be able to view equipment alerts, run Pre-Season Tests, and generate valuable reports.To get started, please enter your billing info."
    }

  }
  closeModal() {
    this.emitCloseModal.emit();
  }

}
