import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment_ from 'moment';

// Services
import { HelperService } from 'src/app/shared/services/helper.service';
import { MeliService } from 'src/app/modules/meli/services/meli.service';

// Models
import { Inventory, Customer } from 'src/app/shared/models/inventory.model';
import { Email, Phone, Address } from 'src/app/shared/models/common.model';

// Constants
import { COMMON_CONSTANTS, PRESEASON_STATUS } from 'src/app/shared/constants/commom.constant';

// Enum
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { ScheduleEmergencyShutoffResponse } from 'src/app/shared/models/meli.model';
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { ContractorDetails } from '../../models/contractor.modal';
import { SITE_NAME_CONSTANTS } from '../../constants/site-name.constant';
import { BASE_URL_TYPES } from '../../constants/base-url-types.constant';
const moment = moment_;

@Component({
  selector: 'app-hvac-operations',
  templateUrl: './hvac-operations.component.html',
  styleUrls: ['./hvac-operations.component.scss']
})
export class HvacOperationsComponent implements OnInit {
 @ViewChild('scrollMe',{static:false}) private myScrollContainer: ElementRef;
  @Input() customerDetails: Customer;
  @Input() inventoryDetails: Inventory;
  @Input() currentHVACOperation: string;
  @Input() preSeasonTestDetails: any;
  @Output() emitCloseModal = new EventEmitter();
  @Output() refreshPreSeasonTest = new EventEmitter<any>();
  @Output() refreshEmergencyShutoffStatus = new EventEmitter();
  preSeasonTestForm: FormGroup;
  emergencyShutoffForm: FormGroup;
  commonConstants = COMMON_CONSTANTS;
  isActionBtnLoading = false;
  minDate = new Date();
  hasYesOrNoButtom: boolean = false;
  boardsCount: any;
  roleConstants = ROLE_CONSTANTS;
  scheduleResponseData: any[] = [];
  isShowWarningSymbol: boolean = false;
  confirmationModalLine4Content: any[];
  isShowLoopInformation: boolean = false;
  //for bulk preseason schedule data
  @Input() boardsListArray: any[] = [];
  @Input() isBulkSchedule: boolean = false;
isHeating:boolean=true;
  preSeasonTimeSlotValues = [
    '12 AM - 12:30 AM',
    '12:30 AM - 1 AM',
    '1 AM - 1:30 AM',
    '1:30 AM - 2 AM',
    '2 AM - 2:30 AM',
    '2:30 AM - 3 AM',
    '3 AM - 3:30 AM',
    '3:30 AM - 4 AM',
    '4 AM - 4:30 AM',
    '4:30 AM - 5 AM',
    '5 AM - 5:30 AM',
    '5:30 AM - 6 AM',
    '6 AM - 6:30 AM',
    '6:30 AM - 7 AM',
    '7 AM - 7:30 AM',
    '7:30 AM - 8 AM',
    '8 AM - 8:30 AM',
    '8:30 AM - 9 AM',
    '9 AM - 9:30 AM',
    '9:30 AM - 10 AM',
    '10 AM - 10:30 AM',
    '10:30 AM - 11 AM',
    '11 AM - 11:30 AM',
    '11:30 AM - 12 PM',
    '12 PM - 12:30 PM',
    '12:30 PM - 1 PM',
    '1 PM - 1:30 PM',
    '1:30 PM - 2 PM',
    '2 PM - 2:30 PM',
    '2:30 PM - 3 PM',
    '3 PM - 3:30 PM',
    '3:30 PM - 4 PM',
    '4 PM - 4:30 PM',
    '4:30 PM - 5 PM',
    '5 PM - 5:30 PM',
    '5:30 PM - 6 PM',
    '6 PM - 6:30 PM',
    '6:30 PM - 7 PM',
    '7 PM - 7:30 PM',
    '7:30 PM - 8 PM',
    '8 PM - 8:30 PM',
    '8:30 PM - 9 PM',
    '9 PM - 9:30 PM',
    '9:30 PM - 10 PM',
    '10 PM - 10:30 PM',
    '10:30 PM - 11 PM',
    '11 PM - 11:30 PM',
    '11:30 PM - 12 AM',
  ];
  emergencyShutoffTimeSlotValues = [
    '1 AM',
    '2 AM',
    '3 AM',
    '4 AM',
    '5 AM',
    '6 AM',
    '7 AM',
    '8 AM',
    '9 AM',
    '10 AM',
    '11 AM',
    '12 PM',
    '1 PM',
    '2 PM',
    '3 PM',
    '4 PM',
    '5 PM',
    '6 PM',
    '7 PM',
    '8 PM',
    '9 PM',
    '10 PM',
    '11 PM',
    '12 AM',
  ];
  isShowConfirmationModal = false;
  confirmationModalLine1Content: string;
  confirmationModalLine2Content: string;
  confirmationModalLine3Content: string;
  boardsText: any;
  portalName: any;
  confirmationModalTitle: string = "Warning";
  storeResponse: any;
  isBulkScheduleError: boolean;
  isEmergencyShutoffConfirmationModal: boolean;
  isScheduleConfirmationModal: boolean;
  confirmationModalPrimaryButtonName: string;
  contractorDetails: any;
  isSuccessToasterModal: boolean;
  scheduleModalEmitterFlag:boolean;
  swtichoffModalEmitterFlag: boolean;

  constructor(
    private helperService: HelperService,
    private meliService: MeliService,
    private activatedRoute: ActivatedRoute,
      private sharedService: SharedService,
  ) { }

  ngOnInit() {
    console.log(this.customerDetails)
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    })
    this.boardsCount = this.boardsListArray && this.boardsListArray.length;
    this.boardsCount > 1 ? this.boardsText = 'Boards Being Scheduled' : this.boardsText = 'Board Being Scheduled';
    let currentTime = moment(new Date()).format('hh:mm A');
    currentTime = currentTime && currentTime.toString();
    this.preSeasonTestForm = new FormGroup(
      {
        scheduleDate: new FormControl('', Validators.required),
        time: new FormControl('', Validators.required),
        //type: new FormControl('', Validators.required)
      }
    );

    this.emergencyShutoffForm = new FormGroup(
      {
        scheduleDate: new FormControl('', Validators.required),
        time: new FormControl('', Validators.required)
      }
    );

    if (this.currentHVACOperation) {
      if (this.currentHVACOperation === COMMON_CONSTANTS.EMERGENCY_SHUTOFF) {
        const date = moment(this.minDate).add(3, 'days');
        this.minDate = date && date.toDate();
      }
      
    }

    // // debugger

    // // let time = moment(new Date()).format('HH:mm A');
    // let time = moment(new Date(), 'hh:mm A');

    // time.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });

    // // if (time.hour() >= 8 && (time.hour() + time.minute()) <= 6.30) {

    // // }

    // if (time.format('hh:mm A') >= moment('08:00 AM', 'hh:mm A').format('hh:mm A') &&
    //   time.format('hh:mm A') <= moment('06:30 PM', 'hh:mm A').format('hh:mm A')) {

    // }

    // // debugger
     const siteName = this.helperService.handleBaseUrl(BASE_URL_TYPES.SITE_NAME);
      if (siteName === SITE_NAME_CONSTANTS.KEENHOME_CONTRACTOR  ||
      siteName === SITE_NAME_CONSTANTS.KEENHOME_MDU) {
      this.getContractorInformation();
    }
     

  }
//////get contractor details
getContractorInformation() {
    // debugger
    this.sharedService.getContractorInformation((response: ContractorDetails, isError) => {
      if (!isError) {
        this.contractorDetails = response;
      }

    });
  }

  handleSelectedDate(event: any) {
    // clearing time value to reduce the error, otherwise this leads to selection of past time
    if (this.currentHVACOperation &&
      this.currentHVACOperation === COMMON_CONSTANTS.PRE_SEASON_TEST) {
      this.preSeasonTestForm.patchValue({
        scheduleDate: event
      });
    } else if (this.currentHVACOperation &&
      this.currentHVACOperation === COMMON_CONSTANTS.EMERGENCY_SHUTOFF) {
      this.emergencyShutoffForm.patchValue({
        scheduleDate: event,
        time: ''
      });
    }
  }

  handleTimeSlotValue(times: any) {
    // let times = this.preSeasonTestForm.value.time;
    times = times.split('-');
    if (times && times.length >= 2) {
      return [times[0].trim(), times[1].trim()];
    } else if (times && times.length === 1) {
      return [times[0].trim()];
    }
  }

  handleTestType(data:any)
  {
    if(data=="heating")
    {
this.isHeating=true

    }
    else{
this.isHeating=false
    }
  }

  //function to schedule the preseasontest 
  handleSchedulePreSeasonTest() {
    const fromToTimes = this.handleTimeSlotValue(this.preSeasonTestForm.value.time);
    const date = moment(this.preSeasonTestForm.value.scheduleDate).format('YYYY-MM-DD');
    let startTime = date + ' ' + moment(fromToTimes[0], 'hh:mm A').format('hh:mm:ss A');
    let completeTime = moment(startTime).add(30, 'minute').toDate()
    let payload = {};
    if (this.isBulkSchedule) {
      payload = {
        boards: this.boardsListArray,
        scheduleTime: startTime,
        completionTime: completeTime,
        testType: this.isHeating?"heat-test":"cool-test",
        user: localStorage.getItem('userName')
      };
    }
    else {
      payload = {
        boardSerialNumber: this.inventoryDetails && this.inventoryDetails.meliSerialNumber,
        deviceType: this.inventoryDetails && this.inventoryDetails.deviceType,
        scheduleTime: startTime,
        completionTime: completeTime,
        testType:  this.isHeating?"heat-test":"cool-test",
        user: this.portalName == this.roleConstants.MDU ?
          this.customerDetails && this.customerDetails.email ? this.customerDetails.email[0].email : localStorage.getItem('userName')
          : this.customerDetails.email[0].email,
      };
    }
    this.isActionBtnLoading = true;
    this.meliService.handleSchedulePreSeasonTest(payload, this.isBulkSchedule).subscribe(
      (response) => {
        this.storeResponse = response;
        this.isActionBtnLoading = false;
        // this.closeModal();
        //this.storeResponse = response;
        //let day = moment.utc(response.responseData.scheduleTime).local().format('MM/DD/YY hh:mm A');
        if (this.isBulkSchedule) {
          let res = response.responseData;
          if (res) {
            res.forEach(info => {
              if (info.statusCode === "4444") {
                this.scheduleResponseData.push(info);
              }
            });
            if (this.scheduleResponseData.length > 0) {
              this.toggleConfirmationModal();
              this.confirmationModalTitle = "Warning";
              let test = this.scheduleResponseData.length > 1 ? 'tests' : 'test';
              let reason = this.scheduleResponseData.length>1?'reason':'reasons';
              this.confirmationModalLine1Content = `${this.scheduleResponseData.length} Pre-Season ${test} could not be scheduled for the following ${reason}:`;
              this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content ="";
              this.hasYesOrNoButtom = false;
              this.isShowWarningSymbol = false;
              this.isShowLoopInformation = true;
              this.confirmationModalLine4Content = this.scheduleResponseData;
              this.isBulkScheduleError=true;
              this.confirmationModalPrimaryButtonName="OK"
                           
            }
            else {
              //this.refreshPreSeasonTest.emit(response.responseData);
             // this.closeModal();
              //this.helperService.showToaster(ToasterType.SUCCESS, "Pre-Season test scheduled successfully");
            this.hasYesOrNoButtom = false;
      this.confirmationModalTitle = "";
      this.confirmationModalLine1Content = `Pre-Season Test has been scheduled. An email will be sent to the customer.`;
      this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content =""; 
      this.isShowWarningSymbol = true;
      this.isShowLoopInformation = false;
      this.confirmationModalPrimaryButtonName="OK";
      this.isSuccessToasterModal=true;
      this.scheduleModalEmitterFlag = true;
            this.toggleConfirmationModal();
            }
          }
        }
        else {
          //this.refreshPreSeasonTest.emit(response.responseData);
          //this.closeModal();

          //let res = response.responseData.message + " on " + moment.utc(response.responseData.scheduleTime).format('MM-DD-YYYY h:mm A');
          //console.log(res)
          //this.helperService.showToaster(ToasterType.SUCCESS, res);
          
      this.hasYesOrNoButtom = false;
      this.confirmationModalTitle = "";
      this.confirmationModalLine1Content = `Pre-Season Test has been scheduled. An email will be sent to the customer.`;
      this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content =""; 
      this.isShowWarningSymbol = true;
      this.isShowLoopInformation = false;
      this.confirmationModalPrimaryButtonName="OK";
      this.isSuccessToasterModal=true;
      this.scheduleModalEmitterFlag = true;
            this.toggleConfirmationModal();
        }
      }, (error) => {
        this.isActionBtnLoading = false;
        this.closeModal();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  handleIsPreSeasonScheduled() {
    const preSeasonTime = moment.utc(this.preSeasonTestDetails && this.preSeasonTestDetails.responseData &&
      this.preSeasonTestDetails.responseData.scheduleTime).local().format('YYYY-MM-DD hh:mm A');

    const time = this.handleTimeSlotValue(this.emergencyShutoffForm.value.time);
    const date = moment(this.emergencyShutoffForm.value.scheduleDate).format('YYYY-MM-DD');
    const emergencyShutoffTime = date + ' ' + moment(time[0], 'hh:mm A').format('hh:mm A');
    if (preSeasonTime === emergencyShutoffTime) {
      this.hasYesOrNoButtom = false;
      this.confirmationModalTitle = "Warning";
      this.confirmationModalLine1Content = `A Pre-season Test has been scheduled for this time. 
        Please cancel the Pre-Season Test before proceeding to schedule an Emergency Shut-off.`;
        this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content ="";
      this.isShowWarningSymbol = false;
      this.isShowLoopInformation = false;
      this.toggleConfirmationModal();
    } else {
      this.handleScheduleEmergencyShutoff();
    }
  }

  handleScheduleEmergencyShutoff() {

    const time = this.handleTimeSlotValue(this.emergencyShutoffForm.value.time);
    const date = moment(this.emergencyShutoffForm.value.scheduleDate).format('YYYY-MM-DD');
    let startTime = date + ' ' + moment(time[0], 'hh:mm A').format('hh:mm:ss A');
    const scheduledTime = moment(new Date(startTime)).format("YYYY-MM-DD hh:mm:ss A");
    const payload = {
      boardSerialNumber: this.inventoryDetails && this.inventoryDetails.meliSerialNumber,
      scheduleTime: scheduledTime
    };
    this.isActionBtnLoading = true;
    this.meliService.handleScheduleEmergencyShutoff(payload).subscribe(
      (response: ScheduleEmergencyShutoffResponse) => {
        this.refreshEmergencyShutoffStatus.emit();
        this.isActionBtnLoading = false;
        //this.closeModal();
        const day = moment(
          response.scheduleEmergencyShutoffResponseDetails &&
          response.scheduleEmergencyShutoffResponseDetails.scheduleTime
        ).local().format('MM-DD-YYYY h:mm A');
        //const res = response.scheduleEmergencyShutoffResponseDetails &&
          //response.scheduleEmergencyShutoffResponseDetails.message + " on " + day;
        //this.helperService.showToaster(ToasterType.SUCCESS, res);
         this.hasYesOrNoButtom = false;
      this.confirmationModalTitle = "";
      this.confirmationModalLine1Content = `Shut-off has been scheduled. An email will be sent to the customer.`;
      this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content =""; 
      this.isShowWarningSymbol = true;
      this.isShowLoopInformation = false;
      this.confirmationModalPrimaryButtonName="OK";
      this.isSuccessToasterModal=true;
      this.swtichoffModalEmitterFlag = true;
            this.toggleConfirmationModal();
      }, (error) => {
        this.isActionBtnLoading = false;
        this.closeModal();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );

  }

  handleValidateTime(form: FormGroup, time: any) {
    // checking date is selected or not. If not, then time slot is disabled.
    if (form && form.get('scheduleDate') && form.get('scheduleDate').valid) {
      // If selected date is > today's date then, enabled all time slots.

      if (Date.parse((form.value && form.value.scheduleDate)) <=
        Date.parse(new Date().toString())) {

        let startTime: any = this.handleTimeSlotValue(time);

        if (startTime && startTime.length) {
          startTime = moment(new Date()).format('YYYY-MM-DD') + ' ' +
            moment(startTime[0], 'hh:mm A').format('hh:mm:ss A');
        }

        if (Date.parse(startTime) <
          Date.parse(moment(new Date()).format('YYYY-MM-DD hh:mm:ss A'))) {
          return true;
        } else {
          return false;
        }

      } else {
        return false;
      }
    } else {
      return true;
    }

  }

  handleActionBtnValidation() {
    if (this.currentHVACOperation) {
      if (this.currentHVACOperation === COMMON_CONSTANTS.PRE_SEASON_TEST) {
        if (this.preSeasonTestForm.valid) {
          return true;
        }
      } else if (this.currentHVACOperation === COMMON_CONSTANTS.EMERGENCY_SHUTOFF) {
        if (this.emergencyShutoffForm.valid) {
          return true;
        }
      }
      return false;
    }
  }


  handleActionBtn() {
    if (this.currentHVACOperation) {
      if (this.currentHVACOperation === COMMON_CONSTANTS.PRE_SEASON_TEST) {

        if (this.isBulkSchedule) {
          let getStatus = this.getScheduledStatus();
          console.log(getStatus)
          if (getStatus) {
            if(this.boardsCount==1){
              this.confirmationModalLine1Content = 'There are previously scheduled Pre-Season Test in this selection.';
            this.confirmationModalLine2Content = 'This action will overwrite any previously scheduled test.';
            this.confirmationModalLine3Content = `Are you sure you want to schedule ${this.boardsCount} Pre-Season Test?`;
            }
            else if(this.boardsCount>1){
              this.confirmationModalLine1Content = 'There are previously scheduled Pre-Season Tests in this selection.';
              this.confirmationModalLine2Content = 'This action will overwrite any previously scheduled tests.';
              this.confirmationModalLine3Content = `Are you sure you want to batch schedule ${this.boardsCount} Pre-Season Tests?`;
            }
            
          }
          else {
            if(this.boardsCount==1){
              this.confirmationModalLine1Content = `Are you sure you want to schedule ${this.boardsCount} Pre-Season Test?`;
            this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content ="";
            }
            else if(this.boardsCount>1){
              this.confirmationModalLine1Content = `Are you sure you want to batch schedule ${this.boardsCount} Pre-Season Tests?`;
           this.confirmationModalLine2Content="";
      this.confirmationModalLine3Content ="";
            }
            
          }
           this.hasYesOrNoButtom = true;
           this.confirmationModalTitle = "Warning";
          this.isShowWarningSymbol = false;
          this.isShowLoopInformation = false;
          this.toggleConfirmationModal();
        }
        else {
           this.handleSchedulePreSeasonTest();
        }
          
      } else if (this.currentHVACOperation === COMMON_CONSTANTS.EMERGENCY_SHUTOFF) {
        // if (this.emergencyShutoffForm.valid) {
        //   return true;
        // }
        this.handleIsPreSeasonScheduled();
      }
    }
  }

  //get the status whether any of the selected board is scheduled previously or not

  getScheduledStatus() {
    console.log(this.boardsListArray)
    let isScheduledData;
    this.boardsListArray.forEach(data => {
      if (data.scheduled != PRESEASON_STATUS.CRIICAL_FAULTS_DETECTED &&
        data.scheduled != PRESEASON_STATUS.FAULTS_DETECTED &&
        data.scheduled != PRESEASON_STATUS.NOT_SCHEDULED &&
        data.scheduled != PRESEASON_STATUS.PASS && data.scheduled != PRESEASON_STATUS.TEST_INCOMPLETE && data.scheduled != PRESEASON_STATUS.TEST_DIDNOT_RUN) {
        isScheduledData = true;
      }
      else {
        isScheduledData = false;
      }
    });
    return isScheduledData
  }
  handleSelectedTime(event) {
    this.preSeasonTestForm.patchValue({
      time: event && moment(event).format('hh:mm A')
    });
  }

  handleEmail(emailList: Email[], requiredType: string) {
    const email = this.helperService.handleEmailSelection(emailList, requiredType);
    return email && email.email;
  }

  handlePhone(phoneList: Phone[], requiredType: string) {
    const phone = this.helperService.handlePhoneSelection(phoneList, requiredType);
    return phone && this.helperService.handleFormatPhoneNumber(phone.phoneNumber);
    console.log(phone.phoneNumber)
  }

  toggleConfirmationModal() {
    this.isShowConfirmationModal = !this.isShowConfirmationModal;
    if (this.isBulkScheduleError) {
      this.closeModal();
      this.refreshPreSeasonTest.emit([]);
    }
   

  }

  closeModal() {
    this.emitCloseModal.emit();
  }

  handleConfirmationModalEmitter(data) {
    this.toggleConfirmationModal();
    if(!this.swtichoffModalEmitterFlag && !this.scheduleModalEmitterFlag)
    {
 this.handleSchedulePreSeasonTest();
    }
   
     if(this.scheduleModalEmitterFlag)
    {
      this.closeModal();
      this.scheduleModalEmitterFlag=false;
      this.refreshPreSeasonTest.emit(this.storeResponse.responseData);
    }
    if(this.swtichoffModalEmitterFlag)
    {
      this.closeModal();
      this.swtichoffModalEmitterFlag=false;
       //this.refreshEmergencyShutoffStatus.emit();
      //this.refreshPreSeasonTest.emit(this.storeResponse.responseData);
    }
   
  }


  scrolldown()
  {
    console.log("scrolldown");
     try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
        } catch(err) { }      
  }

}

//const b=moment(new Date(moment.utc(scheduleTime).valueOf())).format();
    // const scheduledTime = new Date(startTime).format("YYYY-MM-DD hh:mm:ss A");
    //const completedTime = new Date(completeTime).format("YYYY-MM-DD hh:mm:ss A");
    // let completeTime = date + ' ' + moment(fromToTimes[1], 'hh:mm A').format('hh:mm:ss A');

            // thingName: this.inventoryDetails.thingName,
        // thingName:'CM-MW01-KA2019-01-000098',