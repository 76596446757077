import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'src/app/shared/services/helper.service';
import { ToasterType } from 'src/app/shared/constants/toaster.enum';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as moment from 'moment';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { environment as channelEnvironment } from 'projects/channel/src/environments/environment';
import { environment as mduEnvironment } from 'projects/mdu/src/environments/environment';
import { ROLE_CONSTANTS } from '../../constants/role.constant';
import { ActivatedRoute, Router } from '@angular/router';
import { COMMON_CONSTANTS, DOWNLOAD_TEMPLATE_FILE_NAME, UPLOAD_TEMPLATE_NAMES } from '../../constants/commom.constant';
import { CustomerService } from 'projects/contractor/src/app/modules/customer/services/customer.service';
import { ContractorSharedService } from 'projects/contractor/src/app/shared-contractor/services/shared.service';
import { UploadProperyListResponse, uploadSuccessResponse } from '../../models/properties.model';
import { TechnicianService } from 'src/app/modules/technician/services/technician.service';


@Component({
  selector: 'app-upload-list-modal',
  templateUrl: './upload-list-modal.component.html',
  styleUrls: ['./upload-list-modal.component.scss']
})
export class UploadListModalComponent implements OnInit {
  @ViewChild('customerBulkUpload', { static: false }) customerBulkUpload: ElementRef;
  @ViewChild('technicianBulkUpload', { static: false }) technicianBulkUpload: ElementRef;
  @Output() emitCloseModal = new EventEmitter();
  @Input() modalTitle: string;
  @Input() modalInfo: any;
  isTechModal = false;
  errorFileName: string;
  selectedFile: any;
  fileType: any;
  uploadCustomerFileLoading: boolean = false;
  uploadTechnicianFileLoading: boolean = false;
  modalType: string;
  confirmationModalLine1Content: any;
  confirmationModalLine2Content: any;
  confirmationModalLine3Content: any;
  errorCount: number;
  confirmationModalEmitterFlag: any;
  isConfirmationModalHasYesOrNoButton: boolean;
  isSuccessToasterModal: boolean;
  isForUploadError: boolean;
  isShowInfoModal: any[]=[];
  data: any = {};
  custModal: boolean = false;
  techModal: boolean = false;
  modalContent: string;
  portalName: any;
  uploadStatusTimeInterval: NodeJS.Timer;
  uploadTechStatusTimeInterval: any;
  arraytemplate: any[]=[];
  uploadCustomerText = "Upload Customer File";
  uploadTechinicanText = "Upload Technician File";
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private technicianService: TechnicianService,
    private helperService: HelperService,
    private contractorSharedService: ContractorSharedService,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    //console.log(this.router.url)
    this.arraytemplate=[];
    this.activatedRoute.data.subscribe((data) => {
      if (data) {
        this.portalName = data.portalName;
      }
    });

    if (this.modalInfo.sitename == 'keenhome-contractor') {
      if (this.modalInfo.consumerExist == false) {
        this.custModal = true;
        this.modalTitle = "Upload Your Customer List"
        this.modalContent = "For the best experience, download the Customer Template and upload your entire customer list. You can always add new customers manually by visiting the Customers tab.";
      this.fetchuploadStatus("cust",UPLOAD_TEMPLATE_NAMES.CONSUMER);
      }
      else if (this.modalInfo.consumerExist == true) {
        this.onNextButtonClick();

         //this.fetchuploadStatus("cust",UPLOAD_TEMPLATE_NAMES.CONTRACTOR_TECHNICIAN);
      }
       
    }

    else if (this.modalInfo.sitename == 'keenhome-mdu') {
      if (this.modalInfo.technicianExist == false) {
        this.custModal = false;
        this.techModal = true;
        this.modalTitle = "Upload Your Technician List"
        this.modalContent = "Upload your technician CSV or add technicians manually by visiting your technicians list.";
      this.fetchuploadStatus("cust",UPLOAD_TEMPLATE_NAMES.MDU_TECHNICIAN);
      }
      else {
        this.closeModal()
      }
    }

  }
  onNextButtonClick() {
    if (this.modalInfo.technicianExist == false) {
      this.custModal = false;
      this.techModal = true;
      this.modalTitle = "Upload Your Technician List"
      this.modalContent = "For the best experience, download the Technicians Template and upload your company’s technicians. You can always add new technicians manually by visiting the Technicians tab.";
    this.fetchuploadStatus("tech",UPLOAD_TEMPLATE_NAMES.CONTRACTOR_TECHNICIAN);
    }
    else {
      this.closeModal()
    }


  }
  handleButton(type, value?) {
    if (type == "customer_template") {
      this.downloadTemplate()
    }
    else if (type == "technician_template") {
      this.downloadTechnicianTemplate()
    }
    else if (type == "nextbutton") {
      this.onNextButtonClick();
    }
    else if (type == "skipbutton") {
      //this.onNextButtonClick();
       this.closeModal();
    }
  }


  downloadTemplate() {
    const queryParams = {
      filename: DOWNLOAD_TEMPLATE_FILE_NAME.CUSTOMER
    };
    this.sharedService.downloadTemplate(queryParams).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "customer-template.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      }, (error) => {
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  downloadTechnicianTemplate() {
    const queryParams = {
      filename: this.modalInfo.sitename == 'keenhome-contractor' ? DOWNLOAD_TEMPLATE_FILE_NAME.CONTRACTOR_TECHNICIAN : DOWNLOAD_TEMPLATE_FILE_NAME.MDU_TECHNICIAN
    };
    this.sharedService.downloadTemplate(queryParams).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
        const a: any = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = "technician-template.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      }, (error) => {
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

  downloadErrorFileInfo(value) {
    window.open(value);
    // let queryParams = {
    //   filename: this.errorFileName
    // }
    // this.sharedService.downloadErrorTemplateFile(queryParams).subscribe((response) => {
    //   const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
    //   const a: any = document.createElement('a');
    //   document.body.appendChild(a);
    //   a.style = 'display: none';
    //   const url = window.URL.createObjectURL(blob);
    //   a.href = url;
    //   a.download = "ErrorInformation.xlsx";
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // }, (error) => {
    //   const message = this.helperService.handleAPIErrors(error);
    //   this.helperService.showToaster(ToasterType.ERROR, message);
    // })
  }

  handleOnSelectFiles(event, modalType) {
    //console.log('Z', event.target.files[0].name.split('.'))
    if (event.target && event.target.files && event.target.files[0] &&
      event.target.files[0].name) {
      const names = event.target.files[0].name.split('.');
      if (names && names.length && (names[names.length - 1] == 'xlsx' ||
        names[names.length - 1] == 'csv')) {
        // this.selectedCompanyLogo = event.target.files[0] as File;
        // this.companyLogoName = event.target.files[0].name;
        this.selectedFile = event.target.files[0] as File;
        this.fileType = names[names.length - 1].toUpperCase()
        // let companyLogoName = event.target.files[0].name;
        if (modalType == 'cust') {
          this.uploadCustomerText = "Customer Upload is in Progress"
          let templateName = UPLOAD_TEMPLATE_NAMES.CONSUMER;
          this.uploadFile(this.selectedFile, (names[names.length - 1]).toUpperCase(), templateName,modalType);
        }
        if (modalType == 'tech') {
          this.uploadTechinicanText = "Technician Upload is in Progress"
        let templateName= this.portalName === ROLE_CONSTANTS.CONTRACTOR?UPLOAD_TEMPLATE_NAMES.CONTRACTOR_TECHNICIAN:UPLOAD_TEMPLATE_NAMES.MDU_TECHNICIAN;
          this.uploadFile(this.selectedFile, (names[names.length - 1]).toUpperCase(),templateName,modalType);
        }
      } else {
        const message = "Selected file should be xlsx/csv format";
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    }
  }
  handleInfoModalEmitter(value1,value2) {
    if (value1=== 'show-error-file') {
      this.downloadErrorFileInfo(value2)
    }
  }
  // uploadTechnicianFile(selectedDocument, fileType) {
  //   const filedata = new FormData();
  //   if (selectedDocument) {
  //     filedata.append('file', selectedDocument);
  //     filedata.append('fileType', fileType);
  //   }
  //   const queryParams = {
  //     templateName: this.portalName === ROLE_CONSTANTS.CONTRACTOR?UPLOAD_TEMPLATE_NAMES.CONTRACTOR_TECHNICIAN:UPLOAD_TEMPLATE_NAMES.MDU_TECHNICIAN,    };
  //   this.uploadTechnicianFileLoading = true;
  //   this.technicianService.uploadTechnicianList(queryParams,filedata).subscribe(
  //     (response: UploadProperyListResponse) => {
  //       this.uploadTechnicianFileLoading = false;
  //       if (response.responseData.in_valid_records_count > 0) {
  //         this.handleModalType('uploaderror', response)

  //       }
  //       else {
  //         this.helperService.showToaster(ToasterType.SUCCESS, response && response.responseData.Message);
  //       }

  //       // this.helperService.showToaster(ToasterType.SUCCESS, response && response.technicianBulkUploadResponseDetails &&
  //       //response.technicianBulkUploadResponseDetails.message);
  //       this.handleResetBulkUpload();
  //     },
  //     (error) => {
  //       this.uploadTechnicianFileLoading = false;
  //       this.handleResetBulkUpload();
  //       const message = this.helperService.handleAPIErrors(error);
  //       this.helperService.showToaster(ToasterType.ERROR, message);
  //     }
  //   );
  // }
 

  uploadFile(selectedDocument, fileType,templateName,uploadType) {
    //console.log(uploadType)
    const filedata = new FormData();
    if (selectedDocument) {
      filedata.append('file', selectedDocument);
      filedata.append('fileType', fileType);
    }
      const queryParams = {
      templateName: templateName
    };
     this.uploadCustomerFileLoading = true;
     this.handleLoadingTrue(uploadType);
    this.sharedService.uploadExcelList(queryParams,filedata).subscribe(
      (response: UploadProperyListResponse) => {
        this.handleLoadingTrue(uploadType);
        this.fetchuploadStatus(uploadType,templateName);
      },
      (error) => {
          this.handleLoadingFalse(uploadType);
        this.handleResetBulkUpload();
        const message = this.helperService.handleAPIErrors(error);
        this.helperService.showToaster(ToasterType.ERROR, message);
      }
    );
  }

   uploadFileStatus(type,template?)
  {
     const queryParams = {
      templateName:template,
    };
    this.sharedService.uploadExcelListStatus(queryParams).subscribe(
      (response)=>{
      //console.log(response)
      if(response.statusInfo=="Completed" && response.responseData.isSeen !=true)
      {   this.handleLoadingFalse(type);
          this.clearuploadInterval(type);
          if (response.responseData.in_valid_records_count > 0 ) {
            this.handleModalType('uploaderror', response ,template)}
          
          else {
              this.helperService.showToaster(ToasterType.SUCCESS, response && response.responseData.message)
              this.uploadFileStatusReset(template);
              //console.log(this.modalInfo.technicianExist,this.techModal)
              if(!this.modalInfo.technicianExist&& template=="CONSUMER")
              {
                  this.onNextButtonClick();
              }
              else if(!this.modalInfo.technicianExist&& template=="CONTRACTOR_TECHNICIAN")
              {this.closeModal();}
              else if(this.modalInfo.technicianExist && template=="CONSUMER")
              {this.closeModal();}
             
            }
      }
      else if((response.statusInfo=="Completed" && response.responseData.isSeen ==true) || response.statusInfo=="No result found")
      { this.handleLoadingFalse(type); }
      else
      { this.handleLoadingTrue(type); }
    },(error)=>{
          this.clearuploadInterval(type);
            this.handleLoadingFalse(type);
            const message = this.helperService.handleAPIErrors(error);
            this.helperService.showToaster(ToasterType.ERROR, message);
          }
    );
  }
  uploadFileStatusReset(template,index?)
  {
    const queryParams = {
  templateName:template,
    };
    this.sharedService.uploadExcelListStatusReset(queryParams).subscribe(response=>{ 
        this.handleResetBulkUpload();     
    },err=>{
        this.handleResetBulkUpload();
    });
  }
  fetchuploadStatus(type,template?)
  {
    //console.log("statusssssssssssssssssssssssssss")
  this.uploadFileStatus(type,template);
    if(type=="cust")
    {
 this.uploadStatusTimeInterval = setInterval(()=>{
              this.uploadFileStatus(type,template);
         },30000) 
    }
    else{
       this.uploadTechStatusTimeInterval = setInterval(()=>{
              this.uploadFileStatus(type,template);
         },30000) 
    }
     
  }
  clearuploadInterval(type?)
  {
      if(type == 'cust')
      {
         if (this.uploadStatusTimeInterval) {
              clearInterval(this.uploadStatusTimeInterval);
                }
      }
      else
      {
        if (this.uploadTechStatusTimeInterval) {
              clearInterval(this.uploadTechStatusTimeInterval);
                }
      }
  }
   handleResetBulkUpload() {
    if (this.customerBulkUpload && this.customerBulkUpload.nativeElement) {
      this.customerBulkUpload.nativeElement.value = null;
    }
    if (this.technicianBulkUpload && this.technicianBulkUpload.nativeElement) {
      this.technicianBulkUpload.nativeElement.value = null;
    }
  }

  handleLoadingFalse(type)
  {
    if(type=="cust")
    {
      this.uploadCustomerFileLoading = false;
        this.uploadCustomerText = "Upload Customer List File";
    }
      else 
      {this.uploadTechnicianFileLoading = false;
          this.uploadTechinicanText = "Upload Technician List File";
      }
  }
   handleLoadingTrue(type)
  {
    type=="cust"?this.uploadCustomerFileLoading = true:this.uploadTechnicianFileLoading = true;
  }
  handleModalType(type: string, value?: any, value2?: any, value3?: any) {
    this.modalType = type;
    this.confirmationModalLine1Content = null;
    this.confirmationModalLine2Content = null;
    this.confirmationModalLine3Content = null;
    this.errorCount = 0;
    this.errorFileName = null;
    this.confirmationModalEmitterFlag = null;
    this.isConfirmationModalHasYesOrNoButton = false;
    this.isSuccessToasterModal = false;
    this.isForUploadError = false;
    if (type === 'uploaderror') {
      this.confirmationModalLine1Content = "Your file has been uploaded successfully but with "
      this.confirmationModalLine2Content = "You may continue or correct the errors and re-upload."
      //this.confirmationModalLine3Content = "Your existing technicians will not be duplicated."
      // this.errorCount = value.responseData.in_valid_records_count;
      // this.errorFileName = value.responseData.invalidDataFileLinkUrl;
      this.confirmationModalEmitterFlag = 'show-error-file';
      this.isConfirmationModalHasYesOrNoButton = false;
      this.isSuccessToasterModal = true;
      this.isForUploadError = true;
       //this.arraytemplate.push(value2);
       
       this.showConfirmationModal(value,value2);
    }
  }

  showConfirmationModal(value,value2) {
    //console.log(value2)
    value2 == (UPLOAD_TEMPLATE_NAMES.CONTRACTOR_TECHNICIAN || UPLOAD_TEMPLATE_NAMES.MDU_TECHNICIAN)?
    this.confirmationModalLine3Content = "Your existing technicians will not be duplicated.":
    this.confirmationModalLine3Content = "Your existing Customers will not be duplicated.";
     let d = {
          status:true,
          errorCount:value.responseData.in_valid_records_count,
          errorFileName:value.responseData.invalidDataFileLinkUrl,
          confirmationModalLine3Content:this.confirmationModalLine3Content,
          template:value2
        }
        this.isShowInfoModal.push(d)
  }
  closeConfirmationModal(index,template)
  {
    this.isShowInfoModal[index]=false;
    this.uploadFileStatusReset(template,index);
    let removeData = this.isShowInfoModal.findIndex( item => item.template === template );
    this.isShowInfoModal.splice( removeData, 1 );
    //removeByAttr(this.isShowInfoModal,"template",template)
    //console.log(this.isShowInfoModal)
  }
  closeModal() {
    this.emitCloseModal.emit();
  }
  ngOnDestroy() {
    if (this.uploadStatusTimeInterval) {
              clearInterval(this.uploadStatusTimeInterval);
                }
                 if (this.uploadTechStatusTimeInterval) {
              clearInterval(this.uploadTechStatusTimeInterval);
                }
  }
}


