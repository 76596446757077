export const COMMON_CONSTANTS = {
    PAGINATION_NEXT: 'NEXT',
    PAGINATION_PREVIOUS: 'PREVIOUS',
    PAGINATION_FIRST: 'FIRST',
    PAGINATION_LAST: 'LAST',
    ADDRESS_LINES_COMBINATOR: '---',
    COMPANY_LOGO_COMMON_NAME: 'primary',
    EMAIL_PRIMARY: 'primary',
    EMAIL_SECONDARY: 'secondary',
    IN_BUSINESS_SINCE_START_YEAR: 1900,
    FAHRENHEIT: 'fahrenheit',
    CELCIUS: 'celsius',
    PASCAL: 'pascal',
    WATER_COLUMN: 'water-column',
    WC: 'wc',
    ADDRESS_TYPE_SERVICE: 'service-address',
    ADDRESS_TYPE_BILLING: 'billing-address',
    ADDRESS_TYPE_REGISTERED_CORPORATE: 'registered-corporate',
    ADDRESS_TYPE_BRANCH_ADDRESS: 'branch-office',
    SENSOR_STATUS_WORKING: 'working',
    SENSOR_STATUS_NOT_WORKING: 'not-working',
    SENSOR_STATUS_INACTIVE: 'inactive',
    SENSOR_STATUS_NOT_INSTALLED: 'Not Installed',
    SENSOR_STATUS_INSTALLED: 'Installed',
    DISCHARGE_AIR_TEMPERATURE: 'Discharge Air Temperature',
    SUPPLY_AIR_TEMPERATURE: 'Supply Air Temperature',
    RETURN_AIR_TEMPERATURE: 'Return Air Temperature',
    SUCTION_LINE_TEMPERATURE: 'Suction Line Temperature',
    LIQUID_LINE_TEMPERATURE: 'Liquid Line Temperature',
    RETURN_PRESSURE: 'Return Pressure',
    SUPPLY_PRESSURE: 'Supply Pressure',
    AMBIENT_LINE_TEMPERATURE: 'Ambient Temperature',
    DELTA: 'DAT-RAT',
    SUPPLY_AND_RETURN_AIR_STATIC_PRESSURE_DIFFERENCE: 'Supply And Return Air Static Pressure Difference',
    CONDENSATE_SWITCH: 'Condensate Switch',
    DISCHARGE_AIR_TEMPERATURE_COLOR: '#71CECA',
    RETURN_AIR_TEMPERATURE_COLOR: '#F89409',
    SUCTION_LINE_TEMPERATURE_COLOR: '#D289CC',
    LIQUID_LINE_TEMPERATURE_COLOR: '#CEE816',
    AMBIENT_LINE_TEMPERATURE_COLOR: '#19e800',
    SUPPLY_AND_RETURN_AIR_STATIC_PRESSURE_DIFFERENCE_COLOR: '#FFC6C6',
    AVG_DELTA_T: '#00B7A5',
    DELTA_COLOR: '#F4C2B7',
    PRIORITY_MINOR: 'Minor',
    PRIORITY_MAJOR: 'Major',
    INDOOR_EQUIPMENT: 'indoor',
    OUTDOOR_EQUIPMENT: 'outdoor',
    AIR_CONDITIONER: 'Air Conditioner',
    GAS_FURNACE: 'Gas Furnace',
    HEAT_PUMP: 'Heat Pump',
    HEAT_PUMP_WITH_ELEC_STRIP: 'Heat Pump w/ Elec Strip',
    PRE_SEASON_TEST: 'pre-season-test',
    EMERGENCY_SHUTOFF: 'emergency-shutoff',
    ALERTS_SITE_TOUR: `The Alerts tab is a sortable view of the current alerts across
    every installed ComfortHome board.`,
    CUSTOMERS_SITE_TOUR: `The Customers tab allows you to conveniently communicate directly
    with the customer and a get quick view of customer installations.`,
    INVENTORY_SITE_TOUR: `The Inventory tab shows all the Performance Board units in your 
    possession, installed in customer's homes, or in the RMA process.`,
    TECHNICIANS_SITE_TOUR: `The Technicians tab allows you to view all your technicians, their
    job status and job history.`,
    SEARCH_SITE_TOUR: `The search bar allows you to search of specific Performance Boards, 
    customers, and technicians. Enter all or part of the search term to search.`,
    PHONE_NUMBERS_COUNT_MAX: 2,
    INFINITE_SCROLL_THROTTLE: 200,
    INFINITE_SCROLL_DISTANCE: 2,
    INITIAL_TABLE_ROW_COUNT: 10,
    SCROLL_TABLE_ROW_COUNT: 10,
    TABLE_TR_HEIGHT: 45,
    TRUE: true,
    FALSE: false,
    SENSOR_INSTALLED: 'Installed',
    SENSOR_NOT_INSTALLED: 'Not_Installed',
};

export const HVAC_ID = {
    ID1: 1,
    ID2: 2,
    ID3: 3,
    ID4:4,
    FUEL_TYPE_ELECTRIC: 4,
   
    INDOOR_EQUIP_TYPE_AIR_HANDLER: 2,
    INDOOR_EQUIP_TYPE_AIR_HANDLER_WITH_ELEC_STRIP: 3,
    INDOOR_EQUIP_TYPE_HEAT_PUMP: 4,
    INDOOR_EQUIP_TYPE_HEAT_PUMP_WITH_ELEC_STRIP: 5,
     INDOOR_EQUIP_TYPE_FURNACE: 1,
}

export const BOARDTYPES = {
    Celluar: 'Cellular',
    Wi_Fi: 'Wi-Fi',
}

export const REPORTSTATUS = {
    Pass: 'Pass',
    Fault_Detected: 'Faults Detected',
    Critical_Fault: 'Critical Faults Detected',
    Incomplete: 'Test Incomplete',
    Test_Didnot_Run: 'Test Did Not Run',
    Heating_Failed: 'Heating Test Failed',
    Cooling_Failed: 'Cooling Test Failed'
}

export const CHART_TYPES = {
    SPLINE: 'spline',
    LINE: 'line',
    COLUMN: 'column'
}

export const CHART_UNIT_TYPES = {
    TEMPERATURE: 'temperature',
    PRESSURE: 'pressure'
}

export const TABS_CONSTANTS = {
    Table: 'table',
    Map: 'map'
}

export const COLUMN_SEARCH_CONSTANTS = {
    PROPERTYNAME: 'propertyName',
    PROPERTYUNIT: 'propertyUnitNumber',
    ADDRESS: 'streetAddress',
    CITY: 'city',
    STATE: 'state',
    PRESEASONTESTSTATUS: 'preseasonTestStatus',
    PRESEASONTESTDATE: 'preseasonTestDate',
    INSTALLED_ON: 'installedOn',

}

export const PRESEASON_STATUS = {
    PASS: 'Pass',
    NOT_SCHEDULED: 'Not Scheduled',
    FAULTS_DETECTED: 'Faults Detected',
    CRIICAL_FAULTS_DETECTED: 'Critical Faults Detected',
    TEST_INCOMPLETE: 'Test Incomplete',
    TEST_DIDNOT_RUN: "Test Did Not Run",
    TEST_FAILED_TO_RUN: "Test Failed To Run",
    HEATING_FAILED: 'Heating Test Failed',
    COOLING_FAILED: 'Cooling Test Failed',
    SCHEDULED: 'SCHEDULED',
    RUNNING:'RUNNING'
}

export const DOWNLOAD_TEMPLATE_FILE_NAME = {
    CONTRACTOR_TECHNICIAN: 'ContractorTechnicianSheet.xlsx',
    MDU_TECHNICIAN: 'MDUTechnicianSheet.xlsx',
    MDU_PROPERTY: 'MDUPropertySheet.xlsx',
    CHANNEL_DEALER: 'DealerInfoSheet.xlsx',
    CUSTOMER: 'ConsumerInfoSheet.xlsx'
}

export const MAP_STATUS = {
    ALL: 'all',
    MINOR: 'Minor',
    MAJOR: 'Major',
    FAULTS_DETECTED: 'fault',
    CRIICAL_FAULTS_DETECTED: 'critical',
    DEFAULT:'default'
}

export const SUBSCRIPTION_PLAN_ID = {
    PREMIUM_ID: 'Premium',
    PREMIUM_PLUS_ID: 'Premium+'
}

export const TECHNICIAN_STATUS = {
    PENDING: 'Pending',
    ACTIVE: 'Active',
    DE_ACTIVATED: 'De-Activated'
}

export const SIGNAL_STRENGTH_STATUS = {
    WEAK :'Weak ( -70 dBm to -100 dBm )',
    FAIR :'Fair ( -60 dBm to -70 dBm )',
    GOOD :'Good ( -50 dBm to -60 dBm )',
    EXELLENT :'Excellent ( 0 dBm to -50 dBm )',
    POOR:'Poor (0 to 15dBm)'
}

export const UPLOAD_TEMPLATE_NAMES={
    CONSUMER:'CONSUMER',
    CONTRACTOR_TECHNICIAN:'CONTRACTOR_TECHNICIAN',
    MDU_TECHNICIAN:'MDU_TECHNICIAN',
    MDU_PROPERTY:'MDU_PROPERTY',
    DEALER:'DEALER',
}

export const CUSTOMER_TYPES={
    RESIDENTIAL:"RESIDENTIAL",
    COMPANY:'COMPANY'
}