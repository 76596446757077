import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  constructor() { }

  @HostListener('document:click')
  public onClick(): void {

    const htmlCollection = document.getElementsByClassName('modal');
    if (htmlCollection && htmlCollection.length) {
      document.body.setAttribute('class', 'overflow_hidden');
    } else {
      document.body.removeAttribute('class');
    }

  }

}
