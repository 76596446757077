export class QueryParameter {
  size: number;
  index: number;
  id: number;

  constructor(data) {
    this.size = data.size;
    this.index = data.index;
    this.id = data.id;
  }
}

export class ApiDetails {
    endPoint: string;
    queryParameter: QueryParameter;

    constructor(data) {
        this.endPoint = data.endPoint;
        this.queryParameter = new QueryParameter(data.queryParameter);
    }
}

export class SearchResponseDetails {
    name: string;
    store: string;
    apiDetails: ApiDetails;

    constructor(data) {
        this.name = data.name;
        this.store = data.store;
        this.apiDetails = new ApiDetails(data.api);
    }
}

export class SearchResponse {
    statusCode: string;
    statusMessage: string;
    statusInfo?: any;
    searchList: SearchResponseDetails[];
    requestData?: any;

    constructor(data) {
        this.statusCode = data.statusCode;
        this.statusMessage = data.statusMessage;
        this.statusInfo = data.statusInfo;
        this.searchList = data.responseData && data.responseData.length &&
        data.responseData.map((value, index) => new SearchResponseDetails(value));
        this.requestData = data.requestData;
    }
}
