import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Params, RoutesRecognized } from '@angular/router';
import * as momenttimezone from 'moment-timezone';

// Services
// import { SharedService } from 'src/app/shared/services/shared.service';

// Environment
// import { environment } from 'src/environments/environment';
import { environment as contractorEnvironment } from 'projects/contractor/src/environments/environment';
import { environment as channelEnvironment } from 'projects/channel/src/environments/environment';
import { environment as mduEnvironment } from 'projects/mdu/src/environments/environment';

// Models
import { Customer } from 'src/app/shared/models/customer.model';
// import { PhoneAndEmail } from 'src/app/shared/models/alerts.model';
import { Toaster } from 'src/app/shared/models/toaster.model';
import { SensorDetails, ModeDetails, HVACCycleResponseDetails } from 'src/app/shared/models/graph.model';
import { SearchResponseDetails } from 'src/app/shared/models/search.model';
import { Email, Phone, Address } from 'src/app/shared/models/common.model';

// Constants
import { ROLE_CONSTANTS } from 'src/app/shared/constants/role.constant';
import { BASE_URL_TYPES } from 'src/app/shared/constants/base-url-types.constant';
import { CHART_UNIT_TYPES, COMMON_CONSTANTS } from 'src/app/shared/constants/commom.constant';
import { deltaSVG } from 'src/app/shared/constants/svg.constant';
import { dateFormat } from 'highcharts';
import { CookieService } from 'ngx-cookie-service';
import { ParsedProperty } from '@angular/compiler';
import * as Highcharts from 'highcharts';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  customerPersonalInformation = {
    email: '',
    phone: '',
    address: '',
  };
  toasterSubject: Subject<Toaster> = new Subject();
  selectedTab: Subject<string> = new Subject();
  addressObject: Subject<object> = new Subject();
  selectedSearchData: any;
  listenerForTechnicianSearchChange: Subject<
    SearchResponseDetails
  > = new Subject();
  selectedSiteTour: Subject<string> = new Subject();
  listenerForCustomerSearchChange: Subject<
    SearchResponseDetails
  > = new Subject();
  listenerForMeliSearchChange: Subject<SearchResponseDetails> = new Subject();
  listenerForContractorSearchChange: Subject<
    SearchResponseDetails
  > = new Subject();
  listenerForPropertySearchChange: Subject<SearchResponseDetails> = new Subject();

  listenerForAlertChange = new Subject<boolean>()

  listenerForUnassignBoard = new Subject();
  // advanceDiagnosticChartDataFarenheit: any = {
  //   isFahrenheit: true,
  //   // isPascal: false
  //   // airTemperature: {},
  //   // airStaticPressure: {},
  //   // suctionLineTemperature: {},
  //   // thermostatReads: {
  //   //   cooling: {},
  //   //   fanSpeed: {},
  //   //   heatPump: {},
  //   //   heatingFurnace: {}
  //   // }
  // };
  // advanceDiagnosticChartDataCelcius: any = {
  //   isFahrenheit: false,
  //   // isPascal: true
  //   // airTemperature: {},
  //   // airStaticPressure: {},
  //   // suctionLineTemperature: {},
  //   // thermostatReads: {
  //   //   cooling: {},
  //   //   fanSpeed: {},
  //   //   heatPump: {},
  //   //   heatingFurnace: {}
  //   // }
  // };

  diffStaticPressure = {
    pascalData: {},
    waterColumnData: {},
  };

  temperature = {
    celciusData: {},
    fahrenheitData: {},
  };

  thermostatReads = {
    fanSpeed: null,
    cooling: null,
    heatPump: null,
    heatFurnace: null,
  };


  alertDiscoveryChartData = {
    chartdata: {},
    chartcommondata: {},
    unit: '',
  };

  alertDiscoveryChartDataFahrenheit = {
    chartdata: {},
    chartcommondata: {},
    unit: '°F',
  };

  alertDiscoveryChartDataCelcius = {
    chartdata: {},
    chartcommondata: {},
    unit: '°C',
  };

  alertDiscoveryChartDataPascal = {
    chartdata: {},
    chartcommondata: {},
    unit: 'Pa'
  };
  alertDiscoveryChartDataWc = {
    chartdata: {},
    chartcommondata: {},
    unit: '"WC',
  };
  alertDiscoveryChartDataFan = {
    chartdata: {},
    chartcommondata: {},
    unit: '',
  };
  // check whether it is temperature or pressure

  phoneCodesList = [];
  idleTime: any;
  warningIdleTime: any;
  warningIdleTimeSubject: Subject<any> = new Subject();
  isAlreadyWarningIdleTimeRunning = false;
  SiteTourFinised: Subject<boolean> = new Subject();
  uploadLogoData:Subject<any> = new Subject();
  BulkUploadPopupData:Subject<any>=new BehaviorSubject("false");
  HVACCycleDetails = {
    fahrenheitData: {},
    celciusData: {}
  };
  sitetourEnableData: Subject<any> = new Subject();


  constructor(
    private router: Router,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute // private sharedService: SharedService,
  ) { }

  handleBaseUrl(flag) {
    let url;
    // this.activatedRoute.data.subscribe(data => {
    //   if (data) {
    //     if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
    //       if (flag === BASE_URL_TYPES.LOGIN) {
    //         url = contractorEnvironment.loginBaseUrl;
    //       } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
    //         url = contractorEnvironment.baseUrl;
    //       } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
    //         url = contractorEnvironment.updateRelayBaseUrl;
    //       }
    //     } else if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
    //       if (flag === BASE_URL_TYPES.LOGIN) {
    //         url = channelEnvironment.loginBaseUrl;
    //       } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
    //         url = channelEnvironment.baseUrl;
    //       } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
    //         url = channelEnvironment.updateRelayBaseUrl;
    //       }
    //     }
    //   }
    // });

    let portalName = localStorage.getItem('portalName');
    // this.activatedRoute.data.subscribe(data => {
    //   if (data && data.portalName) {
    //     portalName = data.portalName;
    //   }
    // });

    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    // this.router.events.subscribe(val => {
    //   if (val instanceof RoutesRecognized) {
    //     if (val.state.root.firstChild.data) {
    //       portalName = val.state.root.firstChild.data.portalName;
    //     }
    //   }
    // });

    if (route.snapshot && route.snapshot.data && route.snapshot.data.portalName) {
      portalName = route.snapshot.data.portalName;
    }

    // console.log(
    //   'localStorage.setItem(portalName, data.portalName);',
    //   localStorage.getItem('portalName'));
console.log(portalName)
    if (portalName === ROLE_CONSTANTS.CONTRACTOR) {
      if (flag === BASE_URL_TYPES.LOGIN) {
        url = contractorEnvironment.loginBaseUrl;
      } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
        url = contractorEnvironment.baseUrl;
      } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
        url = contractorEnvironment.updateRelayBaseUrl;
      } else if (flag === BASE_URL_TYPES.USER_POOL_ID) {
        url = contractorEnvironment.userPoolId;
      } else if (flag === BASE_URL_TYPES.PRE_SEASON_TEST) {
        url = contractorEnvironment.preSeasonTestBaseUrl;
      } else if (flag === BASE_URL_TYPES.LAMBDA) {
        url = contractorEnvironment.lambdaBaseUrl;
      }
      else if (flag === BASE_URL_TYPES.COMFORTBRIDGE) {
        url = contractorEnvironment.comfortBridgeUrl;
      } else if (flag === BASE_URL_TYPES.SITE_NAME) {
        url = contractorEnvironment.siteName;
      }
    } else if (portalName === ROLE_CONSTANTS.CHANNEL) {
      if (flag === BASE_URL_TYPES.LOGIN) {
        url = channelEnvironment.loginBaseUrl;
      } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
        url = channelEnvironment.baseUrl;
      } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
        url = channelEnvironment.updateRelayBaseUrl;
      } else if (flag === BASE_URL_TYPES.USER_POOL_ID) {
        url = channelEnvironment.userPoolId;
      } else if (flag === BASE_URL_TYPES.LAMBDA) {
        url = channelEnvironment.lambdaBaseUrl;
      }
      else if (flag === BASE_URL_TYPES.COMFORTBRIDGE) {
        url = channelEnvironment.comfortBridgeUrl;
      } else if (flag === BASE_URL_TYPES.SITE_NAME) {
        url = channelEnvironment.siteName;
      }
    } else if (portalName === ROLE_CONSTANTS.MDU) {
      if (flag === BASE_URL_TYPES.LOGIN) {
        url = mduEnvironment.loginBaseUrl;
      } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
        url = mduEnvironment.baseUrl;
      } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
        url = mduEnvironment.updateRelayBaseUrl;
      } else if (flag === BASE_URL_TYPES.USER_POOL_ID) {
        url = mduEnvironment.userPoolId;
      } else if (flag === BASE_URL_TYPES.PRE_SEASON_TEST) {
        url = mduEnvironment.preSeasonTestBaseUrl;
      } else if (flag === BASE_URL_TYPES.LAMBDA) {
        url = mduEnvironment.lambdaBaseUrl;
      }
      else if (flag === BASE_URL_TYPES.COMFORTBRIDGE) {
        url = mduEnvironment.comfortBridgeUrl;
      } else if (flag === BASE_URL_TYPES.SITE_NAME) {
        url = mduEnvironment.siteName;
      }

    }

    /*
    let route = this.router.routerState.root;
    while (route.firstChild) {
    route = route.firstChild;
    }
    // console.log('return ',route.snapshot.data);
    // let data = route.snapshot.data;
    if (route.snapshot && route.snapshot.data) {
      let data = route.snapshot.data;
        if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
          if (flag === BASE_URL_TYPES.LOGIN) {
            url = contractorEnvironment.loginBaseUrl;
          } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
            url = contractorEnvironment.baseUrl;
          } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
            url = contractorEnvironment.updateRelayBaseUrl;
          }
        } else if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
          if (flag === BASE_URL_TYPES.LOGIN) {
            url = channelEnvironment.loginBaseUrl;
          } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
            url = channelEnvironment.baseUrl;
          } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
            url = channelEnvironment.updateRelayBaseUrl;
          }
        }
      }
    */
    //   this.router.events.subscribe(val => {
    //     debugger
    //     if (val instanceof RoutesRecognized) {

    //       if (val.state.root.firstChild.data) {
    //         const data = val.state.root.firstChild.data;
    //         if (data.portalName === ROLE_CONSTANTS.CONTRACTOR) {
    //           if (flag === BASE_URL_TYPES.LOGIN) {
    //             url = contractorEnvironment.loginBaseUrl;
    //           } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
    //             url = contractorEnvironment.baseUrl;
    //           } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
    //             url = contractorEnvironment.updateRelayBaseUrl;
    //           }
    //         } else if (data.portalName === ROLE_CONSTANTS.CHANNEL) {
    //           if (flag === BASE_URL_TYPES.LOGIN) {
    //             url = channelEnvironment.loginBaseUrl;
    //           } else if (flag === BASE_URL_TYPES.COMMON_BASE) {
    //             url = channelEnvironment.baseUrl;
    //           } else if (flag === BASE_URL_TYPES.UPDATE_RELAY) {
    //             url = channelEnvironment.updateRelayBaseUrl;
    //           }
    //           console.log(url);
    //         }
    //          console.log(url);
    //       }

    //         console.log(val.state.root.firstChild.data.portalName);
    //         return url;

    //     }
    // });
    return url;
  }

  handlePortalType() {
    let portalId = null;
    let portalName = localStorage.getItem('portalName');
    if (portalName == ROLE_CONSTANTS.CONTRACTOR) {
      return portalId = 1;
    }
    else if (portalName == ROLE_CONSTANTS.MDU) {
      return portalId = 2
    }
    else if (portalName == ROLE_CONSTANTS.CHANNEL) {
      return portalId = 3
    }
  }
  // handleAuthTime() {
  //   const authTime: Date = new Date(+localStorage.getItem('authTime'));
  //   if (authTime) {
  //     return moment(authTime).format('hh:mm A');
  //   }
  // }

  handleIsSessionTimeOutApplicable() {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    if (route.snapshot && route.snapshot.queryParams &&
      route.snapshot.queryParams.sessionTimeout === 'false') {
      return false;
    }
    return true;
  }

  handleStartIdleTime() {
    this.idleTime = setTimeout(
      () => {
        if (this.router.url !== '/login' && !this.isAlreadyWarningIdleTimeRunning &&
          this.handleIsSessionTimeOutApplicable()) {
          this.setValueForWarningIdleTime();
        }
      }, 29 * 60000
    );
  }

  handleResetIdleTime() {
    this.handleClearTimeOut(this.idleTime);
    this.handleClearTimeOut(this.warningIdleTime);
    this.handleStartIdleTime();
  }

  handleResetWarningIdleTime() {
    this.handleClearTimeOut(this.idleTime);
    this.handleClearTimeOut(this.warningIdleTime);
    // this.handleWarningIdleTime();
  }

  setListnerForWarningIdleTime() {
    return this.warningIdleTimeSubject.asObservable();
  }

  setValueForWarningIdleTime() {
    // debugger
    // if (!this.isAlreadyWarningIdleTimeRunning) {
    this.warningIdleTimeSubject.next(true);
    // }
  }

  handleWarningIdleTime() {
    // this.setValueForIsAlreadyIdleTimeRunning(true);
    // this.handleClearTimeOut(this.idleTime);
    this.warningIdleTime = setTimeout(() => {
      this.handleClearTimeOut(this.idleTime);
      this.isAlreadyWarningIdleTimeRunning = true;
      this.handleAutoLogout();
    }, 60000);
  }

  setValueForIsAlreadyIdleTimeRunning(value: boolean) {
    // this.isAlreadyWarningIdleTimeRunning = value;
  }

  handleClearTimeOut(time) {
    clearTimeout(time);
  }

  handleParseLogoutQueryParam() {
    let userName;
    // if (isRememberMeChecked === 'true') {
   // userName = this.cookieService.get('username');
    // } else {
    userName = localStorage.getItem('userName');
    // }
    return {
      userName,
      userPoolId: this.handleBaseUrl(BASE_URL_TYPES.USER_POOL_ID)
    };
  }

  handleAutoLogout() {
    // localStorage.removeItem('token')
    // // localStorage.clear();
    // // sessionStorage.clear();
    // this.router.navigate(['/login']);
    // const queryParams = {};
    // // this.sharedService.handleLogout(queryParams).subscribe(
    // //   (response) => {

    // //   }, (error) => {

    // //   }
    // // );
    this.handleLogoutAPISuccessResponse();
    localStorage.setItem('isAutoLogout', 'true');
    this.isAlreadyWarningIdleTimeRunning = false;
  }

  handleLogoutAPISuccessResponse() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('disableBillingAfterRefresh');
    const isRememberMeChecked = localStorage.getItem('isRememberMeChecked');
    const isUsername = localStorage.getItem('userName');
    const isPassword = localStorage.getItem('password');
    localStorage.clear();
    localStorage.setItem('isRememberMeChecked', isRememberMeChecked);
    if(localStorage.getItem('isRememberMeChecked')=="true")
    {
        localStorage.setItem('userName',isUsername);
        localStorage.setItem('password',isPassword)
    }
    // sessionStorage.clear();
    window.location.reload();
    // this.router.navigate(['/login']);
  }

  handleConvertBASE64Encode(value: any) {
    return btoa(value);
  }

  // handleEmailSelection(emailList: PhoneAndEmail) {
  //   if (emailList && emailList.home && emailList.home.length) {
  //     return emailList.home[0];
  //   } else if (emailList && emailList.office && emailList.office.length) {
  //     return emailList.office[0];
  //   } else if (emailList && emailList.mobile && emailList.mobile.length) {
  //     return emailList.mobile[0];
  //   } else {
  //     return null;
  //   }
  // }

  handleEmailSelection(emailList: Email[], requiredType: string) {
    let selctedemail: Email;
    if (emailList && emailList.length) {
      selctedemail = emailList.find(currentValue => currentValue.type === requiredType);
      // return emailList.home[0];
    }
    // if (selctedemail && selctedemail.email) {
    //   selctedemail = selctedemail.email;
    // }
    return selctedemail;
    //  else if (emailList && emailList.office && emailList.office.length) {
    //   return emailList.office[0];
    // } else if (emailList && emailList.mobile && emailList.mobile.length) {
    //   return emailList.mobile[0];
    // } else {
    //   return null;
    // }
  }

  // handlePhoneSelection(phoneList: PhoneAndEmail) {
  //   if (phoneList && phoneList.home && phoneList.home.length) {
  //     return phoneList.home[0];
  //   } else if (phoneList && phoneList.office && phoneList.office.length) {
  //     return phoneList.office[0];
  //   } else if (phoneList && phoneList.mobile && phoneList.mobile.length) {
  //     return phoneList.mobile[0];
  //   } else {
  //     return null;
  //   }
  // }

  handlePhoneSelection(phoneList: Phone[], requiredType: string) {
    let selectedPhone: Phone;
    if (phoneList && phoneList.length) {
      selectedPhone = phoneList.find(currentValue => currentValue.type === requiredType);
      // return phoneList.home[0];
    }
    // if (selectedPhone && selectedPhone.phoneNumber) {
    //   selectedPhone = selectedPhone.phoneNumber;
    // }
    return selectedPhone;
    //  else if (phoneList && phoneList.office && phoneList.office.length) {
    //   return phoneList.office[0];
    // } else if (phoneList && phoneList.mobile && phoneList.mobile.length) {
    //   return phoneList.mobile[0];
    // } else {
    //   return null;
    // }
  }

  handleAddressSelection(addressList: Address[], requiredType: string) {
    let selectedPhone: Address;
    if (addressList && addressList.length) {
      selectedPhone = addressList.find(currentValue => currentValue.type === requiredType);
      // return phoneList.home[0];
    }
    return selectedPhone;
  }

  handleFormatPhoneNumber(phoneNumber: any) {
    let number, zip, middle, last;
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/\s+/g, '');
      number = phoneNumber.match(/\d+/g);
    }
    if (number && number.length) {
      number = number.join('');
      zip = number.slice(0, 3);
      middle = number.slice(3, 6);
      last = number.slice(6, 10);

      if (number.length > 6) {
        number = `(${zip}) ${middle}-${last}`;
      } else if (number.length > 3) {
        number = `(${zip}) ${middle}`;
      } else if (number.length > 0) {
        number = `(${zip}`;
      }
    }
    return number;
  }

  handleFormatExpiry(phoneNumber: any) {
    let number, month, year;
    if (phoneNumber) {
      phoneNumber = phoneNumber.replace(/\s+/g, '');
      number = phoneNumber.match(/\d+/g);
    }
    if (number && number.length) {
      number = number.join('');
      month = number.slice(0, 2);
      year = number.slice(2, 4);

      if (number.length > 2) {
        number = `${month}/${year}`;
      } else if (number.length > 0) {
        number = `${month}`;
      }
    }
    return number;
  }

  validateExpiry(control: AbstractControl) {

    const value = control.value;
    const regexp = new RegExp(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/);
    if (value) {
      if (value.length < 5) {
        return { invalidFormat: true };
      }
      else {
        let date = new Date();
        let currentyear = date.getFullYear();
        let currentmonth = date.getMonth();
        let givenyear = value.split("/");
        let year = moment(givenyear[1], "YY");
        let fullyear = parseInt(year.format('YYYY'));
        let month = moment(givenyear[0], "MM");
        let fullmonth = parseInt(month.format('MM'));
        if (fullyear < currentyear) {
          return { invalidYear: true }
        }
        else if (fullyear == currentyear) {
          if (fullmonth < currentmonth + 1) {
            // console.log(fullmonth, currentmonth + 1);
            return { invalidMonth: true }
          }
        }
      }
      return null;
    }
    return null;
  }
  validateAmount(amount: any) {
    let before, after, number
    if (amount) {
      amount = amount.replace(/\s+/g, '');
      number = amount.match(/\d+/g);
    }
    if (number && number.length) {
      number = number.join('');
      before = number.slice(0, 1);
      after = number.slice(1, 3)
      if (number.length > 1) {
        number = `${before}.${after}`;
      }
    }
    return number;
  }


  handleCustomerDetails(emails, phones, address) {
    // this.customerPersonalInformation.email = this.handleEmailSelection(emails);

    // this.customerPersonalInformation.phone = this.handlePhoneSelection(phones);

    if (address && address.length) {
      this.customerPersonalInformation.address = address[0];
    }
    // }

    return this.customerPersonalInformation;
  }

  handleRemoveAddressLinesCombinator(address: string) {
    if (address) {
      const splitAddress = address.split(COMMON_CONSTANTS.ADDRESS_LINES_COMBINATOR);
      if (splitAddress[0] && splitAddress[1]) {
        return splitAddress[0] + ',' + splitAddress[1];
      } else if (splitAddress[0]) {
        return splitAddress[0];
      } else {
        return '';
      }
    }
    return '';
  }

  navigateToRelaventPage(url: string, queryParams?: any) {
    if (queryParams) {
      this.router.navigate([url], { queryParams: queryParams });
    } else {
      this.router.navigate([url]);
    }
  }

  setListenerForToaster() {
    return this.toasterSubject.asObservable();
  }

  setListenerForSiteTourEnableorNot() {
    return this.sitetourEnableData.asObservable();
  }

  setSiteTourEnableorNot(value) {
    this.sitetourEnableData.next(value);

  }

  
  setListenerForUploadLogo() {
    return this.uploadLogoData.asObservable();
  }

  setUploadLogo(value) {
    this.uploadLogoData.next(value);

  }

  setListenerForBulkUploadPopupDisplay()
  {
    return this.BulkUploadPopupData.asObservable();
  }

  BulkUploadPopupDisplay(value)
  {
    this.BulkUploadPopupData.next(value)
  }

  setListenerForSelectedTab() {
    return this.selectedTab.asObservable();
  }

  setSelectedTab(value: string) {
    console.log(value)
    if (value.includes('?')) {
      value = value.substring(0, value.indexOf('?'));
    }
    this.selectedTab.next(value);
  }

  showToaster(type, message) {
    const toasterContent = {
      type,
      message,
    };
    this.toasterSubject.next(toasterContent);
  }

  setSelectedSearchData(data: any, page, flag?: boolean) {
    this.selectedSearchData = data;
    if (flag && page === 'technician') {
      this.listenerForTechnicianSearchChange.next(data);
    } else if (flag && page === 'customer') {
      this.listenerForCustomerSearchChange.next(data);
    } else if (flag && page === 'meli') {
      this.listenerForMeliSearchChange.next(data);
    } else if (flag && page === 'contractor') {
      this.listenerForContractorSearchChange.next(data);
    } else if (flag && page === 'property') {
      this.listenerForPropertySearchChange.next(data);
    }
  }

  getSelectedSearchData() {
    return this.selectedSearchData;
  }

  setListenerForTechnicianSearchChange() {
    return this.listenerForTechnicianSearchChange.asObservable();
  }

  setListenerForCustomerSearchChange() {
    return this.listenerForCustomerSearchChange.asObservable();
  }

  setListenerForMeliSearchChange() {
    return this.listenerForMeliSearchChange.asObservable();
  }

  setListenerForContractorSearchChange() {
    return this.listenerForContractorSearchChange.asObservable();
  }

  setListenerForPropertySearchChange() {
    return this.listenerForPropertySearchChange.asObservable();
  }


  setListenerForSiteTour() {
    return this.selectedSiteTour.asObservable();
  }

  setSelectedSiteTour(value: string) {
    this.selectedSiteTour.next(value);
  }


  setListenerForSiteTourFinised() {
    return this.SiteTourFinised.asObservable();
  }

  setSiteTourFinised(value: boolean) {
    this.SiteTourFinised.next(value);
  }
  handleAPIErrors(err) {
    let message;
    // message = err.error.status;
    message = 'Error';

    // if (err.status === 400 || err.status === 403) {
    //   message = err.error &&  err.error.message ?
    //             err.error[0].message : 'Not Found';
    // } else if (err.status === 404) {
    //   message = 'Detail not found';
    // } else if (err.status === 500) {
    //   message = err.error;
    // } else if (err.status === 0) {
    //   message = err.statusText;
    // }
    if (
      err.error &&
      (err.error.statusCode === '4444' || err.error.statusMessage === 'ERROR')
    ) {
      return err.error.statusInfo ? err.error.statusInfo : 'Error';
    } else if (err.status === 400) {
      return 'Not Found';
    }
    else if(err.name==="HttpErrorResponse")
    {
      return 'Something went wrong, please try again';
    }
    return message;
  }

  validateIsEmail(control: AbstractControl) {
    const value = control.value;
    /*
      (?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'
      (?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x
      0b\x0c\x0e-\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-
      9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-
      9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9
      ]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0
      b\x0c\x0e-\x7f])+)\])
    */

    // tslint:disable:max-line-length
    // const regexp = new RegExp(
    //   /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // );
    const regexp = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$"
    );
    // tslint:enable:max-line-length
    if (value) {
      if (!regexp.test(value)) {
        return { invalidEmail: true };
      }
      return null;
    }
    return null;
  }

  validateOnlySpaces(control: AbstractControl) {
    const value = control.value;
    // debugger
    if (value && !value.trim().length) {
      return { isOnlyWhiteSpaces: true };
    }
    return null;
  }

  validateAlteastOneCharacter(control: AbstractControl)
  {
     const value = control.value;
     if(value && value.trim().length){
      const regexp = new RegExp(/[a-zA-Z]/);
        if(!regexp.test(value))
        {
          return { isOnlyWhiteSpaces: true };
        }
    }
  }

  validatePhoneNumber(control: AbstractControl) {
    const value = control.value;
    const regexp = new RegExp(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/);
    if (value) {
      if (!regexp.test(value)) {
        return { invalidPhoneNumber: true };
      }
      return null;
    }
    return null;
  }
  validateWithOldPassword(oldpassword) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      console.log(control.value, oldpassword)
      if (control.value) {
        if (control.value === control.parent.get(oldpassword).value) {
          return { passwordMatch: true };
        }
        return null;
      }
      return null;
    };
  }
  handleAddressLinesCombination(addressLine1: string, addressLine2: string) {
    return (
      addressLine1 + COMMON_CONSTANTS.ADDRESS_LINES_COMBINATOR + addressLine2
    );
  }

  handleAddressLinesSplit(address: string) {
    if (address) {
      return address.split(COMMON_CONSTANTS.ADDRESS_LINES_COMBINATOR);
    }
    return '';
  }

  handleDateSorting(list: any, sortingType: any, key: string, key1?: string) {
    if (list && list.length) {
      list.sort((a, b) => {
        let date1: any;
        let date2: any;
        if (!key1) {
          date1 = new Date(a[key]);
          date2 = new Date(b[key]);
        } else {
          if (a[key]) {
            date1 = new Date(a[key][key1]);
          }
          if (b[key]) {
            date2 = new Date(b[key][key1]);
          }
        }
        if (!sortingType) {
          // Ascending
          return date2 - date1;
        } else if (sortingType) {
          // Descending
          // list.reverse();
          return date1 - date2;
        }
      });
    }
    return list;
  }

  handleAlphabetSorting(
    list: any,
    sortingType: any,
    key: string,
    key1?: string
  ) {
    if (list && list.length) {
      list.sort((a, b) => {
        if (!sortingType) {
          // Ascending

          if (!key1) {
            if (a[key] < b[key]) {
              return -1;
            }
            if (a[key] > b[key]) {
              return 1;
            }
            return 0;
          } else {
            if (a[key] && b[key] && a[key][key1] < b[key][key1]) {
              return -1;
            }
            if (a[key] && b[key] && a[key][key1] > b[key][key1]) {
              return 1;
            }
            return 0;
          }
        } else if (sortingType) {
          // Descending
          // list.reverse();

          if (!key1) {
            if (a[key] < b[key]) {
              return 1;
            }
            if (a[key] > b[key]) {
              return -1;
            }
            return 0;
          } else {
            if (a[key] && b[key] && a[key][key1] < b[key][key1]) {
              return 1;
            }
            if (a[key] && b[key] && a[key][key1] > b[key][key1]) {
              return -1;
            }
            return 0;
          }
        }
      });
    }
    return list;
  }

  handlePhoneCodes(response) {
    const phonesCodes = [];
    if (response && response.length) {
      response.map((value, index) => {
        if (
          value.name &&
          value.callingCodes &&
          value.callingCodes.length &&
          value.callingCodes[0]
        ) {
          phonesCodes.push({
            name: value.name,
            code: value.callingCodes[0],
          });
        }
      }, this);
    }
    this.phoneCodesList = phonesCodes;
    return phonesCodes;
  }

  handleSelectDefaultValueForPhoneCodeDropdown(
    selectedCountryForPhoneCode,
    flag
  ) {
    let selectedCountry;
    if (this.phoneCodesList && this.phoneCodesList.length) {
      this.phoneCodesList.map((value, index) => {
        if (value.name === selectedCountryForPhoneCode) {
          if (flag === 'name') {
            selectedCountry = value.name;
          } else {
            selectedCountry = value.code;
          }
        }
      });
    }
    return selectedCountry;
  }

  setListenerForAddressObject() {
    return this.addressObject.asObservable();
  }

  handleAddress(htmlElement, type?: string, index?: number) {
    const autocomplete = new google.maps.places.Autocomplete(htmlElement, {
      types: ['geocode'],
    });
    autocomplete.setFields(['address_component', 'place_id', 'formatted_address']);
    // autocomplete.setFields(["place_id", "geometry", "name"]);

    let place;
    let street = '';
    let city;
    let state;
    let zipcode;

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      place = autocomplete.getPlace();
      const placeId = place.place_id;
      const formattedAddress = place.formatted_address;

      const componentForm = {
        street_number: '',
        street_address: '',
        route: '',
        neighborhood: '',
        premise: '',
        sublocality_level_3: '',
        sublocality_level_2: '',
        sublocality_level_1: '',
        locality: '', // City
        administrative_area_level_2: '',
        administrative_area_level_1: '', // State
        // country: 'long_name',
        postal_code: '',
      };

      const keys = Object.keys(componentForm);
      // for (let i = 0; i < place.address_components.length; i++) {
      //   const addressType = place.address_components[i].types[0];
      //   if (keys.includes(addressType)) {
      //     // var val = place.address_components[i][componentForm[addressType]];
      //     componentForm[addressType] = place.address_components[i].long_name;
      //   }
      // }

      // if (place.address_components && place.address_components.length) {
      //   place.address_components.map((value, index) => {
      //     const addressType = value.types[0];
      //     if (keys.includes(addressType)) {
      //       // var val = place.address_components[i][componentForm[addressType]];
      //       componentForm[addressType] = value.long_name;
      //     }
      //   });
      // }

      if (keys && keys.length) {
        keys.map((value, keysIndex) => {
          if (place.address_components && place.address_components.length) {
            place.address_components.map((placeValue, placeIndex) => {
              if (placeValue.types.includes(value)) {
                componentForm[value] = placeValue.long_name;
              }
            });
          }
        });
      }

      street = '';
      if (componentForm.street_number) {
        street += componentForm.street_number + ' ';
      }

      if (componentForm.street_address) {
        street += componentForm.street_address + ' ';
      }

      if (componentForm.route) {
        street += componentForm.route + ' ';
      }

      if (componentForm.premise) {
        street += componentForm.premise + ' ';
      }

      if (componentForm.neighborhood) {
        street += componentForm.neighborhood + ' ';
      }

      if (componentForm.sublocality_level_3) {
        street += componentForm.sublocality_level_3 + ' ';
      }

      if (componentForm.sublocality_level_2) {
        street += componentForm.sublocality_level_2 + ' ';
      }

      if (componentForm.sublocality_level_1) {
        street += componentForm.sublocality_level_1 + ',';
      }
      if (
        street != null &&
        street.length > 0 &&
        street.charAt(street.length - 1) === ','
      ) {
        street = street.substring(0, street.length - 1);
      }

      if (componentForm.locality) {
        city = componentForm.locality;
      } else {
        city = componentForm.administrative_area_level_2;
      }

      // city = componentForm.locality ? componentForm.locality : componentForm.administrative_area_level_2;

      state = componentForm.administrative_area_level_1;

      zipcode = componentForm.postal_code;

      const payload = {
        street,
        city,
        state,
        zipcode,
        type,
        index,
        placeId,
        formattedAddress,
      };
      this.addressObject.next(payload);
    });
  }

  scrollToFirstInvalidControl(form: FormGroup, nativeElemnt) {

    for (const key of Object.keys(form.controls)) {

      if (form.controls[key].invalid && nativeElemnt) {
        // const invalidControl = nativeElemnt.querySelector('[formcontrolname="' + key + '"]');
        let invalidControl = nativeElemnt.querySelector(
          "form .ng-invalid"
        );
        if (invalidControl && invalidControl.hasChildNodes() &&
          (invalidControl.tagName && invalidControl.tagName.toLowerCase() !== 'select')) {
          invalidControl = invalidControl.querySelector('[formcontrolname="' + key + '"]');
        }
        if (invalidControl) {
          invalidControl.focus();
        } else if (form.controls[key]['controls'] && form.controls[key]['controls'].length) {
          const firstInvalidControl: HTMLElement = nativeElemnt.querySelector(
            "form .ng-invalid"
          );
          form.controls[key]['controls'].map((value: FormGroup, index) => {
            this.scrollToFirstInvalidControl(value, firstInvalidControl);
          })
        } else if (form.controls[key]['controls'] && Object.keys(form.controls[key]['controls']) &&
          Object.keys(form.controls[key]['controls']).length) {
          const firstInvalidControl: HTMLElement = nativeElemnt.querySelector(
            "form .ng-invalid"
          );
          this.scrollToFirstInvalidControl(form.controls[key] as FormGroup, firstInvalidControl);
        }
        break;
      }
    }

    // const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
    //   "form .ng-invalid"
    // );

    // firstInvalidControl.scrollIntoView({ behavior: 'smooth' });

    // // firstInvalidControl.focus(); //without smooth behavior
  }

  handleConvertDateToUTCFormat(timeStamp) {
    const date = new Date(timeStamp);

    const dateInUTC = Date.UTC(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      new Date(date).getHours(),
      new Date(date).getMinutes(),
      new Date(date).getSeconds()
    );

    return dateInUTC;
  }



  handleThermostatReading(modeDetails: ModeDetails) {
    const coolingArray = [];

    const fanSpeedArray = [];

    const heatPumpArray = [];

    const heatingFurnaceArray = [];

    if (modeDetails && modeDetails.ac && modeDetails.ac.length) {
      modeDetails.ac.map((value, index) => {
        if (value.mode === 'ON') {
          coolingArray.push({
            color: 'rgba(0, 113, 188, 0.5)',
            from: this.handleConvertDateToUTCFormat(value.startTime),
            to: this.handleConvertDateToUTCFormat(value.endTime),

          });
        }
      });
    }

    if (modeDetails && modeDetails.fan && modeDetails.fan.length) {
      modeDetails.fan.map((value, index) => {
        if (value.mode === 'ON') {
          fanSpeedArray.push({
            color: 'rgba(255, 255, 255, 0.5)',
            from: this.handleConvertDateToUTCFormat(value.startTime),
            to: this.handleConvertDateToUTCFormat(value.endTime),
          });
        }
      });
    }

    if (modeDetails && modeDetails.heatPump && modeDetails.heatPump.length) {
      modeDetails.heatPump.map((value, index) => {
        if (value.mode === 'ON') {
          heatPumpArray.push({
            color: 'rgba(251, 176, 59, 0.5)',
            from: this.handleConvertDateToUTCFormat(value.startTime),
            to: this.handleConvertDateToUTCFormat(value.endTime),
          });
        }
      });
    }

    if (
      modeDetails &&
      modeDetails.heatFurnance &&
      modeDetails.heatFurnance.length
    ) {
      modeDetails.heatFurnance.map((value, index) => {
        if (value.mode === 'ON') {
          heatingFurnaceArray.push({
            color: 'rgba(251, 176, 59, 0.5)',
            from: this.handleConvertDateToUTCFormat(value.startTime),
            to: this.handleConvertDateToUTCFormat(value.endTime),
          });
        }
      });
    }

    return {
      cooling: coolingArray,
      fanSpeed: fanSpeedArray,
      heatPump: heatPumpArray,
      heatFurnace: heatingFurnaceArray,
    };
  }

  farenheitConvertion(data) {
    return data * (9 / 5) + 32;
  }

  handleChartData(response: SensorDetails, flag) {
    // this.advanceDiagnosticChartData.airTemperature['supplyAirTemperature'] = [];
    // this.advanceDiagnosticChartData.airTemperature['returnAirTemperature'] = [];
    console.log(response);
    if (response.alerts && response.alerts.length) {
      const supplyArray = [];

      const returnArray = [];

      const coilAirTemperatureArray = [];

      const filterStaticPressureArray = [];

      const deltaDataArray = [];


      // const coolingArray = [];

      // const fanSpeedArray = [];

      // const heatPumpArray = [];

      // const heatingFurnaceArray = [];

      const liquidLineTemperatureArray = [];

      const supplyArrayFarenheit = [];

      const returnArrayFarenheit = [];

      const filterStaticPressureArrayWaterColumn = [];

      const coilAirTemperatureArrayFarenheit = [];

      const liquidLineTemperatureFarenheit = [];

      const deltaDataArrayFarenheit = [];

      const ambientTemperature = [];

      const ambientTemperatureFarenheit = [];

      const returnPressureWC = [];

      const returnPressurePascal = [];

      const supplyPressureWC = [];

      const supplyPressurePascal = [];
      const  annotationF=[];
      const annotationC=[];

       response.alarmDataList&&response.alarmDataList.map((data)=>{
annotationF.push({alerttime:this.timeConversion(data['46'].x),alertvalue:parseInt(data['46'].y)})
annotationC.push({alerttime:this.timeConversion(data['45'].x),alertvalue:parseInt(data['45'].y)})
       });
        //const alertoccuredtime = this.timeConversion(response.notch.x);
      //const alertoccuredvalue = response.notch.y;
      //annotationC =  response.notchInCelciusList;
      response.alerts.map((value, index) => {
        //console.log(value);
        // value.dischargeAirTemperature = indedx + 100;
        // const date = new Date(value.timeStamp);

        // const dateInUTC = Date.UTC(
        //   new Date(date).getFullYear(),
        //   new Date(date).getMonth(),
        //   new Date(date).getDate(),
        //   new Date(date).getHours(),
        //   new Date(date).getMinutes(),
        //   new Date(date).getSeconds()
        // );

        //const dateInUTC = new Date(value.timeStamp).getTime();
        const date = new Date(value.timeStamp);
        const dateInUTC = Date.UTC(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          new Date(date).getHours(),
          new Date(date).getMinutes(),
          new Date(date).getSeconds()
        );
        supplyArray.push([
          // date.getTime(),
          dateInUTC,
          value.dischargeAirTemperature && value.dischargeAirTemperature.celsius &&
             value.dischargeAirTemperature.celsius.data,
          value.delta && value.delta.celsius &&
          value.delta.celsius.data,
          // value.outsideTemperature && value.outsideTemperature.celsius &&
          // value.outsideTemperature.celsius.data
          // value.dischargeAirTemperature,
          // value.delta
        ]);

        if (value.dischargeAirTemperature !== null) {
          supplyArrayFarenheit.push([
            // date.getTime(),
            dateInUTC,
            value.dischargeAirTemperature && value.dischargeAirTemperature.fahrenheit &&
            value.dischargeAirTemperature.fahrenheit.data,
            value.delta && value.delta.fahrenheit &&
            value.delta.fahrenheit.data,
            // value.outsideTemperature && value.outsideTemperature.fahrenheit &&
            // value.outsideTemperature.fahrenheit.data
            // (this.farenheitConvertion(value.dischargeAirTemperature)),
            // (this.farenheitConvertion(value.delta)),
          ]);
        } else {
          supplyArrayFarenheit.push([
            dateInUTC,
            null
          ]);
        }

        if (value.outsideTemperature !== null) {
          ambientTemperature.push([
            // date.getTime(),
            dateInUTC,
            value.outsideTemperature && value.outsideTemperature.celsius &&
            value.outsideTemperature.celsius.data
            // (this.farenheitConvertion(value.dischargeAirTemperature)),
            // (this.farenheitConvertion(value.delta)),
          ]);
        } else {
          ambientTemperature.push([
            dateInUTC,
            null
          ]);
        }

        if (value.outsideTemperature !== null) {
          ambientTemperatureFarenheit.push([
            // date.getTime(),
            dateInUTC,
            value.outsideTemperature && value.outsideTemperature.fahrenheit &&
            value.outsideTemperature.fahrenheit.data
            // (this.farenheitConvertion(value.dischargeAirTemperature)),
            // (this.farenheitConvertion(value.delta)),
          ]);
        } else {
          ambientTemperatureFarenheit.push([
            dateInUTC,
            null
          ]);
        }

        returnArray.push([
          // date.getTime(),
          dateInUTC,
          value.returnAirtemperature && value.returnAirtemperature.celsius &&
          value.returnAirtemperature.celsius.data,
          value.delta && value.delta.celsius &&
          value.delta.celsius.data
          // value.returnAirtemperature,
          // value.delta
        ]);

        if (value.returnAirtemperature !== null) {
          returnArrayFarenheit.push([
            // date.getTime(),
            dateInUTC,
            value.returnAirtemperature && value.returnAirtemperature.fahrenheit &&
            value.returnAirtemperature.fahrenheit.data,
            value.delta && value.delta.fahrenheit &&
            value.delta.fahrenheit.data
            // (this.farenheitConvertion(value.returnAirtemperature)),
            // (this.farenheitConvertion(value.delta)),
          ]);
        } else {
          returnArrayFarenheit.push([
            dateInUTC,
            null
          ]);
        }


        // deltaDataArray.push([
        //   dateInUTC,
        //   value.delta,
        // ])
        // if (value.delta !== null) {
        //   deltaDataArrayFarenheit.push([
        //     // date.getTime(),
        //     dateInUTC,
        //     ((value.dischargeAirTemperature * 9) / 5 + 32) - ((value.returnAirtemperature * 9) / 5 + 32),
        //   ]);
        // } else {
        //   deltaDataArrayFarenheit.push([
        //     dateInUTC,
        //     null
        //   ]);
        // }

        coilAirTemperatureArray.push([
          dateInUTC,
          value.suctionAirTemperature && value.suctionAirTemperature.celsius &&
          value.suctionAirTemperature.celsius.data,
          value.delta && value.delta.celsius &&
          value.delta.celsius.data
          // value.suctionAirTemperature
        ]);

        if (value.suctionAirTemperature !== null) {
          coilAirTemperatureArrayFarenheit.push([
            dateInUTC,
            value.suctionAirTemperature && value.suctionAirTemperature.fahrenheit &&
            value.suctionAirTemperature.fahrenheit.data,
            // (this.farenheitConvertion(value.suctionAirTemperature)),
          ]);
        } else {
          coilAirTemperatureArrayFarenheit.push([
            dateInUTC,
            null
          ]);
        }
        filterStaticPressureArrayWaterColumn.push([
          dateInUTC,
          value.pressure && value.pressure.wc && value.pressure.wc.data,
          value.supplyPressure && value.supplyPressure.wc && value.supplyPressure.wc.data,
          value.returnPressure && value.returnPressure.wc && value.returnPressure.wc.data
          // value.pressure / 249,
          // value.supplyPressure ? value.supplyPressure / 249 : value.supplyPressure,
          // value.returnPressure ? value.returnPressure / 249 : value.returnPressure
        ]);

        if (value.pressure !== null) {
          filterStaticPressureArray.push([
            dateInUTC,
            value.pressure && value.pressure.pascal && value.pressure.pascal.data,
            value.supplyPressure && value.supplyPressure.pascal && value.supplyPressure.pascal.data,
            value.returnPressure && value.returnPressure.pascal && value.returnPressure.pascal.data
            // value.pressure,
            // value.supplyPressure,
            // value.returnPressure
          ]);
        } else {
          filterStaticPressureArray.push([
            dateInUTC,
            null
          ]);
        }

        if (value.returnPressure !== null) {
          returnPressureWC.push([
            dateInUTC,
            value.returnPressure && value.returnPressure.wc && value.returnPressure.wc.data,
          ]);
        } else {
          returnPressureWC.push([
            dateInUTC,
            null
          ]);
        }

        if (value.returnPressure !== null) {
          returnPressurePascal.push([
            dateInUTC,
            value.returnPressure && value.returnPressure.pascal && value.returnPressure.pascal.data,
          ]);
        } else {
          returnPressurePascal.push([
            dateInUTC,
            null
          ]);
        }

        if (value.supplyPressure !== null) {
          supplyPressureWC.push([
            dateInUTC,
            value.supplyPressure && value.supplyPressure.wc && value.supplyPressure.wc.data,
          ]);
        } else {
          supplyPressureWC.push([
            dateInUTC,
            null
          ]);
        }

        if (value.supplyPressure !== null) {
          supplyPressurePascal.push([
            dateInUTC,
            value.supplyPressure && value.supplyPressure.pascal && value.supplyPressure.pascal.data,
          ]);
        } else {
          supplyPressurePascal.push([
            dateInUTC,
            null
          ]);
        }

        // coolingArray.push([
        //   dateInUTC,
        //   value.cooling
        // ]);

        // if (value.cooling) {
        //   coolingArray.push({
        //     color: '#3e4c66',
        //     from: dateInUTC,
        //     to: dateInUTC,
        //   });
        // }

        // fanSpeedArray.push([
        //   dateInUTC,
        //   value.fanSpeed
        // ]);

        // if (value.fanSpeed) {
        //   fanSpeedArray.push({
        //     color: '#3e4c66',
        //     from: dateInUTC,
        //     to: dateInUTC,
        //   });
        // }

        // heatPumpArray.push([
        //   dateInUTC,
        //   value.heatPump
        // ]);

        // if (value.heatPump) {
        //   heatPumpArray.push({
        //     color: '#532d3a',
        //     from: dateInUTC,
        //     to: dateInUTC,
        //   });
        // }

        // heatingFurnaceArray.push([
        //   dateInUTC,
        //   value.heatingFurnance
        // ]);

        // if (value.heatingFurnance) {
        //   heatingFurnaceArray.push({
        //     color: '#532d3a',
        //     from: dateInUTC,
        //     to: dateInUTC,
        //   });
        // }

        liquidLineTemperatureArray.push([
          dateInUTC,
          value.liquidLineTemperature && value.liquidLineTemperature.celsius &&
          value.liquidLineTemperature.celsius.data,
          // value.liquidLineTemperature
        ]);

        if (value.liquidLineTemperature !== null) {
          liquidLineTemperatureFarenheit.push([
            dateInUTC,
            value.liquidLineTemperature && value.liquidLineTemperature.fahrenheit &&
            value.liquidLineTemperature.fahrenheit.data,
            // (this.farenheitConvertion(value.liquidLineTemperature)),
          ]);
        } else {
          liquidLineTemperatureFarenheit.push([
            dateInUTC,
            null
          ]);
        }
      });
      // debugger
      const thermostatData = this.handleThermostatReading(response.modeDetails);
      this.thermostatReads.fanSpeed = thermostatData.fanSpeed;
      this.thermostatReads.cooling = thermostatData.cooling;
      this.thermostatReads.heatPump = thermostatData.heatPump;
      this.thermostatReads.heatFurnace = thermostatData.heatFurnace;

      // this.advanceDiagnosticChartDataCelcius.fanSpeed = fanSpeedArray;
      // this.advanceDiagnosticChartDataCelcius.cooling = coolingArray;
      // this.advanceDiagnosticChartDataCelcius.heatPump = heatPumpArray;
      // this.advanceDiagnosticChartDataCelcius.heatingFurnace = heatingFurnaceArray;

      // this.advanceDiagnosticChartDataFarenheit.fanSpeed = fanSpeedArray;
      // this.advanceDiagnosticChartDataFarenheit.cooling = coolingArray;
      // this.advanceDiagnosticChartDataFarenheit.heatPump = heatPumpArray;
      // this.advanceDiagnosticChartDataFarenheit.heatingFurnace = heatingFurnaceArray;

      if (flag === 'advance-diagnostic') {
        this.temperature.celciusData = {
          unit: COMMON_CONSTANTS.CELCIUS,
          title: 'Temperature',
          yAxisTitle: 'Temperature',
          emptydata1: '',
          chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
          chartName: 'investigate-overview',
          annotation:annotationC,
          data: [
            {
              name: COMMON_CONSTANTS.SUPPLY_AIR_TEMPERATURE,
              data: supplyArray,
              color: COMMON_CONSTANTS.DISCHARGE_AIR_TEMPERATURE_COLOR,
              keys: ['x', 'y', 'z', 'z1'],
              selected: true,
                visible:true,
              checkBoxClassName: 'dat'
            },
            {
              name: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE,
              data: returnArray,
              color: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE_COLOR,
              keys: ['x', 'y', 'z'],
              selected: true,
                visible:true,
              checkBoxClassName: 'rat'
            },
             {
              name: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE,
              data: liquidLineTemperatureArray,
              color: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'llt'
            },
            {
              name: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE,
              data: coilAirTemperatureArray,
              color: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'slt'
            },
           
            {
              name: COMMON_CONSTANTS.AMBIENT_LINE_TEMPERATURE,
              data: ambientTemperature,
              color: COMMON_CONSTANTS.AMBIENT_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'ambt'
            },
            // {
            //   name: COMMON_CONSTANTS.DELTA,
            //   data: deltaDataArray,
            //   color: COMMON_CONSTANTS.DELTA_COLOR,
            // },
          ],
        };

        /*

        this.advanceDiagnosticChartDataCelcius.airTemperature = {
          title: 'Air Temperature',
          yAxisTitle: 'Temperature',

          data: [
            {
              name: COMMON_CONSTANTS.DISCHARGE_AIR_TEMPERATURE,
              data: supplyArray,
            },
            {
              name: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE,
              data: returnArray,
            },
          ],
        };

        this.advanceDiagnosticChartDataCelcius.airStaticPressure = {
          title: 'Differential Static Pressure',
          yAxisTitle: 'Pressure',

          data: [
            {
              name: 'Differential Static Pressure',
              data: filterStaticPressureArray,
            },
          ],
        };

        this.advanceDiagnosticChartDataCelcius.suctionLineTemperature = {
          title: 'Suction & Liquid Line Temperature',
          yAxisTitle: 'Temperature',

          data: [
            {
              name: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE,
              data: coilAirTemperatureArray,
            },
            {
              name: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE,
              data: liquidLineTemperature,
            },
          ],
        };

        this.advanceDiagnosticChartDataCelcius.thermostatReads.cooling = {
          title: 'Cooling',
          yAxisTitle: 'Cooling',

          data: [
            {
              name: 'Cooling',
              data: coolingArray,
            },
          ],
        };

        this.advanceDiagnosticChartDataCelcius.thermostatReads.fanSpeed = {
          title: 'Fan/Heat Pump',
          yAxisTitle: 'Fan/Heat Pump',

          data: [
            {
              name: 'Fan',
              data: fanSpeedArray,
            },
            {
              name: 'Heat Pump',
              data: heatPumpArray,
            },
          ],
        };

        // this.advanceDiagnosticChartDataCelcius.thermostatReads.heatPump = {
        //   title: 'Heat Pump',
        //   yAxisTitle: 'Heat Pump',

        //   data: [
        //     {
        //       name: 'Heat Pump',
        //       data: heatPumpArray,
        //     }
        //   ]
        // };

        this.advanceDiagnosticChartDataCelcius.thermostatReads.heatingFurnace = {
          title: 'Heating Furnace',
          yAxisTitle: 'Heating Furnace',

          data: [
            {
              name: 'Heating Furnace',
              data: heatingFurnaceArray,
            },
          ],
        };

        */

        // Fahrenheit

        this.temperature.fahrenheitData = {
          unit: COMMON_CONSTANTS.FAHRENHEIT,
          title: 'Temperature',
          yAxisTitle: 'Temperature',
          emptydata1: '',
          chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
          chartName: 'investigate-overview',
 annotation:annotationF,
          data: [
            {
              name: COMMON_CONSTANTS.SUPPLY_AIR_TEMPERATURE,
              data: supplyArrayFarenheit,
              color: COMMON_CONSTANTS.DISCHARGE_AIR_TEMPERATURE_COLOR,
              keys: ['x', 'y', 'z', 'z1'],
              selected: true,
              visible:true,
              checkBoxClassName: 'dat'
            },
            {
              name: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE,
              data: returnArrayFarenheit,
              color: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE_COLOR,
              keys: ['x', 'y', 'z'],
              selected: true,
                visible:true,
              checkBoxClassName: 'rat',
              id: 'dataseries'
            },
            {
              name: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE,
              data: liquidLineTemperatureFarenheit,
              color: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'llt'
            },
            {
              name: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE,
              data: coilAirTemperatureArrayFarenheit,
              color: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'slt'
            },
            
            {
              name: COMMON_CONSTANTS.AMBIENT_LINE_TEMPERATURE,
              data: ambientTemperatureFarenheit,
              color: COMMON_CONSTANTS.AMBIENT_LINE_TEMPERATURE_COLOR,
              selected: true,
                visible:true,
              checkBoxClassName: 'ambt'
            },
            // {
            //   name: COMMON_CONSTANTS.DELTA,
            //data: deltaDataArrayFarenheit,
            //   color: COMMON_CONSTANTS.DELTA_COLOR,
            // },
          ],
        };

        // Water Column

        this.diffStaticPressure.waterColumnData = {
          unit: COMMON_CONSTANTS.WATER_COLUMN,
          title: response.sensorType,
          yAxisTitle: 'Pressure',
          chartUnitType: CHART_UNIT_TYPES.PRESSURE,

          data: [
            {
              name: response.sensorType,
              data: filterStaticPressureArrayWaterColumn,
              color: COMMON_CONSTANTS.SUPPLY_AND_RETURN_AIR_STATIC_PRESSURE_DIFFERENCE_COLOR,
              keys: ['x', 'y', 's', 'r'],
              selected: true
            },
          ],
        };

        // if (response && response.sensorType === 'Total Static Pressure') {
        //   this.diffStaticPressure.waterColumnData['data'].push(
        //     {
        //       name: COMMON_CONSTANTS.RETURN_PRESSURE,
        //       data: returnPressureWC,
        //       color: '#cecece',
        //       selected: true
        //     },
        //     {
        //       name: COMMON_CONSTANTS.SUPPLY_PRESSURE,
        //       data: supplyPressureWC,
        //       color: '#cecece',
        //       selected: true
        //     }
        //   )
        // }

        // Pascal

        this.diffStaticPressure.pascalData = {
          unit: COMMON_CONSTANTS.PASCAL,
          title: response.sensorType,
          yAxisTitle: 'Pressure',
          chartUnitType: CHART_UNIT_TYPES.PRESSURE,

          data: [
            {
              name: response.sensorType,
              data: filterStaticPressureArray,
              color: COMMON_CONSTANTS.SUPPLY_AND_RETURN_AIR_STATIC_PRESSURE_DIFFERENCE_COLOR,
              keys: ['x', 'y', 's', 'r'],
              selected: true
            },
          ],
        };

        // if (response && response.sensorType === 'Total Static Pressure') {
        //   this.diffStaticPressure.pascalData['data'].push(
        //     {
        //       name: COMMON_CONSTANTS.RETURN_PRESSURE,
        //       data: returnPressurePascal,
        //       color: '#cecece',
        //       selected: true
        //     },
        //     {
        //       name: COMMON_CONSTANTS.SUPPLY_PRESSURE,
        //       data: supplyPressurePascal,
        //       color: '#cecece',
        //       selected: true
        //     }
        //   )
        // }

        /*

        this.advanceDiagnosticChartDataFarenheit.airTemperature = {
          title: 'Air Temperature',
          yAxisTitle: 'Temperature',

          data: [
            {
              name: COMMON_CONSTANTS.DISCHARGE_AIR_TEMPERATURE,
              data: supplyArrayFarenheit,
            },
            {
              name: COMMON_CONSTANTS.RETURN_AIR_TEMPERATURE,
              data: returnArrayFarenheit,
            },
          ],
        };

        this.advanceDiagnosticChartDataFarenheit.airStaticPressure = {
          title: 'Differential Static Pressure',
          yAxisTitle: 'Pressure',

          data: [
            {
              name: 'Differential Static Pressure',
              data: filterStaticPressureArray,
            },
          ],
        };

        this.advanceDiagnosticChartDataFarenheit.suctionLineTemperature = {
          title: 'Suction & Liquid Line Temperature',
          yAxisTitle: 'Temperature',

          data: [
            {
              name: COMMON_CONSTANTS.SUCTION_LINE_TEMPERATURE,
              data: coilAirTemperatureArrayFarenheit,
            },
            {
              name: COMMON_CONSTANTS.LIQUID_LINE_TEMPERATURE,
              data: liquidLineTemperatureFarenheit,
            },
          ],
        };

        this.advanceDiagnosticChartDataFarenheit.thermostatReads.cooling = {
          title: 'Cooling',
          yAxisTitle: 'Cooling',

          data: [
            {
              name: 'Cooling',
              data: coolingArray,
            },
          ],
        };

        this.advanceDiagnosticChartDataFarenheit.thermostatReads.fanSpeed = {
          title: 'Fan/Heat Pump',
          yAxisTitle: 'Fan/Heat Pump',

          data: [
            {
              name: 'Fan',
              data: fanSpeedArray,
            },
            {
              name: 'Heat Pump',
              data: heatPumpArray,
            },
          ],
        };

        // this.advanceDiagnosticChartDataFarenheit.thermostatReads.heatPump = {
        //   title: 'Heat Pump',
        //   yAxisTitle: 'Heat Pump',

        //   data: [
        //     {
        //       name: 'Heat Pump',
        //       data: heatPumpArray,
        //     }
        //   ]
        // };

        this.advanceDiagnosticChartDataFarenheit.thermostatReads.heatingFurnace = {
          title: 'Heating Furnace',
          yAxisTitle: 'Heating Furnace',

          data: [
            {
              name: 'Heating Furnace',
              data: heatingFurnaceArray,
            },
          ],
        };

        */

        // this.advanceDiagnosticChartDataCelcius = JSON.parse(JSON.stringify(this.advanceDiagnosticChartData));
        // this.advanceDiagnosticChartData = JSON.parse(JSON.stringify(this.advanceDiagnosticChartDataFarenheit));
        // return this.advanceDiagnosticChartDataFarenheit;
        return {
          temperature: this.temperature.fahrenheitData,
          diffStaticPressure: this.diffStaticPressure.waterColumnData,
          thermostatReads: this.thermostatReads,
          plotBandsData: [],
         
        };
      }

      // if (flag === 'alert-discovery') {
      //   this.alertDiscoveryChartDataCelcius.temperature = {
      //     title: 'Air Temperature',
      //     yAxisTitle: 'Temperature',
      //     data: [
      //       {
      //         name: 'Supply',
      //         data: supplyArray,
      //       },
      //     ],
      //   };

      //   // this.alertDiscoveryChartDataCelcius.pressure = {
      //   //   title: 'Supply/Return Static Pressure Difference',
      //   //   yAxisTitle: 'Pressure',

      //   //   data: [
      //   //     {
      //   //       name: 'Supply/Return Static Pressure Difference',
      //   //       data: filterStaticPressureArray,
      //   //     },
      //   //   ],
      //   // };

      //   this.temperature.fahrenheitData = {
      //     title: 'Air Temperature',
      //     yAxisTitle: 'Temperature',

      //     data: [
      //       {
      //         name: 'Supply',
      //         data: supplyArrayFarenheit,
      //       },
      //     ],
      //   };

      //   // this.alertDiscoveryChartDataFahrenheit.pressure = {
      //   //   title: 'Supply/Return Static Pressure Difference',
      //   //   yAxisTitle: 'Pressure',

      //   //   data: [
      //   //     {
      //   //       name: 'Supply/Return Static Pressure Difference',
      //   //       data: filterStaticPressureArray,
      //   //     },
      //   //   ],
      //   // };

      //   // this.alertDiscoveryChartDataCelcius = JSON.parse(JSON.stringify(this.alertDiscoveryChartData));
      //   // this.alertDiscoveryChartData = JSON.parse(JSON.stringify(this.alertDiscoveryChartDataFahrenheit));
      //   return this.alertDiscoveryChartDataFahrenheit;
      // }
    }

    // return [
    //   this.advanceDiagnosticChartDataFarenheit,
    //   this.alertDiscoveryChartDataFahrenheit
    // ];
  }

  handleHVACCycleData(response: HVACCycleResponseDetails[], unitType: string) {
     //debugger
    var avgDeltaTFahrenheitValues = [];
    var avgDeltaTCelciusValues = [];
    var dailyRunTimeFahrenheitValues = [];
    var dailyRunTimeCelciusValues = [];
    var categoriesValues = [];
    var dailyRunCyclesFahrenheitValues = [];
    var dailyRunCyclesCelciusValues = [];
    var avgCycleRunTimeFahrenheitValues = [];
    var avgCycleRunTimeCelciusValues = [];

    response && response.length && response.map((value: HVACCycleResponseDetails, index) => {
      // const date = new Date(value.timeStamp);

      let dateInUTC: any = moment(value.timeStamp).toDate();

      // let dateInUTC = Date.UTC(
      //   new Date(a).getFullYear(),
      //   new Date(a).getMonth(),
      //   new Date(a).getDate(),
      //   // new Date(date).getHours(),
      //   // new Date(date).getMinutes(),
      //   // new Date(date).getSeconds()
      // );

      // debugger

      dateInUTC = new Date(dateInUTC).setHours(0, 0, 0, 0);
      avgDeltaTFahrenheitValues.push([
        dateInUTC,
        value.avgTempSplitInFahrenheit!=null?parseFloat(value.avgTempSplitInFahrenheit.toFixed(0)):value.avgTempSplitInFahrenheit
      ]);
      console.log(avgDeltaTFahrenheitValues)
      avgDeltaTCelciusValues.push([
        dateInUTC,
        value.avgTempSplitInCelcius!=null?parseFloat(value.avgTempSplitInCelcius.toFixed(0)):value.avgTempSplitInCelcius
      ]);

      dailyRunTimeFahrenheitValues.push({
        y: value.totalRunTimeInHrs,
        t: value.outsideTempInFahrenheit!=null?value.outsideTempInFahrenheit.toFixed(0) + '°F':value.outsideTempInFahrenheit+'°F'
      });

      dailyRunTimeCelciusValues.push({
        y: value.totalRunTimeInHrs,
        t: value.outsideTempInCelcius!=null?value.outsideTempInCelcius.toFixed(0) + '°C':value.outsideTempInCelcius+'°C'
      });

      categoriesValues.push(
        dateInUTC
      );

      dailyRunCyclesFahrenheitValues.push({
        y: value.totalRunCycles,
        t: value.outsideTempInFahrenheit!=null?value.outsideTempInFahrenheit.toFixed(0) + '°F':value.outsideTempInFahrenheit + '°F'
      });

      dailyRunCyclesCelciusValues.push({
        y: value.totalRunCycles,
        t: value.outsideTempInCelcius!=null?value.outsideTempInCelcius.toFixed(0) + '°C':value.outsideTempInCelcius+ '°C'
      });

      avgCycleRunTimeFahrenheitValues.push({
        y: value.avgCycleRunTimeInMin,
        t: value.outsideTempInFahrenheit!=null?value.outsideTempInFahrenheit.toFixed(0) + '°F':value.outsideTempInFahrenheit+ '°F'
      });

      avgCycleRunTimeCelciusValues.push({
        y: value.avgCycleRunTimeInMin,
        t: value.outsideTempInCelcius!=null?value.outsideTempInCelcius.toFixed(0) + '°C':value.outsideTempInCelcius + '°C'
      });

    });

    this.HVACCycleDetails.fahrenheitData = {
      avgDeltaT: {
        unit: COMMON_CONSTANTS.FAHRENHEIT,
        title: 'Temperature',
        yAxisTitle: 'Max  ∆T',
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,

        data: [
          {
            name: 'Max  ∆T',
            data: avgDeltaTFahrenheitValues,
            color: COMMON_CONSTANTS.AVG_DELTA_T,
            marker: {
              fillColor: '#FFFFFF'
            },
          }
        ],
      },

      dailyRunTime: {
        unit: COMMON_CONSTANTS.FAHRENHEIT,
        title: 'Daily Runtime',
        yAxisTitle: 'Hours',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Hour(s)',
            data: dailyRunTimeFahrenheitValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      },

      dailyRunCycles: {
        unit: COMMON_CONSTANTS.FAHRENHEIT,
        title: 'Run Cycles',
        yAxisTitle: 'Run Cycles',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Daily Run Cycles',
            data: dailyRunCyclesFahrenheitValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      },

      avgCycleRunTime: {
        unit: COMMON_CONSTANTS.FAHRENHEIT,
        title: 'Cycle Runtime',
        yAxisTitle: 'Minutes',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Avg Cycle Runtime',
            data: avgCycleRunTimeFahrenheitValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      }


      //       series: [{
      //         data: [
      //           { y: 29.9, t: "12:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" },
      //           { y: 71.5, t: "14:45" }
      //         ]
      //       },
      //       {
      //         name: `Avg <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      // <path d="M1.32513 9.75L6.00049 1.95774L10.6758 9.75H1.32513Z" stroke="#000000" stroke-width="1.5"/></svg>`,
      //         color: '#71CECA',
      //         /* visible: false,*/
      //         /* data: [
      //           {y:29.9,t:"12:45"}, 
      //           {y:71.5,t:"14:45"}
      //         ] */
      //       }
      //       ]
    }

    this.HVACCycleDetails.celciusData = {

      avgDeltaT: {
        unit: COMMON_CONSTANTS.CELCIUS,
        title: 'Temperature',
        yAxisTitle: 'Max  ∆T',
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,

        data: [
          {
            name: 'Max  ∆T',
            data: avgDeltaTCelciusValues,
            color: COMMON_CONSTANTS.AVG_DELTA_T,
            marker: {
              fillColor: '#FFFFFF'
            },
          }
        ],
      },

      dailyRunTime: {
        unit: COMMON_CONSTANTS.CELCIUS,
        title: 'Daily Runtime',
        yAxisTitle: 'Hours',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Hours',
            data: dailyRunTimeCelciusValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      },

      dailyRunCycles: {
        unit: COMMON_CONSTANTS.CELCIUS,
        title: 'Run Cycles',
        yAxisTitle: 'Run Cycles',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Daily Run Cycles',
            data: dailyRunCyclesCelciusValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      },

      avgCycleRunTime: {
        unit: COMMON_CONSTANTS.CELCIUS,
        title: 'Cycle Runtime',
        yAxisTitle: 'Minutes',
        categoriesData: categoriesValues,
        chartUnitType: CHART_UNIT_TYPES.TEMPERATURE,
        data: [
          {
            name: 'Avg Cycle Runtime',
            data: avgCycleRunTimeCelciusValues,
            color:'#00B7A5'
          },
          {
            name: 'Avg Ambient Temp',
            color: '#FFC06A',
          }
        ],
      }

    }

    // return this.HVACCycleDetails;
    return this.handleReturnHVACCycleData(unitType);
  }

  handleReturnHVACCycleData(unitType: string) {
    // debugger
    if (unitType === COMMON_CONSTANTS.FAHRENHEIT) {
      return this.HVACCycleDetails.fahrenheitData;
    } else {
      return this.HVACCycleDetails.celciusData;
    }
  }

  getThresholdData(value, name,type) {
    let obj = {
      value: value,
      color: '#808080',
      width: 2,
      zIndex: 4,
      dashStyle: 'ShortDash',
      label: {
        text: value + type+' ' + name,
        style: {
          color: 'rgba(255,255,255,0.85)',
          fontFamily: 'SourceSansPro-Regular',
          fontSize: '1.2rem',
        }
      },
    }
    return obj;
  }
  handleAlertDiscoveryChartData(responsedata, flag) {
    console.log(responsedata);
    const response = responsedata.alertDetails;
    const invalidresponse = responsedata.inActiveValues;
    if (
      response.alerts &&
      response.alerts.length
    ) {
      const DataArray = [];
      let DataObject = {};
      //const DataArrayFarenheit = [];
      //const DataArrayPascal = [];

      const ThresholdArray = [];
      const ThresholdArrayFarenheit = [];
      const ThresholdArrayPascal = [];

      const bondsArray = [];
      const DataTimeArray = [];
      // check alert code type
      // const codetype = response.alertCode;
      const alertcodetype = response.convertParam;

      // console.log(alertcodetype);
      response.alerts.map((value, indedx) => {
        const date = new Date(value.timestamp);
        const dateInUTC = Date.UTC(
          new Date(date).getFullYear(),
          new Date(date).getMonth(),
          new Date(date).getDate(),
          new Date(date).getHours(),
          new Date(date).getMinutes(),
          new Date(date).getSeconds()
        );
        DataTimeArray.push(dateInUTC);
        let obj = {};
        obj['x'] = dateInUTC;
        obj['y'] = value.delta;
        obj['z'] = value.outsideTemperature;
        if (alertcodetype == 'PRESSURE') {
          obj['supply'] = value.supplyPressure;
          obj['return'] = value.returnPressure;
        }
        obj['color']=value.delta==0.3?response.shortCycleOffColor:''
        DataArray.push(obj);
        // DataObject = {
        //   data: DataArray,
        //   keys: ['x', 'y', 'z']
        // }
        // DataArrayFarenheit.push([dateInUTC, value.delta != null ? (this.farenheitConvertion(value.delta)) : value.delta]);
        //DataArrayPascal.push([dateInUTC, value.delta != null ? value.delta * 249 : value.delta]);

      });

      // invalid data format
      invalidresponse.map((value) => {
        const fromtime = this.timeConversion(value.startTime);
        const totime = this.timeConversion(value.endTime);
        bondsArray.push({
          color: value.color,
          from: fromtime,
          to: totime,

          // zIndex: 5,
        });
      });

      /*for threshold value */
      if (response.threshold) {
        if (response.convertParam == 'TEMPERATURE' && (responsedata.deltaUnit == "null" || responsedata.deltaUnit == 'fahrenheit')) {
          response.threshold.fahrenheit && response.threshold.fahrenheit.map((value, index) => {
            let fahrenheitThreshold = this.getThresholdData(value.value, value.name, ' °F')
            ThresholdArray.push(fahrenheitThreshold);
          });
        }
        else {
          response.threshold.celsius && response.threshold.celsius.map((value, index) => {
            // for wc and celsius type
            let celsiusThreshold = this.getThresholdData(value.value, value.name,' °C')
            ThresholdArray.push(celsiusThreshold);
          });

        }
        if (response.convertParam == 'PRESSURE' && (responsedata.deltaUnit == "null" || responsedata.deltaUnit == 'wc')) {
          response.threshold.wc && response.threshold.wc.map((value, index) => {
            // for wc and celsius type
            let wcThreshold = this.getThresholdData(value.value, value.name,' "WC')
            ThresholdArray.push(wcThreshold);
          });
        }
        else {
          response.threshold.pascal && response.threshold.pascal.map((value, index) => {
            let pascalThreshold = this.getThresholdData(value.value, value.name,' Pa')
            ThresholdArray.push(pascalThreshold);
          });

        }




      }

      this.alertDiscoveryChartData.chartcommondata = {
        title: response.title,
        yAxisTitle: response.isDelta ? deltaSVG.DELTA + ' ' + response.yAxis : response.yAxis,
        xAxisTitle: response.xAxis,
        xdata: DataTimeArray,
        color: response.color,
        charttype: response.graphType,
        fromtime: response.alerts[0].timestamp,
        totime:
          response.alerts[
            response.alerts.length - 1
          ].timestamp,
        serialno: response.pb,
        emptydata: '',
        convertparam: response.convertParam,
        alertCode:response.alertCode

      };

      // alert occured time convertion
      const alertoccuredtime = this.timeConversion(response.notch.x);
      const alertoccuredvalue = response.notch.y;

      this.alertDiscoveryChartData.chartdata = {
        threshold: ThresholdArray,
        data: DataArray,
        alerttime: alertoccuredtime,
        alertvalue: alertoccuredvalue ? alertoccuredvalue : 0,
        bonds: bondsArray,

      };

      if (response.convertParam == 'TEMPERATURE') {

        (responsedata.deltaUnit == "null" || responsedata.deltaUnit == 'fahrenheit') ?
          this.alertDiscoveryChartData.unit = '°F' : this.alertDiscoveryChartData.unit = '°C';
      }
      else if (response.convertParam == 'PRESSURE') {
        (responsedata.deltaUnit == "null" || responsedata.deltaUnit == 'wc') ?
          this.alertDiscoveryChartData.unit = '"WC' : this.alertDiscoveryChartData.unit = 'Pa'

      } else if (response.convertParam == '' || response.convertParam == null) {
        this.alertDiscoveryChartData.unit = ''
      }





      // this.alertDiscoveryChartData.chartcommondata = this.alertDiscoveryChartDataCelcius.chartcommondata =
      //   this.alertDiscoveryChartDataFahrenheit.chartcommondata =
      //   this.alertDiscoveryChartDataPascal.chartcommondata =
      //   this.alertDiscoveryChartDataWc.chartcommondata =
      //   this.alertDiscoveryChartDataFan.chartcommondata = {
      //     title: response.title,
      //     yAxisTitle: response.isDelta ? deltaSVG.DELTA + ' ' + response.yAxis : response.yAxis,
      //     xAxisTitle: response.xAxis,
      //     xdata: DataTimeArray,
      //     color: response.color,
      //     charttype: response.graphType,
      //     fromtime: response.alerts[0].timestamp,
      //     totime:
      //       response.alerts[
      //         response.alerts.length - 1
      //       ].timestamp,
      //     serialno: response.pb,
      //     emptydata: '',
      //     convertparam: response.convertParam

      //   };

      //  this.alertDiscoveryChartDataCelcius.chartdata = this.alertDiscoveryChartDataWc.chartdata =
      // this.alertDiscoveryChartDataFan.chartdata = {
      //   threshold: ThresholdArray,
      //   data: DataArray,
      //   alerttime: alertoccuredtime,
      //   alertvalue: alertoccuredvalue ? alertoccuredvalue : 0,
      //   bonds: bondsArray,
      // };

      // this.alertDiscoveryChartDataFahrenheit.chartdata = {
      //   threshold: ThresholdArrayFarenheit,
      //   data: DataArrayFarenheit,
      //   alerttime: alertoccuredtime,
      //   alertvalue: alertoccuredvalue ? (this.farenheitConvertion(alertoccuredvalue)) : 0,
      //   bonds: bondsArray,
      // };
      // this.alertDiscoveryChartDataPascal.chartdata = {
      //   threshold: ThresholdArrayPascal,
      //   data: DataArrayPascal,
      //   alerttime: alertoccuredtime,
      //   alertvalue: alertoccuredvalue ? (alertoccuredvalue * 249) : 0,
      //   bonds: bondsArray,
      // };
      // if (alertcodetype === 'p' || alertcodetype === 'P') {
      //   return this.alertDiscoveryChartDataWc;
      // }
      // else
      // if (response.convertParam === 'TEMPERATURE') {
      //   return this.alertDiscoveryChartDataCelcius;
      // } else if (response.convertParam === 'PRESSURE') {
      //   return this.alertDiscoveryChartDataWc;
      // } else {
      //   return this.alertDiscoveryChartDataFan;
      // }
      return this.alertDiscoveryChartData;

    }
  }

  // timeconvertion
  timeConversion(timevalue) {
    const time = new Date(timevalue);
    const timeUTC = Date.UTC(
      new Date(time).getFullYear(),
      new Date(time).getMonth(),
      new Date(time).getDate(),
      new Date(time).getHours(),
      new Date(time).getMinutes(),
      new Date(time).getSeconds()
    );
    return timeUTC;
  }
  handleReturnChartData(flag: string) {
    const temperature = this.temperature;
    const thermostatReads = this.thermostatReads;
    const diffStaticPressure = this.diffStaticPressure;
    const plotBandsData = [];
    return {
      temperature,
      thermostatReads,
      diffStaticPressure,
      plotBandsData,
    };
  }

  handleReturnAlertDiscoverChartData(flag: string, type: string) {
    console.log(flag, type);
    if (flag === COMMON_CONSTANTS.FAHRENHEIT && type === 'temperature') {
      return this.alertDiscoveryChartDataFahrenheit;
    } else if (flag === COMMON_CONSTANTS.CELCIUS && type === 'temperature') {
      return this.alertDiscoveryChartDataCelcius;
    } else if (flag === COMMON_CONSTANTS.WATER_COLUMN && type === 'pressure') {
      return this.alertDiscoveryChartDataWc;
    } else if (flag === COMMON_CONSTANTS.PASCAL && type === 'pressure') {
      return this.alertDiscoveryChartDataPascal;
    }

  }


  //setalertnotifcation
  setListnerForAlertNotification() {
    return this.listenerForAlertChange.asObservable();
  }

  setAlertNotification(data) {

    this.listenerForAlertChange.next(data);

  }


  setUnassignBoard(value: string) {
    this.listenerForUnassignBoard.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

}
